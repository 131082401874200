import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  org: null,
  orgIsLoading: false,
  parentOrg: null,
  districtsList: [],
  districtSelected: null,
  districtsListIsLoading: false,
  schoolsList: [],
  schoolSelected: null,
  schoolsListIsLoading: false,
  something: 0,
};

const updateSomething = (state, action) => {
  return update(state, {
    something: { $set: action.value },
  });
};

// const schoolsListGetSuccess = (state, action) => {
//   return update(state, {
//     schoolsList: { $set : action.value}
//   })
// }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SOMETHING_UPDATE':
      return updateSomething(state, action);
    case 'SCHOOLS_LIST_GET_START':
      return update(state, {
        schoolsListIsLoading: { $set: true },
      });
    case 'SCHOOLS_LIST_GET_SUCCESS':
      return update(state, {
        schoolsList: { $set: action.value },
        schoolsListIsLoading: { $set: false },
      });
    case 'SCHOOL_SELECTED_SET':
      return update(state, {
        schoolSelected: { $set: action.value },
      });
    case 'ORG_CHANGE_START':
      return update(state, {
        orgIsLoading: { $set: true },
      });
    case 'ORG_CHANGE_SUCCESS':
      return update(state, {
        orgIsLoading: { $set: false },
        org: { $set: action.value },
      });
    case 'DISTRICTS_LIST_GET_START':
      return update(state, {
        districtsListIsLoading: { $set: true },
      });
    case 'DISTRICTS_LIST_GET_SUCCESS':
      return update(state, {
        districtsList: { $set: action.value },
        districtsListIsLoading: { $set: false },
      });
    case 'DISTRICT_SELECTED_SET':
      return update(state, {
        districtSelected: { $set: action.value },
      });
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
