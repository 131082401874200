import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Papa from 'papaparse';
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/Spinner/Spinner';
import FieldPicker from '../../SurveyAddParticipant/FieldPicker';

const StudentRosterUpdate = props => {
  const [state, setState] = useState({
    cleverFormattedRoster: undefined,
    intervalId: null,
    fileName: null,
    error: null,
    isWaiting: false,
    inputAddParticipantMethod: undefined,
    participants: [],
    csvProcessSummary: {},
    csvParsedResults: undefined,
    csvHeaderMap: undefined,
    readyToSubmit: false,
    cleverSelectedGrades: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state.csvProcessSummary.htmlTargetFile)
      Papa.parse(state.csvProcessSummary.htmlTargetFile, {
        header: true,
        skipEmptyLines: true,
        preview: 5,
        complete: showFieldPicker,
      });
  }, [state.csvProcessSummary.htmlTargetFile]);

  const requiredFields = [
    {
      key: 'firstName',
      label: 'First Name',
      description: 'First name of participant',
    },
    {
      key: 'lastName',
      label: 'Last Name',
      description: 'Last name of participant',
    },
    {
      key: 'birthDate',
      label: 'Date of birth',
      description: 'Date of birth of participant',
    },
    { key: 'gender', label: 'Gender', description: 'Gender of participant' },
  ];
  const optionalFields = [
    { key: 'middleName', label: 'Middle Name', description: '' },
    { key: 'grade', label: 'Grade', description: '' },
    { key: 'studentId', label: 'Student ID', description: '' },
    { key: 'parentEmail', label: 'Parent Email', description: '' },
    { key: 'participantEmail', label: 'Participant Email', description: '' },
  ];
  const allValidFields = [
    ...requiredFields.map(f => f.key),
    ...optionalFields.map(f => f.key),
  ];
  const templateFields = [
    'firstName',
    'middleName',
    'lastName',
    'birthDate',
    'gender',
    'grade',
    'studentId',
    'groupName',
    'notifyEmail',
    'notifyMobile',
    'participantEmail',
  ];

  const validateParticipant = item => {
    const allRequiredPresent = requiredFields.every(rf => item[rf.key] !== '');
    const validParticipant = Object.fromEntries(
      Object.entries(item).filter(([k]) => allValidFields.includes(k)),
    );
    return allRequiredPresent ? validParticipant : false;
  };

  const formatRosterPerGrade = roster => {
    const formattedRoster = { total: roster.length, perGrade: {} };
    roster.forEach(student => {
      const grade = student.grade || 'Unknown';
      !formattedRoster.perGrade.hasOwnProperty(grade) &&
        (formattedRoster.perGrade[grade] = []);
      formattedRoster.perGrade[grade].push(student);
    });
    return formattedRoster;
  };

  // Field Picker
  const showFieldPicker = results => {
    // first verify if this is using TM's template by checking all fields
    if (
      results.meta.fields.every(csvFieldName =>
        templateFields.includes(csvFieldName),
      )
    ) {
      // proceed with the default mapping
      validateFields({
        notifyEmail: 'parentEmail',
      });
    } else {
      setState(prevState => ({
        ...prevState,
        csvParsedResults: results,
        csvProcessSummary: {
          ...prevState.csvProcessSummary,
          status: 'fieldPicker',
        },
      }));
    }
  };

  const validateFields = headerMap => {
    Papa.parse(state.csvProcessSummary.htmlTargetFile, {
      header: true,
      skipEmptyLines: true,
      complete: processPapaResults,
      transformHeader: h => {
        return headerMap.hasOwnProperty(h) && headerMap[h] !== null
          ? headerMap[h]
          : h;
      },
    });
  };

  const cancelFieldPicker = () => {
    setState(prevState => ({
      ...prevState,
      csvProcessSummary: {},
      csvParsedResults: undefined,
      csvHeaderMap: undefined,
    }));
  };

  // File Upload Method
  const processPapaResults = results => {
    const validRosterEntries = [];
    const processSummary = {
      csvRosterPerGrade: [],
      invalidRows: [],
      comment: [],
    };

    // check if some fields are invalid
    if (results.errors.length > 0) {
      processSummary['status'] = 'error';
      processSummary['error'] = results.errors
        .map(
          e =>
            `Row: ${e.row}, type:${e.type}, code: ${e.code}, message: ${e.message}`,
        )
        .join('\n');
    } else {
      processSummary['status'] = 'success';
      if (!results.meta.fields.every(f => allValidFields.includes(f))) {
        processSummary['comment'].push('Some fields will be ignored');
      }
      results.data.forEach((parsedParticipant, rowIndex) => {
        const validParticipant = validateParticipant(parsedParticipant);
        if (!validParticipant) {
          processSummary.invalidRows.push(rowIndex);
        } else {
          validRosterEntries.push(validParticipant);

          setState(prevState => ({
            ...prevState,
            // participants: [parsedParticipant]
          }));
        }
      });
      if (processSummary.invalidRows.length > 0) {
        processSummary['comment'].push(
          'These rows have invalid data: ' +
            processSummary.invalidRows.join(','),
        );
      }
    }
    processSummary.csvRosterPerGrade = formatRosterPerGrade(validRosterEntries);
    processSummary.validRosterEntriesCount = validRosterEntries.length;
    setState(prevState => ({
      ...prevState,
      csvProcessSummary: { ...prevState.csvProcessSummary, ...processSummary },
    }));
  };

  const handleFileChange = event => {
    const ROSTER_MAX_SIZE = 300000; // (300kB)
    if (event.target.files[0].size > ROSTER_MAX_SIZE) {
      setState(prevState => ({
        ...prevState,
        csvProcessSummary: {
          ...prevState.csvProcessSummary,
          status: 'error',
          error: 'File is too large',
        },
      }));
      return;
    }
    const filename = event.target.files[0].name;
    const htmlTargetFile = event.target.files[0];
    setState(prevState => ({
      ...prevState,
      csvProcessSummary: { filename, htmlTargetFile },
    }));
  };

  const cleverGradeCheckboxChange = event => {
    const newSelectedGrades = state.cleverSelectedGrades;
    const index = newSelectedGrades.indexOf(event.target.value);
    if (event.target.checked) {
      index > -1 || newSelectedGrades.push(event.target.value);
    } else {
      index > -1 && newSelectedGrades.splice(index, 1);
    }
    let participants = [];
    newSelectedGrades.forEach(grade => {
      participants = [
        ...participants,
        ...(state.inputAddParticipantMethod === 'addParticipantClever'
          ? state.cleverFormattedRoster.perGrade[grade]
          : state.csvProcessSummary.csvRosterPerGrade.perGrade[grade]),
      ];
    });
    setState(prevState => ({
      ...prevState,
      cleverSelectedGrades: newSelectedGrades,
      participants: participants,
    }));
  };

  const GradeSelector = ({
    perGradeRoster,
    selectedGrades,
    onCheckboxChange,
  }) => {
    const checkBoxes = Object.keys(perGradeRoster.perGrade).map(grade => (
      <div key={grade} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={selectedGrades.includes(grade)}
          onChange={onCheckboxChange}
          value={grade}
          id={`gradeSelectorCheckbox-${grade}`}
        />
        <label
          className="form-check-label"
          htmlFor={`gradeSelectorCheckbox-${grade}`}
        >
          Grade {grade} ({perGradeRoster.perGrade[grade].length} students)
        </label>
      </div>
    ));
    return checkBoxes;
  };
  const handleCSVSubmit = () => {
    setIsLoading(true);
    const parsedParticipants = state.participants;
    props.onSubmit(parsedParticipants);
  };
  return (
    <div className="file-input-create-survey-step-group">
      <div className="input-group-student-roster">
        {!state.csvProcessSummary.status ||
        !state.csvProcessSummary.status === 'error' ? (
          <>
            <p>
              To upload a roster, you may use{' '}
              <a href="https://landing-page-links.s3.amazonaws.com/ParticipantRosterExample.csv">
                our CSV template
              </a>{' '}
              or use your own CSV file.
            </p>

            <div className="yellow">
              <p>
                Click the button below to select a CSV file on your computer.
              </p>
            </div>
            <input
              type="file"
              name="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: 'block', margin: '10px auto' }}
            />
            {state.csvProcessSummary?.status === 'error' &&
              state.csvProcessSummary.error && (
                <>
                  <div className="text-danger">
                    {state.csvProcessSummary.error}
                  </div>
                </>
              )}
          </>
        ) : null}
        {state.csvProcessSummary.status === 'success' && (
          <>
            <p>
              File <code>{state.csvProcessSummary.filename}</code> has been
              processed successfully:{' '}
              {state.csvProcessSummary.validRosterEntriesCount} participants are
              ready to be added { state.csvProcessSummary.invalidRows.length ? <>({state.csvProcessSummary.invalidRows.length}{' '}
                invalid entries were ignored)</> : null }
            </p>
            <div className="yellow bold">
              Select one or more grades to add to the assessment.
            </div>
            <GradeSelector
              perGradeRoster={state.csvProcessSummary.csvRosterPerGrade}
              selectedGrades={state.cleverSelectedGrades}
              onCheckboxChange={cleverGradeCheckboxChange}
            />
            <Button
              type="submit"
              className="small-button mt-4"
              onClick={handleCSVSubmit}
              disabled={state.participants.length == 0}
            >
              {isLoading && <Spinner />} Add{' '}
              {state.participants.length > 0 ? state.participants.length : null}{' '}
              participants
            </Button>
          </>
        )}
        {state.csvProcessSummary.status === 'fieldPicker' && (
          <FieldPicker
            csvParsedResults={state.csvParsedResults}
            requiredFields={requiredFields}
            optionalFields={optionalFields}
            validateHeaderMap={validateFields}
            cancel={cancelFieldPicker}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
    // user: state.auth.isAuthenticated,
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSignedUrl: file => dispatch(actions.getSignedUrl(file)),
    uploadRoster: file => dispatch(actions.uploadRoster(file)),
    checkRosterUploadStatus: () => dispatch(actions.checkRosterUploadStatus()),
    addToManualRosterLength: () => dispatch(actions.addToManualRosterLength()),
    createSurvey: (participants, cb) =>
      dispatch(actions.createSurvey(participants, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentRosterUpdate);
