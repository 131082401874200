import update from 'immutability-helper';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  playingAudio: false,
};

const playPause = (state, action) => {
  return update(state, {
    playingAudio: { $set: action.playingAudio },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PLAY_PAUSE:
      return playPause(state, action);
    default:
      return state;
  }
};

export default reducer;
