import React from 'react';
import { connect } from 'react-redux';

const CreateSchoolFormWrapper = ({
  error,
  children
}) => {
  return (
    <>
      <div>
        {typeof error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{error}</span> : null}
      </div>
      {children}
    </>
  );
}

const mapStateToProps = state => {
  return {
    error: state.organizations.error,
  };
};

export default connect(
  mapStateToProps
)(CreateSchoolFormWrapper);
