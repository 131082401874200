import React from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  // useHistory
} from 'react-router-dom';
import * as actions from '../../store/actions';
// import { 
  // Breadcrumb, BreadcrumbItem, 
  // Button } from 'reactstrap';
// import ListOrganizations from '../../containers/Admin/Organizations/ListOrganizations/ListOrganizations';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import userRole from '../../consts/roles';

const Breadcrumbs = ({
  // breadcrumbs,
  // resetOrganization,
  // resetRole,
  role,
  bc_orgName,
  // bc_higherOrgName,
  realRole,
}) => {
  // const history = useHistory();
  // const isUK = process.env.REACT_APP_STAGE === 'uk';

  // const resetRoleAndOrg = () => {
  //   resetOrganization();
  //   resetRole();

  //   if (realRole == userRole.TM_ADMIN) {
  //     history.push('/admin/tm');
  //   }
  //   if (realRole == userRole.DISTRICT_CHAMPION) {
  //     history.push(`/admin/${userRole.DISTRICT_CHAMPION}`);
  //   }
  //   if (realRole == userRole.SCHOOL_CHAMPION) {
  //     history.push('/admin');
  //   }
  // };

  return (
    <div>
      {bc_orgName && <div className="auth flex">
        {role !== realRole ? (
          <div >
            {/* <Button className="button-back" onClick={resetRoleAndOrg}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Return to{' '}
              {realRole === 'tm_admin'
                ? `${isUK ? 'Admin' : 'Terrace Admin'}`
                : `${isUK ? 'Group' : 'Organization Champion'}`}{' '}
              Home
            </Button> */}
          </div>
        ) : null}
      </div>}

      {/* <div className='flex'>
        <Breadcrumb className='breadcrumb'>
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem
              active={breadcrumbs.length - 1 === index}
              key={breadcrumb.key}
              className='breadcrumb-item'
            >
              {breadcrumbs.length - 1 === index ?
                <span>
                  {breadcrumb.label}
                </span>
                :
                <a href={breadcrumb.url}>
                  {breadcrumb.label}
                </a>}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </div> */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    realRole: state.auth.realRole,
    bc_orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgId: state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    bc_higherOrgName: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.name,
    bc_higherOrgId: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.orgId,
    realOrganization: state.organizations.realOrganization,
    organization: state.organizations.organization
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetRole: () => dispatch(actions.resetRole()),
    resetOrganization: () => dispatch(actions.resetOrganization()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Breadcrumbs));
