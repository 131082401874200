import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/Spinner/Spinner';

class NotifyParentModal extends Component {
  state = { submitting: false };
  handleSubmit = () => {
    this.setState({ submitting: true });
    const participant = this.props.item;
    const values = {
      participant,
      surveyId: this.props.surveyId,
    };
    this.props.notifyParentReport(values, this.props.surveyId);
  };

  render() {
    return (
      <ModalWrapper className="survey-status-modal-wrapper" show={this.props.show} onExited={this.props.closeModal}>
        <ModalHeader toggle={this.props.closeModal}>Report Notification</ModalHeader>
        <ModalBody bsPrefix="modalContent survey-status-modal">
          Are you certain you would like to email or text this report link? <br />
          <strong>This action can not be undone.</strong>
          <div className="btnContainer-edit-participant">
            <Button onClick={() => this.handleSubmit()} type="submit" className="modalBtn" size="md" color="success" disabled={this.state.submitting}>
              {this.state.submitting && <Spinner />} Send Notification
            </Button>
            <Button onClick={this.props.closeModal} className="modalBtn" size="md" variant="outline-primary">
              Cancel
            </Button>
          </div>
        </ModalBody>
      </ModalWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    notifyParentReport: (participantData, surveyId) => dispatch(actions.notifyParentReport(participantData, surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(null, mapDispatchToProps)(NotifyParentModal);
