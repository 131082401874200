import React, { useState } from 'react';
import { connect } from 'react-redux';
import CreateSurveyStep from '../CreateSurveyStep/CreateSurveyStep';
import IndicatorSelectForm from '../IndicatorSelectForm/IndicatorSelectForm';
import SurveyDateSelect from '../SurveyDateSelect/SurveyDateSelect';
import SurveyNameSelect from '../SurveyNameSelect/SurveyNameSelect';
// import StudentRosterUpload from '../StudentRosterUpload/StudentRosterUpload';
import RemoteSubmitButton from '../RemoteSubmitButton/RemoteSubmitButton';
import DesignatedEmailRecipient from '../DesignatedEmailRecipient/DesignatedEmailRecipient';
import WellnessCenterCheck from '../WellnessCenterCheck/WellnessCenterCheck';
import CategorySelector from '../IndicatorSelectForm/CategorySelector';
import { Button } from 'reactstrap';
import OverlayExistingAssessments from './OverlayExistingAssessments';
import OrderRandomizationStep from './OrderRandomizationStep/OrderRandomizationStep';


const CreateSurveyStepGroup = (props) => {
  const state = {
    isWaiting: false,
  };

  const [showExistingAsssessments, setShowExistingAssessments] = useState(false);

  let surveyValid = (() => {
    const surveyObj = props.surveyCrud.createSurveyObj;
    let result = (surveyObj.chosenIndicators.length > 0) && surveyObj.surveyName !== "" && surveyObj.year !== "" && surveyObj.quarter !== "";
    if (surveyObj.appliedTo === 'adult') result = result && surveyObj.profession;
    return result;
  })();


  const handleOnClick = () => {
    setShowExistingAssessments(true)
  }
  const depressionDomainSelected = props.surveyCrud.createSurveyObj.chosenIndicators.some(indicatorId => props.indicators.find( i => i.indicatorId === indicatorId).domainId === 'depression');
  const designatedRecipientText = <>Designated Email Recipients { depressionDomainSelected && <strong>(REQUIRED since depression indicator is selected)</strong>}</>;

  return (
    <>
      <CreateSurveyStep
        number="1"
        header="Assessment Information"
        subhead={"All fields are required."}
      >
        <div className='d-flex'>
          <SurveyNameSelect
            quarter={props.surveyCrud.createSurveyObj.quarter}
            year={props.surveyCrud.createSurveyObj.year}
            orgName={props.orgName}
            appliedTo={props.surveyCrud.createSurveyObj.appliedTo}
            indicatorIdsByCategory={props.surveyCrud.indicatorIdsByCategory}
            chosenIndicators={props.surveyCrud.createSurveyObj.chosenIndicators}
          />
          <SurveyDateSelect appliedTo={props.surveyCrud.createSurveyObj.appliedTo} />
        </div>
          <CategorySelector
            primary={props.parentOrg ? props.parentOrg.primary : null}
            secondary={props.parentOrg ? props.parentOrg.secondary : null}
            adult={props.parentOrg ? props.parentOrg.adult : null}
          />
      </CreateSurveyStep>
      <CreateSurveyStep
        number="2"
        header="Indicators"
        subhead={"Select the indicators you would like to include in this assessment. You may also "}
        button={
          <Button
            onClick={handleOnClick} size='sm' className='mx-2'
          >
            Copy from Past Assessment
          </Button>}
      >
        <OverlayExistingAssessments
          showExistingAsssessments={showExistingAsssessments}
          setShowExistingAssessments={setShowExistingAssessments}
        />
        <IndicatorSelectForm
          primary={props.parentOrg ? props.parentOrg.primary : null}
          secondary={props.parentOrg ? props.parentOrg.secondary : null}
          adult={props.parentOrg ? props.parentOrg.adult : null}
        />
      </CreateSurveyStep>

      <CreateSurveyStep
        number="3"
        header="Enter designated email recipients for priority reports"
        subhead={designatedRecipientText}
      >
        <DesignatedEmailRecipient />
      </CreateSurveyStep>
      <CreateSurveyStep
        number="4"
        header={"Assessment Options"}
        subhead={"Use these options to further customize the experience of participants."}
      >
        <OrderRandomizationStep />
        {  props.surveyCrud.createSurveyObj.appliedTo === 'adult' &&  <WellnessCenterCheck /> }
      </CreateSurveyStep>
      <CreateSurveyStep
        active={surveyValid}
      >
        <RemoteSubmitButton isWaiting={state.isWaiting} createSurvey={props.createSurvey} orgId={props.orgId}/>
      </CreateSurveyStep>
    </>
  );
}

const mapStateToProps = state => {
  return {
    surveyCrud: state.surveyCrud,
    indicators: state.surveyCrud.indicators,
    formErrors: state.form['participant-entry-form'],
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg,
    createSurveyObj: state.surveyCrud.createSurveyObj,
    manualRoster: state.surveyCrud.manualRoster,
    grades: state.surveyCrud.grades,
    studentsByGrade: state.surveyCrud.studentsByGrade,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    orgId: state.organizations.schoolSearchName
  };
};

export default connect(mapStateToProps)(CreateSurveyStepGroup);
