import React from 'react';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import { Col, Container, Row } from 'reactstrap';
import logo from '../../../../assets/imgs/tm-logo-v2.png';

import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = (props) => {
  const resetPassword = (username, code, newPassword) => {
    // Collect confirmation code and new password and submit
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => {
        props.history.push('/login');
      })
      .catch(err => console.log(err));
  };

  const handleSubmit = values => {
    resetPassword(values.username, values.code, values['new-password']);
  };


  const breadcrumbs = [
    {
      key: 0,
      url: '/admin',
      label: 'Home',
    },
    {
      key: 1,
      label: 'Reset Password',
    },
  ];

  return (
  <div className="page reset-password">
      <Container className='mt-3'>
      <Row className="mb-4">
          <img style={{ width: '200px' }} src={logo} />
        </Row>
        <Row>
          <Col>
      <div className="sub-header">
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </div>
      <h1 className="mb-4">Reset Password</h1>
      <ResetPasswordForm onSubmit={handleSubmit} />
      </Col>
        </Row>
      </Container>

    </div>
  );

}

export default withRouter(ResetPassword);
