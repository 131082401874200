const sanitizePhoneNumber = (phoneNumber) => { //sanitize for storage into cognito
  let result = false;
  if(!phoneNumber)
  {
    return;
  }
  let startsWithUSCountryCode = phoneNumber.substring(0,2) === "+1";
  //if we have a US country code already, add only the '+' char that gets removed, otherwise add the default US code entirely
  if (typeof phoneNumber === "string") {
    result = `${phoneNumber.replace(/\D/g, '')}`;
    result = ((startsWithUSCountryCode) ? '+': '+1') + result;
  }
 
  return result;
};

export default sanitizePhoneNumber
