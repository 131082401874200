import React, { Component } from 'react';
import { connect } from 'react-redux';
import getSupportedLanguages from '../../utilities/getContent/supportedLanguages';
import * as actions from '../../store/actions';

class LanguagePicker extends Component {
  // This doesn't change on a render by render basis so is fine as a static value
  supportedLanguages = getSupportedLanguages();

  render() {
    return (
      <select
        className="language-picker"
        onChange={(event) => {
          this.props.setLang(event.target.value);
        }}
        value={this.props.lang}
      >
        { this.supportedLanguages.map((item) =>
            <option
              key={item.value}
              value={item.value}
            >
              {item.label}
            </option>
        )}
      </select>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLang: (newLang) => dispatch(actions.setLang(newLang)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguagePicker);
