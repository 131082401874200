import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

const wellnesscenterAuthLayout = (WrappedComponent) => {
  const WellnessCenterLayout = (props) => {
    return (
      props.loggedIn ?
        <WrappedComponent  {...props}  />
        :
        <Redirect to="/wellness-center" />

    );
  }

  const mapStateToProps = state => {
    return {
      lang: state.meta.lang,
      loggedIn: state.wellnessCenterAuth.loggedIn,
    };
  };
  return connect(mapStateToProps)(WellnessCenterLayout);
};
export default wellnesscenterAuthLayout;
