import React from 'react';
import { getContent } from '../../../../../utilities/getContent/getContent';
import { Label, Input, FormGroup } from 'reactstrap';
import * as actions from '../../../../../store/actions';
import { connect } from 'react-redux';

const CategorySelector = props => {
  const appliedTo = props.surveyCrud?.createSurveyObj?.appliedTo;

  if (appliedTo === 'adult') {
    props.surveyCrud.professions.forEach((profession, i) => {
      props.surveyCrud.professions[i]['professionLabel'] = getContent(profession.professionId) || '';
    });
  }

  const handleChange = value => {
    props.setAppliedTo(value);
  };

  return (
    <div className="button-container-indicator-select my-3">
      <div> Applied To:</div>
      <FormGroup tag="fieldset" className="button-container-indicator-select__input-set">
        {props.primary === false ? null : (
          <>
            <FormGroup inline check>
              <Input name='radio1' id="appliedToRadio_k3" type="radio" onChange={() => handleChange('k3')} /> <Label for='appliedToRadio_k3' check>{getContent('admin_label-grK-3')}</Label>
            </FormGroup>
            <FormGroup inline check>
              <Input name='radio1' id="appliedToRadio_elementary" type="radio" onChange={() => handleChange('elementary')} /> <Label for='appliedToRadio_elementary' check>{getContent('admin_label-gr3-5')}</Label>
            </FormGroup>
          </>
        )}

        {props.secondary === false ? null : (
          <FormGroup inline check>
            <Input name='radio1' id="appliedToRadio_uppergrades" type="radio" onChange={() => handleChange('uppergrades')} defaultChecked /> <Label for='appliedToRadio_uppergrades' check>{getContent('admin_label-upper-grades')}</Label>
          </FormGroup>
        )}

        {props.adult === false ? null : (
          <FormGroup inline check>
            <Input name='radio1' id="appliedToRadio_adult" type="radio" onChange={() => handleChange('adult')} /> <Label for='appliedToRadio_adult' check>{getContent('admin_label-adult')}</Label>
          </FormGroup>
        )}
      </FormGroup>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    surveyCrud: state.surveyCrud,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCheckedIndicatorInForm: indicatorId => dispatch(actions.toggleCheckedIndicatorInForm(indicatorId)),
    setAppliedTo: value => dispatch(actions.setAppliedTo(value)),
    toggleCategoryIndicators: category => dispatch(actions.toggleCategoryIndicators(category)),
    setProfession: profession => dispatch(actions.setProfession(profession)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelector);
