import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';

class ResiliencyDistribution extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && (payload.appliedTo === 'adult');
    const { tmEducatorsLink,tmEducatorsLinkUK } = this.props.links;
    const isUK = process.env.REACT_APP_STAGE === 'uk';
    const getAggregatedDistributionForResiliency = ({ self }) => {
      const currentScores = self.props.payload.reports.distributionForResiliency || [];
      const relatedScores =
        (self.props.selectedRelatedReport && self.props.selectedRelatedReport.reports.distributionForResiliency)
        || [];

      const result = [];

      for (const score of currentScores)
        result.push({
          current: score,
          related: {
            ...score,
            at_risk: null,
            moderate: null,
            satisfactory: null,
            optimal: null
          }
        });

      for (const score of relatedScores) {
        let maybeExisting = result.find(({ current }) => current.indicator === score.indicator);

        if (maybeExisting)
          maybeExisting.related = score; else {
          maybeExisting = {
            related: score,
            current: {
              ...score,
              at_risk: null,
              moderate: null,
              satisfactory: null,
              optimal: null
            }
          };

          result.push(maybeExisting);
        }
      }

      return result;
    };

    return (
      <div className="page group-report" style={{ marginTop: '100px' }}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h4 className="mb-3 text-uppercase">Participant Distribution Across Resiliency Indicators</h4>
            <table className="table table-sm table-tm-dark-striped">
              <thead>
                <tr>
                  <th>Indicator</th>
                  <th className="col-red">May Need Assistance</th>
                  <th className="col-yellow">Slight Concern</th>
                  <th className="col-light-green">Satisfactory</th>
                  <th className="col-green">Optimal</th>
                </tr>
              </thead>
              <tbody>
                {
                  getAggregatedDistributionForResiliency({ self: this })
                    .map(({ current, related }, index) => {
                      return (
                        <tr key={index}>
                          <td className="font-weight-bold w-20">{current.indicator}</td>
                          <td className="col-red w-20">
                            <div className="score font-weight-bold">
                              {current.at_risk > 0
                                ?
                                isAdult
                                  ?
                                  <span>
                                    {Math.round(current.at_risk)}%
                              </span>
                                  :
                                  <a href={
                                    isUK ? tmEducatorsLinkUK : tmEducatorsLink
                                  }>{Math.round(current.at_risk)}%</a>
                                : `${Math.round(current.at_risk)}%`}
                            </div>
                            <div className="previous text-dark">
                              {
                                related.at_risk
                                  ? (`${this.props.selectedRelatedReport.year} - ${Math.round(related.at_risk)} %`)
                                  : 'No historical data'
                              }
                            </div>
                          </td>
                          <td className="col-yellow w-20">
                            <div className="score font-weight-bold">{Math.round(current.moderate)}%</div>
                            <div className="previous text-dark">
                              {
                                related.moderate
                                  ? (`${this.props.selectedRelatedReport.year} - ${Math.round(related.moderate)} %`)
                                  : 'No historical data'
                              }
                            </div>
                          </td>
                          <td className="col-light-green w-20">
                            <div className="score font-weight-bold">{Math.round(current.satisfactory)}%</div>
                            <div className="previous text-dark">
                              {
                                related.satisfactory
                                  ? (`${this.props.selectedRelatedReport.year} - ${Math.round(related.satisfactory)} %`)
                                  : 'No historical data'
                              }
                            </div>
                          </td>
                          <td className="col-green w-20">
                            <div className="score font-weight-bold">{Math.round(current.optimal)}%</div>
                            <div className="previous text-dark">
                              {
                                related.optimal
                                  ? (`${this.props.selectedRelatedReport.year} - ${Math.round(related.optimal)} %`)
                                  : 'No historical data'
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })
                }
              </tbody>
            </table>

            <div className="box-comment">
              <h3>What is this table telling me?</h3>
              <p>
                {isAdult ? getContent("report_overall_page05_table_1_adult") : getContent("report_overall_page05_table_1_student")}
              </p>
              <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
              <p>
                {isAdult ? getContent("report_overall_page05_table_2_adult") : getContent("report_overall_page05_table_2_student")}
              </p>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRelatedReport: state.overallSurveyReport.selectedRelatedReport
  };
};

export default connect(
  mapStateToProps
)(withRouter(ResiliencyDistribution));

