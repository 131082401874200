import React from 'react';
import classnames from 'classnames';

const TMCard = props => (
  <div className={classnames(`tm-card tm-card-${props.padding || 'md'}`, { 'tm-card-headerless': !props.header }, props.className || null)}>
    {props.header ? (
      <div className="tm-card-header">
        {props.header.map((content, index) => {
          return <div key={index}>{content}</div>;
        })}
      </div>
    ) : (
      ''
    )}
    <div className="tm-card-content">{props.children}</div>
  </div>
);

export default TMCard;
