import React, { useEffect,  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateSurveyStepGroup from './CreateSurveyStepGroup/CreateSurveyStepGroup';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';
import * as actions from '../../../../store/actions';

const CreateSurvey = React.memo(() => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isRosterUploading,
    manualRoster,
    parentOrg,
    activeOrg
  } = useSelector(state => ({
    isLoading: state.surveyCrud.isLoading,
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
    parentOrg: state.navigation.org?.parentOrg,
    organization: state.organizations?.organization,
    schoolSearchName: state.organizations.schoolSearchName,
    districtSearchName: state.organizations?.districtSearchName,
    OrgState:state.organizations,
    activeOrg: state.navigation.org
  }));
  const schoolOrgId = activeOrg?.classification === 'school' ? activeOrg.orgId : null;
  
  useEffect(() => {
    dispatch(actions.clearCreateSurveyState());
    if (parentOrg) {
      if (parentOrg.secondary) {
        dispatch(actions.setAppliedTo('uppergrades'));
      } else if (parentOrg.primary) {
        dispatch(actions.setAppliedTo('elementary'));
      } else if (parentOrg.adult) {
        dispatch(actions.setAppliedTo('adult'));
      }
    }
    if (schoolOrgId) {
      dispatch(actions.setSurveyOrgId(schoolOrgId));
    }
    dispatch(actions.listIndicators());
  }, [dispatch, activeOrg]);

  // const districtId = districtSearchName || organization?.parentOrgId;

  return (
    <div className='create-survey-container'>
      <div className='sub-header__create_assessment'>Create Assessment</div>
      {schoolOrgId
        ? (
          <>
            <p>Complete this form to create a new assessment. Participants can be added after the assessment is created.</p>
            <CreateSurveyStepGroup
              createSurvey={() => dispatch(actions.createSurvey())}
              manualRoster={manualRoster}
            />
            {(isRosterUploading || isLoading) && (
              <Backdrop>
                <Spinner />
              </Backdrop>
            )}
          </>
        )
        :
        // (!schoolSearchName && !districtSearchName &&
          <div className='error-message'>
            <p>Error: Please select District and School to create a survey.</p>
          </div>
        // )
        }
    </div>

  );
})
CreateSurvey.displayName = 'CreateSurvey';

export default CreateSurvey;

