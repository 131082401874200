import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// import HOCS
import withLayout from '../../hoc/Layout/SurveyLayout';

// Import route components
import WellnessCenterAccess from './WellnessCenterAccess/WellnessCenterAccess';
import ParticipantInfoConfirmation from '../Surveys/ParticipantInfoConfirmation/ParticipantInfoConfirmation';

// Errors
import Error404 from '../Errors/Error404';

class Surveys extends Component {
  render() {
    return (
      <Switch>
        {/*
          USAGE EXAMPLE FOR isAuthorized:
          <Route path='/studentsection' component={isAuthorized(isAuthenticated(withLayout(StudentSection)), 'USER')} />
          <Route path="/admin" component={isAuthenticated(withLayout(Admin, true), { userType: 'user' })} />
        */}
        <Route path="/wellness-center" component={withLayout(WellnessCenterAccess, { showLogo: false })} />
        <Route path="/wellness-center/verify" component={withLayout(ParticipantInfoConfirmation, { showLogo: true, showLang: true })} />
        <Route component={withLayout(Error404, { showLogo: true, showContact: true, showUser: true, showLang: false })} />
      </Switch>
    );
  }
}

export default Surveys;
