import * as actionTypes from '../actions/actionTypes';

const initialState = {
  modalType: null,
  modalProps: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case actionTypes.CLOSE_MODAL:
      return initialState;
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
