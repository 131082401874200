import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import * as actions from '../../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import IconGroup from '../../../../assets/imgs/icon_group.svg';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import formatPhoneNumber from '../../../../utilities/formatPhoneNumber';

class ListResponders extends Component {
  state = {
    show: true,
  };
  componentDidMount() {
    this.props.listResponders();
  }
  isUK = () => process.env.REACT_APP_STAGE === 'uk';

  getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: 'Responders',
      },
    ];

    return breadcrumbs;
  };

  generateTableData = () => {
    // Waits for survey details to be populated
    if (this.props.users && this.props.users.length > 0) {
      const result = this.props.users.map(item => {
        return {
          name: item.firstName || item.lastName ? <span>{`${item.firstName || ''} ${item.lastName || ''}`}</span> : '',
          email: item.email,
          phoneNumber: formatPhoneNumber(item.phoneNumber),
          icon: (
            <>
              <img className="blue-fill-icon" onClick={() => this.openAssignDistrictModal(item.username)} src={`${IconGroup}`} alt="icon group" />
              <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" onClick={() => this.openUpdateUserModal(item)} />
              <FontAwesomeIcon icon={faTrash} className="blue-fill-icon" onClick={() => this.openDeleteUserConfirmationModal(item)} />
            </>
          ),
        };
      });

      return result;
    }

    return [];
  };

  openDeleteUserConfirmationModal = user => {
    let firstName = user.firstName ? user.firstName : '';
    let lastName = user.lastName ? user.lastName : '';
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>
            You are attempting to delete user “
            <strong>
              {`${firstName}`} {`${lastName}`}
            </strong>
            ”. Are you sure you want to continue? You cannot undo this action.{' '}
          </p>
          <Button type="button" className="small-button green" size="lg" onClick={() => this.props.deleteUser(user.username)}>
            Delete user
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };
  openAssignDistrictModal = responderId => {
    this.props.openModal({
      modalType: 'ASSIGN_RESPONDER_MODAL',
      show: true,
      districts: this.props.organizations,
      responderId,
      closeModal: e => this.closeModal(e),
    });
  };

  openAddResponderModal = () => {
    this.props.openModal({
      modalType: 'ADD_RESPONDER_MODAL',
      show: true,
      addUser: this.props.createUser,
      closeModal: e => this.closeModal(e),
    });
  };

  openUpdateUserModal = user => {
    this.props.openModal({
      modalType: 'ADD_RESPONDER_MODAL',
      show: true,
      addUser: this.props.updateUser,
      user,
      closeModal: e => this.closeModal(e),
    });
  };

  closeModal = e => {
    // Prevent the form from submitting
    if (e) {
      e.preventDefault();
    }

    this.props.closeModal();
  };

  render() {
    let createButtonText = 'Create Responder';
    let alertText = 'There are currently no responders. Please add a responder and assign them.';

    return (
      <>
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
          <Button type="submit" className="small-button green" size="lg" variant="success" onClick={this.openAddResponderModal}>
            {createButtonText}
          </Button>
        </div>
        {!this.props.isUsersLoaded ? (
          <>
            <Backdrop>
              <Spinner />
            </Backdrop>
          </>
        ) : (
          <>
            {this.props.users && this.props.users.length < 1 && this.state.show ? (
              <Alert
                className="alert"
                onClose={() => {
                  this.setState({ show: !this.state.show });
                }}
                dismissible
                variant="info"
              >
                <div className="alertHeader">No Responders Exist Yet</div>
                <div>{alertText}</div>
              </Alert>
            ) : null}
            <div className={this.props.users.length > 0 ? null : 'hidden'}>
              <TableGenerator
                config={{
                  headers: ['NAME', 'EMAIL', 'PHONE', ''],
                  contentRows: this.generateTableData(),
                }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    isUsersLoaded: state.users.isLoaded,
    organization: state.organizations.organization,
    role: state.auth.role,
    organizations: state.organizations.organizations,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => {
      dispatch(actions.closeModal());
      dispatch(actions.clearUserErrors());
    },
    createUser: userProps => dispatch(actions.createUser(userProps)),
    listResponders: () => dispatch(actions.listResponders()),
    updateUser: (userId, values) => dispatch(actions.updateUser(userId, values)),
    deleteUser: userId => dispatch(actions.deleteUser(userId, 'responder')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListResponders));
