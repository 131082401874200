import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import * as actions from '../../../../store/actions/';

import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';

import Page01 from './Page-01';
import Page02 from './Page-02';
import Page03 from './Page-03';

class ParentSurveyReport extends Component {
	componentDidMount() {
		const parsed = queryString.parse(this.props.location.search);
		const participantId = parsed.pId;
		this.props.getReport(participantId);
	}

	render() {
		console.log('THIS.props.report : ', this.props.report);
		console.log('IsLoading: ', this.props.isLoading);
		return (
			<div className="tm-report-group">
				{/* Header is sticky so it prints on each page */}
				{this.props.isLoading === false && this.props.report ? (
					<>
						<Page01
							payload={this.props.report}
							isParentReport={true}
							showPrint={this.props.role && this.props.role === 'responder' ? false : true}
						/>
						<Page02
							payload={this.props.report}
						/>
						<Page03
							payload={this.props.report}
						/>
					</>
				) : (
						<Backdrop>
							<Spinner />
						</Backdrop>
					)}
			</div>
		);
  }
}

const mapStateToProps = state => {
	console.log("mapStateToProps");
	return {
		report: state.reports.report,
		isLoading: state.reports.isLoading,
		role: state.auth.role
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getReport: participantId => dispatch(actions.getReport(participantId))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ParentSurveyReport));
