import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import Spinner from '../../../components/Spinner/Spinner';
import { getContent } from '../../../utilities/getContent/getContent';
import TMCard from '../../../components/TMCard/TMCard';
import { getLogo } from './../../../assets/helper';
import * as actions from '../../../store/actions';

class SurveySubmitting extends Component {
  state = {
    // baseUrl: process.env.REACT_APP_WEB_URL, // Not Used
    wellnessCenter: "/wellness-center",
    authCode: this.props.receivedSurveyData.parentSurveyAuthCode,
    isWellnessCenter: this.props.receivedSurveyData.wellnessCenterCheck,
  };
	retrySubmission = () => {
		// Turn this to a end survey component like tim suggested.
		// Spinning wheel while submitting, then you are done message or retry button
		const { surveyDataToSend, saveSurveyParticipantResponseData } = this.props;

		saveSurveyParticipantResponseData({
			...surveyDataToSend,
			surveySubmittedAt: Date.now()
		});
	};

	redirectToFinish = () => {
		if (this.props.receivedSurveyData.wellnessCenterCheck) {
			this.props.history.push(
				`/wellness-center?pId=${this.props.receivedSurveyData.parentSurveyAuthCode}&firstName=${this.props.receivedSurveyData.firstName}&lastName=${this.props.receivedSurveyData.lastName}`
			);
		} else this.props.history.push('/survey/complete');
	};

	render() {
		return (
			<div className="page-container-survey-complete">
				{this.props.isSurveySubmissionLoading ? (
					<TMCard
						className="tm-card-stepper"
						header={[
							<div className="text-center mb-4">
								<img src={getLogo()} alt="Logo" />
							</div>
						]}
					>
						<div>
							<p>{getContent('generic_please-wait')}</p>
							<Spinner />
						</div>
					</TMCard>
				) : (
					<TMCard
						className="tm-card-stepper"
						header={[
							<div className="text-center mb-4">
								<img src={getLogo()} alt="Logo" />
							</div>
						]}
					>
						<div>
							{this.props.saveSurveyResponseError ? (
								<div>
									<p>There was a problem submitting your assessment results.</p>
									<p>Do not navigate away from this page. Please retry submitting your results.</p>
									<Button
										type="button"
										className="small-button green"
										size="lg"
										onClick={this.retrySubmission}
									>
										{getContent('generic_retry')}
									</Button>
								</div>
							) : (
								<div style={{ marginTop: '-36px' }}>
									<p>Assessment results successfully saved.</p>
                  <p>Thank you for completing the assessment.  By clicking on the "Finish Assessment" button, you will be taken to your results.</p>
                  {this.state.isWellnessCenter ? (
                    <p>Should you wish to revisit your findings at a later date, please go to the following site:  
                    <a href={this.state.wellnessCenter}>terracemetrics.org/wellness-center</a>.
                    <b>You will need to enter your first and last name and the following wellness code:  [{this.state.authCode}]</b>
                  </p>) : (<p></p>)
                  }
									<Button
										type="button"
										className="small-button green"
										size="lg"
										onClick={this.redirectToFinish}
									>
										{getContent('generic_finish-survey')}
									</Button>
								</div>
							)}
						</div>
					</TMCard>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isSurveySubmissionLoading: state.survey.isSaveSurveyResponseLoading,
		saveSurveyResponseError: state.survey.saveSurveyResponseError,
		surveyDataToSend: state.survey.surveyDataToSend,
		survey: state.survey,
		receivedSurveyData: state.survey.receivedSurveyData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveSurveyParticipantResponseData: surveyDataToSend =>
			dispatch(actions.saveSurveyParticipantResponseData(surveyDataToSend)),
		loginWellnessCenter: values => dispatch(actions.loginWellnessCenter(values))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SurveySubmitting));
