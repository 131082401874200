import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import ListDistricts from './ListDistricts/ListDistricts';
import ListSchools from './ListSchools/ListSchools';
import * as actions from '../../../../store/actions';

const OrgList = props => {
  const showDistricts = props.role === 'tm_admin' && props.activeOrg === null;
  const activeDistrict = props.activeOrg ? (props.activeOrg.classification === 'school-district' ? props.activeOrg : props.activeOrg.parentOrg) : null;

  useEffect(() => {
    if (!props.isLoadedOrganizations) {
      props.listOrganizations(activeDistrict);
    } 
  }, [activeDistrict]);

  // useEffect(() => {
  //   if (props.orgListNeedsUpdate) {
  //     console.log(`org list needs update!`);
  //     props.listOrganizations(activeDistrict);
  //   }
  // }, [props.orgListNeedsUpdate]);

  const openCreateOrgModal = (item = null, type) => {
    props.openModal({
      modalType: type,
      show: true,
      closeModal: e => closeModal(e),
      item: item,
    });
  };

  const closeModal = e => {
    if (e) {
      e.preventDefault();
    }
    props.closeModal();
  };

  const handleDeleteOrg = (org, event) => {
    event.currentTarget.disabled = true;
    event.currentTarget.innerText = 'Please Wait...';
    props.deleteOrganization(
      org,
          () => {
          closeModal();
          // console.log(`org = ${JSON.stringify(org)}`);
          // if (org.classification === 'school') {
          //   props.schoolsListGet();
          // }
          // if (org.classification === 'school-district') {
          //   props.districtsListGet();
          // }
        }
    );
  };

  const openDeleteOrgConfirmationModal = item => {
    // Can't delete clever items, re-sync them from clever
    // if (!!item.cleverItem) return true; //before
    if (item.cleverItem) return true;

    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: `Deleting "${item.name}"`,
      content: (
        <div>
          <p>Are you sure you want to continue ? You cannot undo this action.</p>
          <div className="float-right">
            <Button type="button" className="small-button" size="lg" onClick={event => handleDeleteOrg(item, event)}>
              Delete
            </Button>
            <Button type="button" className="small-blue-button-outlined" size="lg" onClick={props.closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      ),
    });
  };

  const modalType = showDistricts ? 'CREATE_DISTRICT_MODAL' : 'CREATE_SCHOOL_MODAL';
  const buttonText = showDistricts ? 'Organization' : 'Group';

  return (
    <div className="list-organisations">
      <Button className="small-button float-right" onClick={() => openCreateOrgModal(null, modalType)}>
        {`Create ${buttonText}`}
      </Button>
      {showDistricts ? (
        <ListDistricts
          role={props.role}
          // idToken={props.isAuthenticated.signInUserSession.idToken.jwtToken}
          realRole={props.realRole}
          organizations={props.organizations}
          openDeleteOrgConfirmationModal={openDeleteOrgConfirmationModal}
          openCreateOrgModal={openCreateOrgModal}
          openModal={props.openModal}
          closeModal={props.closeModal}
          hasSearched={props.hasSearched}
          isLoadingOrganizations={props.isLoadingOrganizations}
          districtSelect={props.districtSelect}
        />
      ) : (
        <ListSchools
          role={props.role}
          realRole={props.realRole}
          organizations={props.organizations}
          openDeleteOrgConfirmationModal={openDeleteOrgConfirmationModal}
          openCreateOrgModal={openCreateOrgModal}
          openModal={props.openModal}
          closeModal={props.closeModal}
          hasSearched={props.hasSearched}
          isLoadingOrganizations={props.isLoadingOrganizations}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    activeOrg: state.navigation.org,
    role: state.auth.role,
    isLoadingOrganizations: state.organizations.isLoadingOrganizations || state.navigation.orgIsLoading,
    isLoadedOrganizations: state.organizations.isLoadedOrganizations,
    // orgListNeedsUpdate: state.organizations.orgListNeedsUpdate,
    organizations: state.organizations.organizations,
    error: state.organizations.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: item => dispatch(actions.createOrganization(item)),
    districtSelect: (value, callback) => dispatch(actions.districtSelect(value, callback)),
    listOrganizations: parentOrg => dispatch(actions.listOrganizations(parentOrg)),
    deleteOrganization: (id, onSuccess, onError) => dispatch(actions.deleteOrganization(id, onSuccess, onError)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => {
      dispatch(actions.closeModal());
      dispatch(actions.clearCreateOrgErrors());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrgList));
