import logo from './imgs/tm-logo-v2.png';
import ukLogo from './imgs/mymyndlogo.png';
import tmlogosq from './imgs/tm-logo-v2-square.png';

const { REACT_APP_STAGE } = process.env;

export const getLogo = () => {
  return REACT_APP_STAGE === 'uk' ? ukLogo : logo;
};

export const getMobileLogo = () => {
  return tmlogosq;
}
