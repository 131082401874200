import disagree from '../../../assets/imgs/moderately_disagree.png';
import mildlyDisagree from '../../../assets/imgs/mildly_disagree.png';
import stronglyDisagree from '../../../assets/imgs/strongly_disagree.png';
import agree from '../../../assets/imgs/agree.png';
import mildlyAgree from '../../../assets/imgs/somewhat_agree.png';
import stronglyAgree from '../../../assets/imgs/strongly_agree.png';
import thumbUp from '../../../assets/imgs/thumbUp.png';
import thumbDown from '../../../assets/imgs/thumbDown.png';

export const color = {
	0: ['#fff'],
	2: ['#fff'],
	3: ['#b93333', '#e16c69', '#a6c086'],
	4: ['#b93333', '#e16c69', '#a6c086', '#56af56'],
	5: ['#b93333', '#e16c69', '#d9c363', '#a6c086', '#56af56'],
	6: ['#b93333', '#CD504E', '#e16c69', '#a6c086', '#56af56', '#3e893e'],
	7: ['#b93333', '#CD504E', '#e16c69', '#d9c363', '#a6c086', '#56af56', '#3e893e'],
	8: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
};

export const emoticon = {
	0: [undefined],
	2: [undefined, undefined],
	4: [stronglyDisagree,disagree, agree, stronglyAgree],
	5: [stronglyDisagree,disagree,undefined,agree, stronglyAgree],
	6: [stronglyDisagree,disagree, mildlyDisagree, mildlyAgree,agree, stronglyAgree],
	7: [stronglyDisagree,disagree, mildlyDisagree, undefined,  mildlyAgree,agree, stronglyAgree],
	8: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]
};

export const emoji = {
  stronglyDisagree,
  disagree,
  mildlyDisagree,
  mildlyAgree,
  agree,
  stronglyAgree,
  thumbUp,
  thumbDown
}