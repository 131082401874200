import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddParticipantForm from './AddParticipantForm';
import Overlay from "../../../../../components/Overlay/overlay";
import StudentRosterUpload from "../../CreateSurvey/StudentRosterUpload/StudentRosterUpload";
import CleverStudentSelection from '../../../SurveyCRUD/CreateSurvey/StudentRosterUpload/CleverStudentSelection';

import * as actions from '../../../../../store/actions';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const AddParticipantModal = (props) => {

  // const [mode, setMode] = useState(1);
  // TODO SHRUTI : if props.idp = 'clever' show Clever tab
  const { surveyId, surveyOrg } = props;
  const hasIdp = surveyOrg.idp === 'clever' || surveyOrg.idp === 'classlink';
  const idpInfo = hasIdp? { schoolId: surveyOrg.orgId, districtId: surveyOrg.parentOrg.orgId, idpName: surveyOrg.idp} : null;
  const [isLoading, setLoading] = useState(false)
  // const handleClick = (value) => {
  //   setMode(value);
  // };

  const handleParticipantSubmission = values => {
    setLoading(true)
    const birthday = `${values['year-0']}-${values['month-0'].value}-${values['day-0']}`;
    const participant = [{
      surveyId,
      orgId: surveyOrg.orgId,
      birthDate: birthday,
      firstName: values[`firstName-0`],
      middleName: values[`middleName-0`],
      lastName: values[`lastName-0`],
      grade: !Object.keys(values).includes(`grade-0`) ? null : values[`grade-0`].toString(),
      groupName: values[`group-name-0`],
      studentId: values[`studentId-0`],
      gender: values[`custom-gender-0`] ? values[`custom-gender-0`] : values[`gender-0`].value,
      parentEmail: values[`parentEmail-0`],
      parentMobile: values[`parentMobile-0`],
      participantEmail: values[`participantEmail-0`],
    }];

    props.addParticipantToSurvey(participant, surveyId);
  };

  const handleParticipantSubmissionCSV = (participantsValues) => {
    const { surveyId } = props;

    const participant = participantsValues;
    props.addParticipantToSurvey(participant, surveyId);
  };
  const [isReady, setReady] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <Overlay
      className="add-participant-modal-wrapper"
      isOpen={props.show}
      onToggle={props.closeModal}
      variant="medium"
      title={"Add Participant"}
    >
      <div>
        <Nav tabs className='navigation-tab'>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => { toggle('1'); }}
            >
              Manually <FontAwesomeIcon icon={faCircleInfo} color='#3c7dc2'  id='addmanually'/>
            </NavLink>
           {isReady && <UncontrolledTooltip target={"addmanually"}> 
              Use this tab to add an individual student (boxes in red are required information)
            </UncontrolledTooltip>}
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => { toggle('2'); }}
            >
              Upload from File <FontAwesomeIcon icon={faCircleInfo} color='#3c7dc2'  id='addfile'/>
            </NavLink>
            {isReady && <UncontrolledTooltip target={"addfile"}> 
              Use this tab to upload a CSV roster file
            </UncontrolledTooltip>}
          </NavItem>
          {hasIdp && <NavItem>
            <NavLink
              className={'text-capitalize ' + (activeTab === '3' ? 'active' : '')}
              onClick={() => { toggle('3'); }}
            >
              { idpInfo.idpName } Student Selection
            </NavLink>
          </NavItem>}
        </Nav>
        <TabContent activeTab={activeTab} className='p-4'>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <AddParticipantForm
                  manualRoster={props.manualRoster}
                  surveyStatus={props.surveyStatus}
                  surveyName={props.surveyName}
                  onSubmit={handleParticipantSubmission}
                  closeModal={props.closeModal}
                  // mode={mode}
                  // callback={() => handleClick(1)}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <StudentRosterUpload
                  onSubmit={handleParticipantSubmissionCSV}
                  // mode={mode}
                  // callback={() => handleClick(2)}
                />
              </Col>
            </Row>
          </TabPane>
        {hasIdp && <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <CleverStudentSelection // keep this name but also used for Classlink
                  onSubmit={handleParticipantSubmissionCSV}
                  // callback={() => handleClick(3)}
                  // mode={mode}
                  idpInfo={idpInfo}
                />
              </Col>
            </Row>
          </TabPane>}
        </TabContent>
      </div>
    </Overlay>
  );
};

const mapStateToProps = state => {
  return {
    manualRoster: state.surveyCrud.manualRoster,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addParticipantToSurvey: (participant, surveyId) => dispatch(actions.addParticipantToSurvey(participant, surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddParticipantModal);
