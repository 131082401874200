import axios from 'axios';
import * as actionTypes from '../actionTypes';

const { REACT_APP_API_URL } = process.env;

const getUrlWithQueryParams = ({ rootUrl, keys, formInputs }) => {
  // eslint-disable-next-line no-unused-vars
  const queryObj = keys.reduce((accum, key, _) => {
    if (!formInputs[key]) {
      return {
        ...accum,
        [key]: '',
      };
    }
    return {
      ...accum,
      [key]: formInputs[key],
    };
  }, {});
  return (
    rootUrl +
    keys
      .map(key => {
        return `${key}=${queryObj[key]}`;
      })
      .join('&')
  );
};

export const updateSearchReportsObj = payload => {
  return {
    type: actionTypes.UPDATE_SEARCH_REPORT,
    payload,
  };
};

export const toggleShowReportSearch = () => {
  return {
    type: actionTypes.TOGGLE_SHOW_REPORT_SEARCH,
  };
};

export const clearReportStateOptions = () => {
  return {
    type: actionTypes.CLEAR_REPORT_STATE_OPTION,
  };
};

export const clearReportDistrictOptions = () => {
  return {
    type: actionTypes.CLEAR_REPORT_DISTRICT_OPTION,
  };
};

export const clearReportSchoolOptions = () => {
  return {
    type: actionTypes.CLEAR_REPORT_SCHOOL_OPTION,
  };
};

export const setSearchValuesReports = () => {
  return (dispatch, getState) => {
    const QUERY_KEY_PARAMS = ['country', 'state', 'district', 'school'];
    dispatch(setSearchValuesStart());
    let {
      reports: {
        reportSearchStateProvince: state,
        reportSearchCountryRegion: country,
        reportSearchDistrict: { orgId: district },
        reportSearchSchool: { id: school },
      },
      auth: { role, idToken },
      organizations: { organization },
    } = getState();
    let roleAccess = { state, country, district, school },
      rootUrl = `${REACT_APP_API_URL}/search?`;

    /* eslint-disable */
    if (role !== 'tm_admin' && organization && Object.keys(organization).length > 0) {
      const { state, country } = organization;
      if (role === 'district_champion') {
        const { orgId: district } = organization;
        roleAccess = { ...roleAccess, country, state, district };
      }
      if (role === 'school_champion') {
        const { parentOrgId: district, orgId: school } = organization;
        roleAccess = { ...roleAccess, country, state, district, school };
      }
    }
    /* eslint-enable */

   getUrlWithQueryParams({ rootUrl, keys: QUERY_KEY_PARAMS, formInputs: roleAccess });
    axios({
      url: getUrlWithQueryParams({ rootUrl, keys: QUERY_KEY_PARAMS, formInputs: roleAccess }),
      method: 'GET',
      headers: { Authorization: idToken  },
    })
      .then(res => {
        dispatch(setSearchValuesSuccess(res.data));
      })
      .catch(err => {
        dispatch(setSearchValuesFail(err));
        console.log('err', err);
      });
  };
};

export const setSearchValuesStart = () => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_REPORTS_START,
  };
};

export const setSearchValuesFail = err => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_REPORTS_FAIL,
    err,
  };
};

export const setSearchValuesSuccess = (values) => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_REPORTS,
    values
  };
};

export const searchReports = (values = {}) => {
  return (dispatch, getState) => {
    const reportType = values.reportType || getState().reports.reportSearchReportType;
    dispatch(searchReportsStart());
    const school = () => {
      if (getState().organizations.organization && getState().organizations.organization.orgId && getState().auth.role === 'school_champion') {
        return getState().organizations.organization.orgId;
      } else if (values.school && values.school.id) {
        return values.school.id;
      } else {
        return '';
      }
    };
    const district = () => {
      if (getState().auth.role === 'school_champion' && getState().organizations.organization.parentOrgId) {
        return getState().organizations.organization.parentOrgId;
      }

      if (getState().auth.role === 'district_champion' && getState().organizations.organization && getState().organizations.organization.orgId) {
        return getState().organizations.organization.orgId;
      }

      if (values.district && values.district.orgId) {
        return values.district.orgId;
      }
      return '';
    };
    const _state = () => {
      if (getState().organizations.organization && getState().organizations.organization.state && getState().auth.role !== 'tm_admin') {
        return getState().organizations.organization.state;
      } else if (values.state) {
        return values.state;
      } else {
        return '';
      }
    };
    const _country = () => {
      if (getState().organizations.organization && getState().organizations.organization.country && getState().auth.role !== 'tm_admin') {
        return getState().organizations.organization.country;
      } else if (values.country) {
        return values.country;
      } else {
        return '';
      }
    };
    // http://localhost:3000/reports/search?reportType=studentReport&year=&country=&name=&state=&districtId=&schoolId=a194924a-6406-4499-8ee3-39795d043fec&surveyParticipantId=f3ee811b-a73c-4b8c-af32-c790340084da&sortKey=participantStatus_0:priority_0:contacted_0:condy_ailey
    const url = `${REACT_APP_API_URL}/reports/search?reportType=${reportType}&year=${values.year || ''}&country=${_country() || values.country || ''}&name=${values.name ||
      ''}&state=${_state()}&districtId=${district()}&schoolId=${school()}&surveyParticipantId=${values.lastKey || ''}&sortKey=${values.sortKey || ''}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken  },
    })
      .then(res => {
        dispatch(searchReportsSuccess({ ...res.data, reportType }, values.pageNumber));
      })
      .catch(err => {
        dispatch(searchReportsFail(err));
      });
  };
};

export const clearSearchReportsPaginationValues = () => {
  return {
    type: actionTypes.CLEAR_SEARCH_REPORTS_PAGINATION_VALUES,
  };
};

export const searchReportsStart = () => {
  return {
    type: actionTypes.SEARCH_REPORT_START,
  };
};

export const searchReportsSuccess = ({ lastEvaluatedKey, currentExclusiveStartKey, reports, reportType, isLastKey }, pageNumber) => {
  //console.log('here');
  return {
    type: actionTypes.SEARCH_REPORT_SUCCESS,
    lastEvaluatedKey,
    currentExclusiveStartKey,
    reports,
    reportType,
    pageNumber,
    isLastKey,
  };
};

export const searchReportsFail = err => {
  return {
    type: actionTypes.SEARCH_REPORT_FAIL,
    err,
  };
};

export const getReportStart = () => {
  return {
    type: actionTypes.GET_REPORT_START,
  };
};

export const getReportSuccess = report => {
  return {
    type: actionTypes.GET_REPORT_SUCCESS,
    report,
  };
};

export const getReportFail = err => {
  return {
    type: actionTypes.GET_REPORT_FAIL,
    err,
  };
};

export const getReport = participantId => {
  return (dispatch, getState) => {
    dispatch(getReportStart());
    //const participantId = '7b824211-ba03-4433-a1b3-3d45165f4dfc';
    //const participantId = '74164b33-75e4-4c31-853e-76b48d36d9a7';
    const url = `${REACT_APP_API_URL}/reports/participant/${participantId}`;
    let authToken = getState().auth.idToken;

    // HACK FOR CAMPBELL - TO REMOVE
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('token')) {
      authToken = searchParams.get('token');
    }


    axios({
      url,
      method: 'GET',
      headers: { Authorization: authToken  },
    })
      .then(res => {
        // console.log('GET REPORT RESPONSE: ', res);
        dispatch(getReportSuccess(res.data));
      })
      .catch(err => {
        console.log('ERROR GETTING REPORT: ', err);
        dispatch(getReportFail(err));
//        console.log('Retrying');
//        dispatch(getReport(participantId));
      });
  };
};

export function toggleShowNotConcerningResponses(){
  return {
    type: actionTypes.TOGGLE_SHOW_NOT_CONCERNING_RESPONSES,
  };
}
