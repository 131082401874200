import React from 'react';

const CreateSurveyStep = ({
  active,
  number,
  header,
  subhead,
  button,
  children
}) => {
    return (
      <div className='survey-step-container' data-active={active}>
        <div className='step-header'>
          <p className='step-number'>{number}</p>
          <div>
            <p>{header}</p>
            <p>{subhead}
              {button ? button : ""}
            </p>
          </div>
        </div>
        {children}
      </div>
    )
}

export default CreateSurveyStep
