import React, { Component } from 'react';
import { getLogo } from './../../../../assets/helper';
import titleImg1 from '../../../../assets/imgs/group-survey-title1.jpg';
import titleImg2 from '../../../../assets/imgs/group-survey-title2.jpg';
import adultImg from '../../../../assets/imgs/collage4.png';
import adultImg2 from '../../../../assets/imgs/FirstResponders2.png';
class GroupTitlePage extends Component {
  render() {
    console.log("page1");
    console.log(this.props);
    const payload = this.props.payload;
    const isAdult = false;
    const isUK = process.env.REACT_APP_STAGE === 'uk';
    const curDate = new Date();
    const currentYear = curDate.getFullYear();
    const currentMonth = curDate.getMonth();
    const curDay = curDate.getDate();
    const monthNames = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
    const currentMonthName = monthNames[currentMonth];
    const titleImg1Style = { backgroundImage: `url(${isAdult ? adultImg2 : titleImg1})`, backgroundPosition:'center' };
    const titleImg2Style = { backgroundImage: `url(${isAdult ? adultImg : titleImg2})` };
    return (
      <div className="page group-report group-title">
        <header>
          <div className="container">
            <div className="row">
              <div className="branding col-8">
                <img src={getLogo()} alt="Logo" />
              </div>
              <div className="d-print-none col-4 mt-4">
              <button
                className="small-blue-button-outlined print"
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </button>
            </div>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="row title-band">
            <div className="col-12">
              <div className="title-band--summary">
                <h1>Parent-Reported Behavior</h1>
                <h2>Summary of Results</h2>
                <div className="locations">
                  {payload.district}
                  <br />
                  {payload.school}
                  <br />
                  <strong>{isUK ? payload.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : payload.surveyName}</strong> {!payload.previousSurveyName ? <span></span> : <span> comparing to <strong>{ !isUK ? payload.previousSurveyName : payload.previousSurveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary')}</strong></span>}
                </div>
              </div>
            </div>
          </div>
          <div className="row title-photos">
            <div className="col-4 title-col">
              <div className="title-image" style={titleImg1Style} />
              <div className="title-copyright title-box">
                <small className="vertical-center">
                  &copy; Copyright {isUK ? 'MyMynd' : 'Terrace Metrics'} {currentYear}
                </small>
              </div>
            </div>
            <div className="col-8">
              <div className="title-date title-box">
                <h5 className="vertical-center">
                  {currentMonthName} {curDay}, {currentYear}
                </h5>
              </div>
              <div className="title-image" style={titleImg2Style} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupTitlePage;
