import React, { Component } from 'react';
import GroupHeader from './Header';

class DistrictIntroPage extends Component {
  render() {
    const payload = this.props.payload;
    const responseList = payload.surveyResponses[0].indicators[0].responses;
    const totalScore = responseList.reduce((accumulator, item) => accumulator + item.responseScore, 0);
    const questionList = payload.surveyIndicators[0].questions;
    const mappedResponseQuestionList = responseList.map(resp => {
      const matchedItem = questionList.find(question => question.questionText === resp.questionText);
      return { ...resp, ...matchedItem };
    });
    const scoreSocial = mappedResponseQuestionList.filter(item => item.questionCategory === 'Social Adaptability Concerns').reduce((accumulator, item) => accumulator + item.responseScore, 0);
    const scoreAcademic = mappedResponseQuestionList.filter(item => item.questionCategory === 'Academic Concerns').reduce((accumulator, item) => accumulator + item.responseScore, 0);
    const scoreAggression = mappedResponseQuestionList.filter(item => item.questionCategory === 'Verbal/Social Aggression').reduce((accumulator, item) => accumulator + item.responseScore, 0);
    const tableRowsquestionSocial = mappedResponseQuestionList.filter(item => item.questionCategory === 'Social Adaptability Concerns').map((item, index) => (
      <tr key={index}>
        <td>{item.questionTopic}</td>
        <td>{item.responseScore}</td>
      </tr>
    ));
    const tableRowsquestionAcademic = mappedResponseQuestionList.filter(item => item.questionCategory === 'Academic Concerns').map((item, index) => (
      <tr key={index}>
        <td>{item.questionTopic}</td>
        <td>{item.responseScore}</td>
      </tr>
    ));
  const tableRowsquestionAggression = mappedResponseQuestionList.filter(item => item.questionCategory === 'Verbal/Social Aggression').map((item, index) => (
    <tr key={index}>
      <td>{item.questionTopic}</td>
      <td>{item.responseScore}</td>
    </tr>
    ));
    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5">
            <div className="d-flex flex-row mt-5">
              <h1 className="text-uppercase">Results</h1>
            </div>
            <div className="d-flex flex-row mt-5">
              <table className="table table-sm table-tm-dark-striped">
                <tbody>
                <tr className="font-weight-bold">
                  <td>{payload.participant.firstName} {payload.participant.lastName}</td>
                  <td>Total Academic Score =  {totalScore}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row mt-5">
              <div>
                <h1 className="text-uppercase">Your Individual Ratings</h1>
              </div>
            </div>
            <div className="d-flex flex-row mt-5 justify-content-around">
              <table className="table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Social Adaptability Concerns</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionSocial}
                  <tr className="font-weight-bold">
                    <td>
                      Total Score 
                    </td>
                    <td>
                      {scoreSocial}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Academic Concerns</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionAcademic}
                  <tr className="font-weight-bold">
                    <td>
                      Total Score 
                    </td>
                    <td>
                      {scoreAcademic}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Verbal/Social Aggression</th>
                  </tr>
                </thead>
                <tbody>
                  {tableRowsquestionAggression}
                  <tr className="font-weight-bold">
                    <td>
                      Total Score 
                    </td>
                    <td>
                      {scoreAggression}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default DistrictIntroPage;
