import React from 'react';

const RenderField = ({ input, meta: { touched, error, warning }, ...rest }) => {
  return (
    <>
      <input {...input} {...rest} />
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
    </>
  );
};

export default RenderField;
