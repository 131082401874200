import React, { useState } from 'react';
// import { useEffect, useState } from 'react';
// import { Button, Col, Form, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../../../store/actions';
import { Badge, Button,  } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';

const { REACT_APP_API_URL, REACT_APP_STAGE } = process.env;

const consentLabels = {
  CONSENT_TYPE_NO_CONSENT: 'No Consent',
  CONSENT_TYPE_ACTIVE_CONSENT: 'Active Consent',
  CONSENT_TYPE_PASSIVE_CONSENT: 'Passive Consent',
};

const ConsentStatus = props => {
  const [sending, setSending] = useState(false);
  const API_TOKEN = useSelector(state => state.auth.idToken);
  const surveyHasConsent = typeof props.surveyDetails.consentType === 'string' && props.surveyDetails.consentType !== 'CONSENT_TYPE_NO_CONSENT';
  const consentStatusInfo = {
    sendingCount: 0, // if consent request was sent, how many times
    response: {
      received: 0,
      true: 0,
      false: 0,
    },
    readyParticipants: 0
  };
  if (surveyHasConsent) {
    consentStatusInfo.sendingCount = props.surveyDetails.consentLog?.length || 0;
    props.surveyDetails.participants.forEach(p => {
      if (p.consentMeta) {
        consentStatusInfo.response.received++;
        consentStatusInfo.response[p.consentGranted === true ? 'true' : 'false']++;
      }
    });
    consentStatusInfo.readyParticipants =
      props.surveyDetails.consentType === 'CONSENT_TYPE_ACTIVE_CONSENT' ? consentStatusInfo.response.true : props.surveyDetails.participants.length - consentStatusInfo.response.false;
  }
  // const gridWidths = { left: 3, right: 9 };
  // const initValues = {
  //   consentType: props.surveyDetails.consentType ? props.surveyDetails.consentType : 'CONSENT_TYPE_NO_CONSENT',
  //   consentTemplate: surveyHadSavedTemplate ? props.surveyDetails.consentTemplate : props.parentOrg.consentTemplate,
  // };

  const sendRequest = () => {
    // /v2/survey/notifications/send-consent/:surveyId?force=[true|false]
    setSending(true);
    axios({
      url: `${REACT_APP_API_URL}/v2/survey/notifications/send-consent/${props.surveyId}` + (REACT_APP_STAGE === 'dev' ? '?force=true' : ''),
      method: 'GET',
      headers: { Authorization: API_TOKEN },
    })
      .then(() => {
        setSending(false);
        props.getSurvey(props.surveyId);
        props.openToaster(`Consent Sent!`);
      })
      .catch(() => {
        setSending(false);
        props.openToaster(`An error occured`);
      });
  };

  return (
    <>
      {surveyHasConsent ? (
        <div className="pt-1 pb-1 mb-2 border-top border-bottom" >
          <h2>Consent Information</h2>
          <div className="d-print-none create-survey-container__details mt-3 mb-3">
            <div>
              Consent Type: <Badge>{consentLabels[props.surveyDetails.consentType]}</Badge>
            </div>
            <div>
              Consent Status: <Badge>{consentStatusInfo.sendingCount > 0 ? 'Sent' : 'Not Sent'}</Badge>{' '}
              {consentStatusInfo.sendingCount > 0 ? (
                <>
                  <Badge className='green-badge'>{consentStatusInfo.readyParticipants} participants ready</Badge> (Responses: {consentStatusInfo.response.true} positive, {consentStatusInfo.response.false}{' '}
                  negative)
                </>
              ) : null}
            </div>
            <div>
              <Button disabled={sending} onClick={sendRequest} color='primary' className="">
                { sending ? <FontAwesomeIcon className='fa-spin' icon={faSpinner}/> : <FontAwesomeIcon icon={faEnvelope} />} Send Requests
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    surveyDetails: state.surveyCrud.surveyDetails,
    parentOrg: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg ? state.organizations.organization.parentOrg : state.organizations.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSurvey: (surveyId, direction, lastKey, sortKey) => dispatch(actions.getSurvey(surveyId, direction, lastKey, sortKey)),
    openToaster: value => dispatch(actions.openToaster(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentStatus);
