import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavItem, NavLink, Offcanvas, OffcanvasBody, UncontrolledTooltip, } from "reactstrap";
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import {
  faPeopleGroup,
  faAnglesRight,
  faAnglesLeft,
  faFilePen,
  faFileCirclePlus,
  faFileCircleCheck,
  faFolderClosed,
  faBuilding,
  faSchool,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';
import userRole from "../../consts/roles";

const NavbarMobile = ({role, selectedDistrictId, selectedSchoolId}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname + location.search === path;
  };

  return (
    <div className='sidebar-mobile'>
      <div className='tm-layout__flex__canvas-icon__burger'>
        <FontAwesomeIcon icon={faAnglesRight} onClick={toggle} />
      </div>
      <Offcanvas toggle={toggle} isOpen={isOpen} style={{ width: "150px" }}>
        <OffcanvasBody className="h-100" style={{ height: "100%" }}>
          <div className='sidebar'>
            <div className='tm-layout__flex__canvas-icon'>
              <div>
                <FontAwesomeIcon icon={faAnglesLeft} onClick={toggle} />
              </div>
            </div>
            <div className='sidebar__nav-items'>
              <div className='sidebar__nav-items__link'>
                {`Assessments`.toUpperCase()}
              </div>
              <ul>
                <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list?status=ongoing') ? 'sidebar__nav-items__sub-link__active' : ''}`} >
                  <FontAwesomeIcon icon={faFilePen} />
                  <RouterNavLink to={`/admin/survey/list?status=ongoing`} className={"sidebar__nav-items__sub-link"}>
                    Ongoing
                  </RouterNavLink>
                </NavItem>
                <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list?status=completed') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                  <FontAwesomeIcon icon={faFileCircleCheck} />
                  <RouterNavLink to={`/admin/survey/list?status=completed`} className={"sidebar__nav-items__sub-link"}>Completed</RouterNavLink>
                </NavItem>
                <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list?status=previous') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                  <FontAwesomeIcon icon={faFolderClosed} />
                  <RouterNavLink to="/admin/survey/list?status=previous" className="sidebar__nav-items__sub-link">
                    Previous Years</RouterNavLink>
                </NavItem>
                {/* <NavItem className='sidebar__nav-items__li'>
                  <FontAwesomeIcon icon={faFileCirclePlus} />
                  <NavLink to="#" className='sidebar__nav-items__sub-link'>Create New</NavLink>
                </NavItem> */}
                <NavItem className='sidebar__nav-items__li' >
              <FontAwesomeIcon icon={faFileCirclePlus} />
              {(selectedDistrictId && selectedSchoolId) || (role == userRole.SCHOOL_CHAMPION) ?
                <RouterNavLink to="/admin/survey/create" className='sidebar__nav-items__sub-link'>Create New</RouterNavLink>
                :
                <div>
                  <div className='sidebar__nav-items__sub-link--disabled' id='assessmentnew'>Create New</div>
                  <UncontrolledTooltip target={"assessmentnew"}>
                    Please Select District and Group to Create a new Assessment.
                  </UncontrolledTooltip>
                </div>
              }
            </NavItem>
              </ul>
              <div className='sidebar__nav-items__link'>
                {`Participants`.toUpperCase()}
              </div>
              <ul>
                <NavItem className={`sidebar__nav-items__li ${isActive('/admin/participant/search') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                  <FontAwesomeIcon icon={faPeopleGroup} />
                  <NavLink href="/admin/participant/search" className='sidebar__nav-items__sub-link'>Search</NavLink>
                </NavItem>
                <NavItem className='sidebar__nav-items__li'>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <NavLink href="#" className='sidebar__nav-items__sub-link'>Priority</NavLink>
                </NavItem>
              </ul>
              {role !== userRole.SCHOOL_CHAMPION && 

              <><div className='sidebar__nav-items__link'>
                  {`My organization`.toUpperCase()}
                </div><ul>
                    <NavItem className='sidebar__nav-items__li'>
                      <FontAwesomeIcon icon={faBuilding} />
                      <NavLink href="/admin/users" className='sidebar__nav-items__sub-link'>Edit Organization</NavLink>
                    </NavItem>
                    <NavItem className={`sidebar__nav-items__li ${isActive('/admin/organizations/all') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                      <FontAwesomeIcon icon={faSchool} />
                      <NavLink href="/admin/organizations/all" className='sidebar__nav-items__sub-link'>Manage Groups</NavLink>
                    </NavItem>
                  </ul></>}
            </div>
            <div className="sidebar__copyright">&copy; Copyright Terrace Metrics 2024 </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}
export default NavbarMobile;