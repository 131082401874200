export default [
  {
    label: 'Resiliency Indicators',
    contentId:'report_heading-resilience-indicators',
    description: 'report_content-resilience-indicators',
    indicators: [
      {
        label: 'Grit',
        contentId: 'indicator_grit',
        description: 'indicator_grit-description',
      },
      {
        label: 'Grit - ES',
        contentId: 'indicator_grit',
        description: 'indicator_grit-description',
      },
      {
        label: 'Hope',
        contentId: 'indicator_hope',
        description: 'indicator_hope-description',
      },
      {
        label: 'Leadership',
        contentId: 'indicator_leadership',
        description: 'indicator_leadership-description',
      },
      {
        label: 'Resiliency',
        contentId: 'indicator_resiliency',
        description: 'indicator_resiliency-description',
      },
      {
        label: 'Global Satisfaction',
        contentId: 'indicator_global-satisfaction',
        description: 'indicator_global-satisfaction-description',
      },
      {
        label: 'Positive School Experiences',
        contentId: 'indicator_positive-school-experiences',
        description: 'indicator_positive-school-experiences-description',
      },
      {
        label: 'Personal Standards',
        contentId: 'indicator_personal-standards',
        description: 'indicator_personal-standards-description',
      },
    ],
  },
  {
    label: 'Risk Indicators',
    contentId: 'report_heading-risk-indicators',
    description: 'report_content-risk-indicators',
    indicators: [
      {
        label: 'Anxiety',
        contentId: 'indicator_anxiety',
        description: 'indicator_anxiety-description',
      },
      {
        label: 'Bullying Others',
        contentId: 'indicator_bullying-others',
        description: 'indicator_bullying-others-description',
      },
      {
        label: 'Depression',
        contentId: 'indicator_depression',
        description: 'indicator_depression-description',
      },
      {
        label: 'Brief Depression',
        contentId: 'indicator_brief_depression',
        description: 'indicator_depression-description',
      },
      {
        label: 'Getting Bullied',
        contentId: 'indicator_getting-bullied',
        description: 'indicator_getting-bullied-description',
      },
      {
        label: 'Ostracism',
        contentId: 'indicator_ostracism',
        description: 'indicator_ostracism-description',
      },
      {
        label: 'Self Criticism',
        contentId: 'indicator_self-criticism',
        description: 'indicator_self-criticism'
      },
    ],
  },
  {
    label: 'Supplemental Indicators',
    contentId: 'report_heading-supplemental-indicators',
    description: 'report_content-supplemental-indicators',
    indicators: [
      {
        label: 'Drug / Alcohol',
        contentId: 'indicator_drug-alcohol',
        description: 'indicator_drug-alcohol-description',
      },
      {
        label: 'School Violence',
        contentId: 'indicator_school-violence',
        description: 'indicator_school-violence-description',
      },
      {
        label: 'Trauma',
        contentId: 'indicator_trauma',
        description: 'indicator_trauma-description',
      },
      {
        label: 'Intimate Partner Violence',
        contentId: 'indicator_intimate-partner-violence',
        description: 'indicator_intimate-partner-violence-description',
      },
    ],
  },
];
