import React, { useState } from 'react';
import Overlay from '../../../../components/Overlay/overlay';
import * as actions from '../../../../store/actions';
import { connect, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Button, Container } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const roleHr = { district_champion: 'Organization Champion', school_champion: 'Group Champion', tm_admin: 'TM Admin' };

// This modal is for editing all possible survey attributes
// But for now it's only for DRs (Designated Recipients)
const SurveyEditModal = props => {
  const idToken = useSelector(state => state.auth.idToken);
  const [DRs, setDRs] = useState(props.surveyDetails.designatedRecipient || []);
  const [saving, setSaving] = useState(false);
  const selectOptions = props.champions.map(champion => {
    const championRoleHr = roleHr[champion.role] || null;
    return {
      label: `${champion.firstName} ${champion.lastName} (${championRoleHr})`,
      value: champion.email,
    };
  });
  props.surveyDetails.designatedRecipient && props.surveyDetails.designatedRecipient.forEach(emailAddress => {
    if (!props.champions.some(c => c.email === emailAddress)) {
      // designated recipient is not in the champions list
      selectOptions.push({ label: emailAddress, value: emailAddress });
    }
  });
  const selectDefaultValue = selectOptions.filter(option =>
    DRs.includes(option.value),
  );
  const changeHandler = selectedOptions => {
    const values = selectedOptions.map(option => option.value);
    setDRs(values);
  };
  const saveNewDRs = async () => {
    setSaving(true);
    console.log(`setSaving true`);
    const url = `${process.env.REACT_APP_API_URL}/surveys`;
    const response = await axios({
      url,
      method: 'POST',
      data: {
        surveys: [
          {
            id: props.surveyId,
            designatedRecipient: DRs,
          },
        ],
      },
      headers: { Authorization: idToken },
    });
    if (response.status === 200) {
      props.refreshData(props.surveyId);
      props.closeModal()
    }
    setSaving(false);
    console.log(`setSaving false`);
  };

  return (
    <Overlay
      isOpen={true}
      variant="small"
      title="Edit Assessment Information"
      onToggle={props.closeModal}
    >
      <Container>
        <label htmlFor="designatedRecipientSelect" >Designated Recipients</label>
        <CreatableSelect
          isMulti
          name='designatedRecipientSelect'
          defaultValue={selectDefaultValue}
          isClearable
          options={selectOptions}
          formatCreateLabel={v => `Add ${v}`}
          onChange={changeHandler}
        />
        <div className="form-text">Select Designated Recipients from the list of district/school champions, or enter any email address.</div>
        <Button className="mt-3" color="primary" disabled={saving} onClick={saveNewDRs}>
          {saving? ( <><FontAwesomeIcon className="me-2 fa-spin"  icon={faSpinner}/>Saving ...</>) : "Save Changes"}
        </Button>
      </Container>
    </Overlay>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
    refreshData: (surveyId) => dispatch(actions.getSurvey(surveyId)),    
  };
};

export default connect(null, mapDispatchToProps)(SurveyEditModal);
