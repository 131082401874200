import { Amplify, Auth } from 'aws-amplify';
import * as actionTypes from '../actionTypes';
// import getAuthenticatedUser from '../../../utilities/getAuthenticatedUser';
import { getOrganization, getOrganizationSuccess } from '../organizations/organizations';
import { getUser, applyCleverUser } from '../users/users';
// import mixpanel from 'mixpanel-browser';
// const { REACT_APP_MIXPANEL_TOKEN, REACT_APP_STAGE } = process.env;

// console.log(`userPoolWebClientId: ${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}`);
Amplify.configure({
  Auth: {
    //identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'us-east-1:ca41d833-0bc4-4b5a-bae1-b1c2ff0309c9',
    region: process.env.REACT_APP_REGION || 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_Ios05vo7O',
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '7ac8pk0etqbaqfp0qjth9jmlup',
  },
});

export const checkAuthStateStart = () => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_START,
  };
};

// export const checkAuthStateSuccessV2 = session => {
//   return {
//     type: actionTypes.CHECK_AUTH_STATE_SUCCESS_V2,
//     session,
//   };
// };

export const checkAuthStateSuccess = (user, idToken, accessToken, isCleverLogin, orgs = null) => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_SUCCESS,
    user,
    idToken,
    accessToken,
    isCleverLogin,
    orgs,
  };
};

export const checkAuthState = (redirectToLogin) => {
  return async (dispatch, getState) => {
    dispatch(checkAuthStateStart());
    // if (isCleverUser) {
    //   user = JSON.parse(cleverUserRaw);
    //   const accessToken = user.access_token;
    //   idToken = accessToken;
    //   role = user['custom:role'];
    //   const orgs = user.otherOrgs;
    //   dispatch(checkAuthStateSuccess(user, idToken, accessToken, null, true, orgs));
    //   applyCleverUser(user);
    // } else {
    const currentSession = await Auth.currentSession().catch(() => null);
    // const user = await getAuthenticatedUser();
    if (!currentSession) {
      // logged out
      dispatch(logout(redirectToLogin));
      return;
    }
    const user = currentSession.idToken.payload;
    const idToken = currentSession.idToken.jwtToken;
    const accessToken = currentSession.accessToken.jwtToken;
    // const role = currentSession.idToken.payload['custom:role'];
    dispatch(checkAuthStateSuccess(user, idToken, accessToken, false));
    // TODO : get rid of users.user in redux, instead use auth.user
    if (user.sub !== getState().users.user?.username) {
      dispatch(getUser(user.sub));
    }
    // }
    // HACK: To set isLoaded to true so layout HOC can render wrapped component
    // let org = null;
    // if (role !== 'tm_admin') {
    //   org = getState().organizations.realOrganization ? getState().organizations.realOrganization : getState().organizations.organization;
    // } else if (role === 'tm_admin' && getState().organizations.organization) {
    //   org = getState().organizations.organization;
    // }
    // dispatch(getOrganizationSuccess(org));
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const loginSuccess = (user, idToken, accessToken, refreshToken, isCleverLogin) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user,
    idToken,
    accessToken,
    refreshToken,
    isCleverLogin,
  };
};

export const loginFail = err => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: err,
  };
};

export const setTempUser = user => {
  return {
    type: actionTypes.SET_TEMP_USER,
    tempUser: user,
  };
};

export const loginWithClever = user => {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(loginStart());
    const idToken = user.access_token;
    dispatch(loginSuccess(user, idToken, idToken, null, true));
    dispatch(applyCleverUser(user));
    localStorage.setItem('cleverUserData', JSON.stringify(user));
    const orgId = Array.isArray(user['custom:orgId']) ? user['custom:orgId'][0] : user['custom:orgId'];
    if (orgId) {
      dispatch(getOrganization(orgId, idToken));
    } else {
      throw new Error('org id is required');
    }
  };
};

export const login = (username, password, redirectToChangePassword, redirectSuccess) => {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    // mixpanel.init(REACT_APP_MIXPANEL_TOKEN, { debug: REACT_APP_STAGE === 'dev', track_pageview: 'full-url', persistence: 'localStorage' });
    dispatch(loginStart());

    Auth.signIn({
      username,
      password,
    })
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(setTempUser(user));
          redirectToChangePassword();
          return;
        }
        const { ['custom:orgId']: orgId } = user.attributes;
        // const { given_name, family_name, email, sub, ['custom:orgId']: orgId, ['custom:role']: role } = user.attributes;
        // mixpanel.identify(sub);
        // mixpanel.people.set({
        //   $first_name: given_name,
        //   $last_name: family_name,
        //   $email: email,
        //   role: role,
        //   orgId: orgId,
        // });

        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;
        dispatch(loginSuccess(user, idToken, accessToken, refreshToken, false));
        dispatch(getUser(user.username));
        redirectSuccess();
        // const orgId = user.attributes['custom:orgId'];
        // if (orgId && role === "district_champion") {
        //   dispatch(schoolsListGet(orgId));
        // }
        if (orgId) {
          dispatch(getOrganization(orgId, idToken));
        } else {
          dispatch(getOrganizationSuccess(null));
        }
      })
      .catch(err => {
        dispatch(loginFail(err));
      });
  };
};

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const logoutSuccess = response => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    signOutResponse: response,
  };
};

export const logoutFail = err => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: err,
  };
};

export const logoutV2 = (cb) => {
  return async dispatch => {
    dispatch(logoutStart());
    await Auth.signOut();
    localStorage.clear();
    dispatch(logoutSuccess());
    if (cb) cb();
  };
};

export const logout = cb => {
  return dispatch => {
    dispatch(logoutStart());
    try {
      localStorage.clear();
      Auth.signOut()
        .then(res => {
          dispatch(logoutSuccess(res));
          dispatch(resetApp());
          if (cb) {
            cb();
          }
        })
        .catch(err => {
          dispatch(logoutFail(err));
        });
    } catch (err) {
      dispatch(logoutFail(err));
    }
  };
};

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFail = err => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL,
    error: err,
  };
};

export const changePassword = (newPassword, cb, cbError) => {
  return (dispatch, getState) => {
    dispatch(changePasswordStart());
    Auth.completeNewPassword(getState().auth.tempUser, newPassword)
      .then(user => {
        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;
        dispatch(loginSuccess(user, idToken, accessToken, refreshToken, false));
        if (cb) {
          cb();
        }
      })
      .catch(err => {
        console.log(err);
        if (cbError) cbError(err);
      });
  };
};

export const changeRole = role => {
  return {
    type: actionTypes.CHANGE_ROLE,
    role,
  };
};

export const resetRole = () => {
  return {
    type: actionTypes.RESET_ROLE,
  };
};

export const resetApp = () => {
  return {
    type: actionTypes.RESET_APP,
  };
};
