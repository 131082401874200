import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import Backdrop from '../../../components/Backdrop/Backdrop';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router-dom';
import ParticipantHeader from '../../Admin/Reports/Shared/ParticipantHeader';
import TFSReport from '../../Admin/Reports/Shared/TFSReport';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, CardBody } from 'reactstrap';
import ButtonArrow from '../../../components/Button/ButtonArrow';
import MOCK_SUGGESTIONS from '../../Admin/Reports/mock-suggestions';
import MOCK_ADULT_SUGGESTIONS from '../../Admin/Reports/mock-suggestions-adult';
import { getContent } from '../../../utilities/getContent/getContent';

const WellnessCenterHome = ({ location, report, isLoading, getReport, history }) => {
  const [accOpenHr, setOpenHr] = useState('1');
  const [accOpenLr, setOpenLr] = useState('1');

  const accToggleHr = (id) => {
    if (accOpenHr === id) {
      setOpenHr();
    } else {
      setOpenHr(id);
    }
  };
  const accToggleLr = (id) => {
    if (accOpenLr === id) {
      setOpenLr();
    } else {
      setOpenLr(id);
    }
  };

  useEffect(() => {
    getReport(location.state.participantId);
  }, [getReport, location.state.participantId]);

  const isAdult = report && report.appliedTo && report.appliedTo === 'adult';

  const higherRiskIndicators = report && report.indicators && report.indicators.filter(indicator => indicator.riskLevel === 'at_risk' || indicator.riskLevel === 'moderate');

  const lowerRiskIndicators = report && report.indicators && report.indicators.filter(indicator => indicator.riskLevel !== 'at_risk' && indicator.riskLevel !== 'moderate');

  const suggestions = isAdult ? Object.assign({}, MOCK_ADULT_SUGGESTIONS) : Object.assign({}, MOCK_SUGGESTIONS);

  const handleClick = () => {
    history.push(`parent?pId=${location.state.participantId}`);
  };

  return (
    <>
      <div className="tm-report tm-report-student tm-report-parent">
        {isLoading === false && report ? (
          <>
            <ParticipantHeader payload={report} isAdult={isAdult} />
            <div className="wellness-center-home-header">
              <div className="summary">
                <h1 className="mb-3">Resiliency Assessment</h1>
                <div className="d-flex flex-row justify-content space-between">
                  <h5>Summary of Results</h5>
                  <span
                    className="button-text ml-3"
                    onClick={handleClick}
                  >
                    View full details and printable version
                  </span>
                </div>
              </div>
            </div>

            <TFSReport payload={report} isWellnessCenterHome={true} showTimeBox={false} isParentReport={true} />
            {higherRiskIndicators && higherRiskIndicators.length > 0 ? (
              <div className="page page-parent-report">
                <h1 className="mb-4 text-uppercase">FURTHER INFORMATION FOR INDICATORS REQUIRING ATTENTION:</h1>

                {/* eslint-disable-next-line array-callback-return */}
                {/* High Risk Indicators */}
                {higherRiskIndicators &&
                  higherRiskIndicators.map((indicator, i) => {
                    const suggestion = suggestions[indicator.indicator.toLowerCase()];
                    if (suggestion !== undefined)
                      return (
                        <Accordion key={`hri-${i}`} open={accOpenHr} toggle={accToggleHr}>
                          <AccordionItem>
                            <AccordionHeader  targetId={`hri-${i}`}>
                              {getContent(suggestion.label)}
                            </AccordionHeader>
                            <AccordionBody accordionId={`hri-${i}`}>
                              <CardBody>
                                <div className="row">
                                  <div className="col flex-column align-content-stretch report-font-size">
                                    {suggestion.intro && isAdult === true ? <div className="mb-3">{getContent(suggestion.intro)}</div> : null}
                                    {isAdult === true ? (
                                      <div className="mb-3">
                                        {suggestion.tips.map((tip, ti) => {
                                          return (
                                            <div className="mb-3" key={ti}>
                                              {getContent(tip)}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <ul className="mb-3">
                                        {suggestion.tips.map((tip, ti) => {
                                          return (
                                            <li className="mb-3" key={ti}>
                                              {getContent(tip)}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                    <div style={{ marginBottom: '32px' }} className="font-weight-bold report-font-size">
                                      {isAdult === true && suggestion.suicidalContext ? getContent('report_suggestions-adult-suicidal-context') : null}
                                    </div>
                                  </div>
                                  {/* <div className="col">
																		<img
																			src="https://dummyimage.com/600x400/000/fff"
																			alt="placeholder"
																		/>
																	</div> */}
                                </div>
                              </CardBody>
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      );
                  })}
              </div>
            ) : null}
            {/* Lower Risk Indicators */}
            {lowerRiskIndicators && lowerRiskIndicators.length > 0 ? (
              <div className="page page-parent-report">
                <h1 className="mb-4 text-uppercase">FURTHER INFORMATION FOR OTHER INDICATORS:</h1>

                {/* eslint-disable-next-line array-callback-return */}
                {/* High Risk Indicators */}
                {lowerRiskIndicators &&
                  lowerRiskIndicators.map((indicator, i) => {
                    const suggestion = suggestions[indicator.indicator.toLowerCase()];
                    if (!suggestion) {
                      return <></>;
                    }
                    return (
                      <Accordion  key={`lri-${i}`} open={accOpenLr} toggle={accToggleLr}>
                          <AccordionItem>
                          <AccordionHeader targetId={`lri-${i}`}>

                              {getContent(suggestion.label)}
                              </AccordionHeader>
                              <AccordionBody  accordionId={`lri-${i}`}>
                              <CardBody>
                              <div className="row">
                                <div className="col flex-column align-content-stretch">
                                  {suggestion.intro && isAdult === true ? <div className="mb-3">{getContent(suggestion.intro)}</div> : null}
                                  {isAdult === true ? (
                                    <div className="mb-3">
                                      {suggestion.tips.map((tip, ti) => {
                                        return (
                                          <div className="mb-3" key={ti}>
                                            {getContent(tip)}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <ul className="mb-3">
                                      {suggestion.tips.map((tip, ti) => {
                                        return (
                                          <li className="mb-3" key={ti}>
                                            {getContent(tip)}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                  <div style={{ marginBottom: '32px' }} className="font-weight-bold">
                                    {isAdult === true && suggestion.suicidalContext ? getContent('report_suggestions-adult-suicidal-context') : null}
                                  </div>
                                </div>
                                {/* <div className="col">
																		<img
																			src="https://dummyimage.com/600x400/000/fff"
																			alt="placeholder"
																		/>
																	</div> */}
                              </div>
                            </CardBody>
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                    );
                  })}
              </div>
            ) : null}
            {isAdult &&
              <div className="page page-parent-report">
                <h1 className="mb-4 text-uppercase">Learn More</h1>
                <a href="https://workplace.terracemetrics.org/?loginpress_code=jhk4X3BbAzKlANHVaahLJB9R5TVGGc">
                  <ButtonArrow className="button green" text="Go to Wellness Center" type="button" />
                </a>
              </div>
            }
          </>
        ) : (
          <Backdrop>
            <Spinner />
          </Backdrop>
        )}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    report: state.reports.report,
    isLoading: state.reports.isLoading,
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: participantId => dispatch(actions.getReport(participantId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WellnessCenterHome));
