import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  content: '',
  show: false,
};

const openToaster = (state, action) => {
  return update(state, {
    content: { $set: action.content },
    show: { $set: true },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_TOASTER:
      return openToaster(state, action);
    case actionTypes.CLOSE_TOASTER:
      return initialState;
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
