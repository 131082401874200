import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as IconSpeaker } from '../../assets/imgs/icon-speaker.svg';
import getSupportedLanguages from '../../utilities/getContent/supportedLanguages';

import * as actions from '../../store/actions';

class ButtonAudio extends PureComponent {
  supportedLanguages = getSupportedLanguages();

  currentLang = this.supportedLanguages.find((thisLang) => {
    return thisLang.value === this.props.lang;
  });

  componentDidMount() {
    if (this.props.playlist[0] && this.currentLang.audio) {
      const audioPlayer = this.audioElement;
      let currentTrackNum = 0;

      const resetPlayer = () => {
        currentTrackNum = 0;
        changeTrackNum(currentTrackNum);
      }

      const changeTrackNum = (newTrackNum, autoPlay = false) => {
        if (this.props.playlist[newTrackNum]) {
          audioPlayer.src = this.props.playlist[newTrackNum];
          audioPlayer.load();

          if (autoPlay === true) {
            audioPlayer.play();
          }
        }
      }

      audioPlayer.addEventListener('play', (e) => {
        //e.stopPropagation();
        this.props.playPause(e);
      });

      audioPlayer.addEventListener('pause', (e) => {
        //e.stopPropagation();
        this.props.playPause(e);

        if (audioPlayer.currentTime < audioPlayer.duration) {
          resetPlayer();
        }
      });

      // Move to next track and play.
      audioPlayer.addEventListener('ended', () => {
        if (this.props.playlist.length > 1
          && (currentTrackNum + 1) < this.props.playlist.length
        ) {
          currentTrackNum += 1;
          changeTrackNum(currentTrackNum, true);
        } else if (currentTrackNum > 0
          && (currentTrackNum + 1) === this.props.playlist.length
        ) {
          resetPlayer();
        }
      });
    }
  }

  render() {
    this.currentLang = this.supportedLanguages.find((thisLang) => {
      return thisLang.value === this.props.lang;
    });

    return (this.props.playlist[0] && this.currentLang.audio ? (
      <button
        className={`audio-button${this.props.label ? ' has-label' : ''}${this.props.className ? ` ${this.props.className}` : ''}`}
        data-label={this.props.label}
        onClick={() => {
          if (!this.audioElement.paused) {
            this.audioElement.pause();
          } else {
            this.audioElement.play();
          }
        }}
      >
        <audio
          ref={ref => this.audioElement = ref}
          src={process.env.PUBLIC_URL + this.props.playlist[0]}
        />
        <IconSpeaker />
        <span>{this.props.label}</span>
      </button>
    ) : null);
  }
}

const mapStateToProps = state => {
  return {
    playingAudio: state.media.playingAudio,
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    playPause: () => dispatch(actions.playPause()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ButtonAudio);
