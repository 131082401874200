import React, { Component } from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';


class QuestionsOfConcernPage extends Component {

  getSurveyType = () => {
    const appliedTo = this.props.payload.appliedTo || null;
    // const surveyName = this.props.payload.surveyName || null;
    switch (appliedTo) {
      case "adult":
        return 'adult';
      case "upper_grades":
        return 'uppergrades';
      case "elementary":
        return 'elementary';
      default:
        return null;
    }
  }

  render() {
    const payload = this.props.payload;
    const filteredIndicators = payload.indicators.filter(item => item.responses && item.responses.length > 0);
    const surveyType = this.getSurveyType();
    const replacements = [
      { match: '{payload\\.participant\\.firstName}', replacement: payload.participant.firstName },
      { match: '{payload\\.participant\\.lastName}', replacement: payload.participant.lastName },
    ];
  
    if (payload.selfHarmResponse > 0 || filteredIndicators.length || (!payload.surveyResultIsValid && payload.validityQuestions)) {
      return (
        <div className="page questions-of-concern">
          <main>
            <h1 className="mb-2 mt-2 text-uppercase">{getContent('report_questions-of-concern')}</h1>
            <p className="mb-5">
              {getContent('report_questions-of-concern-intro', replacements)}
            </p>
            {/* Validity Questions */}

            {!payload.surveyResultIsValid && payload.validityQuestions ? (
              <div className="concerns-container">
                <h2 className="mb-4">{getContent('report_validity-questions')}</h2>
                <table className="table table-sm table-bordered results mb-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{getContent('report_question')}</th>
                      <th className="text-center">{getContent('report_answer')}</th>
                    </tr>
                    {payload.validityQuestions.map((item, index) => {
                      return (
                        <tr key={getContent(item.questionText, null, surveyType)}>
                          <td width="30" className="text-center">
                            {index + 1}.
                          </td>
                          <td>{getContent(item.questionText)}</td>
                          <td className="text-center">{getContent(item.responseOptionField, null, surveyType)}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            ) : null}

            {/* At risk answers */}
            {/* map over all indicators with responses here */}
            {payload.selfHarmResponse > 0 &&
              <div className="concerns-container" key="self-harm-indicator">
                <h2 className="mb-4">{getContent('indicator_self-harm')}</h2>
                <table className="table table-sm table-bordered results mb-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>QUESTION</th>
                      <th className="text-center">ANSWER</th>
                    </tr>
                    <tr key={"self-harm-id"}>
                      <td width="30" className="text-center">
                        1.
                        </td>
                      <td>{getContent('question_4fc7b0e5-fc24-50a1-b104-80a4341c3406')}</td>
                      <td className="text-center">{getContent(payload.selfHarmResponseOptionField)}</td>
                    </tr>
                  </thead>
                </table>
              </div>
            }

            {filteredIndicators && filteredIndicators.map((concern, ci) => {
              return (
                <div className="concerns-container" key={ci}>
                  <h2 className="mb-4">{getContent(concern.contentId || concern.indicator)}</h2>
                  <table className="table table-sm table-bordered results mb-4">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{getContent('report_question')}</th>
                        <th className="text-center">{getContent('report_answer')}</th>
                      </tr>
                      {concern.responses.map((qa, ni) => {
                        if (payload.selfHarmResponse > 0 && qa.questionText === 'question_4fc7b0e5-fc24-50a1-b104-80a4341c3406') return null;
                        return (
                          <tr key={ni}>
                            <td width="30" className="text-center">
                              {ni + 1}.
                            </td>
                            <td>{getContent(qa.questionText, null, surveyType)}</td>
                            <td className="text-center">{getContent(qa.responseOptionField, null, surveyType)}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>

                  {/* end answers */}
                </div>
                // end concerns-container
              );
            })}
            {/* end at risk answers */}
          </main>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default QuestionsOfConcernPage;
