import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';
import * as actions from '../../../../store/actions';
import { Button, Col, Container, Row } from 'reactstrap';
import logo from '../../../../assets/imgs/tm-logo-v2.png';

const ChangePassword = ({ changePassword }) => {
  const [submitting, setSubmitting] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(null);

  const changePasswordSuccess = () => {
    setSubmitting(false);
    setPasswordChanged('success');
  };

  const changePasswordFailed = error => {
    setSubmitting(false);
    setPasswordChanged(error);
  };
  const handleChangePassword = values => {
    // TODO: Validate newPassword === confirmPassword
    setSubmitting(true);
    changePassword(values.newPassword, changePasswordSuccess, changePasswordFailed);
  };

  return (
    <div className="change-password">
      <Container className='mt-3'>
        <Row className="mb-4">
          <img style={{ width: '200px' }} src={logo} />
        </Row>
        <Row>
          <Col>
            <h1 className="mb-4">New Password Required </h1>
            <p>Welcome to Terrace Metrics!</p>
            <p>In order to proceed, please set your password.</p>
            <p>Your New Password:</p>
            <ul>
              <li>Must be at least 8 characters long</li>
              <li>Must contain at least one uppercase character</li>
              <li>Must contain at least one number</li>
              <li>Must contain at least one symbol (@,$, etc)</li>
            </ul>
            {passwordChanged ? (
              <div>
                {passwordChanged === 'success' ? (
                  <>
                    <div className="alert alert-success" role="alert">
                      Your password has been saved successfully.
                    </div>
                    <Button
                      color="primary"
                      onClick={() => {
                        window.location.href = `/login`;
                      }}
                    >
                      Go to Login page
                    </Button>
                  </>
                ) : (
                  <div className="alert alert-error" role="alert">
                    Sorry, there was an error.
                  </div>
                )}
              </div>
            ) : (
              <ChangePasswordForm onSubmit={handleChangePassword} submitting={submitting} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (newPassword, cb, cbError) => dispatch(actions.changePassword(newPassword, cb, cbError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));
