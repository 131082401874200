import {
  faArrowLeftRotate,
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Container } from 'reactstrap';

// requiredFields = [ {key: "firstName", label: "First Name", description: "First name" }, ... ]
// headerMap = { someWeirdColumnName: "firstName" , somethingUseless: null,  ...}
const FieldPicker = ({
  requiredFields,
  optionalFields,
  csvParsedResults,
  validateHeaderMap,
  cancel,
}) => {
  const [headerMap, setHeaderMap] = useState(
    Object.fromEntries(
      csvParsedResults.meta.fields.map(fieldName => [fieldName, null]),
    ),
  );
  const reverseMap = Object.fromEntries(
    Object.entries(headerMap)
      .filter(([, fieldKey]) => fieldKey !== null)
      .map(([csvFieldName, fieldKey]) => [fieldKey, csvFieldName]),
  );
  const allRequiredSelected = () => {
    const selectedKeys = Object.keys(reverseMap);
    // console.log(`selectedkeys = `, selectedKeys);
    return requiredFields.every(rf => selectedKeys.includes(rf.key));
  };

  // TODO: check if value is set elsewhere
  const mapValue = (csvFieldName, action, fieldKey) => {
    setHeaderMap(prevState => ({
      ...prevState,
      ...(action === 'select-option' && csvFieldName)? { [csvFieldName]: fieldKey} : {},
      ...reverseMap.hasOwnProperty(fieldKey) ?  { [reverseMap[fieldKey]]: null } : {}
    }));
    // if (action === 'select-option') {
    //   if (reverseMap.hasOwnProperty(fieldKey))
    //     delete headerMap[reverseMap[fieldKey]];
    //   if (csvFieldName)
    //     setHeaderMap(prevState => ({
    //       ...prevState,
    //       [csvFieldName]: fieldKey,
    //     }));
    // }
    // if (action === 'clear' && reverseMap.hasOwnProperty(fieldKey)) {
    //   console.log('clearing', fieldKey, reverseMap[fieldKey]);
    //   setHeaderMap(prevState => ({
    //     ...prevState,
    //     [reverseMap[fieldKey]]: null,
    //   }));
    // }
  };

  const getMapValue = fieldKey => {
    const selectedOption =
      selectOptions.find(option => option.value === reverseMap[fieldKey]) ||
      null;
    return selectedOption;
  };

  const checkField = (fieldKey, isRequired) => {
    const correctValue = reverseMap[fieldKey] !== undefined;
    const isMissing = isRequired && !correctValue;
    return {
      correctValue,
      isMissing,
    };
  };

  const selectOptions = csvParsedResults.meta.fields.map(fieldName => {
    const sampleData =
      csvParsedResults.data
        .slice(0, 3)
        .map(row => row[fieldName] || '(empty)')
        .join(', ') + ', ...';
    return {
      value: fieldName,
      label: (
        <>
          <span className="fw-bold">{fieldName}</span>
          <span className="text-muted fst-italic ms-1">{sampleData}</span>
        </>
      ),
    };
  });
  return (
    <Container>
      <Button onClick={cancel} className='float-end' size='sm'><FontAwesomeIcon icon={faArrowLeftRotate} className='me-1' />Change file</Button><h3>Column Mapping</h3>
      <p>
        The file you just uploaded does not conform to our template. Please
        select which column in you file correspond to each of the required
        fields.
      </p>
      <fieldset className="row mb-3">
        <legend>
          Required Fields
          {allRequiredSelected() && (
            <FontAwesomeIcon color={'green'} icon={faCircleCheck} />
          )}{' '}
        </legend>
        {requiredFields.map(field => {
          const checkFieldResponse = checkField(field.key, true);
          return (
            <div
              className="field-select-wrapper col-12"
              key={`selectDiv-${field.key}`}
            >
              <label className="mt-2" htmlFor={`select-${field.key}`}>
                {field.label}{' '}
                {checkFieldResponse.correctValue && (
                  <FontAwesomeIcon color={'green'} icon={faCircleCheck} />
                )}{' '}
                {checkFieldResponse.isMissing && (
                  <FontAwesomeIcon color={'red'} icon={faCircleExclamation} />
                )}
                {field.description && (
                  <span className="selectDescription text-muted fst-italic ms-1">
                    {field.description}
                  </span>
                )}
              </label>
              <Select
                required={true}
                name={`select-${field.key}`}
                placeholder={'Select'}
                options={selectOptions}
                onChange={(selectedOption, { action }) =>
                  mapValue(selectedOption?.value, action, field.key)
                }
                value={getMapValue(field.key)}
              />
            </div>
          );
        })}
      </fieldset>
      <fieldset className="row mb-3">
        <legend>Optional Fields</legend>
        {optionalFields.map(field => {
          const checkFieldResponse = checkField(field.key, false);
          return (
            <div
              className="field-select-wrapper col-12"
              key={`selectDiv-${field.key}`}
            >
              <label className="mt-2" htmlFor={`select-${field.key}`}>
                {field.label}{' '}
                {checkFieldResponse.correctValue && (
                  <FontAwesomeIcon color={'green'} icon={faCircleCheck} />
                )}{' '}
                {checkFieldResponse.isMissing && (
                  <FontAwesomeIcon color={'red'} icon={faCircleExclamation} />
                )}
                {field.description && (
                  <span className="selectDescription text-muted fst-italic ms-1">
                    {field.description}
                  </span>
                )}
              </label>
              <Select
                name={`select-${field.key}`}
                placeholder={'Select'}
                isClearable={true}
                options={selectOptions}
                onChange={(selectedOption, { action }) =>
                  mapValue(selectedOption?.value, action, field.key)
                }
                value={getMapValue(field.key)}
              />
            </div>
          );
        })}
      </fieldset>
      <Button block color="primary" disabled={!allRequiredSelected()} onClick={() => validateHeaderMap(headerMap)}>Proceed with selected columns </Button>
    </Container>
  );
};

export default FieldPicker;
