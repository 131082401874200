import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: false,
  isLoadingOrganizations: false, // listOrganizations
  isLoadedOrganizations: false,
  orgListNeedsUpdate: false,
  error: null,
  createOrgError: null,
  organizations: [],
  organization: null,
  realOrganization: null,
  schoolSearchName: '',
  districtSearchName: '',
  isOrgSearchVisible: false,
  hasSearched: false,
  isDistrictSyncLoading: false,
};

const showOrgSearch = state => {
  return update(state, {
    isOrgSearchVisible: { $set: true },
  });
};

const listOrganizationsStart = state => {
  return update(state, {
    isLoadingOrganizations: { $set: true },
    error: { $set: null },
  });
};
const hideOrgSearch = state => {
  return update(state, {
    isOrgSearchVisible: { $set: !state.isOrgSearchVisible },
  });
};
const listOrganizationsSuccess = (state, action) => {
  return update(state, {
    isLoadingOrganizations: { $set: false },
    organizations: { $set: action.organizations },
    hasSearched: { $set: action.organizations.hasSearched },
    isLoadedOrganizations: { $set: true },
  });
};

const listOrganizationsFail = (state, action) => {
  return update(state, {
    isLoadingOrganizations: { $set: false },
    error: { $set: action.err },
  });
};

const getOrganizationStart = state => {
  return update(state, {
    isLoading: { $set: true },
    isLoaded: { $set: false },
    error: { $set: null },
  });
};

const getOrganizationSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    isLoaded: { $set: true },
    organization: { $set: action.organization },
  });
};

const getOrganizationFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const createOrganizationStart = state => {
  return update(state, {
    orgListNeedsUpdate: { $set: false },
    createOrgError: { $set: null },
  });
};

const createOrganizationSuccess = (state, action) => {
  return update(state, {
    orgListNeedsUpdate: { $set: action.organization.classification },
  });
};

const createOrganizationFail = (state, action) => {
  return update(state, {
    createOrgError: { $set: action.err },
  });
};

const clearCreateOrgError = state => {
  return update(state, {
    createOrgError: { $set: null },
  });
};

const updateOrganizationStart = (state, action) => {
  return update(state, {
    orgListNeedsUpdate: { $set: false },
    error: { $set: action.err },
  });
};

const updateOrganizationSuccess = (state, action) => {
  return update(state, {
    orgListNeedsUpdate: { $set: action.organization.classification },
  });
};

const updateOrganizationFail = (state, action) => {
  return update(state, {
    error: { $set: action.err },
  });
};

const deleteOrganizationStart = state => {
  return update(state, {
    orgListNeedsUpdate: { $set: false },
    error: { $set: null },
  });
};

const deleteOrganizationSuccess = state => {
  return update(state, {
    orgListNeedsUpdate: { $set: true },
  });
};

const deleteOrganizationFail = (state, action) => {
  return update(state, {
    error: { $set: action.err },
  });
};

const changeOrganizationSuccess = (state, action) => {
  return update(state, {
    realOrganization: { $set: action.realOrg },
    organizations: { $set: null },
  });
};

const resetOrganizationSuccess = (state, action) => {
  let oldValue = action.realOrg;
  if (!state.realOrganization) {
    oldValue = null;
  }
  if (action)
    return update(state, {
      organization: { $set: oldValue },
      realOrganization: { $set: null },
      organizations: { $set: null },
    });
};

const updateSearchOrganizationObj = (state, action) => {
  return update(state, {
    [action.payload.name]: { $set: action.payload.value },
  });
};

const clearOrganization = state => {
  return update(state, {
    isLoading: { $set: initialState.isLoading },
    isLoaded: { $set: initialState.isLoaded },
    isLoadingOrganizations: { $set: initialState.isLoadingOrganizations },
    error: { $set: initialState.error },
    createOrgError: { $set: initialState.createOrgError },
    organizations: { $set: initialState.organizations },
    organization: { $set: initialState.organization },
    realOrganization: { $set: initialState.realOrganization },
    schoolSearchName: { $set: initialState.schoolSearchName },
    districtSearchName: { $set: initialState.districtSearchName },
    isOrgSearchVisible: { $set: initialState.isOrgSearchVisible },
    hasSearched: { $set: initialState.hasSearched },
  });
};

const clearOrganizationSearchOptions = state => {
  return update(state, {
    schoolSearchName: { $set: initialState.schoolSearchName },
    districtSearchName: { $set: initialState.districtSearchName },
  });
};

const clearSchoolSearchOptions = state => {
  return update(state, {
    schoolSearchName: { $set: initialState.schoolSearchName },
  });
};

const clearOrganizationErrors = state => {
  return update(state, {
    error: { $set: null },
  });
};

const startDistrictSyncStart = state => {
  return update(state, {
    isDistrictSyncLoading: { $set: true },
    error: { $set: null },
  });
};

const startDistrictSyncSuccess = (state, action) => {
  return update(state, {
    isDistrictSyncLoading: { $set: false },
    organization: { $set: action.organization },
  });
};

const startDistrictSyncFail = (state, action) => {
  return update(state, {
    isDistrictSyncLoading: { $set: false },
    error: { $set: action.err },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_ORGANIZATIONS_START:
      return listOrganizationsStart(state, action);
    case actionTypes.LIST_ORGANIZATIONS_SUCCESS:
      return listOrganizationsSuccess(state, action);
    case actionTypes.LIST_ORGANIZATIONS_FAIL:
      return listOrganizationsFail(state, action);
    case 'LIST_ORGANIZATIONS_RESET':
      return update(state, {
        isLoadedOrganizations: { $set: false },
        organizations: { $set: []}
      });
    case actionTypes.GET_ORGANIZATION_START:
      return getOrganizationStart(state, action);
    case actionTypes.GET_ORGANIZATION_SUCCESS:
      return getOrganizationSuccess(state, action);
    case actionTypes.GET_ORGANIZATION_FAIL:
      return getOrganizationFail(state, action);
    case actionTypes.CREATE_ORGANIZATION_START:
      return createOrganizationStart(state, action);
    case actionTypes.CREATE_ORGANIZATION_SUCCESS:
      return createOrganizationSuccess(state, action);
    case actionTypes.CREATE_ORGANIZATION_FAIL:
      return createOrganizationFail(state, action);
    case actionTypes.CLEAR_CREATEORG_ERRORS:
      return clearCreateOrgError(state, action);
    case actionTypes.UPDATE_ORGANIZATION_START:
      return updateOrganizationStart(state, action);
    case actionTypes.UPDATE_ORGANIZATION_SUCCESS:
      return updateOrganizationSuccess(state, action);
    case actionTypes.UPDATE_ORGANIZATION_FAIL:
      return updateOrganizationFail(state, action);
    case actionTypes.DELETE_ORGANIZATION_START:
      return deleteOrganizationStart(state, action);
    case actionTypes.DELETE_ORGANIZATION_SUCCESS:
      return deleteOrganizationSuccess(state, action);
    case actionTypes.DELETE_ORGANIZATION_FAIL:
      return deleteOrganizationFail(state, action);
    case actionTypes.CHANGE_ORGANIZATION_SUCCESS:
      return changeOrganizationSuccess(state, action);
    case actionTypes.RESET_ORGANIZATION_SUCCESS:
      return resetOrganizationSuccess(state, action);
    case actionTypes.HIDE_ORG_SEARCH:
      return hideOrgSearch(state, action);
    case actionTypes.UPDATE_ORGANIZATION_SEARCH:
      return updateSearchOrganizationObj(state, action);
    case actionTypes.SHOW_ORG_SEARCH:
      return showOrgSearch(state, action);
    case actionTypes.CLEAR_ORGANIZATION:
      return clearOrganization(state, action);
    case actionTypes.CLEAR_ORGANIZATION_SEARCH_OPTIONS:
      return clearOrganizationSearchOptions(state, action);
    case actionTypes.CLEAR_SCHOOL_SEARCH_OPTIONS:
      return clearSchoolSearchOptions(state, action);
    case actionTypes.CLEAR_ORGANIZATION_ERRORS:
      return clearOrganizationErrors(state, action);
    case actionTypes.START_DISTRICT_SYNC_START:
      return startDistrictSyncStart(state, action);
    case actionTypes.START_DISTRICT_SYNC_SUCCESS:
      return startDistrictSyncSuccess(state, action);
    case actionTypes.START_DISTRICT_SYNC_FAIL:
      return startDistrictSyncFail(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
