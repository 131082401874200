import React from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button} from 'reactstrap';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';

const BulkNotifyParentModal = ({
  surveyId,
  bulkNotifyParentReport,
  show,
  closeModal
}) => {

  const handleSubmit = () => {
    bulkNotifyParentReport(surveyId);
  }

    return (
      <ModalWrapper className='survey-status-modal-wrapper' show={show} onExited={closeModal}>
        <ModalHeader toggle={closeModal}>
            Bulk Report Notification
        </ModalHeader>
        <ModalBody bsPrefix='modalContent survey-status-modal'>
          <strong>IMPORTANT: This action will email/text all the contacts associated with this survey.</strong> <br />  
          <strong>This action can not be undone.</strong>
          <div className="btnContainer-edit-participant">
          <Button
            onClick={() => handleSubmit()}
            type="submit"
            className="modalBtn"
            size="md"
            color="danger"
          >
            Send Notification
          </Button>
          <Button onClick={closeModal} className="modalBtn" size="md" variant="outline-primary">
            Cancel
          </Button>
        </div>
        </ModalBody>
      </ModalWrapper>
    );
}


const mapDispatchToProps = dispatch => {
  return {
    bulkNotifyParentReport: (surveyId) => dispatch(actions.bulkNotifyParentReport(surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};


export default connect(null,mapDispatchToProps)(BulkNotifyParentModal);
