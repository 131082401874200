import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import getAuthenticatedUser from '../../utilities/getAuthenticatedUser';

const isAuthenticated = WrappedComponent => {
  const AuthComponent = props => {
    // const history = useHistory();
    const location = useLocation();

    const redirectToLogin = () => {
      const qsp = (!location.pathname.match(/\/admin\/?$/ )) ? `?redirect=${encodeURIComponent(location.pathname + location.search)}` : ''
      const loginUrl = '/login' + qsp ;
      // console.log(`qsp: ${qsp}`)
      window.location.href = loginUrl;
    };
    const [authStateChecked, setAuthStateCheck] = useState(false);
    useEffect(() => {
      async function checkAuthState() {
        await props.checkAuthState(redirectToLogin);
        // console.log(`checkAuthState has finished and now isAuthenticated = ${props.isAuthenticated} and redirectUrl = ${loginUrl}` )
        setAuthStateCheck(true);
      }
      checkAuthState();
    }, []);

    // useEffect(() => {
    //   checkAndRedirect();
    // });

    // const checkAndRedirect = async () => {
    //   console.log('checkAndRedirect');
    //   const { history } = props;
    //   const user = await getAuthenticatedUser();

    //   if (!user) {
    //     const redirectUrl = `/login?redirect=${encodeURIComponent(location.pathname + location.search)}`;
    //     console.log(`checkAndRedirect is redirecting to ${redirectUrl}`);
    //     history.push(redirectUrl);
    //   }
    // };

    return (
      <>
        {authStateChecked &&
          (props.isAuthenticated ? (
              <WrappedComponent {...props} />
          ) : // <Redirect to={loginUrl} /> // we redirect in checkAuthState now
          null)}
      </>
    );
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.auth.user ? true : false,
    role: state.auth.role
  });

  const mapDispatchToProps = dispatch => ({
    checkAuthState: redirectToLogin => dispatch(actions.checkAuthState(redirectToLogin)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
};

export default isAuthenticated;
