import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { getContent } from '../../../utilities/getContent/getContent';
import TMCard from '../../../components/TMCard/TMCard';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import { getLogo } from './../../../assets/helper';
import WellnessCenterAccessForm from './WellnessCenterAccessForm';
import Backdrop from '../../../components/Backdrop/Backdrop';
import Spinner from '../../../components/Spinner/Spinner';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { createStringDate } from '../../../utilities/dates';

const WellnessCenterAccessV2 = props => {
  const location = useLocation();

  if (props.loggedIn) {
    return <Redirect to={{ pathname: `/wellness-center/home`, state: { participantId: props.participantId } }} />;
  }

  const handleSubmit = values => {
    let submission = {};
    if (values.hasOwnProperty('email')) {
      submission = { ...values };
    } else {
      submission = {
        inviteCode: values.inviteCode.toUpperCase().replace(/[^A-Z]/g, ''),
        firstName: values.firstName,
        lastName: values.lastName,
        // birthDate: birthDate,
      };
    }

    props.loginWellnessCenter(submission);
  };

  useEffect(()=> {
    if (location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed && parsed.pId && parsed.firstName && parsed.lastName) {
        const inviteCode = parsed.pId;
        const firstName = parsed.firstName;
        const lastName = parsed.lastName;
        const submission = {
          inviteCode: inviteCode.toUpperCase().replace(/[^A-Z]/g, ''),
          firstName,
          lastName,
        };
        props.loginWellnessCenter(submission);
      }
    }

  }, [location])

  return (
    <>
      {props.loading || props.loggedIn || location.search ? (
        <Backdrop>
          <Spinner />
        </Backdrop>
      ) : (
        <div className="page-container-survey-access wellness-center">
          <TMCard
            className="tm-card-stepper"
            header={[
              <div className="text-center mb-4">
                <img className="w-75 " src={getLogo()} alt="Logo" />
              </div>,
              <div className="mb-3">
                <LanguagePicker />
              </div>,
              <p>{getContent('wellnesscenter_content-access-1')}</p>,
              <p>{getContent('wellnesscenter_content-access-2')}</p>,
            ]}
          >
            {props.authError ? (
              <div>
                <p className="error-text">{props.authError}</p>
              </div>
            ) : null}
            <WellnessCenterAccessForm onSubmit={handleSubmit} accessCodeVersion={2} />
          </TMCard>
        </div>
      )}
    </>
  );
};



const mapStatetoProps = state => ({
  authError: state.wellnessCenterAuth.error,
  loggedIn: state.wellnessCenterAuth.loggedIn,
  participantId: state.wellnessCenterAuth.participantId,
  showErrorMsg: state.wellnessCenterAuth.showErrorMsg,
});

const mapDispatchToProps = dispatch => ({
  loginWellnessCenter: values => dispatch(actions.loginWellnessCenter(values)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(WellnessCenterAccessV2);
