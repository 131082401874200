import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import Spinner from '../../../../../components/Spinner/Spinner';

// Localizer required for DateTime Picker
moment.locale('en');
momentLocalizer();

const Timegate = (props) => {
  return props.grades && props.grades.length > 0 && (
    <div>
      <p className="timegate-header">Choose a date and time for each grade's survey activation.</p>
      {
        props.grades.map(grade => {
          const choice = moment(grade.value);
          const date = `${choice.format('MMM')} ${choice.format('D')} ${choice.format('YYYY')}`;
          const time = `${choice.format('hh')}:${choice.format('mm')} ${choice.format('A')}`
          return (
            <div className="timegate-wrapper">
              <div className="timegate-label"><p>Grade {grade.label}: </p></div>
              <div className="timegate-component">
                <div className="timegate-component-input">{date}, {time}</div>
                <DateTimePicker
                  name={grade.label}
                  step={15}
                  inputProps={{ disabled: true }}
                  value={grade.value}
                  onChange={(value) => grade.onChange(grade.label, value)} />
              </div>
            </div>
          )
        })
      }
      <Button
        type="button"
        className="small-button green"
        size="lg"
        onClick={props.save}
      >
        Save Activation Dates {props.isLoading && <Spinner />}
      </Button>
    </div>
  );
};

export default Timegate;