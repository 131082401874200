// import React from 'react';
// import { Redirect } from 'react-router-dom';

// const AdminHome = () => {
//   return (
//     // <Redirect to="/admin/survey/list?status=ongoing" />
//     <Redirect to="/admin" />
//   )
// }

// export default AdminHome

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import ButtonNav from '../../../components/Button/ButtonNav';
import * as actions from '../../../store/actions';
// import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
// import { ReactComponent as IconNavReports } from '../../../assets/imgs/icon-nav-reports.svg';
// import { ReactComponent as IconNavSurveys } from '../../../assets/imgs/icon-nav-surveys.svg';
// import { ReactComponent as IconNavUsers } from '../../../assets/imgs/icon-nav-users.svg';
// import { ReactComponent as IconNavSearchParticipant } from '../../../assets/imgs/icon-nav-search-participant.svg';
// import { ReactComponent as IconNavSearch } from '../../../assets/imgs/icon-nav-search.svg';
import Spinner from '../../../components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'reactstrap';
// import CheckBox from '../../../components/CheckBox/CheckBox';

const AdminHome = ({
  // openModal,
  // closeModal,
  // orgName,
  // bc_orgId,
  // bc_level,
  role,
  // updateOrganization,
  isLoading,
  realRole,
  setShowGetStarted,
  // isTMAllowed: isTMAllowedProp,
}) => {
  // const [isTMAllowed, setIsTMAllowed] = useState(isTMAllowedProp || false);
  // const isUK = process.env.REACT_APP_STAGE === 'uk';
  // const history = useHistory();

  // const openConfirmationModal = (confirm, isAllowed) => {
  //   openModal({
  //     modalType: 'ACCESS_CONFIRMATION_MODAL',
  //     show: true,
  //     closeModal: closeModal(),
  //     isAllowed,
  //     confirm,
  //   });
  // };
  // const getRoleBasedData = (role, type, item) => {
  //   const rolesText = {
  //     ADMIN: {
  //       orgs: {
  //         show: true,
  //         text: `${isUK ? 'Groups' : 'Organizations'}`,
  //         descr: `${isUK ? 'Add new Groups or edit existing Groups' : 'Add new Organizations or edit existing Organizations'}`,
  //       },
  //       surveys: {
  //         show: true,
  //         text: 'Assessments',
  //         descr: 'View Created Assessments',
  //       },
  //       search: {
  //         show: false,
  //         text: 'Search participant',
  //         descr: 'View all assessments of a participant',
  //       },
  //       reports: {
  //         show: true,
  //         text: 'Reports',
  //         descr: 'View results from individual Assessment',
  //       },
  //       users: {
  //         show: true,
  //         text: `${isUK ? 'Add MyMynd User' : 'Add Terrace User'}`,
  //         descr: `Set up or edit other ${isUK ? 'MyMynd' : 'Terrace'} Admin users`,
  //       },
  //     },
  //     ORGANIZATION: {
  //       orgs: {
  //         show: true,
  //         text: `${isUK ? 'Organisations' : 'Groups'}`,
  //         descr: `${isUK ? 'View, add, or edit organisations within your group' : 'View, add or edit groups within your organizations'}`,
  //       },
  //       surveys: {
  //         show: true,
  //         text: 'Assessments',
  //         descr: 'View Created Assessments',
  //       },
  //       search: {
  //         show: true,
  //         text: 'Search participant',
  //         descr: 'View all assessments of a participant',
  //       },
  //       reports: {
  //         show: true,
  //         text: 'Reports',
  //         descr: 'View Individual Reports',
  //       },
  //       users: {
  //         show: true,
  //         text: `${isUK ? 'Group Champions' : 'Organization Champions'}`,
  //         descr: 'View, add or edit other users',
  //       },
  //     },
  //     GROUP: {
  //       orgs: {
  //         show: false,
  //         text: '',
  //         descr: '',
  //       },
  //       surveys: {
  //         show: true,
  //         text: 'Assessments',
  //         descr: 'Create new assessments, edit or view existing assessments',
  //       },
  //       search: {
  //         show: true,
  //         text: 'Search participant',
  //         descr: 'View all assessments of a participant',
  //       },
  //       reports: {
  //         show: true,
  //         text: 'Reports',
  //         descr: 'View Individual Reports',
  //       },
  //       users: {
  //         show: true,
  //         text: `${isUK ? 'Organisation Champions' : 'Group Champions'}`,
  //         descr: 'View, add or edit other users',
  //       },
  //     },
  //   };
  //   return rolesText[role][type][item];
  // };

  // const toggleAllowed = () => {
  //   setIsTMAllowed(!isTMAllowed);
  //   const newItem = {
  //     isTMAllowed: !isTMAllowed,
  //     orgId: props.orgId,
  //     name: orgName,
  //   };
  //   updateOrganization(newItem);
  // };

  // useEffect(() => {
  //   getAggregatedOverallReport();
  // }, []);

  // const breadcrumbs = [
  //   {
  //     key: 1,
  //     url: '/admin',
  //     label: 'Home',
  //   },
  // ];

  const showGetStarted = useSelector(state => state.meta.showGetStarted);

  const toggleGetStarted = () => {
    console.log(`clicked! showGetStarted: ${showGetStarted}`)
    setShowGetStarted( (showGetStarted + 1) % 5);
  };

  return (
    <>
      {isLoading ? (
        <div className="page admin-home">
          <Spinner />
        </div>
      ) : role === 'responder' || realRole === 'responder' ? (
        <Redirect to="/responder" />
      ) : (
        <div className="page">
          <div className="p-5 mb-4 bg-body-tertiary rounded-3">
            <div className="container-fluid py-5 text-center">
              <h1 className="display-5 fw-bold">Welcome to Terrace Metrics !</h1>
              <p className="fs-4">
                In this admin app, you can manage the entire process of creating and administering assessments, as well as manage groups/schools within your organization/district.
              </p>
              <Button id="btn_getting_started" className="green fw-bold" onClick={toggleGetStarted} type="B">
                Getting Started
              </Button>
              <Tooltip className="clickable" isOpen={!!showGetStarted} target="btn_getting_started">
                <Button className='admin-home__discovery_btn' onClick={toggleGetStarted}>({showGetStarted}/4) Next</Button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    resetRole: () => dispatch(actions.resetRole()),
    resetOrganization: () => dispatch(actions.resetOrganization()),
    getAggregatedOverallReport: () => dispatch(actions.getAggregatedOverallReport()),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
    closeModal: () => dispatch(actions.closeModal()),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    setShowGetStarted: value => dispatch(actions.setShowGetStarted(value)),
  };
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    realRole: state.auth.realRole,
    orgId: state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_orgId: state.organizations && state.organizations.organization && state.organizations.organization.orgId,
    bc_higherOrgName: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.name,
    bc_higherOrgId: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.orgId,
    bc_level: state.auth.role === 'tm_admin' ? 'ADMIN' : state.auth.role === 'school_champion' ? 'GROUP' : state.auth.role === 'district_champion' ? 'ORGANIZATION' : 'NA',
    isLoading: state.organizations.isLoading,
    reportDetails: state.surveyCrud.reportDetails,
    isTMAllowed: state.organizations && state.organizations.organization && state.organizations.organization.isTMAllowed,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminHome));
