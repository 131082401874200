import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as actions from '../../../../../store/actions/navigation/navigation';
import OrgLogo from './OrgLogo';


const SelectDistrict = props => {
  // const [selectedValue, setSelectedValue] = useState(null);
  const selectedValue = props.navigation.districtSelected;
  const isSelectAvailable = props.user['custom:role'] === 'tm_admin';
  const districtOrg = props.navigation.org ? (props.navigation.org.classification === 'school-district' ? props.navigation.org : props.navigation.org.parentOrg) : null;

  useEffect(() => {
    if (props.user['custom:role'] === 'tm_admin') {
      if (props.navigation.districtsList.length === 0) {
        props.districtsListGet();
      }
    } else {
      // make user's org active
      if (!props.navigation.org) {
        console.log(`orgchange in select district`);
        props.orgChange(props.user['custom:orgId']);
      }
    }
  }, [props.user]);

  const onChange = (event, { action }) => {
    props.schoolSelectedSet(null);
    if (action === 'select-option') {
      props.districtSelect(event);
    }
    if (action === 'clear') {
      props.districtSelect(null);
    }
  };



  return (
    <div>
      {isSelectAvailable ? (
        <div id="header_district_select_form" className="org_logo">
          <small className="text-muted">Organization:</small>
          <Select
            className="search-survey-input-select"
            classNamePrefix="select"
            // isSearchable
            options={props.navigation.districtsList}
            placeholder="Select Organization"
            onChange={onChange}
            isClearable
            isDisabled={props.navigation.districtsListIsLoading }
            // onFocus={handleOnFocus}
            isLoading={props.navigation.districtsListIsLoading }
            value={selectedValue}
          />
        </div>
      ) : (
       <OrgLogo org={districtOrg} label="organization"/>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    navigation: state.navigation,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    districtsListGet: () => dispatch(actions.districtsListGet()),
    districtSelect: value => dispatch(actions.districtSelect(value)),
    schoolSelectedSet: value => dispatch(actions.schoolSelectedSet(value)),
    orgChange: orgId => dispatch(actions.orgChange(orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDistrict);
