import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';

class ResponderConfirmationModal extends Component {
	render() {
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<ModalHeader toggle={this.props.closeModal}>
          {this.props.title}
				</ModalHeader>
				<ModalBody bsPrefix="modalContent survey-status-modal">
					<p>{this.props.body}</p>
					<div className="btnContainer-edit-participant">
						<Button
							onClick={() => {
								this.props.confirm();
							}}
							type="submit"
							className="modalBtn"
							size="lg"
							color="success"
						>
							{this.props.buttonText}
						</Button>
						<Button
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							Cancel
						</Button>
					</div>
				</ModalBody>
			</ModalWrapper>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(actions.closeModal())
	};
};

export default connect(
	null,
	mapDispatchToProps
)(ResponderConfirmationModal);
