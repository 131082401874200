import React from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../store/actions';

const RetakeConfirmationModal = ({
  show,
  closeModal,
  confirm
}) => {
  return (
    <ModalWrapper
      className="survey-status-modal-wrapper"
      show={show}
      onExited={closeModal}
    >
      <ModalHeader closeButton>
        Retake Survey
      </ModalHeader>
      <ModalBody bsPrefix="modalContent survey-status-modal">
        <p>Please confirm that you would like to re-take your assessment, It is not recommended to retake an assessment more than once each month unless directed to do so by a member of our team or following completion of recommended content/activities</p>
        <div className="btnContainer-edit-participant">
          <Button
            onClick={() => { confirm(); }}
            type="submit"
            className="modalBtn"
            size="md"
            color="success"
          >
            Retake Assessment
          </Button>
          <Button
            onClick={closeModal}
            className="modalBtn"
            size="md"
            variant="outline-primary"
          >
            Cancel
          </Button>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RetakeConfirmationModal);
