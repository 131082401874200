import React from 'react';
import axios from 'axios';
import { json2csv } from 'json-2-csv';
// import IconEmail from '../../../../../assets/imgs/icon-email.svg';
import Spinner from '../../../../../components/Spinner/Spinner';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
// import SyncButton from './SyncButton';
import formatPhoneNumber from '../../../../../utilities/formatPhoneNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { REACT_APP_API_URL } = process.env;

const ListDistricts = props => {
  const isUK = process.env.REACT_APP_STAGE === 'uk';
  // eslint-disable-next-line no-unused-vars
  async function exportFile(csv, fileTitle) {
    let exportedFilename = fileTitle + '.csv' || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  async function exportCSVFile(items, fileTitle) {
    await json2csv(
      items,
      (err, csv) => {
        let exportedFilename = fileTitle + '.csv' || 'export.csv';
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          var link = document.createElement('a');
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      { emptyFieldValue: '^' },
    );
  }
  const history = useHistory();
  const redirectToUsers = () => {
    console.log('here!')
    history.push('/admin/users')    
  }
  const generateDistrictTableData = () => {
    const result = props.organizations.map(item => {
      return {
        country: item.country,
        state: item.state,
        districtName: (
          // props.role === 'tm_admin' ? (
          <div className="button-text" id={`locator_${item.name.replace(/\s/g, '-').toLowerCase()}`}>
            <span title='Select district' onClick={() => props.districtSelect({ value: item.orgId, label: item.name })}>{item.name}</span>
            <FontAwesomeIcon title='View Champions' onClick={() => props.districtSelect({ value: item.orgId, label: item.name }, redirectToUsers)} className="hover-only ms-3" icon={faUsers} />
          </div>
        ),
        // ) : (
        //   item.name
        // ),
        // email: (
        //   <a href={`mailto:${item.email}`}>
        //     <img src={IconEmail} alt="envelope icon" />
        //   </a>
        // ),
        cleverOrg: (
          <Badge color="info" className="text-capitalize">
            {item.idp ? item.idp : null}
          </Badge>
        ),
        employerProvider: item.employerProvider || '',
        report: (
          //item.report && item.report.indicators.length > 0 ? (
          <button
            className="button-text"
            onClick={() => {
              const url = `${REACT_APP_API_URL}/org/data?orgId=${item.orgId}`;
              axios({
                url,
                method: 'GET',
                headers: { Authorization: props.idToken },
              })
                .then(res => {
                  const resData = res.data;
                  //if(item.report && item.report.indicators.length > 0)
                  if (resData && resData.indicators.length > 0) {
                    exportCSVFile(resData.indicators, `${item.name} - ${isUK ? 'Group' : 'Organization'} Indicator Report`);
                    const { satisfactory, optimal, at_risk, moderate, no_tfs, totalStudents } = resData;
                    exportCSVFile({ satisfactory, optimal, at_risk, moderate, no_tfs, totalParticipants: totalStudents }, `${item.name} - ${isUK ? 'Group' : 'Organization'} Overall Report`);
                    exportCSVFile(resData.surveyIndicatorResults, `${item.name} - Survey Indicator Results`);
                    exportCSVFile(resData.surveyOverallResults, `${item.name} - Survey Overall Results`);
                  } else {
                    console.log('No Data for ' + item.orgId);
                  }
                  //console.log('Res startDistrictSync: ', res.data);
                  //dispatch(startDistrictSyncSuccess(res.data));
                  //dispatch(openToaster(`District has begun syncing!`));
                })
                .catch(err => {
                  console.log(err);
                  //dispatch(startDistrictSyncFail(err));
                });
            }}
          >
            Data
          </button>
        ),
        //) : null
        responses: (
          <button
            className="button-text"
            onClick={() => {
              const url = `${REACT_APP_API_URL}/org/surveys?orgId=${item.orgId}`;
              axios({
                url,
                method: 'GET',
                headers: { Authorization: props.idToken },
              })
                .then(res => {
                  const erl = res.data.url;
                  axios({
                    url: erl,
                    method: 'GET',
                    crossDomain: true,
                    headers: { 'Access-Control-Allow-Origin': '*' },
                  }).then(resl => {
                    exportCSVFile(resl.data, item.orgId + '_download.csv');
                    //exportFile(resl.data,"Downloadeddata.csv");
                  });
                  // if (resData.surveys)
                  // {
                  //   exportFile(resData.surveys, `${item.name} - ${isUK ? 'Group' : 'Organization'} Surveys CSV Data`);
                  // }
                  // if(resData.surveyResponses){
                  //   resData.surveyResponses.forEach(resp => {
                  //     if(resp && resp.items && resp.items.length > 0)
                  //     {
                  //       exportFile(resp.items, `${resp.name} - ${isUK ? 'Group' : 'Organization'} Responses CSV Data`);
                  //     }
                  //   });
                  // }
                })
                .catch(err => {
                  console.log('issue ');
                  console.log(err);
                });
            }}
          >
            Download
          </button>
        ),
        phone: formatPhoneNumber(item.phoneNumber),
        //cleverOrg: item.cleverData ? <CheckIcon className="blue-fill-icon" /> : '',
        icon: (
          <>
            <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" onClick={() => props.openCreateOrgModal(item, 'CREATE_DISTRICT_MODAL')} />
            <FontAwesomeIcon icon={faTrash} className="blue-fill-icon" disabled={!!item.cleverItem} onClick={() => props.openDeleteOrgConfirmationModal(item)} />
          </>
        ),
      };
    });
    return result;
  };

  const getDistrictTableConfig = () => {
    const config = {
      headers: ['Country', 'State', 'Organization', 'Rostering', 'Employer/Provider', 'Results', 'Responses', 'Phone', ''],
      contentRows: generateDistrictTableData(),
    };
    return config;
  };
  return (
    <>
      {props.isLoadingOrganizations ? (
        <Backdrop>
          <Spinner />
        </Backdrop>
      ) : props.organizations && props.organizations.length === 0 && !props.hasSearched ? (
        <>
          <div className="text-center py-5">
            <h1>The {isUK ? 'Group' : 'Organization'} List is currently empty.</h1>
            <p>
              It looks like no {isUK ? 'groups' : 'organizations'} have been created. Click the button to create a new {isUK ? 'group' : 'organization'}.
            </p>
            <button onClick={() => props.openCreateOrgModal(null, 'CREATE_DISTRICT_MODAL')} className="button green mt-0">
              {isUK ? 'Create Group' : 'Create Organization'}
            </button>
          </div>
        </>
      ) : (
        <>
          <p>{`${props.organizations ? props.organizations.length : '0'} ${isUK ? 'Group' : 'Organization'} Results`}</p>
          {props.organizations && props.organizations.length > 0 ? <TableGenerator config={getDistrictTableConfig()} /> : null}
        </>
      )}
    </>
  );
};

export default ListDistricts;
