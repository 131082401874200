import React from 'react';
import TMCard from '../../../components/TMCard/TMCard';
import { getLogo } from './../../../assets/helper';
import { getContent } from '../../../utilities/getContent/getContent';

const SurveyComplete = () => (
  <div className="page-container-survey-complete">
    <TMCard
      className="tm-card-stepper"
      header={[
        <div className="text-center mb-4">
          <img src={getLogo()} alt="Logo" />
        </div>,
      ]}
    >
      <p>{getContent('survey_content-survey-complete')}</p>
    </TMCard>
  </div>
);

export default SurveyComplete;
