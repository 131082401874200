import React from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../store/actions';

const AccessConfirmationModal = ({
  show,
  closeModal,
  isAllowed,
  confirm
}) => {

  const isUK = process.env.REACT_APP_STAGE === 'uk';

  return (
    <ModalWrapper
      className="survey-status-modal-wrapper"
      show={show}
      onExited={closeModal}
    >
      <ModalHeader toggle={closeModal}>
        {isAllowed ? 'Remove' : 'Allow'} {isUK ? 'MyMynd' : 'Terrace Metrics'} Access {isAllowed ? 'from' : 'to'} all our data.
      </ModalHeader>
      <ModalBody bsPrefix="modalContent survey-status-modal">
        <p>Do you confirm that you wish to {' '}
          {isAllowed ? 'remove' : 'grant'} access for {isUK ? 'MyMynd' : 'Terrace Metrics'} {isAllowed ? 'from' : 'to'} all of your user data?</p>
        <div className="btnContainer-edit-participant">
          <Button
            onClick={() => {
              confirm();
              closeModal()
            }}
            type="submit"
            className="modalBtn"
            size="md"
            color="danger"
          >
            Yes
          </Button>
          <Button
            onClick={closeModal}
            className="modalBtn"
            size="md"
            color="transparent"
          >
            No
          </Button>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AccessConfirmationModal);
