import React, { useEffect,  } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../../store/actions';
import { getLogo } from './../../../../assets/helper';
import LoginForm from './LoginForm';
import loginImage from '../../../../assets/imgs/group-survey-title1.jpg';
// import { ContactForm } from '../../Support/HelpOverlay';

const Login = props => {
  const location = useLocation();
  useEffect(() => {
    props.resetApp();
    // TODO : find out what is it for?
    const parsed = queryString.parse(props.location.search);
    if (!parsed || !parsed.pw || !parsed.un) return;
    const password = decodeURIComponent(parsed.pw);
    const username = decodeURIComponent(parsed.un);
    if (!password || !username) return;
    props.login(username, password, redirectToChangePassword);
  }, [props.location.search]);

  const loginHandler = values => {
    const { login } = props;
    const { username, password } = values;
    login(username, password, redirectToChangePassword, redirectToEncodedUrl);
  };

  const redirectToEncodedUrl = () => {
    const params = new URLSearchParams(location.search);

    const redirect = params.get('redirect');
    console.log(`redirectToEndodedUrl with ${redirect}`);
    if (redirect) {
      props.history.push(redirect);
    } else {
      props.history.push('/admin');
    }
  };

  const redirectToChangePassword = () => {
    props.history.push('/change-password');
  };

  const redirectToResetPassword = () => {
    props.history.push('/reset-password');
  };

  const openForgotPasswordModal = () => {
    props.openModal({
      modalType: 'FORGOT_PASSWORD_MODAL',
      show: true,
      closeModal: props.closeModal,
      redirectToResetPassword: redirectToResetPassword,
    });
  };

  // const openContactModal = () => {
  //   props.openModal({
  //     modalType: 'GENERIC_MODAL',
  //     show: true,
  //     content: (
  //       <>
  //         <ContactForm hide={props.closeModal} addExtra={false} preFilledType={"problem"} preFilledSubject={"Issue with Login"}/>
  //       </>
  //     ),
  //   });
  // };
  const { error } = props;

  return (
    <div className="login-page-main">
      <div className="pageContainer">
        <div>
          <img src={loginImage} className="pageContainer__login-image" />
        </div>
        <div className="pageContainer__login-fields">
          <img className="logo-login" alt="TM Logo" src={getLogo()} />
          <p className="error">{error ? error.message : null}</p>
          <LoginForm onSubmit={loginHandler} />
          <p className="mt-3">
            <a href="#" onClick={openForgotPasswordModal}>
              Forgot Password?
            </a>
            {/* <a
              href="#"
              onClick={() => {
                openContactModal();
              }}
            >
              Login issues
            </a> */}
          </p>
          <p className="mt-3 small">
            &copy; 2024 Terrace Metrics, Inc. <a href="https://www.terracemetrics.org/privacy-policy/">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  error: state.auth.error,
  redirectFromLogIn: state.auth.redirectFromLogIn,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  login: (username, password, redirect, redirectSuccess) => dispatch(actions.login(username, password, redirect, redirectSuccess)),
  changePassword: (newPassword, cb) => dispatch(actions.changePassword(newPassword, cb)),
  openModal: modalProps => dispatch(actions.openModal(modalProps)),
  closeModal: () => dispatch(actions.closeModal()),
  resetApp: () => dispatch(actions.resetApp()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
