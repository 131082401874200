import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
const defaultNewsletter = require('./defaultNewsletter.json');
const { REACT_APP_API_URL } = process.env;

const NewsletterEdit = ({ onSubmit, surveyId }) => {
  const API_TOKEN = useSelector(state => state.auth.idToken);
  const gridWidths = { left: 3, right: 9 };
  const editorRef = useRef(null);
  const initValues = {
    newsletter: defaultNewsletter, // add saved
  };
  
  const handleClick = async event => {
    event.preventDefault();
    const action = event.target.id === 'newsletter_savedraft' ? 'savedraft' : 'send';
    await sendSaveNewsletter({ values: { subject: document.getElementById('inputNewsletterSubject').value, body: editorRef.current.getContent() }, action } );
    onSubmit();
  };

  const sendSaveNewsletter = async ({ values, action }) => {
    await axios({
      url: `${REACT_APP_API_URL}/v2/survey/notifications/nl/${surveyId}`,
      method: 'POST',
      headers: { Authorization: API_TOKEN },
      data: {values, action},
    });
      // .then(() => {
      //   setFormSubmitState('success');
      //   typeof props.onSuccess === 'function' && props.onSuccess();
      //   props.getSurvey(props.surveyId);
      //   props.openToaster(`Consent option was successfully added or edited.`);
      // })
      // .catch(() => {
      //   setFormSubmitState('error');
      // });
  };

  return (
    <Form id="newsletterForm" className="d-flex" onSubmit={onSubmit}>
      <p>Please edit and review carefully before sending.</p>
      <FormGroup row>
        <Label for="inputTemplateSubject" sm={gridWidths.left}>
          Email Subject
        </Label>
        <Col sm={gridWidths.right}>
          <Input id="inputNewsletterSubject" name="inputNewsletterSubject" defaultValue={initValues?.newsletter?.subject} type="text" className="input" />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="inputNewsletterBody">Email Body</Label>
        <Editor
          apiKey="d62wp66vrv0hl4cv54a0zosuur9mbtqhk2tun7d6ste46pts"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={initValues?.newsletter?.body}
          init={{
            height: 500,
            menubar: false,
            // plugins: [
            //   'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            //   'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            //   'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            // ],
            toolbar: 'undo redo | bold italic forecolor fontfamily fontsize | bullist numlist outdent indent |',
            content_style: 'body { font-family:Helvetica ,Arial,sans-serif; font-size:14px }',
            branding: false,
            statusbar: false,
          }}
        />
      </FormGroup>
      <FormGroup row>
        <Col>
          <Button id="newsletter_savedraft" color="primary" onClick={handleClick}>
            Save As Draft
          </Button>
          <Button id="newsletter_send" onClick={handleClick} className="ms-2" color="warning">
            Send Now
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default NewsletterEdit;
