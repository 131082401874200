import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {Button} from 'reactstrap';
import DropdownList from 'react-widgets/lib/DropdownList';

import * as actions from '../../../../store/actions';

const SearchReportsForm = (props) => {

  useEffect(() => {
    props.setSearchValuesReports();
  },[])

  const radioButtonHandler = newRadioVal => {
    props.updateSearchReportsObj({ name: 'reportSearchReportType', value: newRadioVal });
  };

  const isUK =()=> (process.env.REACT_APP_STAGE === 'uk');

  const dropdownChangeHandler = (dropdownName, value) => {
    if (dropdownName === 'reportSearchCountryRegion') {
      props.clearReportStateOptions();
    }
    if (dropdownName === 'reportSearchStateProvince') {
      props.clearReportDistrictOptions();
      props.clearReportSchoolOptions();
    }
    if (dropdownName === 'reportSearchDistrict') {
      props.clearReportSchoolOptions();
    }

    props.updateSearchReportsObj({ name: dropdownName, value: value });
    props.setSearchValuesReports();
  };

    let yearOptions = [];
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < 10; index++) {
      yearOptions.push(currentYear + index);
    }

    const shouldDisableSearch = props.reportSearchReportType === 'studentReport' && props.role === 'tm_admin' && (props.reportSearchDistrict.name === '' || props.reportSearchDistrict.orgId === '');
    return (
      <div className={classnames('container-search-survey', props.className)}>
        <p className="search-survey-header">Search Reports</p>
        <form className="search-survey-form" onSubmit={props.onSubmit}>
          <div className="input-container-search-reports">
            {props.role && props.role === 'tm_admin' && (
              <div>
                <label className="label">Country/Region</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={props.searchCountryRegionOptions}
                  onChange={value => dropdownChangeHandler('reportSearchCountryRegion', value)}
                  value={props.reportSearchCountryRegion}
                  placeholder="- Select -"
                />
              </div>
            )}
            {props.role && props.role === 'tm_admin' && (
              <div>
                <label className="label">{isUK() ?'County' :'State/Province'}</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={props.searchStateProvinceOptions}
                  onChange={value => dropdownChangeHandler('reportSearchStateProvince', value)}
                  value={props.reportSearchStateProvince}
                  placeholder={props.reportSearchCountryRegion === '' ? '' : '- Select -'}
                  disabled={props.reportSearchCountryRegion === ''}
                />
              </div>
            )}
            {props.role && props.role === 'tm_admin' && (
              <div>
                <label className="label">{isUK() ? 'Group':'Organization'}</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={props.searchDistrictOptions}
                  onChange={value => dropdownChangeHandler('reportSearchDistrict', value)}
                  value={props.reportSearchDistrict.name}
                  placeholder={props.reportSearchStateProvince === '' ? '' : `- Select ${isUK() ? 'Group':'Organization'} -`}
                  disabled={props.reportSearchStateProvince === ''}
                />
              </div>
            )}
            {props.role && ['district_champion', 'tm_admin'].includes(props.role) && (
              <div>
                <label className="label">{isUK() ?'Organisation' : 'Group'}</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={props.searchSchoolOptions}
                  onChange={value => dropdownChangeHandler('reportSearchSchool', value)}
                  value={props.reportSearchSchool.name}
                  placeholder={props.reportSearchDistrict && props.reportSearchDistrict.name === '' ? '' : `- Select ${isUK() ? 'Organisation' :'Group'} -`}
                  disabled={props.reportSearchDistrict && props.reportSearchDistrict.name === '' && props.role !== 'district_champion'}
                />
              </div>
            )}
            <div className="radio-container-search-reports">
              <label className="label survey-name-label">Report Type</label>
              <div className="radio-search-reports">
                <div>
                  <label>
                    <input type="radio" value="schoolReport" checked={props.reportSearchReportType === 'schoolReport'} onChange={event => radioButtonHandler(event.target.value)} />
                    {isUK() ?'Organisation' : 'Group'} Report
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" value="studentReport" checked={props.reportSearchReportType === 'studentReport'} onChange={event => radioButtonHandler(event.target.value)} />
                    Participant Report
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label className="label">Year</label>
              <DropdownList
                className="tm-dropdown short"
                data={yearOptions}
                onChange={value => props.updateSearchReportsObj({ name: 'reportSearchYear', value: value })}
                value={props.reportSearchYear}
              />
            </div>
            {props.reportSearchReportType === 'studentReport' ? (
              <div>
                <label className="label survey-name-label">Participant Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="search-survey-input"
                  onChange={event => props.updateSearchReportsObj({ name: 'reportSearchName', value: event.target.value })}
                  value={props.reportSearchName}
                />
              </div>
            ) : null}
          </div>
          <div className="search-survey-submit-container">
            <Button onClick={props.onSubmit} className="small-blue-button" disabled={shouldDisableSearch}>
              Search Reports
            </Button>
            <Button onClick={props.toggleShowReportSearch} className={props.searchReportsResults === null ? 'hidden' : 'small-blue-button-outlined'}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
}

const mapStateToProps = state => {
  return {
    searchReportsResults: state.reports.searchReportsResults,
    showReportsSearch: state.reports.showReportsSearch,
    reportSearchDistrict: state.reports.reportSearchDistrict,
    reportSearchSchool: state.reports.reportSearchSchool,
    SearchCountryRegion: state.reports.searchSearchCountryRegion,
    searchCountryRegionOptions: state.reports.searchCountryRegionOptions,
    searchStateProvinceOptions: state.reports.searchStateProvinceOptions,
    searchDistrictOptions: state.reports.searchDistrictOptions,
    searchSchoolOptions: state.reports.searchSchoolOptions,
    reportSearchCountryRegion: state.reports.reportSearchCountryRegion,
    reportSearchStateProvince: state.reports.reportSearchStateProvince,
    reportSearchYear: state.reports.reportSearchYear,
    reportSearchReportType: state.reports.reportSearchReportType,
    reportSearchName: state.reports.reportSearchName,
    role: state.auth.role,
    orgInfo: state.organizations.organization,
    radioValue: state.reports.radioValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSearchReportsObj: payload => dispatch(actions.updateSearchReportsObj(payload)),
    toggleShowReportSearch: () => dispatch(actions.toggleShowReportSearch()),
    setSearchValuesReports: () => dispatch(actions.setSearchValuesReports()),
    clearReportStateOptions: () => dispatch(actions.clearReportStateOptions()),
    clearReportDistrictOptions: () => dispatch(actions.clearReportDistrictOptions()),
    clearReportSchoolOptions: () => dispatch(actions.clearReportSchoolOptions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchReportsForm);
