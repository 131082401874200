import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import AppRouter from './routes/routes';
import { store, persistor } from './store/configureStore';
import Backdrop from './components/Backdrop/Backdrop';
import Spinner from './components/Spinner/Spinner';
import RootModal from './components/RootModal/RootModal';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/app.scss';
import 'react-sweet-progress/lib/style.css';

// import { initSentry } from './utilities/logging';
// initSentry();

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

const isUK = process.env.REACT_APP_STAGE === 'uk';
const ReduxApp = () => (
  <Provider store={store}>
    <PersistGate
      loading={
        <Backdrop>
          <Spinner />
        </Backdrop>
      }
      persistor={persistor}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{isUK ? 'MyMynd' : 'Terrace Metrics'}</title>
      </Helmet>
      <AppRouter />
      <RootModal />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<ReduxApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
