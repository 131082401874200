import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import axios from 'axios';
import { Button } from 'reactstrap';

const CleverStudentSelection = ({ idToken, idpInfo, onSubmit }) => {

  const [cleverFormattedRoster, setCleverFormattedRoster] = useState(null);
  const [cleverSelectedGrades, setCleverSelectedGrades] = useState([])
  const [selectedParticipants, setSelectedParticipants] = useState([])
  // const districtId = schoolOrg && schoolOrg?.parentOrg && schoolOrg?.parentOrg?.orgId;
  // const schoolId = schoolOrg.orgId;
  console.log(`idpinfo :`, idpInfo)
  useEffect(() => {
    getIdpRoster();
  }, [])

  const GradeSelector = ({ perGradeRoster, selectedGrades, onCheckboxChange }) => {
    const checkBoxes = Object.keys(perGradeRoster.perGrade).map((grade) =>
      <div key={grade} className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={selectedGrades?.includes(grade)}
          onChange={onCheckboxChange}
          value={grade}
          id={`gradeSelectorCheckbox-${grade}`}
        />
        <label className="form-check-label" htmlFor={`gradeSelectorCheckbox-${grade}`}>
          Grade {grade} ({perGradeRoster.perGrade[grade].length} students)
        </label>
      </div>
    );
    return checkBoxes;
  }

  const getIdpRoster = async () => {
    const apiName = `api${idpInfo.idpName}`;
    const url = `${process.env.REACT_APP_API_URL}/${apiName}/getstudents`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: idToken },
      params: {
        districtId: idpInfo.districtId,
        schoolId: idpInfo.schoolId
      }
    })
      .then(res => {
        if (res) {
          setCleverFormattedRoster(formatRosterPerGrade(res.data));
        }
      })
      .catch(err => {
        console.log('ERROR WITH addParticipantToSurvey: ', err);
      });

  }

  const formatRosterPerGrade = (roster) => {
    const formattedRoster = { "total": roster.length, "perGrade": {} };
    roster.forEach(student => {
      (!formattedRoster.perGrade.hasOwnProperty(student.grade)) && (formattedRoster.perGrade[student.grade] = []);
      formattedRoster.perGrade[student.grade].push(student);
    })
    return formattedRoster
  }

  const cleverGradeCheckboxChange = (event) => {
    const grade = event.target.value;
    const keysArray = Object.keys(cleverFormattedRoster?.perGrade);
    const index = keysArray.indexOf(grade);
    let updatedSelectedGrades = [...cleverSelectedGrades];

    if (event.target.checked) {
      if (index > -1 && !updatedSelectedGrades.includes(grade)) {
        updatedSelectedGrades.push(grade);
      }
    } else {
      if (index > -1) {
        updatedSelectedGrades = updatedSelectedGrades.filter(item => item !== grade);
      }
    }

    let updatedParticipants = [];
    updatedSelectedGrades.forEach(grade => {
      updatedParticipants = [...updatedParticipants, ...cleverFormattedRoster.perGrade[grade]];
    });
    setCleverSelectedGrades(updatedSelectedGrades);
    setSelectedParticipants(updatedParticipants)
  };

  const handleCleverSubmit = () => {
    let updatedParticipants = [];
    cleverSelectedGrades.forEach(grade => {
      updatedParticipants = [...updatedParticipants, ...cleverFormattedRoster.perGrade[grade]];
    });
    const selectedParticipantsByGrades = updatedParticipants;
    onSubmit(selectedParticipantsByGrades)
  }

  return (
    <div className='m-4'>
      <h2>
        Add Participants from <span className='text-capitalize'>{idpInfo.idpName}</span>
      </h2>
      {cleverFormattedRoster
        ? <>
          <p>Please select grade(s) you would like to add to this assessment ({cleverFormattedRoster.total} students retrieved for this school)</p>
          <GradeSelector
            perGradeRoster={cleverFormattedRoster}
            selectedGrades={cleverSelectedGrades}
            onCheckboxChange={cleverGradeCheckboxChange} />
        </>
        : <p>Loading student information from online roster...</p>}
      <Button
        type="submit"
        onClick={handleCleverSubmit}
        disabled={cleverFormattedRoster?.perGrade?.length == 0}
        className='small-button mt-4'
      >
        Add {selectedParticipants?.length > 0 ? selectedParticipants?.length : null} participants</Button>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.isAuthenticated,
    role: state.auth.role,
    idToken: state.auth.idToken,
    grades: state.surveyCrud.grades,
    surveyDetails: state.surveyCrud.surveyDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleGradeForSurvey: grade => dispatch(actions.toggleGradeForSurvey(grade)),
    setStudentsByGrade: students => dispatch(actions.setStudentsByGrade(students)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleverStudentSelection);
