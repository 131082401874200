import axios from 'axios';
import * as actionTypes from '../actionTypes';
const { REACT_APP_API_URL } = process.env;

export const loginWellnessCenterStart = () => {
  return {
    type: actionTypes.LOGIN_WELLNESSCENTER_START,
  };
};

export const loginWellnessCenterSuccess = payload => {
  return {
    type: actionTypes.LOGIN_WELLNESSCENTER_SUCCESS,
    ...payload,
  };
};

export const loginWellnessCenterFail = (err, message) => {
  return {
    type: actionTypes.LOGIN_WELLNESSCENTER_FAIL,
    error: err,
    message,
  };
};

export const loginWellnessCenterMessage = payload => {
  return {
    type: actionTypes.LOGIN_WELLNESSCENTER_MESSAGE,
    message: payload.message,
  };
};

export const loginWellnessCenter = values => {
  return dispatch => {
    dispatch(loginWellnessCenterStart());
    return axios({
      method: 'post',
      url: `${REACT_APP_API_URL}/participant/verify-parent`,
      data: values,
    })
      .then(res => {
        if (res.data.type === 'email') {
          dispatch(
            loginWellnessCenterMessage({
              message: res.data.message,
            }),
          );
        } else {
          dispatch(
            loginWellnessCenterSuccess({
              participantId: res.data.participant.surveyParticipantId,
            }),
          );
        }
      })
      .catch(err => {
        let msg = err && err.response && err.response.data.message ? err.response.data.message : 'Invalid login attempt: internal server error.';
        // TODO: Here you may want to pull a pretty error message from the server or throw a redirect callback or something
        dispatch(loginWellnessCenterFail(err, msg));
      });
  };
};
