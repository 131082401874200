import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import axios from 'axios';
import TableParticipants from './TableParticipants';
import Spinner from '../../../components/Spinner/Spinner';
import getSchoolAndDistrict from '../../../utilities/getSchoolAndDistrict';

const PriorityParticipants = props => {
  const initState = {
    // searchInput: { firstName: "", lastName: "", birthDate: "", studentId: "" },
    searchResults: null,
    // inputSearchMethod: "searchByName"
  };
  const [state, setState] = useState(initState);

  const [isLoading, setIsLoading] = useState(false);

  const { schoolId, districtId } = getSchoolAndDistrict(props.navigation.org);
  useEffect(() => {
    searchParticipants();
  }, [districtId, schoolId]);

  const searchParticipants = async () => {
    if (districtId) {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/${districtId}/participant/search`;
      const response = await axios({
        url,
        method: 'GET',
        headers: {
          Authorization: props.idToken,
        },
        params: { orgId: schoolId ? schoolId : undefined, priority: 2 },
      });
      setState(prevState => ({
        ...prevState,
        searchResults: response.data.searchResult,
      }));
      setIsLoading(false);
    }
  };

  return (
    <div className="col">
      <h2>Participants marked Priority 1 and 2</h2>
      {districtId ? <div>These are the participants which were marked as Priority 1 or 2. Only most recents 100 results are shown.</div> : <div>Please Select District first.</div>}
      {state.searchResults && districtId && <TableParticipants participants={state.searchResults} />}
      {isLoading && (
        <div className="d-flex justify-content-center ">
          <Spinner />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = state => {
  return {
    users: state.users.users,
    navigation: state.navigation,
    isUsersLoaded: state.users.isLoaded,
    role: state.auth.role,
    realRole: state.auth.realRole,
    idToken: state.auth.idToken,
  };
};

export default connect(mapStateToProps)(withRouter(PriorityParticipants));
