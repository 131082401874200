import React, { useState } from 'react';
import { Field } from 'redux-form';
import RenderDropdown from '../../../../../components/RenderDropdown/RenderDropdown';
import { required, isValidEmail, hideMissing } from '../../../../../utilities/validation';
import { getContent } from '../../../../../utilities/getContent/getContent';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import { connect } from 'react-redux';
import { months, days, years } from '../../../../../utilities/dates';
import classNames from 'classnames';

const gradeData = [ "K", ...Array.from(Array(13).keys()).slice(1)];
const isUK = process.env.REACT_APP_STAGE === 'uk';

const ParticipantEntryFields = props => {
  const participantEmailValidation =
    props.appliedTo !== 'adult'
      || (props.surveyName && props.surveyName.includes('Adult'))
      ? [isValidEmail] : [isValidEmail, required];

  const [formFields, setFormFields] = useState({
    firstName: null,
    lastName: null,
    studentId: null,
    month: null,
    day: null,
    year: null,
    gender: null
  })

  const handleChange = (fieldName, e) => {
    setFormFields(prevState => ({
      ...prevState,
      [fieldName]: e.target.value
    }));
  };

  const manageChange = (value, index, fieldName) => {
    // handleChange(property,e)
    hideMissing(index)

    setFormFields(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  }

  return (
    <div>
      {props.index > 0 ? <hr className="w-100" /> : null}
      <div className="participant-row mb-4">
        <div className="label-field-container">
          <label className="label bold">Participant Name</label>
          <Field
            name={`firstName-${props.index}`}
            id='locator_participant-firstname'
            placeholder="First name"
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.firstName ? "participant-entry-field--filed" : "participant-entry-field--required")}
            validate={[required]}
            required
            onChange={(e) => handleChange("firstName", e)}
          />
        </div>
        <div className="label-field-container">
          <Field
            name={`middleName-${props.index}`}
            id='locator_participant-middlename'
            placeholder="Middle name"
            type="text"
            component="input"
            className="participant-entry-field"
          />
        </div>
        <div className="label-field-container">
          <Field
            name={`lastName-${props.index}`}
            id='locator_participant-lastname'
            placeholder="Last name"
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.lastName ? "participant-entry-field--filed" : "participant-entry-field--required")}
            validate={[required]}
            required
            onChange={(e) => handleChange("lastName", e)}
          />
        </div>
      </div>
      <div className="participant-row mb-4">
        {props.appliedTo !== 'adult' || (props.surveyName && props.surveyName.includes('Adult')) ? <div className="label-field-container">
          <label className="label">{getContent('admin_participant-entry-grade')}</label>
          <Field
            name={`grade-${props.index}`}
            id='locator_participant-grade'
            type="text"
            placeholder="-Select-"
            className="tm-dropdown short form-inputs"
            data={gradeData}
            component={RenderDropdown}
            textField="label"
            valueField="value"
          />
        </div> : null}
        <div className="label-field-container">
          <label className="label bold">{`${props.appliedTo === 'adult' || (props.surveyName && props.surveyName.includes('Adult'))
            ? 'Participant Id'
            : 'Student Id'
            }`}</label>
          <Field
            name={`studentId-${props.index}`}
            id='locator_participant-id'
            placeholder="ID"
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.studentId ? "participant-entry-field--filed" : "participant-entry-field--required")}
            required
            onChange={(e) => handleChange("studentId", e)}
          />
        </div>
      </div>
      <div className="participant-row mb-4">
        <div className={`label-field-container ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order' : ''}`}>
          <label className="label bold">Date of Birth</label>
          <div className={`row birthday-fields`}>
            <div className={`col-2 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-1' : ''}`}>
              <Field
                name={`month-${props.index}`}
                id='locator_participant-dob-month'
                type="text"
                placeholder="Month"
                className={classNames("tm-dropdown short", formFields.month ? "tm-dropdown--filed" : "tm-dropdown--required")}
                data={months}
                textField="label"
                valueField="value"
                component={RenderDropdown}
                validate={[required]}
                required
                onChange={(e) => manageChange(e, `month-${props.index}`, "month")}
              />
            </div>
            <div className={`col-2 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-0' : ''}`}>
              <Field
                name={`day-${props.index}`}
                id='locator_participant-dob-day'
                type="text"
                placeholder="Day"
                className={classNames("tm-dropdown short", formFields.day ? "tm-dropdown--filed" : "tm-dropdown--required")}
                data={days}
                component={RenderDropdown}
                validate={[required]}
                required
                onChange={(e) => manageChange(e, `day-${props.index}`, "day")}
              />
            </div>
            <div className={`col-2 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-2' : ''}`}>
              <Field
                name={`year-${props.index}`}
                id='locator_participant-dob-year'
                type="text"
                placeholder="Year"
                className={classNames("tm-dropdown short", formFields.year ? "tm-dropdown--filed" : "tm-dropdown--required")}
                data={years}
                component={RenderDropdown}
                validate={[required]}
                required
                onChange={(e) => manageChange(e, `year-${props.index}`, "year")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="participant-row mb-5">
        {props.appliedTo !== 'adult' || (props.surveyName && props.surveyName.includes('Adult')) ? <div className="label-field-container">
          <label className="label bold">Notification Email (optional)</label>
          <Field
            name={`parentEmail-${props.index}`}
            id='locator_participant-notification-email'
            placeholder="Notification Email"
            type="text"
            component="input"
            className="participant-entry-field"
            validate={[isValidEmail]}
          />
        </div> : null}
        <div className="label-field-container">
          <label className="label bold">Notification Mobile (optional)</label>
          <Field
            name={`parentMobile-${props.index}`}
            id='locator_participant-notification-mobile'
            type="tel"
            component={RenderPhoneField}
            className="participant-entry-field"
          />
        </div>
        <div className="label-field-container">
          <label className="label bold">{isUK ? 'Grouping' : 'Group'} Name(optional)</label>
          <Field
            name={`group-name-${props.index}`}
            id='locator_participant-group-name'
            type="text"
            placeholder={isUK ? 'Grouping Name' : 'Group name'}
            className="participant-entry-field"
            component="input"
          />
        </div>
      </div>
      <div className="participant-row mb-5 align-items-start">
        <div className="label-field-container">
          <label className={(props.surveyName && props.surveyName.includes('Adult')) ? 'label' : 'label  bold'}>Participant Email{props.appliedTo !== 'adult' || (props.surveyName && props.surveyName.includes('Adult')) ? '(optional)' : null}</label>
          <Field
            name={`participantEmail-${props.index}`}
            id='locator_participant-email'
            placeholder="Participant Email"
            type="text"
            component="input"
            className="participant-entry-field"
            validate={participantEmailValidation}
          />
        </div>
        <div className="label-field-container">
          <label className="label">{isUK ? 'Gender' : 'Sex'}</label>
          <Field
            name={`gender-${props.index}`}
            id='locator_participant-sex'
            type="text"
            placeholder={isUK ? 'Gender' : 'Sex'}
            className={classNames("tm-dropdown short", formFields.gender ? "tm-dropdown--filed" : "tm-dropdown--required")}
            data={isUK ? [
              { label: 'Male', value: 'm' },
              { label: 'Female', value: 'f' },
              { label: 'Prefer not to say', value: 'no_response' },
              { label: 'Prefer to self describe', value: 'custom' }
            ] : [
              { label: 'Male', value: 'm' },
              { label: 'Female', value: 'f' },
              { label: 'N/A', value: 'no_response' }
            ]}
            textField="label"
            valueField="value"
            component={RenderDropdown}
            validate={[required]}
            onChange={(e) => manageChange(e, `gender-${props.index}`, "gender")}
          />
          {props.formData &&
            props.formData['participant-entry-form'] &&
            props.formData['participant-entry-form'].values &&
            props.formData['participant-entry-form'].values[`gender-${props.index}`] &&
            props.formData['participant-entry-form'].values[`gender-${props.index}`].value === 'custom' ? (
            <Field
              name={`custom-gender-${props.index}`}
              id='locator_participant-sex'
              placeholder="Gender"
              type="text"
              component="input"
              className="participant-entry-field mt-3"
              validate={[required]}
            />
          ) : null}
          {props.formData &&
            props.formData['add-participant-form'] &&
            props.formData['add-participant-form'].values &&
            props.formData['add-participant-form'].values[`gender-${props.index}`] &&
            props.formData['add-participant-form'].values[`gender-${props.index}`].value === 'custom' ? (
            <Field
              name={`custom-gender-${props.index}`}
              id='locator_participant-sex'
              placeholder="Gender"
              type="text"
              component="input"
              className="participant-entry-field mt-3"
              validate={[required]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    formData: state.form
  };
};

export default connect(mapStateToProps)(ParticipantEntryFields);
