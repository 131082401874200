import React from 'react';
import { connect } from 'react-redux';
// import { submit } from 'redux-form';
import { Button } from 'reactstrap';
import {
  useHistory
} from 'react-router-dom';
import Spinner from '../../../../../components/Spinner/Spinner';

const RemoteSubmitButton = ({ isWaiting, createSurvey }) => {

  // NOTE: This function only gets called if there are manual participants, other wise the createSurvey action is dispatched
  //Above note has been fixed
  const history = useHistory();

  const submitSurvey = async () => {
    // dispatch(submit('participant-entry-form'));
    await createSurvey();
    history.push({
      pathname: `/admin/survey/list`, 
      state: { refresh: true }
    });
  };

  return (
    <Button id='locator_save-survey' onClick={submitSurvey} className="survey-margin-left small-button green m-0"
      size="sm">
      Save Assessment
      {isWaiting ? <Spinner /> : null}
    </Button>
  );
};

export default connect()(RemoteSubmitButton);
