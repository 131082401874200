/* eslint-disable no-sequences */
import React, { useEffect, useRef, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import { createPortal } from "react-dom";
import classNames from "classnames";

const CONTENT_VISIBILITY_DELAY = 200;
const PANEL_VISIBILITY_DELAY = 100;
const CLOSING_RETRY_DELAY = 10;


const Overlay = ({
  children,
  isOpen: externalIsOpen,
  onToggle,
  title,
  titleElement,
  hideCloseIcon,
  layout,
  variant = "large",
}) => {
  const inTransitionRef = useRef(false);
  const [inTransition, setInTransition] = useState(false);
  const [visible, setVisible] = useState(externalIsOpen);
  const [isOpen, setIsOpen] = useState(externalIsOpen);
  inTransitionRef.current = inTransition;
  
  const handleClose = function () {
    if (onToggle) {
      onToggle();
    } else {
      handleDelayedClosing(false);
    }
  };

  const preventToggle = (e) => {
    e.stopPropagation();
  };

  const handleDelayedClosing = (value) => {
    if (!inTransitionRef.current) {
      setInTransition(true);
      setTimeout(
        () => {
          setVisible(value);

          if (!value) {
            setInTransition(false);
          }
        },
        value ? 0 : CONTENT_VISIBILITY_DELAY,
      );
      setTimeout(
        () => {
          setIsOpen(value);

          if (value) {
            setInTransition(false);
          }
        },
        value ? PANEL_VISIBILITY_DELAY : 0,
      );
    } else {
      setTimeout(() => {
        handleDelayedClosing(value);
      }, CLOSING_RETRY_DELAY);
    }
  };

  useEffect(() => {
    handleDelayedClosing(externalIsOpen);
  }, [externalIsOpen]);

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  if (!visible) {
    return null;
  }

  if (isOpen) {
    document.documentElement.style.overflow = "hidden";
  } else {
    document.documentElement.style.overflow = "initial";
  }

  const overlaySize = {
    small: "sm",
    medium: "md",
    large: "lg",
    larger: "larger",
    extraLarge: "xxl",
  }[variant];

  return (
    <OverlayPortal>
      <div
        className={classnames([
          "overlay",
          isOpen && "overlay--open",
          !isOpen && "overlay--hidden",
        ])}
        // onClick={handleClose} // avoid closing by mistake when selecting
      >
        <div
          className={classNames([
            "overlay__container",
            `overlay__container--${overlaySize}`,
            layout === "column" && "overlay__container--column-layout",
          ])}
          onClick={preventToggle}
        >
          {!hideCloseIcon && layout !== "column" && (
            <div className="overlay__header">
              <div className="overlay__header__close-container">
                <FontAwesomeIcon
                  onClick={handleClose}
                  className="overlay__header__close"
                  icon={faTimes}
                  size="lg"
                />
              </div>
              {(title || titleElement) && (
                <div className="overlay__header__title">
                  {title ? <h1>{title}</h1> : titleElement}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </OverlayPortal>
  );
};

export default Overlay;

const OverlayPortal = ({ children }) => {
  const [id] = useState(+new Date());
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    el.current.id = String(id);
    document.body.appendChild(el.current);

    return () => {
      el.current.parentElement?.removeChild(el.current);
    };
  }, []);
  return createPortal(children, el.current);
};
