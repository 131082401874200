import axios from 'axios';

const activateItem = async (item, idToken, type = 'user') => {
  const url = `${process.env.REACT_APP_API_URL}/clever/activate`;
  await axios({
    url,
    method: 'POST',
    headers: {
      'Authorization': `bearer ${idToken}`
    },
    data: {
      type,
      item,
    }
  });
}

export default activateItem;