import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import TFSReport from '../Shared/TFSReport';
import ParticipantHeader from '../Shared/ParticipantHeader';
import QuestionsOfConcernPage from './QuestionsOfConcernPage';
import NotConcerningQuestionsPage from './NotConcerningQuestionsPage';

import * as actions from '../../../../store/actions/';

import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';

class StudentReport extends Component {
	componentDidMount() {
		const parsed = queryString.parse(this.props.location.search);
		const participantId = parsed.pId;
    if (parsed.showAll) {
      this.props.toggleShowNotConcerningResponses();
    }
		this.props.getReport(participantId);
	}

	render() {
		console.log('THIS.props.report : ', this.props.report);
		console.log('IsLoading: ', this.props.isLoading);
		return (
			<div className="tm-report tm-report-student">
				{/* Header is sticky so it prints on each page */}
				{this.props.isLoading === false && this.props.report ? (
					<>
						<ParticipantHeader
							payload={this.props.report}
							showPriority={true}
							isParentReport={false}
							showPrint={this.props.role && this.props.role === 'responder' ? false : true}
						/>
						<TFSReport payload={this.props.report} showTimeBox={true} isParentReport={false} />
						{/**  Start Student  **/}
						<QuestionsOfConcernPage payload={this.props.report} />
						{/* Question that are not of concern */}
						{this.props.isShowingNotConcerningResponses ? (
							<NotConcerningQuestionsPage payload={this.props.report} />
						) : null}
					</>
				) : (
						<Backdrop>
							<Spinner />
						</Backdrop>
					)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		report: state.reports.report,
		isLoading: state.reports.isLoading,
		role: state.auth.role,
		isShowingNotConcerningResponses: state.reports.isShowingNotConcerningResponses,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getReport: participantId => dispatch(actions.getReport(participantId)),
    toggleShowNotConcerningResponses: () => dispatch(actions.toggleShowNotConcerningResponses())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(StudentReport));
