import React from 'react';

const Footer = () => {

  const isUK = process.env.REACT_APP_STAGE === 'uk';
  return (
    <div className="page-footer row">
      <div className="col-4">&copy; Copyright {isUK ? 'MyMynd' : 'Terrace Metrics'} {isUK ? '2020' : '2019'}</div>
      <div className="col-8 text-right">For assistance email us at {isUK ? 'support@mymynd.co.uk' : 'info@terracemetrics.org'} or contact us at 800-470-4401.</div>
    </div>
  );
}

export default Footer;
