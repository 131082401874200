import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const ModalWrapper = (props) => {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(true);
  }, []);

  const closeModal = () => {
    setShow(false);
    props.closeModal();
    if (props.resetModalErrorsOnClose) {
      props.clearUserErrors();
      props.clearOrganizationErrors();
    }
  }

  return (
    <Modal
      className={props.className}
      toggle={props.onExited || closeModal}
      isOpen={show}
      size={props.size}
      onExit={props.onExit}
    >
      {props.children}
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
    clearUserErrors: () => dispatch(actions.clearUserErrors()),
    clearOrganizationErrors: () => dispatch(actions.clearOrganizationErrors()),
  }
};

export default connect(null, mapDispatchToProps)(ModalWrapper);
