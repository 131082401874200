import React, { useState, useMemo, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as IconAvatarBG } from '../../../assets/imgs/icon-avatar-bg.svg';
import OrgPicker from '../../../components/OrgPicker/OrgPicker';
import * as actions from '../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ListOrganizations from '../../../containers/Admin/Organizations/ListOrganizations/ListOrganizations';
// import userRole from '../../../consts/roles';
import { Link } from 'react-router-dom';
import HelpOverlay from '../../../containers/Admin/Support/HelpOverlay';
import { getLogo } from './../../../assets/helper';
import { getMobileLogo } from './../../../assets/helper';
import { DropdownToggle, DropdownItem, DropdownMenu, UncontrolledDropdown, Tooltip } from 'reactstrap';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
const helpContentLibrary = require('../../../utilities/getContent/helpContent.json');
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
// import mixpanel from 'mixpanel-browser';
// import CreateDistrictFormWrapper from '../../../containers/Admin/Organizations/CreateOrganization/CreateDistrict/CreateDistrictFormWrapper';
import SelectSchool from '../../../containers/Admin/Organizations/ListOrganizations/SearchOrganizationForm/SelectSchool';
import SelectDistrict from '../../../containers/Admin/Organizations/ListOrganizations/SearchOrganizationForm/SelectDistrict';
// const { REACT_APP_MIXPANEL_TOKEN, REACT_APP_STAGE } = process.env;

const Header = props => {
  const [showingHelp, setShowingHelp] = useState(false);
  const showGetStarted = useSelector(state => state.meta.showGetStarted);

  useEffect(() => {
    // mixpanel.init(REACT_APP_MIXPANEL_TOKEN, { debug: REACT_APP_STAGE === 'dev', track_pageview: 'full-url', persistence: 'localStorage' });
    // mixpanel.identify(props.user.sub);
  }, []);

  const redirectToLogin = () => {
    props.history.push('/login');
  };

  const helpContent = useMemo(() => {
    const contentKey = helpContentLibrary.map.find(entry => {
      const re = new RegExp(entry.pattern);
      return re.test(props.history.location.pathname);
    })?.key;
    const { title, content, videoUrl } = contentKey ? helpContentLibrary.content[contentKey] : { title: 'Get Help', content: null };
    return { title, content, videoUrl };
  }, [props.history.location.pathname]);

  const openUpdateUserModal = () => {
    props.openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      addUser: props.updateUser,
      user: props.userProfile,
      isUserProfile: true,
      closeModal: e => closeModal(e),
    });
  };
  const closeModal = e => {
    // Prevent the form from submitting
    if (e) {
      e.preventDefault();
    }
    props.closeModal();
  };

  const helpShow = () => {
    setShowingHelp(!showingHelp);
  };

  const { showUser } = props;
  const isMultiOrgUser = props.orgs && props.orgs.length && props.orgs.length > 1;
  const name = props.userProfile ? `${props.userProfile.firstName} ${props.userProfile.lastName}` : 'loading...';
  const role = props.role;
  const roleHr = { district_champion: 'Organization Champion', school_champion: 'Group Champion', tm_admin: 'TM Admin' }[role];

  // const isDistrictAvailable = props.realRole == userRole.TM_ADMIN || role == userRole.TM_ADMIN || role == userRole.DISTRICT_CHAMPION;
  // const isSchoolAvailable = props.realRole == userRole.TM_ADMIN || props.realRole == userRole.DISTRICT_CHAMPION || role == userRole.DISTRICT_CHAMPION;

  const { innerWidth: width } = window;
  const isSmallScreen = width < 900;

  // const currentOrg = props.bc_orgName;
  // const higherOrg = props.bc_higherOrgName;

  return (
    <header className="header">
      <div className="container">
        <div className="branding">
          <Link to={role ? '/admin' : '/survey'}>
            <div>{isSmallScreen ? <img src={getMobileLogo()} alt="Logo" className="branding__logo" /> : <img src={getLogo()} alt="Logo" className="branding__logo-web" />}</div>
          </Link>
        </div>
        <SelectDistrict/>
        <SelectSchool/>
        {/* <CreateDistrictFormWrapper>
          <ListOrganizations isSchoolAvailable={isSchoolAvailable} isDistrictAvailable={isDistrictAvailable} district={currentOrg ? currentOrg : null} higherOrg={higherOrg ? higherOrg : null} />
        </CreateDistrictFormWrapper> */}
        {showUser && props.user ? (
          <div className="user">
            <FontAwesomeIcon id="header_help_icon" icon={faQuestionCircle} size="2x" onClick={helpShow} />
            <Tooltip isOpen={showGetStarted === 4} placement="left" target="header_help_icon">
              Get help on any page: view help guides and contact us
            </Tooltip>

            <div className="userWrapper">{isMultiOrgUser ? <OrgPicker orgs={props.orgs} /> : null}</div>
            <HelpOverlay {...helpContent} isOpen={showingHelp} onToggle={helpShow} />

            <UncontrolledDropdown>
              <DropdownToggle id="header_user_dropdown" caret color="transparent">
                <IconAvatarBG className="avatar clickable" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled>
                  <div className="username">{name}</div>
                  <div className="role">{roleHr}</div>
                </DropdownItem>
                <DropdownItem onClick={openUpdateUserModal}>
                  <div>Edit profile</div>
                </DropdownItem>
                <DropdownItem onClick={() => props.logout(redirectToLogin)} className="logout">
                  <div className="logout"> Logout </div>
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <Tooltip isOpen={showGetStarted === 4} placement="bottom" target="header_user_dropdown">
              Manage your user profile, or logout
            </Tooltip>
          </div>
        ) : null}
      </div>
    </header>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    role: state.auth.role,
    realRole: state.auth.realRole,
    userProfile: state.users.user,
    orgs: state.auth.orgs,
    bc_orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    bc_higherOrgName: state.organizations && state.organizations.organization && state.organizations.organization.parentOrg && state.organizations.organization.parentOrg.name,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: cb => dispatch(actions.logout(cb)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    updateUser: (userId, values, isUserProfile) => dispatch(actions.updateUser(userId, values, isUserProfile)),
    updateSomething: (value) => dispatch(actions.updateSomething(value))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
