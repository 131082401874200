import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faStar } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import * as actions from '../../../../store/actions';
import formatPhoneNumber from '../../../../utilities/formatPhoneNumber';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { faCheckCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';

const ListUsers = ({ listUsers, users, openModal, closeModal: closeModalProp, deleteUser, usersLoading, updateOrganization, openToaster }) => {
  const { activeOrg, role } = useSelector(state => {
    return { activeOrg: state.navigation.org, role: state.auth.role };
  });
  const idToken = useSelector(state => state.auth.idToken)

  useEffect(() => {
    if (
      users.length > 0 && // list was fetched already
      (users[0].orgId === activeOrg?.orgId || // it's the correct org
        (users[0].role === 'tm_admin' && activeOrg === null)) // listing tm admin
    )
      return;
    listUsers(activeOrg?.orgId);
  }, [activeOrg]);

  const [isTMAllowed, setIsTMAllowed] = useState(activeOrg?.isTMAllowed || false);

  const openConfirmationModal = (confirm, isAllowed) => {
    openModal({
      modalType: 'ACCESS_CONFIRMATION_MODAL',
      show: true,
      closeModal: closeModal(),
      isAllowed,
      confirm,
    });
  };

  const toggleAllowed = () => {
    setIsTMAllowed(!isTMAllowed);
    const newItem = {
      isTMAllowed: !isTMAllowed,
      orgId: activeOrg.orgId,
    };
    updateOrganization(newItem);
  };

  const resendInvite = async (email) => {
    const url = `${process.env.REACT_APP_API_URL}/user`;
    await axios({
      url,
      method: 'POST',
      data: { email, resend: true },
      headers: { Authorization: idToken },
    })
    openToaster(`Resent Invitation Email to ${email}`);
    listUsers(activeOrg?.orgId);
  }

  const generateTableData = () => {
    if (users && users.length > 0) {
      const result = users.map(item => {
        const inviteIsexpired = (item.status === "FORCE_CHANGE_PASSWORD") && (new Date() - new Date(item.lastModified) > 60 * 60 * 24 * 7 * 1000); // 7 days
        let formattedStatus;
        switch (item.status) {
          case 'FORCE_CHANGE_PASSWORD': // invitation sent
            formattedStatus = (
              <>
                <FontAwesomeIcon className="me-2" color={inviteIsexpired ? 'red' : ''} icon={faEnvelope} />
                {inviteIsexpired ? 'Expired' : 'Pending'}
              </>
            );
            break;
          case 'CONFIRMED': // all good
            formattedStatus = (
              <>
                <FontAwesomeIcon className="me-2" color={'green'} icon={faCheckCircle} />
                Active
              </>
            );
            break;
          default:
            break;
        }
        return {
          star: item.isDesignated === '1' ? <FontAwesomeIcon icon={faStar} className="blue-fill-icon" alt="star icon" /> : null,
          name: item.firstName || item.lastName ? <span>{`${item.firstName || ''} ${item.lastName || ''}`}</span> : '',
          email: item.email,
          phoneNumber: formatPhoneNumber(item.phoneNumber),
          status: formattedStatus,
          icon: (
            <>
            { inviteIsexpired && (<FontAwesomeIcon title="Resend invitation" icon={faEnvelope} className="blue-fill-icon" onClick={()=> resendInvite(item.email)}/>)}
              <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" onClick={() => openUpdateUserModal(item)} />
              <FontAwesomeIcon icon={faTrash} className="blue-fill-icon" onClick={() => openDeleteUserConfirmationModal(item)} />{' '}
            </>
          ),
        };
      });

      return result;
    }
    return [];
  };

  const openDeleteUserConfirmationModal = user => {
    let firstName = user.firstName ? user.firstName : '';
    let lastName = user.lastName ? user.lastName : '';
    openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>
            You are attempting to delete user “
            <strong>
              {`${firstName}`} {`${lastName}`}
            </strong>
            ”. Are you sure you want to continue? You cannot undo this action.{' '}
          </p>
          <Button type="button" className="small-button green" size="md" onClick={() => deleteUser(user.username, activeOrg?.orgId)}>
            Delete user
          </Button>
          <Button type="button" className="small-blue-button-outlined" size="md" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      ),
    });
  };

  const openAddUserModal = () => {
    openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      // addUser: createUser,
      newUser: { role: userTypeListed, orgId: activeOrg?.orgId },
      closeModal: e => closeModal(e),
    });
  };

  const openUpdateUserModal = user => {
    openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      // addUser: updateUser,
      listUsers: listUsers,
      user,
      closeModal: e => closeModal(e),
    });
  };

  const closeModal = e => {
    // Prevent the form from submitting
    if (e) {
      e.preventDefault();
    }

    closeModalProp();
  };
  const userTypeListed = activeOrg === null && role === 'tm_admin' ? 'tm_admin' : activeOrg.classification === 'school' ? 'school_champion' : 'district_champion';
  const userTypeLabel = { tm_admin: 'TM Admin User', district_champion: 'Organization Champion', school_champion: 'Group Champion' }[userTypeListed];
  let createButtonText = `Create ${userTypeLabel}`;
  let alertText = `Add new ${userTypeLabel}s with the button above.`;

  return (
    <div className="d-block w-100">
      {role === 'district_champion' && activeOrg.classification === 'school-district' ? (
        <div className="row my-4">
          <h3>Organization Settings</h3>
          <div className="d-flex justify-content-end">
            <CheckBox
              className="d-flex justify-content-center"
              labelClasses={['indicator-headers d-flex justify-content-center']}
              label={`Allow Terrace Metrics Access to All our Data`}
              name={'isTMAllowed'}
              value={isTMAllowed}
              isChecked={isTMAllowed ? 'checked' : undefined}
              onClick={() => {
                openConfirmationModal(toggleAllowed, isTMAllowed);
              }}
            />
          </div>
          <hr />
        </div>
      ) : null}
      <div className="sub-header">
        <Button type="submit" className="small-button mb-2" size="lg" color="success" onClick={openAddUserModal}>
          {createButtonText}
        </Button>
      </div>
      <h3>{userTypeLabel}s</h3>
      {usersLoading ? (
        <>
          <Backdrop>
            <Spinner />
          </Backdrop>
        </>
      ) : (
        <>
          {users && users.length === 0 ? (
            <Alert className="alert" dismissible variant="info">
              <div className="alertHeader">There are no {userTypeLabel}s yet.</div>
              <div>{alertText}</div>
            </Alert>
          ) : null}
          <div className={users.length > 0 ? null : 'hidden'}>
            <TableGenerator
              config={{
                headers: ['', 'NAME', 'EMAIL', 'PHONE', 'STATUS', 'ACTIONS'],
                contentRows: generateTableData(),
              }}
            />
          </div>
          <>
            {role && role === 'district_champion' ? (
              <p className="ml-3 caption">
                <FontAwesomeIcon icon={faStar} className="blue-fill-icon" alt="star icon" style={{ marginRight: '1rem' }} />
                <span className="align-middle">Denotes Designated Organization Champion. The Designated Organization Champion will be the main point of contact for Terrace Metrics.</span>
              </p>
            ) : null}
          </>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.users.users,
    usersLoading: state.users.isLoading || !state.users.isLoaded,
    organization: state.organizations.organization,
    role: state.auth.role,
    realRole: state.auth.realRole,
    parentOrgIdId: state.organizations?.organization?.orgId || null,
    orgId: state.organizations.schoolSearchName,
    orgName: state.organizations && state.organizations.organization && state.organizations.organization.name,
    parentOrgId: state.organizations.districtSearchName,
    isTMAllowed: state.organizations && state.organizations.organization && state.organizations.organization.isTMAllowed,
    schoolSearchName: state.organizations.schoolSearchName,
    districtSearchName: state.organizations.districtSearchName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openToaster: content => dispatch(actions.openToaster(content)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => {
      dispatch(actions.closeModal());
      dispatch(actions.clearUserErrors());
    },
    listUsers: orgId => dispatch(actions.listUsers(orgId)),
    // createUser: userProps => dispatch(actions.createUser(userProps)),
    // updateUser: (userId, values) => dispatch(actions.updateUser(userId, values)),
    deleteUser: (userId, currentOrg) => dispatch(actions.deleteUser(userId, currentOrg)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListUsers));
