import React from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalWrapper from '../RootModal/ModalWrapper';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const GenericModal = props => {
  return (
    <ModalWrapper show={props.show} onExit={props.onExit}>
      {props.headline ? (
        <ModalHeader toggle={props.closeModal}>
          <FontAwesomeIcon icon={props.headlineIcon || faExclamationTriangle} style={{ marginRight: '16px' }} />
          {props.headline}
        </ModalHeader>
      ) : null}
      <ModalBody bsPrefix="modalContent">{props.content}</ModalBody>
    </ModalWrapper>
  );
};

export default GenericModal;
