import React from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';

const SurveyStatusModal = ({ show, closeModal }) => {
  return (
    <ModalWrapper className='survey-status-modal-wrapper' show={show} onExited={closeModal}>
      <ModalHeader closeButton>
        Survey Status
      </ModalHeader>
      <ModalBody bsPrefix='modalContent survey-status-modal'>
        The survey participants are still being added to the survey.
      </ModalBody>
    </ModalWrapper>
  );
}


const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
  };
};


export default connect(null, mapDispatchToProps)(SurveyStatusModal);
