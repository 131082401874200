import React from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap';
import { Auth } from 'aws-amplify';
import * as actions from '../../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import ForgotPasswordStartForm from './ForgotPasswordStartForm';

const ForgotPasswordModal = ({
  show,
  closeModal,
  redirectToResetPassword
}) => {

  const forgotPasswordStart = (username) => {
    Auth.forgotPassword(username)
      .then(() => {
        closeModal();
        redirectToResetPassword();
      })
      .catch(err => console.log(err));
  }

  const handleSubmit = (values) => {
   forgotPasswordStart(values.username);
  }

  return (
    <ModalWrapper show={show}>
      <ModalHeader toggle={closeModal}>
        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '16px' }} />
        Reset your password
      </ModalHeader>
      <ModalBody bsPrefix='modalContent'>
        <p>Please enter your email address. You will receive a code via email and you can reset your password on the next page with the code.</p>
        <ForgotPasswordStartForm
          onSubmit={handleSubmit}
        />
      </ModalBody>
    </ModalWrapper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default connect(null, mapDispatchToProps)(ForgotPasswordModal);
