import uk from './uk';
import us from './us';
const { REACT_APP_STAGE } = process.env;

export const getLocale = () => {
  return REACT_APP_STAGE === 'uk'
    ? { country: 'UK', stateData: uk.filter(obj => obj.country === 'England') }
    : { country: 'US', stateData: us }
};

export default getLocale;
