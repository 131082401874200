import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import IconEmail from '../../../../assets/imgs/icon-email.svg';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import CheckedImage from '../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

class ResponderHome extends Component {
	state = {
		participants: [],
		responderParticipants: []
	};

	isUK = process.env.REACT_APP_STAGE === 'uk';
	componentDidMount() {
		this.props.getResponderParticipants(this.props.user);
		this.props.getAssignedResponderParticipants(this.props.user);
	}

	getBreadcrumbs = () => {
		const breadcrumbs = [
			{
				key: 1,
				url: '/responder/',
				label: 'Home'
			}
		];

		return breadcrumbs;
	};

	openConfirmationModal = (title, body, buttonText, confirm) => {
		this.props.openModal({
			modalType: 'RESPONDER_CONFIRMATION_MODAL',
			show: true,
			closeModal: this.props.closeModal(),
			title,
			body,
			buttonText,
			confirm
		});
	};

	openNotesModal = (participant, confirm) => {
		this.props.openModal({
			modalType: 'RESPONDER_NOTES_MODAL',
			show: true,
			closeModal: this.props.closeModal(),
			confirm,
			participant
		});
	};

	getContextMenu = item => {
		return (
			<div className="dropdown">
				<div className="small-gray-button-outlined context-menu">...</div>
				<div className="dropdown-content">
					<div>
						<span
							onClick={() => {
                // eslint-disable-next-line no-unused-vars
								new Promise((resolve, reject) => {
									this.props.removeResponderId(item.surveyParticipantId);
									resolve();
								}).then(() => {
                  this.props.getResponderParticipants(this.props.user,1000);
                  this.props.getAssignedResponderParticipants(this.props.user,1000);
								});
							}}
						>
							Remove from my list
						</span>
						<span
							onClick={() => {
								this.openNotesModal(item, (surveyParticipant, surveyId, responder) => {
									this.props.updateParticipant(surveyParticipant, surveyId, responder);
									this.props.getAssignedResponderParticipants(this.props.user);
								});
							}}
						>
							Notes
						</span>
					</div>
				</div>
			</div>
		);
	};
	generateMyListTableData = () => {
		if (this.props.assignedSurveyParticipants && this.props.assignedSurveyParticipants.length > 0) {
			const result = this.props.assignedSurveyParticipants
				.sort((a, b) => a.priority - b.priority)
				.map(participant => ({
					participant: `${participant.firstName} ${participant.lastName}`,
					group: participant.district,
					organization: participant.school,
					priority: (
						<Link
							className="button-text report-link"
							to={{
								pathname: `/admin/reports/student?pId=${participant.surveyParticipantId}`
							}}
							target="_blank"
						>
							<span
								className="report-icon"
								data-num={participant.priority > 0 ? participant.priority : null}
								data-contacted={participant.contacted}
							>
								<ReportIcon />
							</span>
						</Link>
					),
					email: participant.participantEmail ? (
						<a href={`mailto:${participant.participantEmail}`}>
							<img src={IconEmail} alt="envelope icon" />
						</a>
					) : participant.parentEmail ? (
						<a href={`mailto:${participant.parentEmail}`}>
							<img src={IconEmail} alt="envelope icon" />
						</a>
					) : (
						''
					),
					phone: participant.parentPhone || '',
					selected: (
						<img
							className="checkbox"
							style={{ cursor: 'pointer' }}
							alt="Checkbox"
							onClick={() => {
								if (this.state.responderParticipants.includes(participant.surveyParticipantId)) {
									const newParticipants = this.state.responderParticipants.filter(
										surveyParticipantId => surveyParticipantId !== participant.surveyParticipantId
									);
									this.setState({ responderParticipants: newParticipants });
								} else {
									const newParticipants = [
										...this.state.responderParticipants,
										participant.surveyParticipantId
									];
									this.setState({ responderParticipants: newParticipants });
								}
							}}
							src={
								this.state.responderParticipants.includes(participant.surveyParticipantId)
									? CheckedImage
									: UncheckedImage
							}
						/>
					),
					notes: participant.notes ? (
						<FontAwesomeIcon
            icon={faMessage}
							className="blue-fill-icon"
							onClick={() => {
								this.openNotesModal(participant, (surveyParticipant, surveyId, responder) => {
									this.props.updateParticipant(surveyParticipant, surveyId, responder);
									this.props.getAssignedResponderParticipants(this.props.user);
								});
							}}
						/>
					) : (
						''
					),
					menu: this.getContextMenu(participant)
				}));
			return result;
		}
		return [];
	};

	generateTableData = () => {
		console.log(this.props.surveyParticipants);
		if (this.props.surveyParticipants && this.props.surveyParticipants.length > 0) {
			const result = this.props.surveyParticipants
				.sort((a, b) => a.priority - b.priority)
				.map(participant => ({
					participant: `${participant.firstName} ${participant.lastName}`,
					group: participant.district,
					organization: participant.school,
					priority: (
						<div className="button-text report-link">
							<span
								className="report-icon"
								data-num={participant.priority > 0 ? participant.priority : null}
								data-contacted={participant.contacted}
							>
								<ReportIcon />
							</span>
						</div>
					),
					selected: (
						<img
							className="checkbox"
							style={{ cursor: 'pointer' }}
							alt="Checkbox"
							onClick={() => {
								if (this.state.participants.includes(participant.surveyParticipantId)) {
									const newParticipants = this.state.participants.filter(
										surveyParticipantId => surveyParticipantId !== participant.surveyParticipantId
									);
									this.setState({ participants: newParticipants });
								} else {
									const newParticipants = [
										...this.state.participants,
										participant.surveyParticipantId
									];
									this.setState({ participants: newParticipants });
								}
							}}
							src={
								this.state.participants.includes(participant.surveyParticipantId)
									? CheckedImage
									: UncheckedImage
							}
						/>
					)
				}));
			return result;
		}
		return [];
	};
	render() {
		return (
			<>
				<div className="page admin-home">
					<div className="sub-header">
						<Breadcrumbs breadcrumbs={this.getBreadcrumbs()} />
					</div>
					<div className="survey-info flex-row d-print-none justify-content-between mb-3 align-items-end">
						<strong className="list-title">My List</strong>
						<Button
							type="submit"
							className="small-button green"
							size="lg"
							variant="success"
							onClick={() => {
								if (this.state.responderParticipants && this.state.responderParticipants.length > 0) {
									this.openConfirmationModal(
										'Validated Participants',
										'Marking these participants as validated will remove them from your list. Are you sure you want to mark these participants as validated.',
										'Confirm',
										() => {
                      // eslint-disable-next-line no-unused-vars
											new Promise((resolve, reject) => {
												this.props.bulkResponderComplete(this.state.responderParticipants);
												resolve();
											}).then(() => {
												this.setState({ responderParticipants: [] });
                        this.props.closeModal();
                        
												this.props.getResponderParticipants(this.props.user,1000);
												this.props.getAssignedResponderParticipants(this.props.user,1000);
											});
										}
									);
								}
							}}
						>
							Confirm Selection
						</Button>
					</div>

					<TableGenerator
						isLoading={this.props.isAssignedResponderParticipantsLoading}
						config={{
							headers: [
								'PARTICIPANT',
								`${this.isUK ? 'GROUP NAME' : 'DISTRICT NAME'}`,
								`${this.isUK ? 'ORGANISATION NAME' : 'SCHOOL NAME'}`,
								'PRIORITY',
								'EMAIL',
								'PHONE NUMBER',
								'VALIDATED',
								'NOTES',
								''
							],
							contentRows: this.generateMyListTableData()
						}}
					/>

					<div className="survey-info flex-row d-print-none justify-content-between m-3">
						<strong className="list-title">Full List</strong>
						<Button
							type="submit"
							disabled={this.state.participants > 0}
							className="small-button green"
							size="lg"
							variant="success"
							onClick={() => {
								if (this.state.participants.length > 0) {
									this.openConfirmationModal(
										'Add to My List',
										'Adding participants to your list, means that you are taking responsibility for their result validation and will allow you to see their reports, emails, and phone numbers. Are you sure you want to add these participants to your list?',
										'Confirm',
										() => {
                      // eslint-disable-next-line no-unused-vars
											new Promise((resolve, reject) => {
												this.props.bulkAddResponder(this.state.participants, this.props.user);
												resolve();
											}).then(() => {
												this.setState({ participants: [] });
												this.props.closeModal();
												this.props.getResponderParticipants(this.props.user,1000);
												this.props.getAssignedResponderParticipants(this.props.user,1000);
											});
										}
									);
								}
							}}
						>
							Confirm Selection
						</Button>
					</div>

					<TableGenerator
						isLoading={this.props.isResponderParticipantsLoading}
						config={{
							headers: [
								'PARTICIPANT',
								'GROUP NAME',
								`${this.isUK ? 'ORGANISATION NAME' : 'SCHOOL NAME'}`,
								'PRIORITY',
								'ADD TO MY LIST'
							],
							contentRows: this.generateTableData()
						}}
					/>
				</div>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getResponderParticipants: (responderId,timeOut) => dispatch(actions.getResponderParticipants(responderId,timeOut)),
		getAssignedResponderParticipants: (responderId,timeOut) =>
			dispatch(actions.getAssignedResponderParticipants(responderId,timeOut)),
		bulkAddResponder: (participantIds, responderId) =>
			dispatch(actions.bulkAddResponder(participantIds, responderId)),
		bulkResponderComplete: (participantIds, responderId) =>
			dispatch(actions.bulkResponderComplete(participantIds, responderId)),
		removeResponderId: id => dispatch(actions.removeResponderId(id)),
		closeModal: () => dispatch(actions.closeModal()),
		openModal: modalProps => dispatch(actions.openModal(modalProps)),
		updateParticipant: (surveyParticipant, surveyId, responder) =>
			dispatch(actions.updateParticipant(surveyParticipant, surveyId, responder))
	};
};

const mapStateToProps = state => {
	return {
		user: state.auth.isAuthenticated.username,
		surveyParticipants: state.surveyCrud.surveyParticipants,
		assignedSurveyParticipants: state.surveyCrud.assignedSurveyParticipants,
		isResponderParticipantsLoading: state.surveyCrud.isResponderParticipantsLoading,
		isAssignedResponderParticipantsLoading: state.surveyCrud.isAssignedResponderParticipantsLoading
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ResponderHome));
