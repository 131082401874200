export default [
  {
    "name":"Buckinghamshire",
    "abbreviation":"Buckinghamshire",
    "country":"England"
  },
  {
    "name":"Cambridgeshire",
    "abbreviation":"Cambridgeshire",
    "country":"England"
  },
  {
    "name":"Cumbria",
    "abbreviation":"Cumbria",
    "country":"England"
  },
  {
    "name":"Derbyshire",
    "abbreviation":"Derbyshire",
    "country":"England"
  },
  {
    "name":"Devon",
    "abbreviation":"Devon",
    "country":"England"
  },
  {
    "name":"Dorset",
    "abbreviation":"Dorset",
    "country":"England"
  },
  {
    "name":"East Sussex",
    "abbreviation":"East Sussex",
    "country":"England"
  },
  {
    "name":"Essex",
    "abbreviation":"Essex",
    "country":"England"
  },
  {
    "name":"Gloucestershire",
    "abbreviation":"Gloucestershire",
    "country":"England"
  },
  {
    "name":"Greater London",
    "abbreviation":"Greater London",
    "country":"England"
  },
  {
    "name":"Greater Manchester",
    "abbreviation":"Greater Manchester",
    "country":"England"
  },
  {
    "name":"Hampshire",
    "abbreviation":"Hampshire",
    "country":"England"
  },
  {
    "name":"Hertfordshire",
    "abbreviation":"Hertfordshire",
    "country":"England"
  },
  {
    "name":"Kent",
    "abbreviation":"Kent",
    "country":"England"
  },
  {
    "name":"Lancashire",
    "abbreviation":"Lancashire",
    "country":"England"
  },
  {
    "name":"Leicestershire",
    "abbreviation":"Leicestershire",
    "country":"England"
  },
  {
    "name":"Lincolnshire",
    "abbreviation":"Lincolnshire",
    "country":"England"
  },
  {
    "name":"Merseyside",
    "abbreviation":"Merseyside",
    "country":"England"
  },
  {
    "name":"Norfolk",
    "abbreviation":"Norfolk",
    "country":"England"
  },
  {
    "name":"Northamptonshire",
    "abbreviation":"Northamptonshire",
    "country":"England"
  },
  {
    "name":"North Yorkshire",
    "abbreviation":"North Yorkshire",
    "country":"England"
  },
  {
    "name":"Nottinghamshire",
    "abbreviation":"Nottinghamshire",
    "country":"England"
  },
  {
    "name":"Oxfordshire",
    "abbreviation":"Oxforshire",
    "country":"England"
  },
  {
    "name":"Somerset",
    "abbreviation":"Somerset",
    "country":"England"
  },
  {
    "name":"South Yorkshire",
    "abbreviation":"South Yorkshire",
    "country":"England"
  },
  {
    "name":"Staffordshire",
    "abbreviation":"Staffordshire",
    "country":"England"
  },
  {
    "name":"Suffolk",
    "abbreviation":"Suffolk",
    "country":"England"
  },
  {
    "name":"Surrey",
    "abbreviation":"Surrey",
    "country":"England"
  },
  {
    "name":"Tyne and Wear",
    "abbreviation":"Tyne and Wear",
    "country":"England"
  },
  {
    "name":"Warwickshire",
    "abbreviation":"Warwickshire",
    "country":"England"
  },
  {
    "name":"West Midlands",
    "abbreviation":"West Midlands",
    "country":"England"
  },
  {
    "name":"West Sussex",
    "abbreviation":"West Sussex",
    "country":"England"
  },
  {
    "name":"West Yorkshire",
    "abbreviation":"West Yorkshire",
    "country":"England"
  },
  {
    "name":"Worcestershire",
    "abbreviation":"Worcestershire",
    "country":"England"
  }
];
