const getSchoolAndDistrict = (activeOrg) => {
  if (activeOrg === null ) { 
    return { schoolId: null, districtId: null}
  }
  if (activeOrg.classification === 'school') {
    return {
      schoolId: activeOrg.orgId,
      districtId: activeOrg.parentOrg.orgId
    }
  }
  if (activeOrg.classification === 'school-district') {
    return {
      districtId: activeOrg.orgId,
      schoolId: null,
    }
  }
}

export default getSchoolAndDistrict;