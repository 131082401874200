export default {
  at_risk: {
    label: 'May Need Assistance',
    color: 'red',
    url: {
      default: 'http://www.terracemetrics.org/parents/',
      adult: 'https://workplace.terracemetrics.org/?loginpress_code=jhk4X3BbAzKlANHVaahLJB9R5TVGGc',
      defaultUK: 'http://www.mymynd.co.uk/parents/',
      adultUK: 'http://www.mymynd.co.uk/adults/',
    }
  },
  moderate: {
    label: 'Slight Concern',
    color: 'yellow',
  },
  satisfactory: {
    label: 'Satisfactory',
    color: 'light-green',
  },
  optimal: {
    label: 'Optimal',
    color: 'green',
  },
  no_concern: {
    label: 'No Concern',
    color: 'grey',
  },
  no_tfs: {
    label: 'TFS Not Available',
    color: 'grey',
  },
};
