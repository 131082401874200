import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { ModalHeader, ModalBody } from 'reactstrap';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { dateFormat } from '../../../../../utilities/dates';

import * as actions from '../../../../../store/actions';

const NotifyHistoryModal = (props) => {

  const generateTableData = () => {
    if (props.notificationAttempts && props.notificationAttempts.length > 0) {
      let sortedNotifications = props.notificationAttempts;
      sortedNotifications.sort( (n,m) => { return n.timestamp - m.timestamp});
      const result = sortedNotifications.map((logEntry) => {
        return {
          date: moment(logEntry.timestamp).format(`${dateFormat} hh:mm`),
          recipient: logEntry.destinationEmail.split(/\s*,\s*/).join(", "),
          status: logEntry.messageStatus,
          type: logEntry.type
        }
      })
      return result;
    }
    return [];
  }

    return (
      <ModalWrapper 
        className='edit-participant-modal-wrapper' 
        show={props.show} 
        onExited={props.closeModal}
        size="lg"
      >
        <ModalHeader toggle={props.closeModal}>
          Notification History - {props.item.firstName} {props.item.lastName}
        </ModalHeader>
        <ModalBody bsPrefix='modalContent edit-participant-modal'>
          <TableGenerator
            config={{
              headers: ['DATE', 'RECIPIENT', 'STATUS', 'TYPE'],
              contentRows: generateTableData(),
            }}
          />
        </ModalBody>
      </ModalWrapper>
    );
  }

const mapStateToProps = state => {
  return {
    notificationAttempts: state.surveyCrud.notificationAttempts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyHistoryModal);
