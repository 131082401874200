import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../Spinner/Spinner';

const TableGenerator = (props) => {

  const defaultOptions = {
    headers: ['First', 'Second', 'Third'],
    contentRows: [{ key1: 'this', key2: 'is', key3: 'a' }],
    rowFunction: () => { },
    itemsPerPage: 15,
  };

  const config = { ...defaultOptions, ...props.config };
  const makeCell = rowData => {
    let arrayContainer = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(rowData).map(key => {
      arrayContainer.push(
        <td data-key={key} key={uuidv4()}>
          {rowData[key]}
        </td>
      );
    });
    return arrayContainer;
  };

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + config.itemsPerPage;
  const currentItems = config.contentRows.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(config.contentRows.length / config.itemsPerPage);
 
  const showPagination = config.contentRows.length > config.itemsPerPage;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * config.itemsPerPage) % config.contentRows.length;
    setItemOffset(newOffset);
  };

  const { innerWidth: width } = window;
  const isMobileScreen  = width < 600;

  return (
    <>
    <div className='assessment-table' style={{width: isMobileScreen && "300px"}}>
      <Table hover className='tm-table'>
        <thead className="tableHeader">
          <tr>
            {/* render the first index of the first object, here */}
            {config.headers.map((headerTitle, index) => {
              return (
                headerTitle !== undefined && 
              <th key={index}> {headerTitle} </th>);
            })}
          </tr>
        </thead>
        <tbody>
          {/* renders all objects after the first like below */}
          {props.isLoading ? (
            <Spinner />
          ) : (
            currentItems.map((rowData, index) => {
              return (
                <tr
                  key={`${uuidv4()}`}
                  onClick={config.rowFunction.bind(null, index)}
                >
                  {makeCell(rowData)}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
    {showPagination && 
      <ReactPaginate
        previousLabel="<<"
        nextLabel=">>"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={isMobileScreen ? 1 : 5}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        activeClassName="active"
      />}
      </>
  );
};

export default TableGenerator;
