import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import * as tmLinks from './tmLinks';
import Footer from './Footer';
import Page01 from './Page-01';
import Page02 from './Page-02';
import Page03 from './Page-03';
import Page04 from './Page-04';
import Page05 from './Page-05';
import Page06 from './Page-06';

import * as actions from '../../../../store/actions/';

const OverallSurveyReport = ({
  location,
  getReport,
  report,
  isLoading
}) => {
  
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const surveyId = parsed.id;
    getReport(surveyId);
  }, [])

  let component = <div className="tm-report-group">Loading Data</div>;
  if (!isLoading) {
    component = <div className="tm-report-group">
      <Page01 payload={report} />
      <Page02 payload={report} />
      <Page03 payload={report} links={tmLinks} />
      <Page04 payload={report} />
      <Page05 payload={report} links={tmLinks} />
      <Page06 payload={report} links={tmLinks} />
      <Footer />
    </div>;
  }
  return (
    <div>
      {component}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    report: state.overallSurveyReport.report,
    isLoading: state.overallSurveyReport.isLoading,
    error: state.overallSurveyReport.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: (surveyId) => dispatch(actions.getOverallSurveyReport(surveyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OverallSurveyReport));

