import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/Spinner/Spinner';

const NotifyModal = ({ show, closeModal, item, surveyId, notifySurveyInvite }) => {
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async () => {
    setShowLoader(true);
    const participant = item;
    const values = {
      participant,
      surveyId: surveyId
    };
    await notifySurveyInvite(values, surveyId);
  };

  return (
    <ModalWrapper className='survey-status-modal-wrapper' show={show} onExited={closeModal}>
      <ModalHeader toggle={closeModal}>
        Survey Invite
      </ModalHeader>
      <ModalBody bsPrefix='modalContent survey-status-modal'>
        Are you certain you would like to email or text this invite link? <br />
        <strong>This action can not be undone.</strong>
        <div className="btnContainer-edit-participant">
          <Button
            onClick={handleSubmit}
            type="submit"
            className="small-button"
            size="md"
            color="success"
          >
            {showLoader ? <Spinner /> : "Send Invite"}
          </Button>
          <Button onClick={closeModal} className="small-blue-button-outlined" size="md" variant="outline-primary">
            Cancel
          </Button>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    notifySurveyInvite: (participantData, surveyId) => dispatch(actions.notifySurveyInvite(participantData, surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(null, mapDispatchToProps)(NotifyModal);
