import React, {useState, useEffect} from 'react';
import { getLogo } from './../../../../assets/helper';
import { getMobileLogo } from './../../../../assets/helper';

const GroupHeader = ({payload}) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isSmallScreen = windowWidth <= 769;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header>
      <div className="container group-header">
        <div className="d-flex d-row">
          <div className="branding p-2">
            {isSmallScreen ?  
            <img src={getMobileLogo()} alt="Logo" />:
            <img src={getLogo()} alt="Logo" />}
          </div>
          <div className="ml-auto p-3 summary">
            <h2 className="mb-1">Resiliency Assessment</h2>
            <h3>Summary of Results</h3>
            <div className="locations">
              {payload.district}
              <br />
              {payload.school}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default GroupHeader;
