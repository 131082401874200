import React from 'react';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import { getContent } from '../../../../../../utilities/getContent/getContent';

// const SELF_HARM_INDICATOR_ID = '44b68c8d-b49d-460e-9668-a3a4185bcb23';

const IndicatorSelectFormTypeGroup = props => {
  const { typeName, indicatorsByCategoryAndType, indicatorIdsByCategory, chosenIndicators } = props;

  // useEffect(() => {
  //   chosenIndicators.map((preselectedIndicatorId) => {
  //     props.toggleCheckedIndicatorInForm(preselectedIndicatorId)
  //   })
  // },[chosenIndicators])
  const typeNameLabel = getContent(`indicatorType_${typeName}`);
  return (
    <div className="indicator-subgroups-create-survey__block">
      <div className={`subgroup-create-survey`}>
        <div className="subgroup-type-header__sub-head">

            <CheckBox
            labelClasses={['indicator-headers']}
            label={(<>{typeNameLabel} 'Indicators'</>)}
            locatorId={`locator_${typeName}-indicators-button`}
            name={typeName}
            value={typeName}
            isChecked={indicatorIdsByCategory[typeName].every(elem => elem === '44b68c8d-b49d-460e-9668-a3a4185bcb23' || chosenIndicators.indexOf(elem) > -1) ? 'checked' : false}
            onClick={() => props.toggleCategoryIndicators(typeName)}
            classNameLabel={'w-25'}
            description={"Description"}
          />

        </div>
        <div className="indicator-options">
          {indicatorsByCategoryAndType[typeName].map(option => {
            const label = option.displayAs + (option.variant !== 'default' ? ` (${option.variant})` : '');
            return (
              <CheckBox
                key={option.indicatorId}
                locatorId={`locator_${option.indicatorId}`}
                label={label}
                name={option.indicator}
                value={option.id}
                // defaultChecked={chosenIndicators.includes(option.indicatorId)}
                description={option.interpretation}
                isChecked={chosenIndicators.includes(option.indicatorId)}
                onClick={() => props.toggleCheckedIndicatorInForm(option.indicatorId)}
                classNameLabel={'w-25'}
                // onChange={(event) => props.setCheckedIndicatorInForm(event.value)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IndicatorSelectFormTypeGroup;
