import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';

import * as actions from '../../../../../store/actions';
import { Tooltip } from 'reactstrap';
import OrgLogo from './OrgLogo';


const SelectSchool = props => {
  const [firstRender, setFirstRender] = useState(true);
  const selectedValue = props.navigation.schoolSelected;
  const showGetStarted = useSelector(state => state.meta.showGetStarted);
  const isSelectAvailable = props.role !== 'school_champion';
  const isDistrictSelected = props.role === 'district_champion' || props.navigation.districtSelected;

  // const getSchoolList = () => {
  //   if (props.user['custom:role'] === 'district_champion') {
  //     props.schoolsListGet(props.user['custom:orgId']);
  //   }
  //   if (props.user['custom:role'] === 'tm_admin') {
  //     isDistrictSelected && props.schoolsListGet(props.navigation.districtSelected?.value);
  //   }
  // }

  useEffect(() => {
    if (props.navigation.org?.classification === 'school-district') {
      if (firstRender) {
        setFirstRender(false);
        if (props.navigation.schoolsList.length === 0 || props.role === 'tm_admin') {
          props.schoolsListGet();
        }
      } else {
        if (props.role === 'tm_admin') props.schoolsListGet();
      }
    }
  }, [props.navigation.org]);



  const onChange = (event, { action }) => {
    if (action === 'select-option') {
      props.schoolSelect(event);
    }
    if (action === 'clear') {
      props.schoolSelect(null);
    }
    // if (action === 'clear') {
    //   handleHideOrgSearch();
    //   changeRole('district_champion');
    //   changeOrganization(bc_orgId);
    // }
    // if (event) {
    //   updateSearchOrganizationObj({
    //     name: searchClassificationKey,
    //     value: event.value,
    //   });
    //   onSubmit(event.value);
    // }
  };
  return (
    <div>
      {isSelectAvailable ? (
        <>
          {' '}
          <div id="header_school_select_form" className="org_logo">
          <small className="text-muted">Group:</small>
            <Select
              className="search-survey-input-select"
              classNamePrefix="select"
              // isSearchable
              name="color"
              options={props.navigation.schoolsList}
              placeholder={isDistrictSelected ? 'Select Group' : 'Select Organization First'}
              onChange={onChange}
              isClearable
              isDisabled={!isDistrictSelected || props.navigation.schoolsListIsLoading || props.navigation.orgIsLoading}
              // onFocus={handleOnFocus}
              isLoading={props.navigation.schoolsListIsLoading || props.navigation.orgIsLoading}
              value={selectedValue}
            />
          </div>
          <Tooltip isOpen={showGetStarted === 4} placement="bottom" target="header_school_select_form">
            Select a school to perform any action at the school level (e.g. create/view assessments)
          </Tooltip>
        </>
      ) : (
        (
          <OrgLogo org={props.navigation.org} label={"group"}/>
         )
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    navigation: state.navigation,
    role: state.auth.role,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    schoolsListGet: () => dispatch(actions.schoolsListGet()),
    schoolSelect: value => dispatch(actions.schoolSelect(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSchool);
