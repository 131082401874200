import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import SearchReportsForm from './SearchReportsForm';
import * as actions from '../../../../store/actions';
import { dateFormat } from '../../../../utilities/dates';

const SearchReports = ({
  reportSearchCountryRegion,
  reportSearchStateProvince,
  reportSearchDistrict,
  reportSearchSchool,
  reportSearchYear,
  reportSearchName,
  reportSearchReportType,
  showReportsSearch,
  searchReportsResults,
  isLoading,
  role,
  currentReportType,
  currentExclusiveStartKey,
  lastEvaluatedKey,
  pageNumber,
  pages,
  searchReports,
  toggleShowReportSearch,
  changeRole,
  changeOrganization,
  clearSearchReportsPaginationValues
}) => {

  useEffect(() => {
    searchSubmissionHandler();
    return () => {
      clearSearchReportsPaginationValues();
    };
  }, []);

  const isUK = () => (process.env.REACT_APP_STAGE === 'uk');

  const searchSubmissionHandler = (lastKey = null, sortKey = null, pageNumber = 0) => {
    const values = {
      country: reportSearchCountryRegion,
      state: reportSearchStateProvince,
      district: reportSearchDistrict,
      school: reportSearchSchool,
      year: reportSearchYear,
      name: reportSearchName,
      reportType: reportSearchReportType,
      lastKey: lastKey,
      sortKey: sortKey,
      pageNumber,
    };
    searchReports(values);
  };

  const changeRoleAndOrg = (item, isParentOrg) => {
    let orgId = item.orgId;
    let role = 'school_champion';
    if (isParentOrg) {
      orgId = item.parentOrgId;
      role = 'district_champion';
    }

    changeRole(role);
    changeOrganization(orgId);
    history.push('/admin');
  };

  const generateTableDataForStudents = () => {
    const createReportLink = (reportType, item, copy) => {
      if (reportType === 'student') {
        return (
          <Link
            className="button-text"
            to={{
              pathname: `/admin/reports/${reportType}?pId=${item.surveyParticipantId}`,
            }}
            target="_blank"
          >
            {copy}
          </Link>
        );
      }
      return (
        <Link
          className="button-text report-link"
          to={{
            pathname: `/admin/reports/${reportType}?pId=${item.surveyParticipantId}`,
          }}
          target="_blank"
        >
          <span className="report-icon" data-num={item.priority > 0 ? item.priority : null}>
            <ReportIcon />
          </span>
        </Link>
      );
    };

    const createAdminLink = copy => {
      return (
        <Link
          className="button-text"
          to={{
            pathname: `/admin`,
          }}
        >
          {copy}
        </Link>
      );
    };

    if (searchReportsResults && searchReportsResults.length > 0) {
      const result = searchReportsResults.map(item => {
        const obj = {};
        obj.district = role === 'tm_admin' ? createAdminLink(item.district) : item.district;
        obj.school = role === 'tm_admin' ? createAdminLink(item.school) : item.school;
        obj.student = createReportLink('student', item, `${item.firstName} ${item.lastName}`);
        obj.result = createReportLink('parent', item);
        obj.survey = isUK() ? item.surveyName.replace('Uppergrades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName;
        obj.status = item.participantStatus;
        return obj;
      });
      return result;
    }

    return [];
  };

 const generateTableDataForSchools = () => {
    if (searchReportsResults && searchReportsResults.length > 0) {
      const result = searchReportsResults.map(item => {
        const obj = {};
        if (role && role === 'tm_admin') {
          obj.district =
            (
              <button className="button-text" onClick={() => changeRoleAndOrg(item, true)}>
                {item.district}
              </button>
            ) || '';
        } else {
          obj.district = item.district;
        }
        if (role && role === 'tm_admin') {
          obj.school =
            (
              <button className="button-text" onClick={() => changeRoleAndOrg(item)}>
                {item.school}
              </button>
            ) || '';
        } else {
          obj.school = item.school;
        }
        // if and only if you are district champion you will see a school name with plain text instead of the context switch link
        if (role && role === 'district_champion') {
          obj.school = item.school;
        }
        obj.surveyName = isUK() ? item.surveyName.replace('Upper Grades', 'Secondary').replace('Elementary', 'Primary') : item.surveyName;
        obj.date = moment(item.createdAt).format(`${dateFormat} LTS`);
        obj.result = (
          <Link
            className="button-text"
            to={{
              pathname: `/admin/reports/survey?id=${item.surveyId}`,
            }}
            target="_blank"
          >
            <ReportIcon />
          </Link>
        );

        return obj;
      });
      return result;
    }

    return [];
  };

  const getTableConfig = () => {
    const config = {};
    if (currentReportType === 'schoolReport') {
      config.headers = [`${isUK ? 'Group' : 'Organization'}`, `${isUK ? 'Organisation' : 'Group'}`, 'Assessment Name', 'Date', 'Result'];
      config.contentRows = generateTableDataForSchools();
    }

    if (currentReportType === 'studentReport') {
      config.headers = [`${isUK ? 'Group' : 'Organization'}`, `${isUK ? 'Organisation' : 'Group'}`, 'Participant', 'Result', 'Assessment', 'Status'];
      config.contentRows = generateTableDataForStudents();
    }
    return config;
  };


  const breadcrumbs = [
    {
      key: 1,
      url: '/admin',
      label: 'Home',
    },
    {
      key: 2,
      url: '/admin/reports/search',
      label: 'Reports',
    },
  ];

  const getPrevious = () => {
    let lastKey;
    let sortKey;
    let pageNumber = pageNumber - 1;

    if (currentExclusiveStartKey && currentExclusiveStartKey.surveyParticipantId && currentExclusiveStartKey['surveyParticipant-sortKey']) {
      //updateSearchReportsObj({ name: 'lastKey', value: currentExclusiveStartKey.surveyParticipantId });
      //updateSearchReportsObj({ name: 'sortKey', value: currentExclusiveStartKey['surveyParticipant-sortKey'] });
      //lastKey = currentExclusiveStartKey.surveyParticipantId;
      //sortKey = currentExclusiveStartKey['surveyParticipant-sortKey'];
      if (!pages[pageNumber - 1].currentExclusiveStartKey) {
        lastKey = null;
        sortKey = null;
      } else {
        lastKey = pages[pageNumber - 1].currentExclusiveStartKey.surveyParticipantId;
        sortKey = pages[pageNumber - 1].currentExclusiveStartKey['surveyParticipant-sortKey'];
      }
    }
    searchSubmissionHandler(lastKey, sortKey, pageNumber);
  };

  const getNext = () => {
    let lastKey;
    let sortKey;
    if (lastEvaluatedKey && lastEvaluatedKey.surveyParticipantId && lastEvaluatedKey['surveyParticipant-sortKey']) {
      //updateSearchReportsObj({ name: 'lastKey', value: lastEvaluatedKey.surveyParticipantId });
      //updateSearchReportsObj({ name: 'sortKey', value: lastEvaluatedKey['surveyParticipant-sortKey'] });
      lastKey = lastEvaluatedKey.surveyParticipantId;
      sortKey = lastEvaluatedKey['surveyParticipant-sortKey'];
    }
    searchSubmissionHandler(lastKey, sortKey, pageNumber + 1);
  };

  return (
    <div className="survey-list-container">
      <div className="sub-header">
        <BreadCrumbs breadcrumbs={breadcrumbs} />
      </div>
      <SearchReportsForm onSubmit={() => searchSubmissionHandler(null, null)} className={showReportsSearch ? null : 'hidden'} />
      {/* <div className={ searchReportsResults !== null ? null : 'hidden'}> */}
      {isLoading ? (
        <Backdrop>
          <Spinner />
        </Backdrop>
      ) : (
        <>
          <div className='container-search-survey__reports'>
            <div>{`${searchReportsResults.length || 0} Assessment Results`}</div>
            <div className={showReportsSearch ? 'hidden' : 'survey-list-button-header-container'}>
              <Button  
                onClick={toggleShowReportSearch} 
                className={searchReportsResults === null ? 'hidden' : 'small-blue-button'}
              >
                Search Reports
              </Button>
            </div>
          </div>
          <div className={searchReportsResults.length > 0 ? null : 'hidden'}>
            <TableGenerator
              pagination={
                currentReportType === 'studentReport'
                  ? {
                    //next: !isLastKey && lastEvaluatedKey,
                    next: lastEvaluatedKey,
                    previous: pageNumber - 1 >= 0 && currentExclusiveStartKey && currentExclusiveStartKey.surveyParticipantId,
                  }
                  : false
              }
              getPrevious={getPrevious}
              getNext={getNext}
              config={getTableConfig()}
            />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    reportSearchCountryRegion: state.reports.reportSearchCountryRegion,
    reportSearchStateProvince: state.reports.reportSearchStateProvince,
    reportSearchDistrict: state.reports.reportSearchDistrict,
    reportSearchSchool: state.reports.reportSearchSchool,
    reportSearchYear: state.reports.reportSearchYear,
    reportSearchName: state.reports.reportSearchName,
    reportSearchReportType: state.reports.reportSearchReportType,
    showReportsSearch: state.reports.showReportsSearch,
    searchReportsResults: state.reports.searchReportsResults,
    isLoading: state.reports.isLoading,
    role: state.auth.role,
    realRole: state.auth.realRole,
    currentReportType: state.reports.currentReportType,
    currentExclusiveStartKey: state.reports.currentExclusiveStartKey,
    lastEvaluatedKey: state.reports.lastEvaluatedKey,
    isLastKey: state.reports.isLastKey,
    pageNumber: state.reports.pageNumber,
    pages: state.reports.pages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchReports: values => dispatch(actions.searchReports(values)),
    toggleShowReportSearch: () => dispatch(actions.toggleShowReportSearch()),
    changeRole: role => dispatch(actions.changeRole(role)),
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    updateSearchReportsObj: obj => dispatch(actions.updateSearchReportsObj(obj)),
    clearSearchReportsPaginationValues: () => dispatch(actions.clearSearchReportsPaginationValues()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchReports),
);
