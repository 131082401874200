import React from 'react';
import { reduxForm } from 'redux-form';
import { Button } from 'reactstrap';

import ParticipantEntryFields from '../../CreateSurvey/StudentRosterUpload/ParticipantEntryFields';
import Spinner from '../../../../../components/Spinner/Spinner';

const AddParticipantForm = (props) => {
  return (
    <>
      <form onSubmit={props.handleSubmit}>
        {props.error && <span className="error">{props.error}</span>}
        {props.surveyStatus === "Completed" ? (
          <p className='error'><strong>Adding a participant will reopen the assessment.</strong></p>
        ) : null}
        <ParticipantEntryFields surveyName={props.surveyName} index={0} />
        <div className="btnContainer-edit-participant">
          <Button type="submit" className="small-button" size="sm" >
           {props.isLoading && <Spinner/>} Add participant
          </Button>
          <Button onClick={props.closeModal} className="small-blue-button-outlined" size="md" variant="outline-primary">
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}

export default reduxForm({
  form: 'add-participant-form',
})(AddParticipantForm);
