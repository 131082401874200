import React, { useState,  } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import { required, isValidEmail, hideMissing } from '../../../../../utilities/validation';
import RenderDropdown from '../../../../../components/RenderDropdown/RenderDropdown';
import classNames from 'classnames';
import Spinner from '../../../../../components/Spinner/Spinner';

const { country, stateData } = require('../../../../../utilities/locale/').getLocale();

const CreateDistrictForm = (props) => {
  const [primary, setPrimary] = useState(props.initialValues.primary || false);
  const [secondary, setSecondary] = useState(props.initialValues.secondary || false);
  const [adult, setAdult] = useState(props.initialValues.adult || false);

  // useEffect(() => {
  //   const { item } = props;
  //   props.initialize({
  //     ...item,
  //   });
  // }, [])

  const isUK = () => process.env.REACT_APP_STAGE === 'uk';

  const toggleSurveyTypeCheckedPrimary = (type) => {
    if (type === 'primary') {
      setPrimary(!primary);
    } else if (type === 'secondary') {
      setSecondary(!secondary);
    } else if (type === 'adult') {
      setAdult(!adult);
    }
  };

  const isCleverItem = () => {
    return props.item && props.item.cleverData;
  }
  const initialValues = props.initialValues;

  const [formFields, setFormFields] = useState({
    country: initialValues.country,
    state: initialValues.state,
    orgName: initialValues.name,
    employerProvider: null,
    championFName: null,
    championLName: null,
    email: null
  })

  const handleChange = (fieldName, e) => {
    setFormFields(prevState => ({
      ...prevState,
      [fieldName]: e.target.value
    }));
  };

  const handleChangeDropdown = (value, fieldName) => {
    hideMissing(fieldName)
    setFormFields(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  }

  // const onSubmitTest = (values) => {
  //   console.log(values);
  //   props.handleSubmit(values)
  // }

  const { invalid, submitting } = props;
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="mb-2">
        <div className="label-field-container">
          <label className="label" htmlFor="country">
            Country/Region
          </label>
          <Field
            name="country"
            component={RenderDropdown}
            data={[country]}
            className={classNames("tm-dropdown short", formFields.country ? "tm-dropdown--filed" : "tm-dropdown--required")}
            disabled={isCleverItem()}
            required
            validate={[required]}
            onChange={(e) => handleChangeDropdown(e, "country")}
          />
        </div>
        <div>
        </div>
        <div className="label-field-container">
          <label className="label" htmlFor="state">
            {isUK() ? 'County' : 'State/Province'}
          </label>
          <Field
            name="state"
            component={RenderDropdown}
            data={stateData}
            textField="abbreviation"
            valueField="abbreviation"
            className={classNames("tm-dropdown short", formFields.state ? "tm-dropdown--filed" : "tm-dropdown--required")}
            disabled={isCleverItem()}
            required
            validate={[required]}
            onChange={(e) => handleChangeDropdown(e, "state")}
          />
        </div>
      </div>
      <div className="mb-2">
        {props.initialValues.employerProvider ? (
          ''
        ) : (
          <div className="label-field-container">
            <label className="label" htmlFor="employerProvider">
              {`Provider${isUK() ? '' : '(School)'}/Employer`}
            </label>
            <Field
              name="employerProvider"
              component={RenderDropdown}
              data={['Provider', 'Employer']}
              className={classNames("tm-dropdown short", formFields.employerProvider ? "tm-dropdown--filed" : "tm-dropdown--required")}
              onChange={(e) => handleChangeDropdown(e, "employerProvider")}
              required
              validate={[required]}
            />
          </div>
        )}
        <div className="label-field-container label-background-color">
          <label className="label">
            {isUK() ? 'Primary' : 'Elementary'}
            <Field
              name="primary"
              type="checkbox"
              component="input"
              onChange={() => {
                toggleSurveyTypeCheckedPrimary('primary');
              }}
              checked={primary}
            />
          </label>
        </div>
        <div className="label-field-container label-background-color">
          <label className="label">
            {isUK() ? 'Secondary' : 'Upper Grades'}
            <Field
              name="secondary"
              type="checkbox"
              component="input"
              onChange={() => {
                toggleSurveyTypeCheckedPrimary('secondary');
              }}
              checked={secondary}
            />
          </label>
        </div>
        <div className="label-field-container label-background-color">
          <label className="label">
            Adult
            <Field
              name="adult"
              type="checkbox"
              component="input"
              onChange={() => {
                toggleSurveyTypeCheckedPrimary('adult');
              }}
              checked={adult}
            />
          </label>
        </div>
      </div>
      <div className="label-field-container flex-grow-1">
          <label className="label" htmlFor="name">
            {isUK() ? 'Group' : 'Organization'} Name
          </label>
          <Field
            name="name"
            placeholder={`Full ${isUK() ? 'Group' : 'Organization'} Name`}
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.orgName ? "participant-entry-field--filed" : "participant-entry-field--required")}
            disabled={isCleverItem()}
            required
            validate={[required]}
            onChange={(e) => handleChange("orgName", e)}
          />
        </div>
      {!props.item ? (
        <>
          <div className="mb-2">
            <div className="label-field-container">
              <label className="label" htmlFor="firstName">
                Champion Name
              </label>
              <Field
                name="firstName"
                placeholder="First name"
                type="text"
                component="input"
                className={classNames("participant-entry-field", formFields.championFName ? "participant-entry-field--filed" : "participant-entry-field--required")}
                disabled={isCleverItem()}
                required
                validate={[required]}
                onChange={(e) => handleChange("championFName", e)}
              />
            </div>
            <div className="label-field-container">
            <label className="label" htmlFor="firstName">
                Champion Name
              </label>
              <Field
                name="lastName"
                placeholder="Last name"
                type="text"
                component="input"
                className={classNames("participant-entry-field", formFields.championLName ? "participant-entry-field--filed" : "participant-entry-field--required")}
                disabled={isCleverItem()}
                required
                validate={[required]}
                onChange={(e) => handleChange("championLName", e)}
              />
            </div>
          </div>
          <div>
            <div className="label-field-container">
              <label className="label" htmlFor="email">
                Champion Email
              </label>
              <Field
                name="email"
                placeholder="Email Address"
                type="text"
                component="input"
                className={classNames("participant-entry-field", formFields.email ? "participant-entry-field--filed" : "participant-entry-field--required")}
                disabled={isCleverItem()}
                required
                validate={[required, isValidEmail]}
                onChange={(e) => handleChange("email", e)}
              />
            </div>
          </div>
          <div className="d-flex col-4 mb-4 p-0">
            <div className="label-field-container mr-2">
              <label className="label" htmlFor="phoneNumber">
                Champion Phone
              </label>
              <Field
                name="phoneNumber"
                type="tel"
                component={RenderPhoneField}
                className="participant-entry-field"
                disabled={isCleverItem()}
              />
            </div>
          </div>
        </>
      ) : null}
      <div className="btnContainer justify-content-end">
        <Button
          type="submit"
          className="small-button"
          size="lg"
          color="success"
          disabled={invalid || submitting}
        >
         {props.isLoading && <Spinner/>} {props.saveButtonText}
        </Button>
        <Button
          onClick={props.closeModal}
          className="small-blue-button-outlined"
          size="lg"
          color="outline-primary"
        >
          Cancel
        </Button>
      </div>
      <div className="d-flex align-items-center">
        {typeof props.formError === 'string' ? <span className="error">{props.formError}</span> : null}
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'create-district-form',
})(CreateDistrictForm);
