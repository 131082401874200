import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Toast, ToastBody } from 'reactstrap';
import * as actions from '../../store/actions';

const Toaster = (props) => {

  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        // console.log('closing');
        toggle()
      }, 3000)
    }
  }, [props.show])

  const toggle = () => {
    props.closeToaster();
  };

  return (
    <Toast
      onClose={toggle}
      isOpen={props.show}
      className="tm bg-success"
      delay={500}
      autohide={"true"}
    >
      <FontAwesomeIcon
        icon={faXmark}
        type="button"
        className="close"
        onClick={toggle}
        size='1x'
        color='#a9b2c1'
      >
      </FontAwesomeIcon>
      <ToastBody>{props.content}</ToastBody>
    </Toast>
  );
}


const mapStateToProps = state => {
  return {
    show: state.toaster.show,
    content: state.toaster.content,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeToaster: () => dispatch(actions.closeToaster()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toaster);
