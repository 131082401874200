// Verify `password` and `confirmPassword` fields match
export const confirmPasswordsMatch = values => {
  const errors = {}
  const passwordValue = values.password ? values.password : values.newPassword;

  if (passwordValue || values.confirmPassword) {
    if (passwordValue !== values.confirmPassword) {
      errors.confirmPassword = "Passwords don't match";
    }
  }
  return errors;
}

// Field meets all standards required to be a password
export const isValidPassword = value => {
  let passwordErrors = [];
  const passwordRequirements = [
    passwordLength,
    hasLower,
    hasNumber,
  ];

  if (value) {
    passwordErrors = passwordRequirements.map((requirement) => {
      return requirement(value);
    }).filter(value => !!value);
  }

  return passwordErrors.length === 0 ? null : passwordErrors.join('\n');
};

// Field is required to have a value
export const required = value => value ? null : 'Required';

// Field meets length required for passwords
export const passwordLength = value => value && value.length >= 8 ? null : 'Must be at least 8 characters.';

// Field contains at least one uppercase
export const hasUpper = value => value.match(/[A-Z]/) ? null : 'Must contain an uppercase character.';

// Field contains at least one lowercase
export const hasLower = value => value.match(/[a-z]/) ? null : 'Must contain a lowercase character.';

// Field contains at least one number
export const hasNumber = value => value.match(/[0-9]/) ? null : 'Must contain a number.';

// Field contains at least one symbol
export const hasSymbol = value => value.match(/[$^*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/) ? null : 'Must contain a special character.';

export const isValidEmail = value => {
  let emailErrors = [];
  const emailRequirements = [
    validFormat
  ];

  if (value) {
    emailErrors = emailRequirements.map((requirement) => {
      return requirement(value);
    }).filter(value => !!value);
  }

  return emailErrors.length === 0 ? null : emailErrors.join('\n');
};

// Field must contain an @ for valid email
export const validFormat = (value) => {
  if (value.length > 0) {
    if (value.match(/.+@.+\..+/)) {
      return null;
    } else {
      return 'Invalid email address format';
    }
  }
} 

// Validate phone number fields are numeric
export const isValidPhone = value => {
  let phoneErrors = [];
  const phoneRequirements = [
    validPhoneFormat
  ];

  if (value) {
    phoneErrors = phoneRequirements.map((requirement) => {
      return requirement(value);
    }).filter(value => !!value);
  }

  return phoneErrors.length === 0 ? null : phoneErrors.join('\n');
}

export const validPhoneFormat = (value) => {
  if (value.length > 0) {
    if (value.match(/\D/g, '')) {
      return null;
    } else {
      return 'Phone number should be numeric';
    }
  }
} 

export const hideMissing = (elName) => {
  const myClassList = document.querySelector(`[name="${elName}"]`).parentElement.parentElement.classList;
  myClassList.add("selected");
};
