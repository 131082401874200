import React, { useEffect, useState } from 'react';
import { Container, Input, Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import Overlay from '../../../../../components/Overlay/overlay';
import axios from 'axios';
import { setChosenIndicators, listSurveys } from '../../../../../store/actions/surveyCrud/surveyCrud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const { REACT_APP_API_URL } = process.env;

const OverlayExistingAssessments = ({ showExistingAsssessments, setShowExistingAssessments }) => {
  const dispatch = useDispatch();
  const idToken = useSelector(state => state.auth.idToken);
  const surveys = useSelector(state => state.surveyCrud.surveys);
  const activeOrg = useSelector(state => state.navigation.org);
  const appliedTo = useSelector(state => state.surveyCrud.createSurveyObj?.appliedTo);
  const currentSurveysListParams = useSelector(state => state.surveyCrud.surveysListParam);
  const surveysWithSameCategory = surveys.filter(survey => survey.appliedTo == appliedTo).toSorted((s, t) => t.createdAt - s.createdAt);
  const [loading, setLoading] = useState(false);
  const onToggle = () => {
    setShowExistingAssessments(false);
  };

  useEffect(() => {
    const newSurveysListParam = { schoolId: activeOrg.orgId, districtId: activeOrg.parentOrg.orgId }
    if (newSurveysListParam.schoolId === currentSurveysListParams?.schoolId && newSurveysListParam.districtId === currentSurveysListParams?.schoolId) return
    dispatch(listSurveys(newSurveysListParam));
  }, [dispatch]);

  const getIndicatorsForSurveyId = async surveyId => {
    const url = `${REACT_APP_API_URL}/survey/${surveyId}`;
    const res = await axios({
      url,
      method: 'GET',
      headers: { Authorization: idToken },
    });
    return res.data.indicatorsV2;
  };

  const handleOnChange = async surveyId => {
    setLoading(true);
    const indicatorsV2 = await getIndicatorsForSurveyId(surveyId);
    dispatch(setChosenIndicators(indicatorsV2.map(i => i.indicatorId)));
    setLoading(false);
    setShowExistingAssessments(false);
  };

  return (
    <div>
      <Overlay isOpen={showExistingAsssessments} onToggle={onToggle} title={'Select Assessment'} variant="small">
        <Container>
          <p>All the indicators used in the selected assessment will be copied over. You will have the option to add/remove indicators after that.</p>
          {loading ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> Please Wait...
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Assessment</th>
                </tr>
              </thead>
              <tbody>
                {surveysWithSameCategory?.map(survey => (
                  <tr key={`survey-${survey.surveyId}`}>
                    <td >
                      <label>
                      <Input type="checkbox" className='me-2' onChange={() => handleOnChange(survey.surveyId)}></Input>
                      {survey.surveyName}{' '}
                      <small className="text-muted">
                        ({survey.semester} {survey.year})
                      </small>
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Container>
      </Overlay>
    </div>
  );
};

export default withRouter(connect()(OverlayExistingAssessments));
