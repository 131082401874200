import React, { useState } from 'react';
import { Field } from 'redux-form';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import { required, isValidEmail } from '../../../../../utilities/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const SchoolChampionFields = (props) => {

  const [formFields, setFormFields] = useState({
    championFName: null,
    championLName: null,
    email: null,
    phone_Number: null
  })

  const handleChange = (fieldName, e) => {
    if (fieldName == "phone_Number") {
      console.log(e)
      setFormFields(prevState => ({
        ...prevState,
        [fieldName]: e
      }));
    } else {
      setFormFields(prevState => ({
        ...prevState,
        [fieldName]: e.target.value
      }));
    }
  };

  return (
    <div className="mb-4 sibling-border-top">
      {
        props.searchField ? props.searchField : null
      }
      <div className="mb-2">
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`firstName-${props.index}`}
          >
            Champion Name
          </label>
          <Field
            name={`firstName-${props.index}`}
            placeholder="First name"
            type="text"
            component="input"
            disabled={props.disabled}
            className={classNames("participant-entry-field", formFields.championFName ? "participant-entry-field--filed" : "participant-entry-field--required")}
            required
            validate={[required]}
            onChange={(e) => handleChange("championFName", e)}
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <Field
            name={`lastName-${props.index}`}
            placeholder="Last name"
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.championLName ? "participant-entry-field--filed" : "participant-entry-field--required")}

            required
            onChange={(e) => handleChange("championLName", e)}

          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`email-${props.index}`}
          >
            Champion Email
          </label>
          <Field
            name={`email-${props.index}`}
            placeholder=""
            type="text"
            component="input"
            className={classNames("participant-entry-field", formFields.email ? "participant-entry-field--filed" : "participant-entry-field--required")}
            validate={[required, isValidEmail]}
            required
            onChange={(e) => handleChange("email", e)}
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label
            className="label"
            htmlFor={`phoneNumber-${props.index}`}
          >
            Champion Phone
          </label>
          <Field
            name={`phoneNumber-${props.index}`}
            type="tel"
            component={RenderPhoneField}
            validate={[required]}
            required
            className={classNames("participant-entry-field", formFields.phone_Number ? "participant-entry-field--filed" : "participant-entry-field--required")}
            onChange={(e) => handleChange("phone_Number", e)}

          />
        </div>
        <div className="label-field-container icon">
          <button
            onClick={(e) => props.removeChampion(e, props.index)}
            className="btn m-0 p-2"
          >
            <FontAwesomeIcon icon={faTrash} className="blue-fill-icon pointer-events-none m-0" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchoolChampionFields;
