import React from 'react';
import { connect } from 'react-redux';
import Footer from './Footer/Footer';
// import Header from './Header/Header';
import classnames from 'classnames';

const withLayout = (WrappedComponent, props) => {

  const Layout = () => {
    return (
      <div className={classnames('survey-container')}>
        {/* <Header className="row" showLogo={showLogo} showUser={showUser} showLang={showLang} /> */}
        <div>
          <WrappedComponent {...props} />
        </div>
        <Footer className="row" showContact={false} />
      </div>
    );
  };

  const mapStateToProps = state => {
    return {
      lang: state.meta.lang,
    };
  };

  return connect(mapStateToProps)(Layout);
};

export default withLayout;
