import * as actionTypes from '../actionTypes';

export const openModal = (modalProps) => {
  return {
    type: actionTypes.OPEN_MODAL,
    modalType: modalProps.modalType,
    modalProps,
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL
  };
};
