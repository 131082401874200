import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loggedIn: false,
  participantId: null,
  isLoading: false,
  isLoaded: false,
  error: false,
  userName: null,
  userGrade: null,
  userSchoolName: null,
  showErrorMsg: false,
};

const loginWellnessCenterStart = (state) => {
  return update(state, {
    error: { $set: null },
    isLoaded: { $set: false },
    isLoading: { $set: true },
    showErrorMsg: { $set: false },
  });
};

const loginWellnessCenterSuccess = (state, action) => {
  const { participantId } = action;
  return update(state, {
    participantId: { $set: participantId },
    loggedIn: { $set: true },
    isLoading: { $set: false },
    showErrorMsg: { $set: false },
    error: { $set: null },
  });
};

const loginWellnessCenterFail = (state, action) => {
  const { message } = action;
  return update(state, {
    error: { $set: message || 'There was an error.' },
    isLoading: { $set: false },
    showErrorMsg: { $set: true },
  });
};

const loginWellnessCenterMessage = (state, action) => {
  const { message } = action;
  return update(state, {
    error: { $set: message },
    isLoading: { $set: false },
    showErrorMsg: { $set: true },
  });
};

const wellnessCenterAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_WELLNESSCENTER_START:
      return loginWellnessCenterStart(state, action);
    case actionTypes.LOGIN_WELLNESSCENTER_SUCCESS:
      return loginWellnessCenterSuccess(state, action);
    case actionTypes.LOGIN_WELLNESSCENTER_FAIL:
      return loginWellnessCenterFail(state, action);
    case actionTypes.LOGIN_WELLNESSCENTER_MESSAGE:
      return loginWellnessCenterMessage(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default wellnessCenterAuthReducer;
