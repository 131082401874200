const supportedLanguages = [
    {
      label: 'English (US)',
      value: 'en-US',
      audio: true,
    },
    {
      label: 'Español (US)',
      value: 'es-US',
      audio: true,
    },
    {
      label: 'Portuguese',
      value: 'pt-PT',
      audio: true,
    },
    {
      label: 'Creole (Haiti)',
      value: 'ht-ht',
      audio: false,
    },
  ];

const getSupportedLanguages = () => {
  return supportedLanguages;
};

// Using this syntax for polly build just in case its needed;
module.exports = getSupportedLanguages;
