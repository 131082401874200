import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import RenderField from '../../../../components/RenderField/RenderField';
import RenderPhoneField from '../../../../components/RenderPhoneField/RenderPhoneField';

import { confirmPasswordsMatch, required } from '../../../../utilities/validation';

class AddResponderForm extends Component {
  isUK = ()=>( process.env.REACT_APP_STAGE === 'uk');

  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div>
          <label className="label" htmlFor="firstName">{this.props.labelText} First Name</label>
          <Field
            name="firstName"
            placeholder="First Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="lastName">{this.props.labelText} Last Name</label>
          <Field
            name="lastName"
            placeholder="Last Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="email">{this.props.labelText} Email</label>
          <Field
            name="email"
            placeholder="Email Address"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="phoneNumber">{this.props.labelText} Phone</label>
          <div className="modalInput p-0">
            <Field
              name="phoneNumber"
              type="tel"
              component={RenderPhoneField}
              validate={[required]}
            />
          </div>
        </div>
        <div className='btnContainer mt-5'>
          <Button
            type="submit"
            className='small-button green'
            size="lg"
            color="success"
            disabled={ invalid || pristine || submitting }
          >
            {this.props.saveButtonText}
          </Button>
          <Button
            onClick={(e) => this.props.closeModal(e)}
            className='small-blue-button-outlined'
            size="lg"
            variant="outline-primary"
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'add-user-form',
  validate: confirmPasswordsMatch,
})(AddResponderForm);
