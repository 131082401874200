import React from 'react';
import { Button } from 'reactstrap';
import logo from '../../../../assets/imgs/tmLogo.png';
import { getContent } from '../../../../utilities/getContent/getContent';
import { dateFormat, adjustDate } from '../../../../utilities/dates';
import LanguagePicker from '../../../../components/LanguagePicker/LanguagePicker';
import { getLogo } from './../../../../assets/helper';

const ParticipantHeader = (props) => {
    const payload = props.payload;
    const birthday = payload.participant.birthDate ? adjustDate(payload.participant.birthDate).format(dateFormat) : undefined;
    const isAdult = payload.appliedTo && payload.appliedTo === 'adult';
    const profession = payload.profession;
    return (
      <header className={`container-fluid flex-column flex-md-row clearfix ${props.isParentReport === false ? 'd-print-block' : 'd-print-none'}`}>
        {/* Student Details */}
        <section className={`participant col-12 col-md ${props.showPriority ? 'col-print-5' : 'col-print-6'}`}>
          <img className="w-50 mb-3 d-md-none d-print-none" src={logo} alt="Logo" />
          <h1>
            {`${payload.participant.firstName} ${payload.participant.lastName}`} <span>{payload.participant.dob}</span> <span>{payload.participant.studentId || ''}</span>
          </h1>
          {profession ? (
            <p>
              <strong>Profession: {getContent(profession)}</strong>
            </p>
          ) : null}
          <p>
            {birthday ? (
              <span>
                {getContent('report_dob')} {': '}
                {birthday} |{' '}
              </span>
            ) : null}
            {!isAdult ? getContent('report_grade') : null}{' '}
            {!isAdult ? (payload.participant.grade !== null && payload.participant.grade !== undefined ? `${payload.participant.grade} | ` || ' Not Provided | ' : ' Not Provided | ') : null}{' '}
            {payload.participant.schoolName || ''}
          </p>
        </section>
        {props.showPriority ? (
          <div className="d-flex flex-row col-12 col-md col-print-2 p-1">
            {!payload.surveyResultIsValid ? (
              <section className="priority text-center mr-1">
                <h6 className="text-center">{getContent('report_possible-problem-protocol')}</h6>
              </section>
            ) : (
              ''
            )}
            {/* Priority Box */}
            {payload.priority !== 0 && payload.priority !== null && !isAdult ? (
              <section className="priority text-center">
                <h6>{getContent('report_priority')}</h6>
                <h1>{payload.priority}</h1>
              </section>
            ) : null}
          </div>
        ) : null}

        {/* Logo */}
        <section
          className={`branding flex-row col-12 col-md align-items-center justify-content-between justify-content-md-end pt-0 py-md-3 ${props.showPriority ? 'col-print-5' : 'col-print-6'}`}
        >
          {props.isAdult && (
            <a href="https://workplace.terracemetrics.org/?loginpress_code=jhk4X3BbAzKlANHVaahLJB9R5TVGGc">
              <Button type="button" className="small-button green" size="lg">
                Go to Wellness Center
              </Button>
            </a>
          )}
          {props.showLocalization ? (
            <div className="mr-3 d-print-none">
              <LanguagePicker />
            </div>
          ) : null}
          {props.showPrint ? (
            <div className="d-print-none">
              <button
                className="small-blue-button-outlined print"
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </button>
            </div>
          ) : null}
          <img className="d-none d-md-inline d-print-block print-logo" src={getLogo()} alt="Logo" />
        </section>
      </header>
    );
}

export default ParticipantHeader;
