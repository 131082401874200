import React from 'react';
import { Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { faPeopleGroup, faAnglesRight, faAnglesLeft, faFilePen, faFileCirclePlus, faFileCircleCheck, faFolderClosed, faBuilding, faSchool, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import userRole from '../../consts/roles';
import { useDispatch, useSelector } from 'react-redux';
import { openToaster } from '../../store/actions';

export const NavbarVerical = ({ showLang, onClick, isOpen, activeOrgIsSchool, role }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const showGetStarted = useSelector(state => state.meta.showGetStarted);
  const isActive = path => {
    return location.pathname + location.search + location.hash === path;
  };

  return !isOpen ? (
    <Nav vertical className="sidebar-sm">
      <div className="tm-layout__flex__canvas-icon__burger">
        <FontAwesomeIcon icon={faAnglesRight} onClick={onClick} />
      </div>
      <NavItem className={`${isActive('/admin/survey/list#status=In+Progress%2CCreated') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
        <NavLink href={`/admin/survey/list#status=In+Progress%2CCreated`}>
          <FontAwesomeIcon icon={faFilePen} className="tm-layout__flex__canvas-icon__fa" id="ongoingassessments" />
          <UncontrolledTooltip target={`ongoingassessments`}>Ongoing Assessments</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      <NavItem className={`${isActive('/admin/survey/list#status=completed') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
        <NavLink href={`/admin/survey/list#status=Completed`}>
          <FontAwesomeIcon icon={faFileCircleCheck} className="tm-layout__flex__canvas-icon__fa" id="completedassessments" />
          <UncontrolledTooltip target={`completedassessments`}>Completed Assessments</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      <NavItem className={`${isActive('/admin/survey/list#archived=true') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
        <NavLink href={`/admin/survey/list#archived=true`}>
          <FontAwesomeIcon icon={faFolderClosed} className="tm-layout__flex__canvas-icon__fa" id="preassessments" />
          <UncontrolledTooltip target={`preassessments`}>Previous year Assessments</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      <NavItem>
        {activeOrgIsSchool ? (
          <NavLink href={`/admin/survey/create`}>
            <FontAwesomeIcon icon={faFileCirclePlus} className="tm-layout__flex__canvas-icon__fa" id="createassessments" />
            <UncontrolledTooltip target={`createassessments`}>Create New Assessments</UncontrolledTooltip>
          </NavLink>
        ) : (
          <div>
            <FontAwesomeIcon icon={faFileCirclePlus} className="tm-layout__flex__canvas-icon__fa--disabled" id="createassessments" />
            <UncontrolledTooltip target={`createassessments`}>Create New Assessments : Please Select District and Group to Create a new Assessment.</UncontrolledTooltip>
          </div>
        )}
      </NavItem>
      <NavItem className={`${isActive('/admin/participant/search') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
        <NavLink href="/admin/participant/search">
          <FontAwesomeIcon icon={faPeopleGroup} className="tm-layout__flex__canvas-icon__fa" id="searchparticipants" />
          <UncontrolledTooltip target={`searchparticipants`}>Search Participants</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#">
          <FontAwesomeIcon icon={faCircleExclamation} className="tm-layout__flex__canvas-icon__fa" id="priorityparticipants" />
          <UncontrolledTooltip target={`priorityparticipants`}>Priority Participants</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="/admin/users">
          <FontAwesomeIcon icon={faBuilding} className="tm-layout__flex__canvas-icon__fa" id="editorg" />
          <UncontrolledTooltip target={`editorg`}>Edit organization</UncontrolledTooltip>
        </NavLink>
      </NavItem>
      {!(role == userRole.SCHOOL_CHAMPION) && (
        <NavItem className={`${isActive('/admin/organizations') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
          <NavLink href="/admin/organizations">
            <FontAwesomeIcon icon={faSchool} className="tm-layout__flex__canvas-icon__fa" id="managegroup" />
            <UncontrolledTooltip target={`managegroup`}>Manage Groups</UncontrolledTooltip>
          </NavLink>
        </NavItem>
      )}
    </Nav>
  ) : (
    <Nav vertical className="sidebar">
      <div className="tm-layout__flex__canvas-icon">
        {showLang ? <LanguagePicker /> : null}
        <div>
          <FontAwesomeIcon icon={faAnglesLeft} onClick={onClick} />
        </div>
      </div>
      <div className="sidebar__nav-items">
        <div className="sidebar__nav-items__link">{`Assessments`.toUpperCase()}</div>
        <ul>
          <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list#status=In+Progress%2CCreated') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
            <FontAwesomeIcon icon={faFilePen} />
            <RouterNavLink id="sidebar_item_assessment_ongoing" to={`/admin/survey/list#status=In+Progress%2CCreated`} className={'sidebar__nav-items__sub-link'}>
              Ongoing
            </RouterNavLink>
            <Tooltip isOpen={showGetStarted === 1} placement="top" target="sidebar_item_assessment_ongoing">
              See assessments that are ongoing (either recently Created or In Progress)
            </Tooltip>
          </NavItem>
          <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list#status=Completed') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
            <FontAwesomeIcon icon={faFileCircleCheck} />
            <RouterNavLink id="sidebar_item_assessment_completed" to={`/admin/survey/list#status=Completed`} className={'sidebar__nav-items__sub-link'}>
              Completed
            </RouterNavLink>
          </NavItem>
          <Tooltip isOpen={showGetStarted === 1} placement="right" target="sidebar_item_assessment_completed">
            See completed assessments and get reports
          </Tooltip>
          <NavItem className={`sidebar__nav-items__li ${isActive('/admin/survey/list#archived=true') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
            <FontAwesomeIcon icon={faFolderClosed} />
            <RouterNavLink to="/admin/survey/list#archived=true" className="sidebar__nav-items__sub-link">
              Archived
            </RouterNavLink>
            <div id="sidebar_item_assessment_previous" style={{ transform: "translate(0px, 10px)" }}></div>
          </NavItem>
          <Tooltip isOpen={showGetStarted === 1} placement="right" target="sidebar_item_assessment_previous">
            See assessments marked as Archived
          </Tooltip>
          <NavItem id="sidebar_item_assessment_create" className="sidebar__nav-items__li">
            <FontAwesomeIcon icon={faFileCirclePlus} />
            {activeOrgIsSchool ? (
              <RouterNavLink to="/admin/survey/create" className="sidebar__nav-items__sub-link">
                Create New
              </RouterNavLink>
            ) : (
              <div>
                <div className="sidebar__nav-items__sub-link--disabled" id="assessmentnew">
                  Create New
                </div>
                <UncontrolledTooltip target={'assessmentnew'}>Please Select District and Group to Create a new Assessment.</UncontrolledTooltip>
              </div>
            )}
          </NavItem>
          <Tooltip isOpen={showGetStarted === 1} placement="bottom" target="sidebar_item_assessment_create">
            Create new assessment for selected school
          </Tooltip>
        </ul>
        <div className="sidebar__nav-items__link">{`Participants`.toUpperCase()}</div>
        <ul>
          <NavItem className={`sidebar__nav-items__li ${isActive('/admin/participant/search') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
            <FontAwesomeIcon icon={faPeopleGroup} />
            <RouterNavLink id="sidebar_item_participant_search" to={'/admin/participant/search'} className="sidebar__nav-items__sub-link">
              Search
            </RouterNavLink>
            <Tooltip isOpen={showGetStarted === 2} placement="top" target="sidebar_item_participant_search">
              Search for participants by name or ID
            </Tooltip>
          </NavItem>
          <NavItem className={`sidebar__nav-items__li  ${isActive('/admin/participant/priority') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <RouterNavLink id="sidebar_item_participant_priority" to="/admin/participant/priority" className="sidebar__nav-items__sub-link">
              Priority
            </RouterNavLink>
            <Tooltip isOpen={showGetStarted === 2} placement="right" target="sidebar_item_participant_priority">
              Search for participants flagged Priority 1 or 2
            </Tooltip>
          </NavItem>
        </ul>
            <div className="sidebar__nav-items__link">{`My organization`.toUpperCase()}</div>
            <ul>
              <NavItem className={`sidebar__nav-items__li ${isActive('/admin/users') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                <FontAwesomeIcon icon={faBuilding} />
                <RouterNavLink id="sidebar_item_organization_users" to="/admin/users" className="sidebar__nav-items__sub-link">
                  Edit Organization
                </RouterNavLink>
                <Tooltip isOpen={showGetStarted === 3} placement="top" target="sidebar_item_organization_users">
                  Edit your organization information and manage users (champions)
                </Tooltip>
              </NavItem>
              {!(role == userRole.SCHOOL_CHAMPION) && (              
              <NavItem className={`sidebar__nav-items__li ${isActive('/admin/organizations') ? 'sidebar__nav-items__sub-link__active' : ''}`}>
                <FontAwesomeIcon icon={faSchool} />
                <RouterNavLink  id="sidebar_item_organization_groups" to="/admin/organizations" className="sidebar__nav-items__sub-link">
                  Manage Groups
                </RouterNavLink>
                <Tooltip isOpen={showGetStarted === 3} placement="right" target="sidebar_item_organization_groups">
                  Create new groups or edit existing ones
                </Tooltip>
              </NavItem>) }
            </ul>
      </div>
      <div onClick={() => { dispatch(openToaster('hello'))}} className="sidebar__copyright">&copy; Copyright Terrace Metrics 2024</div>
    </Nav>
  );
};
