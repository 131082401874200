import axios from 'axios';
import * as actionTypes from '../actionTypes';

const { REACT_APP_API_URL, STAGE } = process.env;
const API_URL = REACT_APP_API_URL || `https://6du3j6ziaa.execute-api.us-east-1.amazonaws.com/${STAGE}`;

const getReportStart = () => ({
  type: actionTypes.GET_OVERALL_SURVEY_REPORT_START,
});

const getRelatedReportStart = () => ({
  type: actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_START,
});

const getReportSuccess = (report) => ({
  type: actionTypes.GET_OVERALL_SURVEY_REPORT_SUCCESS,
   report,
});

const getRelatedReportSuccess = (report) => ({
  type: actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_SUCCESS,
  selectedRelatedReport: report,
});

const getReportFail = (error) => ({
  type: actionTypes.GET_OVERALL_SURVEY_REPORT_FAIL,
  error,
});

const getRelatedReportFail = (error) => ({
  type: actionTypes.GET_OVERALL_SURVEY_RELATED_REPORT_FAIL,
  error,
});

export const getOverallSurveyReport = (surveyId) => {
  return (dispatch, getState) => {
    dispatch(getReportStart());
    const url = `${API_URL}/reports/survey/${surveyId}`;
    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        console.log('GET OVERALL SURVEY REPORT RESPONSE: ', res);
        dispatch(getReportSuccess(res.data));
      })
      .catch((err) => {
        console.log('ERROR GETTING OVERALL SURVEY REPORT: ', err);
        dispatch(getReportFail(err));
      });
  };
};

export const getOverallSurveyRelatedReport = (surveyId) => {
  return (dispatch, getState) => {
    dispatch(getRelatedReportStart());
    const url = `${API_URL}/reports/survey/${surveyId}`;
    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        console.log('GET OVERALL SURVEY RELATED REPORT RESPONSE: ', res);
        dispatch(getRelatedReportSuccess(res.data));
      })
      .catch((err) => {
        console.log('ERROR GETTING OVERALL SURVEY RELATED REPORT: ', err);
        dispatch(getRelatedReportFail(err));
      });
  };
};
