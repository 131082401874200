import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';
import { schoolsListGetSuccess, districtsListGetSuccess } from '../navigation/navigation';
const { REACT_APP_API_URL } = process.env;

export const listOrganizationsStart = () => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_START,
  };
};

export const hideOrgSearch = () => {
  return {
    type: actionTypes.HIDE_ORG_SEARCH,
  };
};
export const updateSearchOrganizationObj = payload => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_SEARCH,
    payload,
  };
};

export const showOrgSearch = () => {
  return {
    type: actionTypes.SHOW_ORG_SEARCH,
  };
};

export const listOrganizationsSuccess = organizations => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_SUCCESS,
    organizations,
  };
};

export const listOrganizationsFail = err => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_FAIL,
    err,
  };
};

export const listOrganizationsReset = () => {
  return {
    type: "LIST_ORGANIZATIONS_RESET"
  }
}
export const listOrganizations = (params) => {
  const {noChamps} = params || {noChamps: true};
  return async (dispatch, getState) => {
    dispatch(listOrganizationsStart());
    const activeOrg = getState().navigation.org;
    // in case a school is selected, the parentOrg is the district
    const parentOrg = activeOrg ? (activeOrg.classification === 'school-district' ? activeOrg : activeOrg.parentOrg) : null;
    const classification = parentOrg?.classification === 'school-district' ? 'school' : 'school-district';
    // console.log(`parentOrg`, parentOrg);
    // console.log(`classification`, classification);
    const url = `${REACT_APP_API_URL}/org/search?classification=${classification}`
    + (parentOrg ? '&parentOrgId=' + parentOrg.orgId : '')
    + (noChamps? '&noChamps=true' : '');
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        res.data.sort((a,b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
        const schoolsOrDistrictsList = res.data.map(orgItem => {
          return { value: orgItem.orgId, label: orgItem.name };
        });
        if (classification === 'school-district') {
          dispatch(districtsListGetSuccess(schoolsOrDistrictsList));
        } else if (classification === 'school') {
          dispatch(schoolsListGetSuccess(schoolsOrDistrictsList));
        }
        dispatch(listOrganizationsSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrganizationsFail(err));
        throw err;
      });
  };
};

export const getOrganizationStart = () => {
  return {
    type: actionTypes.GET_ORGANIZATION_START,
  };
};

export const getOrganizationSuccess = organization => {
  return {
    type: actionTypes.GET_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const getOrganizationFail = err => {
  return {
    type: actionTypes.GET_ORGANIZATION_FAIL,
    err,
  };
};

export const getOrganization = (orgId, idToken = null) => {
  return (dispatch, getState) => {
    dispatch(getOrganizationStart());
    const url = `${REACT_APP_API_URL}/org?orgId=${orgId}`;

    // NOTE: idToken being passed in from checkAuthState to avoid the race condition
    //    of the idToken not being set in state before this function is called on page load
    axios({
      url,
      method: 'GET',
      headers: { Authorization: idToken || getState().auth.idToken },
    })
      .then(res => {
        dispatch(getOrganizationSuccess(res.data));
      })
      .catch(err => {
        dispatch(getOrganizationFail(err));
      });
  };
};

export const createOrganizationStart = () => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_START,
  };
};

export const createOrganizationSuccess = organization => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const createOrganizationFail = err => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_FAIL,
    err,
  };
};

export const createOrganization = values => {
  return (dispatch, getState) => {
    dispatch(createOrganizationStart());

    const url = `${REACT_APP_API_URL}/org`;
    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(createOrganizationSuccess(res.data.org));
        dispatch(closeModal());
        dispatch(listOrganizations());
        dispatch(openToaster(`${values.name} was successfully added!`));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to create organization';
        dispatch(createOrganizationFail(errMsg));
      });
  };
};

export const updateOrganizationStart = () => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_START,
  };
};

export const updateOrganizationSuccess = organization => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const updateOrganizationFail = err => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_FAIL,
    err,
  };
};

export const updateOrganization = values => {
  return (dispatch, getState) => {
    dispatch(updateOrganizationStart());
    const url = `${REACT_APP_API_URL}/org`;

    axios({
      url,
      method: 'PUT',
      data: { ...values },
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(updateOrganizationSuccess(res.data.Attributes));
        dispatch(getOrganization(values.orgId));
        dispatch(listOrganizations());
        dispatch(closeModal());
        dispatch(openToaster(`Organization/Group was successfully updated.`));
      })
      .catch(err => {
        const errMsg = err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Failed to update organization';
        dispatch(updateOrganizationFail(errMsg));
      });
  };
};

export const deleteOrganizationStart = () => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_START,
  };
};

export const deleteOrganizationSuccess = organization => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const deleteOrganizationFail = err => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_FAIL,
    err,
  };
};

export const deleteOrganization = (org, onSuccess, onError) => {
  return (dispatch, getState) => {
    dispatch(deleteOrganizationStart());
    const url = `${REACT_APP_API_URL}/org/${org.orgId}`;
    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(() => {
        dispatch(deleteOrganizationSuccess());
        dispatch(openToaster('Successfully deleted organization.'));
        dispatch(listOrganizations());
        if (onSuccess) onSuccess();
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Delete to update organization';
        if (onError) onError();
        dispatch(deleteOrganizationFail(errMsg));
      });
  };
};

export const changeOrganizationSuccess = realOrg => {
  return {
    type: actionTypes.CHANGE_ORGANIZATION_SUCCESS,
    realOrg,
  };
};

export const changeOrganization = newOrgId => {
  return (dispatch, getState) => {
    const realOrg = { ...getState().organizations.organization };
    dispatch(changeOrganizationSuccess(realOrg));
    dispatch(getOrganization(newOrgId));
  };
};

export const resetOrganizationSuccess = realOrg => {
  return {
    type: actionTypes.RESET_ORGANIZATION_SUCCESS,
    realOrg,
  };
};

export const resetOrganization = () => {
  return (dispatch, getState) => {
    const realOrg = { ...getState().organizations.realOrganization };
    dispatch(resetOrganizationSuccess(realOrg));
  };
};

export const clearOrganization = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION,
  };
};

export const clearOrganizationSearchOptions = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION_SEARCH_OPTIONS,
  };
};

export const clearSchoolSearchOptions = () => {
  return {
    type: actionTypes.CLEAR_SCHOOL_SEARCH_OPTIONS,
  };
};

export const clearOrganizationErrors = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION_ERRORS,
  };
};

export const clearCreateOrgErrors = () => {
  return {
    type: actionTypes.CLEAR_CREATEORG_ERRORS,
  };
};

export const startDistrictSyncStart = () => {
  return {
    type: actionTypes.START_DISTRICT_SYNC_START,
  };
};

export const startDistrictSyncSuccess = organization => {
  return {
    type: actionTypes.START_DISTRICT_SYNC_SUCCESS,
    organization,
  };
};

export const startDistrictSyncFail = err => {
  return {
    type: actionTypes.START_DISTRICT_SYNC_FAIL,
    err,
  };
};
export const getDistrictData = orgId => {
  return () => {
    `${REACT_APP_API_URL}/org/data/?orgId=${orgId}`;
  };
};
export const startDistrictSync = orgId => {
  return (dispatch, getState) => {
    dispatch(startDistrictSyncStart());
    const url = `${REACT_APP_API_URL}/clever/sync/district?orgId=${orgId}`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(startDistrictSyncSuccess(res.data));
        dispatch(openToaster(`District has begun syncing!`));
      })
      .catch(err => {
        dispatch(startDistrictSyncFail(err));
      });
  };
};
