import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../store/actions';

const OrgPicker = (props) => {
  const [orgs, setOrgs] = useState()

  useEffect(() => {
    getOrgs();
  },[])

  const getOrgs = async () => {
    if (props.orgs) {
      const orgs = await Promise.all(props.orgs.map(async orgId => {
        const { REACT_APP_API_URL } = process.env;
        const url = `${REACT_APP_API_URL}/org?orgId=${orgId}`;
        const response = await axios({
          url,
          method: 'GET',
          headers: { Authorization: props.idToken },
        });

        return response.data;
      }));
      setOrgs(orgs)
      // setState({ orgs });
    }
  }

  return (
    <>
      {
       orgs.length > 1 && props.org ? <select
          className="language-picker"
          onChange={(event) => {
            props.getOrganization(event.target.value);
          }
          }
          value={props.org.orgId}
        >
          {
           orgs.map((item) =>
              <option
                key={item.orgId}
                value={item.orgId}
              >
                {item.name}
              </option>
            )
          }
        </select>
          : null
      }

    </>
  );
}

const mapStateToProps = state => {
  return {
    org: state.organizations.organization,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    getOrganization: orgId => dispatch(actions.getOrganization(orgId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPicker);
