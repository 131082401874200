import axios from 'axios';

const setUpInterceptors = (instance) => {
  instance.interceptors.response.use(null, (error) => {
    console.log('axios interceptor triggered', error);
    if (error.config) {
      console.log('I should retry');
      return axios.request(error.config);
    }

    return Promise.reject(error);
  });

  return instance;
};

const setUpAxios = () => {
  let instance = axios.create({
    timeout: 31000
  });
  instance = setUpInterceptors(instance);
  return instance;
}

export default setUpAxios();

