import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { adjustDate, dateFormat } from '../../../../../utilities/dates';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../../store/actions';
import Overlay from '../../../../../components/Overlay/overlay';
import Spinner from '../../../../../components/Spinner/Spinner';

class BulkSendPdfModal extends Component {
  state = {
    participants: [],
    searchedParticipants: [],
    search: '',
    isSelectAll: false,
    isLoading: false
  };

  componentWillMount() {
    const initialParticipants = this.props.participants
      .filter(participant => participant.participantStatus === 'Completed')
      .sort((p,q) => p.lastName.localeCompare(q.lastName))
      .map((participant, index) => {
        participant.checked = false;
        participant.position = index;
        return participant;
      });
    this.setState({ participants: initialParticipants });
  }

  handleStatusChange = value => {
    console.log(value);
    this.setState({ status: value });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const surveyParticipants = this.state.participants
      .filter(participant => participant.checked === true)
      .map(participant => {
        return {
          id: participant.surveyParticipantId,
          firstName: participant.firstName,
          middleName: participant.middleName,
          lastName: participant.lastName,
          studentId: participant.studentId
        };
      });
    const inviteParticipantsObject = { participants: surveyParticipants, survey: this.props.survey };
    this.props.bulkSendPdf(inviteParticipantsObject);
  };

  searchForNames = e => {
    const searchedParticipants = this.state.participants.filter(
      participant =>
        participant.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        participant.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        (participant.firstName.toLowerCase() + ' ' + participant.lastName.toLowerCase()).includes(e.target.value.toLowerCase()),
    );

    this.setState({ searchedParticipants, search: e.target.value });
  };

  handleClick = index => {
    console.log(index);
    const getClickedParticipant = () => {
      if (this.state.search !== '') {
        const participants = this.state.participants.map(participant => {
          if (participant.position === this.state.searchedParticipants[index].position) {
            participant.checked = !participant.checked;
          }
          return participant;
        });
        this.setState({ participants });
      } else {
        const participants = this.state.participants.map(participant => {
          if (participant.position === index) {
            participant.checked = !participant.checked;
          }
          return participant;
        });
        this.setState({ participants, isSelectAll: false });
      }
    };
    getClickedParticipant();
  };

  generateTableData = () => {
    const result = this.state.participants.map(participant => ({
      name: `${participant.lastName}, ${participant.firstName}`,
      DOB: adjustDate(participant.birthDate).format(dateFormat),
      selected: <img className="checkbox" style={{ cursor: 'pointer' }} alt="Checkbox" src={participant.checked ? CheckedImage : UncheckedImage} />,
    }));
    if (this.state.search !== '')
      return this.state.searchedParticipants.map(participant => ({
        name: `${participant.lastName}, ${participant.firstName}`,
        DOB: adjustDate(participant.birthDate).format(dateFormat),
        selected: <img className="checkbox" alt="Checkbox" src={participant.checked ? CheckedImage : UncheckedImage} />,
      }));
    return result;
  };

  handleIsSelectAll = () => {
    this.setState({
      isSelectAll: !this.state.isSelectAll,
      participants: this.state.participants.map(participant => {
        participant.checked = !this.state.isSelectAll;
        return participant;
      }),
    });
  };

  render() {
    return (
      <Overlay className="survey-status-modal-wrapper" title={'Bulk Download Parent Report PDFs'} isOpen={this.props.show} onToggle={this.props.closeModal}>
        <div className='m-4'>
          <input 
            className="form-control" 
            style={{ border: '1px solid #CACACA' }} 
            type="text" 
            onChange={this.searchForNames} 
            value={this.state.search} 
            placeholder='Search Participants'
          />
          <CheckBox
            className="select-all-invite-container"
            labelClasses={['select-all-invite']}
            label="Select All"
            name="Select All"
            value="Select All"
            isChecked={this.state.isSelectAll}
            onClick={this.handleIsSelectAll}
          />

          <>
            <div className="modal-table-container">
              <TableGenerator
                config={{
                  headers: ['PARTICIPANT', 'DATE OF BIRTH', ''],
                  contentRows: this.generateTableData(),
                  rowFunction: this.handleClick,
                  itemsPerPage: 1000000,
                }}
              />
            </div>
          </>

          <div className="btnContainer-edit-participant">
            <Button onClick={() => this.handleSubmit()} type="submit" className="small-button" size="md" color="success">
              {this.state.isLoading && <Spinner/>}Download Report PDFs
            </Button>
            <Button onClick={this.props.closeModal} className="small-blue-button-outlined" size="md" variant="outline-primary">
              Cancel
            </Button>
          </div>
        </div>
      </Overlay>
    );
  }
}

const mapStateToProps = state => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = dispatch => {
  return {
    bulkSendPdf: sendPdfsObject => dispatch(actions.bulkSendPdf(sendPdfsObject)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkSendPdfModal);
