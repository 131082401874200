import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardText, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import ReactPlayer from 'react-player/file';
import Overlay from '../../../components/Overlay/overlay';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import axios from '../../../utilities/http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faPaperPlane, faPlay, faQuestion } from '@fortawesome/free-solid-svg-icons';
const { REACT_APP_API_URL } = process.env;

const GenericHelp = ({ isMoreHelp }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const toggleContactForm = () => {
    setShowContactForm(b => !b);
  };
  return (
    <>
      <Row className="mt-3">
        <Col>
          {isMoreHelp ? <h2>Need more help?</h2> : null}
          <Button className="impersonate-btn mr-50">
            <FontAwesomeIcon className="me-2" icon={faPlay} />
            Getting started
          </Button>
          <Button className="impersonate-btn">
            <FontAwesomeIcon className="me-2" icon={faBook} />
            User Guides
          </Button>
          <Button className="impersonate-btn">
            <FontAwesomeIcon className="me-2" icon={faQuestion} />
            F.A.Q.
          </Button>
          <Button className="impersonate-btn" onClick={toggleContactForm}>
            <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
            Contact Us
          </Button>
        </Col>
      </Row>
      {showContactForm ? <ContactForm hide={toggleContactForm} /> : null}
    </>
  );
};

const HelpOverlayBody = ({ content, videoUrl }) => {
  return (
    <Container>
      {content ? (
        <Row>
          <Col>
            <p>{content}</p>
            {videoUrl ? <ReactPlayer width="480px" url={videoUrl} controls /> : null}
          </Col>
        </Row>
      ) : null}
      <GenericHelp isMoreHelp={content ? true : false} />
    </Container>
  );
};

const ContactForm = ({ hide, addExtra = true, preFilledType, preFilledSubject }) => {
  const [formSubmitState, setFormSubmitState] = useState('init');
  const user = useSelector(state => state.auth.user) || { family_name: '', given_name: '', email: '' };
  const organization = useSelector(state => state.navigation.org);
  const location = addExtra ? useLocation() : null;
  const idToken = useSelector(state => state.auth.idToken);

  const SubmitForm = () => {
    setFormSubmitState('submitting');
    const formData = new FormData(document.getElementById('helpContactForm'));
    let district, school;
    if (addExtra && organization) {
      if (organization.classification === 'school') {
        school = organization.name;
        district = organization.parentOrg.name;
      } else {
        district = organization.name;
        school = '';
      }
    }
    const formDataValues = {
      name: formData.get('inputName'),
      email: formData.get('inputEmail') || '',
      subject: formData.get('inputSubject') || '',
      description: formData.get('inputDescription'),
      priority: formData.get('inputPriority') || false, // unless specified?
      type: preFilledType || formData.get('inputTypeRequest') || '', // user input
    };
    const ticketExtraData = addExtra
      ? {
          sub: user.sub || '',
          district,
          school,
          path: location.pathname + location.search,
          // tags: [
          //   `district=${district}`,
          //   `school=${school}`,
          //   `path=${location.pathname + location.search}`, // how to do? no props.history here
          // ],
        }
      : {};
    axios({
      url: `${REACT_APP_API_URL}/v2/admin/contact`,
      method: 'POST',
      headers: { Authorization: idToken },
      data: { ...formDataValues, ...ticketExtraData },
    })
      .then(() => {
        setFormSubmitState('success');
      })
      .catch(() => {
        // todo : display error
        setFormSubmitState('init');
      });
  };

  return (
    <Row className="mt-3">
      <Col>
        <Card className="text-dark bg-light mb-3">
          <CardHeader>
            <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
            Contact Form
          </CardHeader>
          <CardBody>
            {/* <CardTitle tag="h2"></CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              Use this form to reach out to us.
            </CardSubtitle> */}
            <CardText tag="div">
              {formSubmitState !== 'success' ? (
                <Form id="helpContactForm">
                  {!preFilledSubject ? (
                    <FormGroup tag="fieldset">
                      <legend>Type of Request</legend>
                      <FormGroup check>
                        <Input name="inputTypeRequest" value="Problem" type="radio" /> <Label check>Help with using the platform</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input name="inputTypeRequest" value="Feature Request" type="radio" /> <Label check>Feedback / Suggestion</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input name="inputTypeRequest" value="Question" type="radio" /> <Label check>Business inquiry</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input name="inputPriority" type="checkbox" /> <Label check>Treat as urgent</Label>
                      </FormGroup>
                    </FormGroup>
                  ) : null}
                  <FormGroup tag="fieldset">
                    <legend>Your Request </legend>
                    <FormGroup>
                      <Label for="inputSubject" className="label bold">
                        Subject
                      </Label>
                      <Input id="inputSubject" name="inputSubject" defaultValue={preFilledSubject || null} placeholder="One line short description" type="text" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="inputDescription" className="label bold">
                        Description
                      </Label>
                      <Input id="inputDescription" name="inputDescription" placeholder="Provide as much detail as possible." rows={4} type="textarea" />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <legend>About you</legend>
                    <FormGroup>
                      <Label for="inputName" className="label bold">
                        Name
                      </Label>
                      <Input id="inputName" name="inputName" defaultValue={`${user?.given_name} ${user?.family_name}`} type="text" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="inputEmail" className="label bold">
                        Email
                      </Label>
                      <Input id="inputEmail" name="inputEmail" defaultValue={user?.email} type="email" />
                    </FormGroup>
                  </FormGroup>
                  <Button disabled={formSubmitState === 'submitting'} onClick={SubmitForm} className="small-button">
                    Submit
                  </Button>
                  <Button disabled={formSubmitState === 'submitting'} onClick={hide} className="small-blue-button-outlined">
                    Cancel
                  </Button>
                </Form>
              ) : (
                <p>We received your message. We'll get back to you shortly.</p>
              )}
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const HelpOverlay = ({ title, content, videoUrl, isOpen, onToggle }) => {
  return (
    <Overlay variant="medium" title={title} isOpen={isOpen} onToggle={onToggle}>
      <HelpOverlayBody content={content} videoUrl={videoUrl} />
    </Overlay>
  );
};

export default HelpOverlay;
export { ContactForm };
