import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const LoginForm = ({ handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async () => {
    setIsLoading(true)
  }

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div>
        <div>
          <Field
            name="username"
            type="text"
            component="input"
            className={classNames('form-control', 'input')}
            placeholder="Username"
            id="locator_login-username"
          />
        </div>
        <div>
          <Field
            name="password"
            type="password"
            component="input"
            className={classNames('form-control', 'input')}
            placeholder="Password"
            id="locator_login-password"
          />
        </div>
         <Button className="button green" id="indicator_login-button"  type="submit" onClick={handleOnSubmit} >
          <div className="row align-content-center">
            <div className="offset-3 col-6 align-content-center">
              Login
            </div>
            <div className="col-3 align-content-center">
            {isLoading ? <Spinner/> :
              <FontAwesomeIcon icon={faArrowRight}/>
              }
            </div>
          </div>
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'login-form',
})(LoginForm);
