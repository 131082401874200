import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import axios from 'axios';
import TableParticipants from './TableParticipants';
import {
  formatInputValueToEpoch,
  formatEpochToInputValue
} from '../../../utilities/dates';
import { Button, Input } from 'reactstrap';
import Spinner from '../../../components/Spinner/Spinner';
import getSchoolAndDistrict from '../../../utilities/getSchoolAndDistrict';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SearchParticipants = (props) => {
  const initState = {
    searchInput: { firstName: "", lastName: "", birthDate: "", studentId: "" },
    searchParams: null,
    searchResults: null,
    inputSearchMethod: "searchByName"
  }
  const [state, setState] = useState(initState);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const activeOrg = useSelector(state => state.navigation.org);
  const { schoolId, districtId } = getSchoolAndDistrict(activeOrg);

  useEffect(() => {
    if ((props.match.params.studentId || props.match.params.participantSearchKey) && activeOrg) {
      const searchInput = {};
      if (props.match.params.studentId) {
        searchInput.studentId = decodeURIComponent(props.match.params.studentId);
      } else {
        const participantSearchKeyArray = decodeURIComponent(props.match.params.participantSearchKey).split("#");
        searchInput.lastName = participantSearchKeyArray[0];
        if (participantSearchKeyArray.length > 1) {
          searchInput.firstName = participantSearchKeyArray[1];
          if (participantSearchKeyArray.length > 2) {
            searchInput.birthDate = formatEpochToInputValue(participantSearchKeyArray[2]);
          }
        }
      }
      const inputSearchMethod = props.match.params.studentId ? "searchByStudentId" : "searchByName";
      setState(prevState => ({
        ...prevState,
        inputSearchMethod: inputSearchMethod,
        searchInput,
      }));
      const searchParams = { ...searchInput };
      (searchInput.birthDate) && (searchParams.birthDate = formatInputValueToEpoch(searchInput.birthDate));
      (schoolId) && (searchParams.orgId = schoolId); // add schoolId if defined
      setState(prevState => ({
        ...prevState,
        searchParams,
      }));
    }
  }, [props.match.params, activeOrg]);

  useEffect(() => {
    if (state.searchParams) {
      searchParticipants()
    }
  }, [state.searchParams])

  const setSearchMethod = (value) => {
    setState(prevState => ({
      ...prevState,
      ...initState,
      inputSearchMethod: value,
    }));
  }

  const handleInputChange = () => {
    const formData = new FormData(document.getElementById('searchParticipantForm'));
    let searchInput;
    if (state.inputSearchMethod === "searchByName") {
      // this is supposed to reset first name and birthdate but it does not work consistently, why?
      // if (formData.get("inputFirstName") === "") {
      //   document.getElementById('inputBirthDate').value = null;
      // }
      // if (formData.get("inputLastName") === "") {
      //   document.getElementById('inputBirthDate').value = null;
      //   document.getElementById('inputFirstName').value = "";
      // }
      searchInput = {
        lastName: formData.get("inputLastName"),
        firstName: formData.get("inputFirstName") || "",
        birthDate: formData.get("inputBirthDate") || ""
      }
    }
    if (state.inputSearchMethod === "searchByStudentId") {
      searchInput = {
        studentId: formData.get("inputStudentId")
      }
    }
    setState(prevState => ({
      ...prevState,
      searchInput: { ...prevState.searchInput, ...searchInput }
    }));
  }

  const isSearchReady = () => {
    return (
      state.inputSearchMethod === "searchByName" && state.searchInput && state.searchInput.lastName !== "")
      || (state.inputSearchMethod === "searchByStudentId" && state.searchInput && state.searchInput.studentId !== "")
  }

  const handleSearchFormSubmit = async (event) => {
    event.preventDefault()
    const searchParams = {};
    let urlWithSearchParams = '/admin/participant/search';
    if (state.inputSearchMethod === "searchByName") {
      let participantSearchKey = state.searchInput.lastName.toUpperCase();
      searchParams.lastName = state.searchInput.lastName;
      if (state.searchInput.firstName) {
        searchParams.firstName = state.searchInput.firstName
        participantSearchKey += "#" + state.searchInput.firstName.toUpperCase();
      }
      if (state.searchInput.birthDate) {
        searchParams.birthDate = formatInputValueToEpoch(state.searchInput.birthDate)
        participantSearchKey += "#" + searchParams.birthDate;
      }
      urlWithSearchParams += `/key/${encodeURIComponent(participantSearchKey)}`;
    }
    if (state.inputSearchMethod === "searchByStudentId") {
      searchParams.studentId = state.searchInput.studentId;
      urlWithSearchParams += `/id/${encodeURIComponent(state.searchInput.studentId)}`;
    }
    history.push(urlWithSearchParams)
    // const newUrl = `${location.pathname}?${searchParams.toString()}`;
    // window.history.pushState({}, '', newUrl); // TODO: fix bad practice !
    // await searchparticipants(searchParams)
  }

  const searchParticipants = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${districtId}/participant/search`;
    setIsLoading(true)
    console.log(state.searchParams);
    const response = await axios({
      url,
      method: 'GET',
      headers: {
        Authorization: props.idToken
      },
      params: state.searchParams
    })
    setState(prevState => ({
      ...prevState,
      searchResults: response.data.searchResult
    }));
    setIsLoading(false)
  };

  return (
    <div className="col">
      <h2>Search Participant</h2>
      {activeOrg ?
        <form id="searchParticipantForm" className='search-participants-form' onSubmit={handleSearchFormSubmit}>
          <div className="d-flex">
            <div className='w-50 d-flex justify-content-between'>
              <div>
                <input
                  type="radio"
                  name="inputSearchMethod"
                  value="searchByName"
                  id="searchByName"
                  checked={state.inputSearchMethod === "searchByName"}
                  onChange={e => { setSearchMethod(e.target.value) }}
                />
                <label htmlFor="searchByName" style={{ marginLeft: "4px" }}>
                  Search By Name
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="inputSearchMethod"
                  value="searchByStudentId"
                  id="searchByStudentId"
                  checked={state.inputSearchMethod === "searchByStudentId"}
                  onChange={e => { setSearchMethod(e.target.value) }}
                />
                <label htmlFor="searchByStudentId" style={{ marginLeft: "4px" }}>
                  Search by Student Id
                </label>
              </div>
            </div>
          </div>
          <div className="search-participants-form__fields">
            {state.inputSearchMethod === "searchByName" &&
              <>
                <div>
                  <label htmlFor="inputLastName">Last name</label>
                  <Input
                    value={state.searchInput.lastName}
                    required
                    type="text"
                    name='inputLastName'
                    className="search-participants-form__input"
                    id="inputLastName"
                    aria-describedby="lastNameHelp"
                    placeholder="Enter last name"
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label htmlFor="inputFirstName">First name</label>
                  <Input value={state.searchInput.firstName} disabled={!state.searchInput?.lastName} type="text" name='inputFirstName' className="search-participants-form__input" id="inputFirstName" aria-describedby="firstNameHelp" placeholder="Enter first name" onChange={handleInputChange} />
                </div>
                <div>
                  <label htmlFor="inputBirthDate">Date of birth</label>
                  <Input value={state.searchInput.birthDate} disabled={!(state.searchInput?.lastName && state.searchInput?.firstName)} type="date" name='inputBirthDate' className="search-participants-form__input" id="inputBirthDate" aria-describedby="birthDateHelp" onChange={handleInputChange} />
                </div>
                <div style={{ marginTop: "22px" }}>
                  <Button type="submit" disabled={!isSearchReady()} className="small-blue-button">Search</Button>
                </div>
              </>
            }
            {state.inputSearchMethod === "searchByStudentId" &&
              <><div>
                <label htmlFor="inputStudentId">Student ID</label>
                <Input value={state.searchInput.studentId} type="text" className='search-participants-form__input' name='inputStudentId' id="inputStudentId" aria-describedby="studentIdHelp" placeholder="Student ID" onChange={handleInputChange} />
                <small id="studentIdHelp" className="form-text text-muted">Enter the unique student ID you've used.</small>
              </div>
                <div>
                  <Button type="submit" disabled={!isSearchReady()} className="small-blue-button">Search</Button>
                </div>
              </>
            }
          </div>
        </form> :
        <div>
          Please Select District to access Search.
        </div>
      }
      {state.searchResults && activeOrg &&
        <TableParticipants enableSearchByName={state.inputSearchMethod === 'searchByStudentId' || !state.searchParams.birthDate} enableSearchById={state.inputSearchMethod !== 'searchByStudentId'} participants={state.searchResults} />
      }
      {isLoading &&
        <div className='d-flex justify-content-center '>
          <Spinner />
        </div>}
    </div>

  )
}
const mapStateToProps = state => {
  return {
    users: state.users.users,
    isUsersLoaded: state.users.isLoaded,
    organizations: state.organizations,
    organization: state.organizations.organization,
    role: state.auth.role,
    realRole: state.auth.realRole,
    idToken: state.auth.idToken,
  };
};


export default connect(mapStateToProps)(withRouter(SearchParticipants));
