import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const TableGeneratorAdvance = props => {
  const defaultOptions = {
    headers: [
      { title: 'First', isSorting: true },
      { title: 'Second', isSorting: true },
      { title: 'Third', isSorting: true },
    ],
    contentRows: [{ key1: 'this', key2: 'is', key3: 'a' }],
    itemsPerPage: 15,
    rowFunction: () => {},
  };
  const config = { ...defaultOptions, ...props.config };

  // fallback if colKey is missing, build it from title
  config.headers.forEach( (v,i,a) => {
    if (!v.colKey) { 
      a[i]['colKey'] = a[i]['title'].toLowerCase().replace(/\s/g, '');
    }
  })

  const [sorting, setSorting] = useState(config.headers.filter(c => c.isSorting).map(header => ({ key: header.colKey, direction: header.direction || null }))); // sorting = [ { key: 'status', direction: null }, ...]
  const currentSortingCol = sorting.find(col => col.direction !== null) || {}
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + config.itemsPerPage;
  const pageCount = Math.ceil(props.config.contentRows.length / config.itemsPerPage);
  const showPagination = props.config.contentRows.length > config.itemsPerPage;

  // Function to sort the data based on the current sorting state
  const sortedData = () => {
    const { key, direction } = currentSortingCol;
    const compareSign = direction === 'asc' ? 1 : -1;
    if (!key || config.sortableColumns[key] === undefined) return config.contentRows;
    const { getValue, compareFn } = config.sortableColumns[key];
    return config.contentRows.toSorted((a, b) => {
      return compareSign * compareFn(getValue(a), getValue(b));
    });
  };

  useEffect(() => {
    setItemOffset(0); // Reset offset when sorting changes
  }, [sorting]);

  const handleSort = key => {
    // console.log(`clicked key: ${key}`);
    setSorting(prevState =>
      prevState.map(sortBefore => {
        // loop through the keys
        // console.log(`before: ${JSON.stringify(sortBefore)}`);
        const sortAfter = {
          key: sortBefore.key,
          direction: sortBefore.key === key ? (sortBefore.direction === 'desc' ? 'asc' : 'desc') : null,
        };
        // console.log(`after: ${JSON.stringify(sortAfter)}`);
        return sortAfter;
      }),
    );
  };

  const handlePageClick = event => {
    const newOffset = (event.selected * config.itemsPerPage) % props.config.contentRows.length;
    setItemOffset(newOffset);
  };

  const makeCell = rowData => {
    return config.headers.map(h => <td key={uuidv4()}>{rowData[h.colKey]}</td>);
  };

  const { innerWidth: width } = window;
  const isMobileScreen = width < 800;
  return (
    <>
      <div className="assessment-table" style={{ width: isMobileScreen && '300px' }}>
        <Table hover className="tm-table">
          <thead className="tableHeader">
            <tr>
              {props.config.headers.map(({ title, colKey, isSorting }, index) => {
                const isCurrentSortingCol = colKey === currentSortingCol.key
                // console.log('th', { colKey, isSorting });
                // console.log('sorting', sorting);
                return (
                  <th key={index} onClick={() => isSorting && handleSort(colKey)}>
                    <span>{title}</span>
                    {isSorting && (
                      <span>
                        {isCurrentSortingCol ? (
                          currentSortingCol.direction === 'asc' ? (
                            <FontAwesomeIcon className="ms-1" icon={faSortUp} />
                          ) : (
                            <FontAwesomeIcon className="ms-1" icon={faSortDown} />
                          )
                        ) : (
                          <FontAwesomeIcon className="ms-1" icon={faSort} />
                        )}
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.isLoading ? (
              <Spinner />
            ) : (
              sortedData()
                .slice(itemOffset, endOffset)
                .map((rowData, index) => (
                  <tr key={`${uuidv4()}`} onClick={config.rowFunction.bind(null, index)}>
                    {makeCell(rowData)}
                  </tr>
                ))
            )}
          </tbody>
        </Table>
      </div>
      {showPagination && (
        <ReactPaginate
          previousLabel="<<"
          nextLabel=">>"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={isMobileScreen ? 1 : 5}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </>
  );
};

export default TableGeneratorAdvance;
