import React, { useState } from 'react';
import { connect } from 'react-redux';
import CreateDistrictForm from './CreateDistrictForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../../../../store/actions';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import activateItem from '../../../../../utilities/activateItem';
import Overlay from '../../../../../components/Overlay/overlay';
const { stateData } = require('../../../../../utilities/locale/').getLocale();

export let newItemAdded = null;

const CreateDistrictModal = props => {
  const [isLoading, setIsLoading] = useState(false)
  const isUK = () => process.env.REACT_APP_STAGE === 'uk';

  const handleSubmit = async values => {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      console.log(values)
      return
    }
    setIsLoading(true)
    const newItem = {
      country: values.country,
      name: values.name,
      classification: 'school-district',
      employerProvider: values.employerProvider,
      primary: values.primary,
      secondary: values.secondary,
      adult: values.adult,
    };
    newItem.state = values.state !== null ? values.state.abbreviation : 'na';
    if (values.employerProvider === 'Provider' && isUK()) {
      newItem.isTMAllowed = false;
    } else {
      newItem.isTMAllowed = true;
    }

    // Updating Item
    if (props.item) {
      newItem.orgId = props.item.orgId;
      props.updateOrganization(newItem);
    }

    // Clever Item
    if (values.search) {
      const user = {
        id: values.searchUser[0].sub,
        isActivated: true,
        type: 'user',
      };
      const org = {
        ...values.search[0],
        orgId: values.search[0].orgId,
        isActivated: true,
        employerProvider: values.employerProvider,
        primary: values.primary,
        secondary: values.secondary,
        adult: values.adult,
      };
      await props.updateOrganization(org);
      await activateItem(user, props.idToken);
    }
    // Brand new item
    if (!props.item && !values.search) {
      newItem.firstName = values.firstName;
      newItem.lastName = values.lastName;
      newItem.email = values.email;
      newItem.phoneNumber = sanitizePhoneNumber(values.phoneNumber);
      props.createOrganization(newItem);
      newItemAdded = newItem
    }
    
    if (props.createOrgError !== null) {
      props.closeModal();
    }
  };

  const getInitialValues = () => {
    const initialValues = {};
    if (props.item) {
      // const phoneNumber = props.item.phoneNumber ? props.item.phoneNumber.substring(2) : '';
      // Initial Values Added here
      initialValues.country = props.item.country;
      // Find the State in StateData
      initialValues.state = stateData.find(state => props.item.state === state.abbreviation);
      initialValues.name = props.item.name;
      initialValues.primary = typeof props.item.primary === 'boolean' ? props.item.primary : true;
      initialValues.secondary = typeof props.item.secondary === 'boolean' ? props.item.secondary : true;
      initialValues.adult = typeof props.item.adult === 'boolean' ? props.item.adult : true;
      initialValues.employerProvider = props.item.employerProvider || null;
      // Not used in Edit for now
      // initialValues.firstName = props.item.firstName;
      // initialValues.lastName = props.item.lastName;
      // initialValues.email = props.item.email;
      // initialValues.phoneNumber = `${phoneNumber}`;
    }
    return initialValues;
  };

  const orgType = isUK() ? 'Group' : 'Organization';
  const initialValues = getInitialValues();
  const wrappedContent = (
    <>
      <div>
        {typeof props.createOrgError === 'string' ? (
          <span className="error" style={{ textAlign: 'center' }}>
            {props.createOrgError}
          </span>
        ) : null}
      </div>
        <CreateDistrictForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          saveButtonText={props.item ? 'Save Edits' : `${isUK() ? 'Add Group' : 'Add Organization'}`}
          closeModal={props.closeModal}
          item={props.item}
          isLoading={isLoading}
        />
    </>
  );
  if (props.isNotModal) return wrappedContent;
  else
    return (
      <Overlay
        isOpen={props.show}
        variant='small'
        title={
          <>
            <FontAwesomeIcon icon={faSchool} color='#377b9d' style={{ marginRight: '1rem' }} />
            <span>{props.item ? `Edit ${orgType}` : `Create new ${orgType}`}</span>
          </>
        }
        onToggle={props.closeModal}
      >
        {wrappedContent}
      </Overlay>
    );
};

const mapStateToProps = state => {
  return {
    error: state.organizations.error,
    createOrgError: state.organizations.createOrgError,
    role: state.auth.role,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(actions.createOrganization(values)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDistrictModal);