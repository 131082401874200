import React from 'react';
import './CheckBox.css';

import classnames from 'classnames';

const CheckBox = props => {
  let labelClasses = [];
  if (props.labelClasses) {
    labelClasses = [...props.labelClasses];
  }

  return (
    <div className={classnames(props.className)} style={{ display: 'flex', marginBottom: '4px', width: '100%' }}>
      <input
        type="checkbox"
        defaultChecked={props.defaultChecked}
        value={props.value}
        name={props.name}
        disabled={props.disabled}
        className="checkbox__input"
        checked={props.isChecked}
        onChange={props.onClick}
      />
      <label className={classnames("pe-2",props.classNameLabel, labelClasses.join(' '))} id={props.locatorId ? props.locatorId : null} onClick={props.onClick} htmlFor={props.name}>
        {props.label}
      </label>
      <div>{props.description}</div>
    </div>
  );
};

export default CheckBox;
