import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import displayPhoneNumber from '../../../../../utilities/displayPhoneNumber';
import CreateSchoolForm from './CreateSchoolForm';
import CreateSchoolFormWrapper from './CreateSchoolFormWrapper';
import * as actions from '../../../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons';
import Overlay from '../../../../../components/Overlay/overlay';

export let newAddedSchool = null;
const CreateSchoolModal = props => {
  const [state, setState] = useState({
    currentPage: 0,
    champions: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.item && props.item.cleverItem) {
      setState({ currentPage: 2 });
      return;
    }
    if (props.item) {
      setState({ currentPage: 1 });
      return;
    }
  }, []);
  // TODO: For editing champions in this modal later down the road
  // console.log('props.item: ', props.item);
  // if (props.item && props.item.schoolChampions) {
  //   if (props.item.schoolChampions.length > 0) {
  //     const schoolChampions = props.item.schoolChampions.map((item, index) => {
  //       const champion = {};
  //       champion[`firstName-${index}`] = item.firstName;
  //       champion[`lastName-${index}`] = item.lastName;
  //       champion[`phoneNumber-${index}`] = item.phoneNumber;
  //       champion[`email-${index}`] = item.email;
  //       return champion;
  //     });
  //     setState({ champions: schoolChampions });
  //   }
  // }

  const addChampion = e => {
    e.preventDefault();
    setState({ champions: [...state.champions, {}] });
  };

  const removeChampion = (e, index) => {
    e.preventDefault();
    const champions = [...state.champions];
    champions.splice(index, 1);
    setState({ champions: champions });
  };


  // showNoChampionWarning = () => {
  //   setState({noChampionWarningShown : true})
  // }
  const handleSubmit = async values => {
    // eslint-disable-next-line no-constant-condition
    if (false) {
      console.log(values)
      return;
    }
    setIsLoading(true);
    const newItem = {
      name: values.name,
      address: values.address,
      city: values.city,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
    };

    const champions = state?.champions?.map((item, index) => {
      return {
        firstName: values[`firstName-${index}`],
        lastName: values[`lastName-${index}`],
        phoneNumber: sanitizePhoneNumber(values[`phoneNumber-${index}`]),
        email: values[`email-${index}`],
      };
    });

 
    // this is after clever due to backwards compatability
    //   We don't want to create or update champions for clever items using the old way
    newItem.schoolChampions = champions;

    // Brand new item
    if (!props.item) {
      const parentOrg = (props.activeOrg.classification === 'school-district' ? props.activeOrg : props.activeOrg.parentOrg);
      newItem.classification = 'school';
      newItem.classificationLevel = values.classificationLevel ? values.classificationLevel.value : undefined;
      newItem.parentOrgId = parentOrg.orgId;
      newItem.state = parentOrg.state,
      newItem.country = parentOrg.country,
      props.createOrganization(newItem);
      newAddedSchool = newItem;
      // }
      return;
    }

    // Updating Item
    if (props.item) {
      newItem.orgId = props.item.orgId;
      props.updateOrganization(newItem);
    }
  };

  let initialValues = {};
  if (props.item) {
    // Initial Values Added here
    // NOTE: Saved for later editing champions on this modal
    // if (state.champions.length > 0) {
    //   initialValues = { ...state.champions.flatten() };
    // }
    initialValues.name = props.item.name;
    initialValues.address = props.item.address;
    initialValues.city = props.item.city;
    initialValues.phoneNumber = displayPhoneNumber(props.item.phoneNumber);
    initialValues.classificationLevel = props.item.classificationLevel;

    if (props.createOrgError !== null) {
      props.closeModal();
    }
  }

  return (
    <Overlay
      isOpen={props.show}
      variant="small"
      onToggle={props.closeModal}
      title={
        <div className="d-flex">
          <FontAwesomeIcon icon={faSchool} className="blue-fill-icon" style={{ marginRight: '1rem' }} />
          {props.item ? 'Edit Group' : 'Create new Group'}
        </div>
      }
    >
      <div>
        {typeof props.createOrgError === 'string' ? (
          <span className="error" style={{ textAlign: 'center' }}>
            {props.createOrgError}
          </span>
        ) : null}
      </div>
      {
        <CreateSchoolFormWrapper item={props.item}>
          <CreateSchoolForm
            onSubmit={handleSubmit}
            isNew={!props.item}
            initialValues={initialValues}
            saveButtonText={props.item ? 'Save' : 'Add Group'}
            champions={state.champions}
            // addChampion={props.item ? null : addChampion}
            addChampion={addChampion}
            removeChampion={removeChampion}
            closeModal={props.closeModal}
            isSchool={props.organization?.employerProvider !== 'Employer'}
            isLoading={isLoading}
          />
        </CreateSchoolFormWrapper>
      }
      {/* {state.currentPage === 2 && <CreateSchoolFormWrapper item={props.item}>
            <CreateSchoolFormClever
              onSubmit={handleSubmit}
              initialValues={initialValues}
              saveButtonText={props.item ? 'Save Edits' : `${isUK() ? 'Add Organisation' : 'Add School'}`}
              closeModal={props.closeModal}
              champions={state.champions}
              addChampion={props.item ? null : addChampion}
              removeChampion={removeChampion}
              item={props.item}
              error={props.error}
              role={props.role}
              organization={props.organization}
              idToken={props.idToken}
            />
          </CreateSchoolFormWrapper>} */}
    </Overlay>
  );
};

const mapStateToProps = state => {
  return {
    activeOrg: state.navigation.org,
    createOrgError: state.organizations.createOrgError,
    role: state.auth.role,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: (values, isDistrictChampion) => dispatch(actions.createOrganization(values, isDistrictChampion)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSchoolModal);
