import React from 'react';
import { Spinner } from 'reactstrap';

// Todo : return logo if available
const OrgLogo = ({ org, label }) => {
    return (
      <div className="org_logo">
        <small className="text-muted">your {label}:</small>
        <br />
        <h3>{org ? org.name : (<Spinner/>)}</h3>
      </div>
    );
};

export default OrgLogo;