import React, { useState } from 'react';
import { connect } from 'react-redux';
// import * as Button from '../../../components/Button/Button';
//import * as actions from '../../../store/actions';
import * as QuizButtonGroup from '../QuizButtonGroup/QuizButtonGroup';
// import classnames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';
import TMButton from '../../../components/TMButton/TMButton';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';

const AdultConsentConfirmation = props => {
  const [answerSelected, setAnswerSelected] = useState(false);
  const [answerValue, setAnswerValue] = useState(null);
  const toggleAnswer = (isAnswerSelected, value) => {
    setAnswerSelected(isAnswerSelected);
    setAnswerValue(value);
  };

  const isAdult = props.appliedTo === "adult";
  console.log('Is Adult? ', isAdult);

  return (
    <div className="container-participant-confirmation">
      <div className="heading">
        <p className="subhead">{getContent('survey_subhead-participant-confirmation')}</p>
        <p className="head">{getContent('survey_heading-adult-consent-confirmation')}</p>
        <AudioButton
          className="mt-2"
          label={getContent('survey_button-read-question')}
          playlist={[
            getAudio('survey_heading-adult-consent-confirmation'),
          ]}
        />
      </div>
      <div className="adultConsent">
        <div>
          <span>
            <strong>{getContent('survey_adult-consent-statement')}<Link to={{pathname: "https://terracemetrics.org/goodwall"}} target={"_blank"}>{'HERE'}</Link></strong>
          </span>
        </div>
      </div>
      <QuizButtonGroup.Fat
        answers={[
          { field: 'response_yes', value: 1 },
          { field: 'response_no', value: 2 },
        ]}
        isSelected={answerSelected}
        answerSelected={toggleAnswer}
      />
      <div className={'row justify-content-center'}>
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={() => {
            if (answerSelected) {
              if (answerValue === 1) {
                // should probably dispatch an action here for surveyStartedAt
                props.history.push('/survey/questions');
              } else if (answerValue === 2) {
                props.history.push('/survey/complete');
              }
            }
          }}
        />
        <TMButton
          className={`btn-round btn-green btn-lg w-300`}
          onClick={() => {
            if (answerValue === 1) {
              // should probably dispatch an action here for surveyStartedAt
              props.history.push('/survey/questions');
            } else if (answerValue === 2) {
              props.history.push('/survey/complete');
            }
          }}
          locatorId='locator_next-button'
          disabled={!answerSelected}
          iconRight={ArrowRight}
        >
          {getContent('survey_button-next-question')}
        </TMButton>
      </div>
    </div>
  );
};

const mapStatetoProps = state => {
  return {
    adultConsent: state.studentAuth.userName,
    appliedTo: state.survey.receivedSurveyData.appliedTo,
  };
};

const mapDispatchToProps = () => {
  return {
    // saveSurveyParticipantResponseData: (surveyDataToSend) => dispatch(saveSurveyParticipantResponseData(surveyDataToSend))
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps,
  )(AdultConsentConfirmation),
);
