import React from 'react';
import { Field, reduxForm } from 'redux-form';
import RenderField from '../../../../components/RenderField/RenderField';
import { isValidPassword, required } from '../../../../utilities/validation';

/**
 * This component is for users who have received a verification code via email
 *   and would like to reset their password
 *
 * TODO: Password Confirmation field, not submitted to Cognito, but for validation purposes
 */
const ResetPasswordForm = ({ invalid, pristine, submitting, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label
          htmlFor="username"
          className="label"
        >
          Username
        </label>
        <Field
          name="username"
          placeholder="username"
          label="username"
          type="text"
          component={RenderField}
          className="input search-survey-input"
          validate={[required]}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="code"
          className="label"
        >
          Code received via email
        </label>
        <Field
          name="code"
          placeholder="code"
          label="code"
          type="text"
          component={RenderField}
          className="input search-survey-input"
          validate={[required]}
        />
      </div>
      <div className="form-group mb-5">
        <label
          htmlFor="new-password"
          className="label"
        >
          New Password
        </label>
        <Field
          name="new-password"
          placeholder="new-password"
          label="new-password"
          type="password"
          component={RenderField}
          className="input search-survey-input"
          validate={[
            isValidPassword,
            required
          ]}
        />
      </div>
      <button
        type="submit"
        className="btn small-blue-button"
        disabled={invalid || pristine || submitting}
      >
        Save new password
      </button>
    </form>
  );
}

export default reduxForm({
  form: 'reset-password'
})(ResetPasswordForm);
