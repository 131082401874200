import React from 'react';
import scoreMap from '../scoreMap';
import { getContent } from '../../../../utilities/getContent/getContent';

const ScoreBlock = ({
  score,
  showLink,
  isAdult,
  solid,
  className: classnameprop
}) => {
  const isUK = process.env.REACT_APP_STAGE === 'uk';
  let scoreDetail = scoreMap[`${score}`] || scoreMap.no_concern;
  // REMOVED as of https://jira.fusionalliance.com/browse/TMOAS-415
  // let indicator = indicator;
  if (scoreDetail) {
    let label = score ? getContent(`risk_${score}`) : getContent('risk_no_concern');
    let url = '';
    if (scoreDetail.url) {
      url = isUK
        ? isAdult
          ? scoreDetail.url.adultUK
          : scoreDetail.url.defaultUK
        : isAdult
          ? scoreDetail.url.adult
          : scoreDetail.url.default;
    }
    let className =
      scoreDetail && solid ? `block-${scoreDetail.color}` : `block-outline-${scoreDetail.color}`;
    // REMOVED as of https://jira.fusionalliance.com/browse/TMOAS-415
    // if (indicator) {
    //   let indicatorStr = indicator.toLowerCase().replace(" ", "");
    //   if (scoreDetail[indicatorStr]) { url = scoreDetail[indicatorStr]; }
    // }
    if (showLink && url) {
      return (
        <div className={`score block ${className} ${classnameprop}`}>
          <a href={`${url}`} target="_blank" className="show-link" rel="noopener noreferrer">
            {label}
          </a>
        </div>
      );
    } else {
      return <div className={`score block ${className} ${classnameprop}`}>{label}</div>;
    }
  } else {
    return '';
  }
}

export default ScoreBlock;
