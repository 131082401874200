import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../../store/actions';

class SurveyNameSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      holdValue: '',
      categories: '0',
    };
  }


  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  handleBlur = () => {
    this.props.setSurveyNameSelect(this.state.value);
  }
   componentDidUpdate(){
    if (this.state.holdValue.length > 1){
      var surveyName = this.state.holdValue
      this.props.setSurveyNameSelect(surveyName)
    } 
    // let setCategories = [];
    // const chosenIndicators = this.props.chosenIndicators;
    // const indicatorIdsByCategory = this.props.indicatorIdsByCategory
    // setCategories =  Object.keys(indicatorIdsByCategory).filter(categoryName => {
    //   if (indicatorIdsByCategory[categoryName].some(elem => chosenIndicators.indexOf(elem) > -1)) {
    //     return categoryName;
    //   } 
    //   return setCategories;
    // });

    // if (prevProps.chosenIndicators !== this.props.chosenIndicators){
    //   let setStateCategories = setCategories.join(" ").toString().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());   
    //   this.setState({
    //     categories: setStateCategories,
    //   })
    //   // eslint-disable-next-line array-callback-return
    // }
  }

	render() {

		return (
			<>
				<div className="file-input-create-survey-step-group d-block">
          <Label className="dropdown-label">Assessment Name</Label>		
          <div className="email-recipient-step">
            <Input
              type="text"
              style={{width: '400px'}}
              className={"participant-entry-field input "}
              value={this.state.value}
              id={"surveyName"}
              onChange={this.handleChange}
              maxLength={255}
              onBlur={this.handleBlur}
            />
          </div>
        </div>
			</>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
    setSurveyNameSelect: (value) => dispatch(actions.setSurveyNameSelect(value))
  };
};

export default withRouter(
	connect(
    null,
		mapDispatchToProps,
	)(SurveyNameSelect)
);