import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../../../store/actions';
import { Editor } from '@tinymce/tinymce-react';
const defaultConsentTemplate = require('./defaultConsentTemplate.json');

const { REACT_APP_API_URL } = process.env;

const ConsentEdit = props => {
  const [formSubmitState, setFormSubmitState] = useState('init');
  const [ready, setReady] = useState(false);
  const API_TOKEN = useSelector(state => state.auth.idToken);
  const isTesting = false;
  const surveyHadSavedTemplate = Object.hasOwn(props.surveyDetails, 'consentTemplate') && props.surveyDetails.consentTemplate !== null;
  const gridWidths = { left: 3, right: 9 };
  const initValues = {
    consentType: props.surveyDetails.consentType ? props.surveyDetails.consentType : 'CONSENT_TYPE_NO_CONSENT',
    consentTemplate: props.surveyDetails.consentTemplate || props.parentOrg.consentTemplate || defaultConsentTemplate,
  };
  const [showTemplate, setShowTemplate] = useState(initValues.consentType !== 'CONSENT_TYPE_NO_CONSENT');
  const editorRef = useRef(null);

  useEffect(() => {
    setReady(true);
  }, []);

  const handleConsentTypeChange = event => {
    const consentType = event.target.value;
    setShowTemplate(consentType !== 'CONSENT_TYPE_NO_CONSENT');
  };

  // this is not needed in fact
  // const resetToOrgDefault = () => {
  //   document.getElementById('inputTemplateSubject').value = props.parentOrg.consentTemplate.subject;
  //   // document.getElementById('inputTemplateBody').value = props.parentOrg.consentTemplate.body;
  //   editorRef.current.setContent(props.parentOrg.consentTemplate.body)
  // };


  const submitConsentEditForm = event => {
    event.preventDefault();
    setFormSubmitState('submitting');
    const formData = new FormData(document.getElementById('consentEditForm'));
    const consentType = formData.get('selectConsentType') === 'CONSENT_TYPE_NO_CONSENT' ? null : formData.get('selectConsentType');
    const payload = {
      surveys: [
        {
          id: props.surveyDetails.surveyId,
          consentTemplate:
            consentType === null
              ? null
              : {
                  subject: formData.get('inputTemplateSubject'),
                  body: editorRef.current.getContent(),
                },
          consentType,
        },
      ],
    };
    if (isTesting) {
      console.log(payload);
    } else {
      axios({
        url: `${REACT_APP_API_URL}/surveys`,
        method: 'POST',

        headers: { Authorization: API_TOKEN },
        data: payload,
      })
        .then(() => {
          setFormSubmitState('success');
          typeof props.onSuccess === 'function' && props.onSuccess();
          props.getSurvey(props.surveyId);
          props.openToaster(`Consent option was successfully added or edited.`);
        })
        .catch(() => {
          setFormSubmitState('error');
        });
    }
  };

  return (
    <Form id="consentEditForm">
      <FormGroup row>
        <Label for="selectConsentType" sm={gridWidths.left}>
          Consent Type <FontAwesomeIcon id="infoConsentType" icon={faInfoCircle} className="blue-fill-icon" />
          {ready ? <UncontrolledTooltip target="infoConsentType">Choose which type of consent is required for this assessment.</UncontrolledTooltip> : null}
        </Label>
        <Col sm={gridWidths.right}>
          <Input id="selectConsentType" name="selectConsentType" type="select" defaultValue={initValues.consentType} onChange={handleConsentTypeChange}>
            <option value={'CONSENT_TYPE_NO_CONSENT'}>No Consent</option>
            <option value={'CONSENT_TYPE_ACTIVE_CONSENT'}>Active Consent</option>
            <option value={'CONSENT_TYPE_PASSIVE_CONSENT'}>Passive Consent</option>
          </Input>
        </Col>
      </FormGroup>
      {showTemplate ? (
        <><hr/>
        <FormGroup tag="fieldset">
          <h2>Consent Request Email</h2>
          <p>
            {!surveyHadSavedTemplate ? (
              <>
                Below is the default email template: please complete <span style={{ backgroundColor: "#f8cac6"}}>highlighted sections</span>. 
                {/* <Button onClick={resetToOrgTemplate} size="sm">
                  Reset to your organization default template
                </Button> */}
              </>
            ) : <>Below is your saved draft template. </>}
            <br/><strong>Edit and review carefully.</strong> 
          </p>
          <FormGroup row className="d-flex align-items-center">
            <Label for="inputTemplateSubject" sm={gridWidths.left}>
              Email Subject <FontAwesomeIcon id="infoTemplateSubject" icon={faInfoCircle} className="blue-fill-icon" />
              {ready ? <UncontrolledTooltip target="infoTemplateSubject">This will be the subject of the email sent to parents for requesting consent</UncontrolledTooltip> : null}
            </Label>
            <Col sm={gridWidths.right}>
              <Input id="inputTemplateSubject" name="inputTemplateSubject" defaultValue={initValues?.consentTemplate?.subject} type="text" className="input" />
            </Col>
          </FormGroup>
          <FormGroup row className="d-flex align-items-center">
            <Label for="inputTemplateBody">
              Email Body <FontAwesomeIcon id="infoTemplateBody" icon={faInfoCircle} className="blue-fill-icon" />
              {ready ? <UncontrolledTooltip target="infoTemplateBody">This text will be included in the email. The links to give or deny consent will be added automatically</UncontrolledTooltip> : null}
            </Label>
            <Editor
              apiKey="d62wp66vrv0hl4cv54a0zosuur9mbtqhk2tun7d6ste46pts"
              onInit={(_evt, editor) => (editorRef.current = editor)}
              initialValue={initValues?.consentTemplate?.body}
              init={{
                height: 500,
                menubar: false,
                plugins: [ 'lists' ],
                //   'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                //   'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                //   'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                // ],
                toolbar: 'undo redo | bold italic underline | backcolor fontfamily fontsize | alignleft aligncenter bullist numlist |',
                content_style: 'body { font-family:Helvetica ,Arial,sans-serif; font-size:12px }',
                branding: false,
                statusbar: false,
              }}
            />
            {/* <Input  id="inputTemplateBody" name="inputTemplateBody" defaultValue={initValues?.consentTemplate?.body} rows={8} type="textarea" /> */}
          </FormGroup>
        </FormGroup>
        </>
      ) : null}
      <Button className="small-button w-20" style={{ marginLeft: 'auto' }} disabled={formSubmitState === 'submitting'} onClick={submitConsentEditForm}>
        {formSubmitState === 'submitting' && <FontAwesomeIcon className="fa-spin " icon={faSpinner} />}
        Save
      </Button>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    surveyDetails: state.surveyCrud.surveyDetails,
    parentOrg: state.surveyCrud.surveyOrg.parentOrg,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSurvey: (surveyId, direction, lastKey, sortKey) => dispatch(actions.getSurvey(surveyId, direction, lastKey, sortKey)),
    openToaster: value => dispatch(actions.openToaster(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentEdit);
