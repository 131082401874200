import React from 'react';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom';
import { logout } from '../../../../store/actions';


const Logout = (props) => {
  props.logout(
    // ()=> { setTimeout( alert('redirect to login'), 5000)}    
  );

  return (
    <div className="pageContainer">
      <p>You have been logged out.</p>      
    </div>
  );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => ({
  logout: cb => dispatch(logout(cb)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
