import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Route Components
import App from '../components/app'; // TODO: Trash?

// Errors
import Error404 from '../containers/Errors/Error404';

// Auth Route Components
import Login from '../containers/Admin/Auth/Login/Login';
import Logout from '../containers/Admin/Auth/Login/Logout';
import CleverLogin from '../containers/Admin/Auth/Login/CleverLogin';
import ResetPassword from '../containers/Admin/Auth/ForgotPassword/ResetPassword';
import ChangePassword from '../containers/Admin/Auth/ChangePassword/ChangePassword';

// Surveys
import Surveys from '../containers/Surveys/Surveys';

// Wellness Center
import WellnessCenter from '../containers/WellnessCenter/WellnessCenter';
import ParentReport from '../containers/Admin/Reports/Parent/Report';
import WellnessCenterHome from '../containers/WellnessCenter/WellnessCenterHome/WellnessCenterHome';

// Admin Routes
import Admin from '../containers/Admin/Admin';

// Responder Routes
import Responder from '../containers/Admin/Responders/Responder';

// HOCs
import withLayout from '../hoc/Layout/Layout';
import wellnessCenterAuth from '../hoc/Layout/WellnessCenterLayout';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        {/* Survey Route */}
        <Route path="/survey" component={Surveys} />

        {/* Wellness Center Route */}
        <Route path="/wellness-center" exact component={WellnessCenter} />
        <Route
          path="/wellness-center/parent"
          exact
          component={wellnessCenterAuth(ParentReport, { showReturnButton: true })}
        />
        <Route path="/wellness-center/home" exact component={wellnessCenterAuth(WellnessCenterHome)} />

        {/* Auth Routes */}
        <Route
          path="/login"
          exact
          component={Login}
        />
        <Route
          path="/logout"
          exact
          component={Logout}
        />
        <Route
          path="/reset-password"
          component={ResetPassword}
        />
        <Route
          path="/clever/login"
          component={withLayout(CleverLogin, {
            showLogo: true,
            showContact: true,
            showUser: false,
            showLang: false,
          })}
        />
        <Route
          path="/change-password"
          component={ChangePassword}
        />

        {/* Admin Routes */}
        <Route path="/admin" component={Admin} />
        {/* Responder Routes */}
        <Route path="/responder" component={Responder} />
        {/* Default Route */}
        <Route path="/" exact component={App} />
        <Route
          component={withLayout(Error404, {
            showLogo: true,
            showContact: true,
            showUser: true,
            showLang: false
          })}
        />
      </Switch>
    </Router>
  );
}

export default AppRouter;
