import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const DropdownButton = ({ label, items, className }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    <DropdownToggle className={className} size="lg" variant="success" caret>
      {label}
    </DropdownToggle>
    <DropdownMenu>
      {items.map((item, index) => {
        return <DropdownItem title={item.title} key={`dropdownKey-${index}`} disabled={item.disabled} onClick={item.onClick}>{item.label}</DropdownItem>
      })}
    </DropdownMenu>
  </Dropdown>;
};

export default DropdownButton;