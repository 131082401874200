import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';
import { listOrganizations } from '../organizations/organizations';
const { REACT_APP_API_URL } = process.env;

export const listUsersStart = () => {
  return {
    type: actionTypes.LIST_USERS_START,
  };
};

export const listUsersSuccess = users => {
  return {
    type: actionTypes.LIST_USERS_SUCCESS,
    users,
  };
};

export const listUsersFail = err => {
  return {
    type: actionTypes.LIST_USERS_FAIL,
    err,
  };
};

export const listUsers = (orgIdParam, includeParent = false) => {
  return (dispatch, getState) => {
    if (!includeParent) dispatch(listUsersStart()); // when includeParent, do not show loading
    let url = `${REACT_APP_API_URL}/users`;

    const orgId = orgIdParam;
    // ? orgIdParam 
    // :
    // getState().organizations ? (getState().organizations?.organization?.orgId || 
    // getState().organizations.districtSearchName ) || getState().organizations.schoolSearchName : null;
    // const role = getState().auth.role;
   
    if (orgId) {
      url = `${REACT_APP_API_URL}/users/${orgId}${includeParent ? '?includeParent=true' : ''}`;
    }

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(listUsersSuccess(res.data.users));
      })
      .catch(err => {
        dispatch(listUsersFail(err));
      });
  };
};

export const updateSearchParticipantSurveysObj = payload => {
  return {
    type: actionTypes.UPDATE_SEARCH_PARTICIPANT_SURVEYS,
    payload,
  };
};

export const listUserSurveys = (values = {}) => {
  return (dispatch, getState) => {
    dispatch(listUserSurveysStart());

    const {
      organizations: { organization: org }
    } = getState();
    
    let bc_level = (getState().auth.role === "tm_admin") ? "ADMIN": (getState().auth.role === "school_champion" || (getState().auth.role === "district_champion" && getState().organizations.organization.parentOrg)) ? "GROUP": (getState().auth.role === "district_champion") ? "ORGANIZATION": "NA";
    const getSchoolOrgId = () => {
      if (org && org.orgId && bc_level === "GROUP") {
        return org.orgId;
      } else {
        return '';
      }
    };
    const getDistrictOrgId = () => {
      if (org.orgId) {
        return org.orgId;
      } else {
        return ''
      }
    };
    let url = `${REACT_APP_API_URL}/surveys/participant?firstName=${values.firstName ? values.firstName :''}&lastName=${values.lastName ? values.lastName : ''}&parentOrgId=${getDistrictOrgId()}&orgId=${getSchoolOrgId()}`;
    updateSearchParticipantSurveysObj({ name: 'userSurveysSearchFirstName', value: '' });
    updateSearchParticipantSurveysObj({ name: 'userSurveysSearchLastName', value: '' });
    
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(listUserSurveysSuccess(res.data));
      })
      .catch(err => {
        dispatch(listUserSurveysFail(err));
      });
  };
};

export const listUserSurveysStart = () => {
  return {
    type: actionTypes.LIST_USER_SURVEYS_START,
  };
};

export const listUserSurveysSuccess = userSurveys => {
  return {
    type: actionTypes.LIST_USER_SURVEYS_SUCCESS,
    userSurveys,
  };
};

export const listUserSurveysFail = err => {
  return {
    type: actionTypes.LIST_USER_SURVEYS_FAIL,
    err,
  };
};

export const listRespondersStart = () => {
  return {
    type: actionTypes.LIST_RESPONDERS_START,
  };
};

export const listRespondersSuccess = users => {
  return {
    type: actionTypes.LIST_RESPONDERS_SUCCESS,
    users,
  };
};

export const listRespondersFail = err => {
  return {
    type: actionTypes.LIST_RESPONDERS_FAIL,
    err,
  };
};

export const listResponders = () => {
  return (dispatch, getState) => {
    dispatch(listOrganizations());
    dispatch(listRespondersStart());

    let url = `${REACT_APP_API_URL}/responders`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(listRespondersSuccess(res.data.users));
      })
      .catch(err => {
        dispatch(listRespondersFail(err));
      });
  };
};

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START,
  };
};

export const getUserSuccess = user => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user,
  };
};

export const getUserFail = err => {
  return {
    type: actionTypes.GET_USER_FAIL,
    err,
  };
};

export const applyCleverUser = user => {
// eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(getUserStart());
    const userProfile = {
      //Dages: Consider using district_username but not guaranteed
      username: user.cleverData.email,
      firstName: user.cleverData.name.first || '',
      lastName: user.cleverData.name.last || '',
      email: user.cleverData.email || '',
      phoneNumber: '',
    };
    dispatch(getUserSuccess(userProfile));
  };
};

export const getUser = userId => {
  return (dispatch, getState) => {
    dispatch(getUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        const userProfile = {
          username: res.data.user.attributes.sub,
          firstName: res.data.user.attributes.firstName || '',
          lastName: res.data.user.attributes.lastName || '',
          email: res.data.user.attributes.email || '',
          phoneNumber: res.data.user.attributes['phone_number'] || '',
        };
        dispatch(getUserSuccess(userProfile));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to get user';
        dispatch(getUserFail(errMsg));
      });
  };
};

export const createUserStart = () => {
  return {
    type: actionTypes.CREATE_USER_START,
  };
};

export const createUserSuccess = users => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    users,
  };
};

export const createUserFail = err => {
  return {
    type: actionTypes.CREATE_USER_FAIL,
    err,
  };
};

export const createUser = values => {
  return (dispatch, getState) => {
    dispatch(createUserStart());
    const url = `${REACT_APP_API_URL}/user`;

    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { Authorization: getState().auth.idToken },
    })
      .then(() => {
        dispatch(createUserSuccess());
        if (values.role === 'responder') {
          dispatch(listResponders());
        }
        dispatch(closeModal());
        const activeOrgId = getState().navigation.org?.orgId; // maybe safer to use values.orgId ?
        dispatch(listUsers(activeOrgId))
        dispatch(openToaster(`${values.firstName} ${values.lastName} was successfully added!`));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Error creating user';
        dispatch(createUserFail(errMsg));
      });
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START,
  };
};

export const updateUserSuccess = values => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    userProfile: values,
  };
};

export const updateUserFail = err => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    err,
  };
};

export const updateUser = (userId, values, isUserProfile = false) => {
  return (dispatch, getState) => {
    dispatch(updateUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;

    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { Authorization: getState().auth.idToken },
    })
      .then(() => {
        dispatch(updateUserSuccess());
        if (values.role === 'responder') {
          dispatch(listResponders());
        } 
        dispatch(closeModal());
        if (isUserProfile) {
          dispatch(getUserSuccess(values));
          dispatch(openToaster('Your profile was successfully changed.'));
        } else {
          const activeOrgId = getState().navigation.org?.orgId
          dispatch(openToaster(`${values.firstName} ${values.lastName} was successfully updated.`));
          dispatch(listUsers(activeOrgId));
        }
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to update user';
        dispatch(updateUserFail(errMsg));
      });
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START,
  };
};

export const deleteUserSuccess = users => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    users,
  };
};

export const deleteUserFail = err => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    err,
  };
};

export const deleteUser = (userId, currentOrg,role = null) => {
  return (dispatch, getState) => {
    dispatch(deleteUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(() => {
        dispatch(deleteUserSuccess());
        if (role === 'responder') {
          dispatch(listResponders());
        } 
        else {
          dispatch(listUsers(currentOrg));
        }

        dispatch(closeModal());
        dispatch(openToaster('User was successfully deleted.'));
      })
      .catch(err => {
        dispatch(deleteUserFail(err));
      });
  };
};

export const clearUsersList = () => {
  return {
    type: actionTypes.CLEAR_USERS_LIST,
  };
};

export const clearUserSurveysState = () => {
  return {
    type: actionTypes.CLEAR_USER_SURVEYS_LIST,
  };
};

export const clearUserErrors = () => {
  return {
    type: actionTypes.CLEAR_USER_ERRORS,
  };
};
