import * as Sentry from '@sentry/browser';

let didInit = false;
export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE
  });

  didInit = true;
};


export const logError = (err) => {
  if (!didInit) throw new Error('Sentry must init before capturing errors');
  Sentry.captureException(err);
};

