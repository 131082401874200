import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import * as actions from '../../../store/actions/survey/survey';
import RadioGroup from '../../../components/RadioGroup/RadioGroup';
import * as QuizButtonGroup from '../QuizButtonGroup/QuizButtonGroup';
import TMButton from '../../../components/TMButton/TMButton';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';
import { getContent, getAudio } from '../../../utilities/getContent/getContent'
import DateInput from '../../../components/DateInput/DateInput';
import NumberInput from '../../../components/NumberInput/NumberInput';
class DemographicQuestions extends Component {
  constructor(props) {
    super(props);
    this.questionRef = React.createRef();
  }

  demographicQuestionSubmitHandler = demographicData => {
    this.props.demographicAnswerSelected(demographicData[this.props.demographicQuestionIndex].questionText);
    if (this.props.demographicQuestionIndex + 1 === demographicData.length) {
      // redirect to reg survey
      this.props.history.push('/survey/questions');
    } else {
      //hide question to animate in
      const opacityClass = 'opacity-hidden';
      let curEl = this.questionRef.current;
      curEl.classList.add(opacityClass);
      // increment demographicQuestionIndex
      this.props.incrementDemographicQuestionIndex();
      //delay removing the class/animating in new question
      setTimeout(() => {
        curEl.classList.remove(opacityClass);
      }, 50);
    }
  };

  render() {
    const displayAudioButtons = this.props.receivedSurveyData.createdAt > 1569844768000 ? true : false;
    let questionComponent = (
      <div className="answerContainer">
        <RadioGroup
          options={this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].responseOptions}
          radioGroupHandler={(index, value) => this.props.toggleDemographicRadioButton(index, value)}
          selectedIndex={this.props.selectedRadioGroupIndex}
          onKeyDown={e => {
            if (e.keyCode === 13 && this.props.demographicQuestionIsSelected) {
              this.demographicQuestionSubmitHandler(this.props.receivedSurveyData.demographicQuestions);
            }
          }}
          showAudioButton={displayAudioButtons}
        />
      </div>
    );

    if (this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionResponseOptionsType === 'demographic-school-activities-yes-or-no') {
      questionComponent = (
        <QuizButtonGroup.Fat
          answers={[
            { field: 'response_yes', value: 1, audio: 'response_yes' },
            { field: 'response_no', value: 2, audio: 'response_no' },
          ]}
          isSelected={this.props.selectedRadioGroupIndex}
          answerSelected={(index, value) => this.props.toggleDemographicRadioButton(index, value)}
          showAudioButton={displayAudioButtons}
        />
      );
    }

    if (this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionResponseOptionsType === 'profession-work-or-personal') {
      questionComponent = (
        <QuizButtonGroup.Skinny
          answers={[
            { field: 'response_work', value: 'response_work' },
            { field: 'response_personal', value: 'response_personal' },
            { field: 'response_school', value: 'response_school' },
            {field: 'response_not-under-distress', value: 'response_not-under-distress'},
          ]}
          isSelected={this.props.selectedRadioGroupIndex}
          answerSelected={(index, value) => this.props.toggleDemographicRadioButton(index, value)}
          showAudioButton={displayAudioButtons}
        />
      );
    }

    if (this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionResponseOptionsType === 'profession-hire-date') {
      questionComponent = (
        <DateInput answerSelected={(index,value)=>this.props.toggleDemographicRadioButton(index,value)}/>
      );
    }
    
    if (this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionResponseOptionsType === 'profession-years-in-profession') {
      questionComponent = (
        <NumberInput answerSelected={(index, value) => this.props.toggleDemographicRadioButton(index, value)}/>
      );
    }


    return (
      <div className="container-demo-questions" ref={this.questionRef}>
        <div className="heading">
          <p className="subhead">{getContent('survey_subhead-please-answer-the-following')}</p>
          <p className="head">{getContent(this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionText)}</p>
          {displayAudioButtons 
          ? <AudioButton
            className="my-3"
            label={getContent('survey_button-read-question')}
            playlist={[
              getAudio(this.props.receivedSurveyData.demographicQuestions[this.props.demographicQuestionIndex].questionText),
            ]}/> 
          : '' }
        </div>
        {questionComponent}
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={() => {
            if (this.props.demographicQuestionIsSelected) {
              this.demographicQuestionSubmitHandler(this.props.receivedSurveyData.demographicQuestions);
            }
          }}
        />
        <TMButton
          className="btn-lg btn-round btn-green w-300"
          onClick={() => this.demographicQuestionSubmitHandler(this.props.receivedSurveyData.demographicQuestions)}
          disabled={!this.props.demographicQuestionIsSelected}
          iconRight={ArrowRight}
          locatorId='locator_next-button'
        >
          {getContent('survey_button-next-question')}
        </TMButton>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    survey: state.survey,
    surveyDataToSend: state.survey.surveyDataToSend,
    demographicQuestionIsSelected: state.survey.demographicQuestionIsSelected,
    receivedSurveyData: state.survey.receivedSurveyData,
    demographicQuestionIndex: state.survey.demographicQuestionIndex,
    selectedRadioGroupIndex: state.survey.selectedRadioGroupIndex,
    selectedRadioGroupValue: state.survey.selectedRadioGroupValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    demographicAnswerSelected: questionText => dispatch(actions.demographicAnswerSelected(questionText)),
    incrementDemographicQuestionIndex: () => dispatch(actions.incrementDemographicQuestionIndex()),
    toggleDemographicRadioButton: (index, value) => dispatch(actions.toggleDemographicRadioButton(index, value)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DemographicQuestions),
);
