import React from 'react';
import { connect } from 'react-redux';
import EditParticipantForm from './EditParticipantForm';
import * as actions from '../../../../../store/actions';
import Overlay from '../../../../../components/Overlay/overlay';

const EditParticipantModal = (props) => {
  
  const handleSubmit = (
    contacted,
    status,
    parentEmail,
    parentMobile,
    id,
    groupName,
    participantEmail,
    notes,
    referredTo,
    consentGranted
  ) => {
    const values = {
      surveyParticipantId: id,
      contacted,
      participantStatus: status,
      parentEmail: parentEmail,
      parentMobile: parentMobile,
      groupName,
      participantEmail,
      notes,
      referredTo,
      consentGranted
    };
    props.updateParticipant(values, props.item );
  }

  return (
    <Overlay
      isOpen={props.show}
      onToggle={props.closeModal}
      title={"Edit Participant"}
      variant='medium'
    >
      <EditParticipantForm
        onSubmit={handleSubmit}
        // initialValues={{
        //   status: props.item.participantStatus
        // }}
        item={props.item}
        isEmployerAdmin={props.isEmployerAdmin}
        // status={props.editParticipantStatus}
        // contacted={props.editParticipantContacted}
        // toggleContacted={(value) => props.toggleParticipantContacted(value)}
        // setParticipantValues={(values) => props.setParticipantValues(values)}
        // updateParticipantStarted={(value) => props.updateParticipantStarted(value)}
        closeModal={props.closeModal}
        surveyStatus={props.surveyStatus}
        surveyName={props.surveyName}
      />
    </Overlay>
  );
}

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateParticipant: (participantData, previousParticipant) => dispatch(actions.updateParticipant(participantData, previousParticipant)),
    // updateParticipantStarted: (value) => dispatch(actions.updateParticipantStarted(value)),
    // setParticipantValues: (participantData) => dispatch(actions.setParticipantValues(participantData)),
    // toggleParticipantContacted: (value) => dispatch(actions.toggleParticipantContacted(value)),
    closeModal: () => dispatch(actions.closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditParticipantModal);
