import React from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';


const NotConcerningQuestionsPage = ({ payload }) => {
  const replacements = [
    { match: '{payload\\.participant\\.firstName}', replacement: payload.participant.firstName },
    { match: '{payload\\.participant\\.lastName}', replacement: payload.participant.lastName },
  ];

  const getSurveyType = () => {
    const appliedTo = payload.appliedTo || null;
    // const surveyName = this.props.payload.surveyName || null;
    switch (appliedTo) {
      case "adult":
        return 'adult';
      case "upper_grades":
        return 'uppergrades';
      case "elementary":
        return 'elementary';
      default:
        return null;
    }
  }

  const indicatorsForAllResponses = payload.surveyResponses[0].indicators;
  const questionsOfConcernResponsesIndicators = payload.indicators.filter(item => item.responses && item.responses.length > 0);
  const questionsOfConcernSmIds = questionsOfConcernResponsesIndicators
    .map(indicator =>
      indicator.responses.map(response => response.questionSmId)
    )
    .reduce((acc, val) => [...acc, ...val], []);

  const indicatorsForNotConcerningResponses = [];

  for (const indicator of indicatorsForAllResponses) {
    const newIndicator = { ...indicator };
    newIndicator.responses = [];

    for (const response of indicator.responses)
      if (questionsOfConcernSmIds.findIndex(smId => smId === response.questionSmId) === -1)
        newIndicator.responses.push(response);

    indicatorsForNotConcerningResponses.push(newIndicator);
  }

  const surveyType = getSurveyType();

  if (payload.selfHarmResponse === 0
    || indicatorsForAllResponses.length
    ||
    (payload.surveyResultIsValid && payload.validityQuestions)) {
    return (
      <div className="page questions-of-concern">
        <main>
          <h1 className="mb-2 mt-2 text-uppercase">{getContent('report_not-questions-of-concern')}</h1>
          <p className="mb-5">
            {getContent('report_not-questions-of-concern-intro', replacements)}
          </p>
          {/* Validity Questions */}

          {!payload.surveyResultIsValid && payload.validityQuestions ? (
            <div className="concerns-container">
              <h2 className="mb-4">{getContent('report_validity-questions')}</h2>
              <table className="table table-sm table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{getContent('report_question')}</th>
                    <th className="text-center">{getContent('report_answer')}</th>
                  </tr>
                  {payload.validityQuestions.map((item, index) => {
                    return (
                      <tr key={getContent(item.questionText, null, surveyType)}>
                        <td width="30" className="text-center">
                          {index + 1}.
                        </td>
                        <td>{getContent(item.questionText)}</td>
                        <td className="text-center">{getContent(item.responseOptionField, null, surveyType)}</td>
                      </tr>
                    );
                  })}
                </thead>
              </table>
            </div>
          ) : null}

          {indicatorsForNotConcerningResponses && indicatorsForNotConcerningResponses.map((concern, ci) => {
            return (
              <div className="concerns-container" key={ci}>
                <h2 className="mb-4">{getContent(concern.contentId || concern.indicator)}</h2>
                <table className="table table-sm table-bordered results mb-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{getContent('report_question')}</th>
                      <th className="text-center">{getContent('report_answer')}</th>
                    </tr>
                    {concern.responses.map((qa, ni) => {
                      return (
                        <tr key={ni}>
                          <td width="30" className="text-center">
                            {ni + 1}.
                          </td>
                          <td>{getContent(qa.questionText, null, surveyType)}</td>
                          <td className="text-center">{getContent(qa.responseOptionField, null, surveyType)}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>

                {/* end answers */}
              </div>
              // end concerns-container
            );
          })}
          {/* end at risk answers */}
        </main>
      </div>
    );
  } else {
    return '';
  }
}

export default NotConcerningQuestionsPage;
