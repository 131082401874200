import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../../../../store/actions';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';

const OrderRandomizationStep = (props) => {
  const { fixedOrder, appliedTo} = useSelector(state => state.surveyCrud.createSurveyObj)
  
  const toggleFixedOrder = () => {
    props.setFixedOrder(!fixedOrder);
  };

  const orderRandomizationText = (appliedTo === 'k3' ? "Option is disabled (Order is always fixed for grades K-3.)": "By default, the order of indicators is randomized for each student. Check the box to disable this.");

  return (
    <div className="d-flex flex-row">
      <CheckBox
        label={`Do Not Randomize Indicators`}
        name={'fixedOrderCheck'}
        isChecked={fixedOrder || appliedTo === 'k3'}
        onClick={toggleFixedOrder}
        disabled={appliedTo === 'k3'}
        description={orderRandomizationText}
        classNameLabel={"fw-bold"}
      />
      
    </div>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    setFixedOrder: (value) => dispatch(actions.setFixedOrder(value))
  };
};
export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(OrderRandomizationStep)
);
