import axios from 'axios';
import { listOrganizations, listOrganizationsReset } from '../organizations/organizations';
const { REACT_APP_API_URL } = process.env;

const updateSomething = value => {
  return {
    type: 'SOMETHING_UPDATE',
    value: value,
  };
};

/* Org Change */
const orgChangeStart = () => {
  return {
    type: 'ORG_CHANGE_START',
  };
};

const orgChangeSuccess = (orgItem) => {
  return {
    type: 'ORG_CHANGE_SUCCESS',
    value: orgItem,
  };
};
const orgChange = (orgId, callback) => {
  return async (dispatch, getState) => {
    dispatch(orgChangeStart());
    if (orgId === null) {
      dispatch(orgChangeSuccess(null));
      dispatch(listOrganizationsReset());
    } else {
      const url = `${REACT_APP_API_URL}/org?orgId=${orgId}`;
      const response = await axios({
        url,
        method: 'GET',
        headers: { Authorization: getState().auth.idToken },
      });
      dispatch(orgChangeSuccess(response.data));
      if (callback) {
        callback()
      }
    }
  };
};

/* schoolsList */
const schoolsListGetStart = () => {
  return {
    type: 'SCHOOLS_LIST_GET_START',
  };
};

const schoolsListGetSuccess = schoolsList => {
  return {
    type: 'SCHOOLS_LIST_GET_SUCCESS',
    value: schoolsList,
  };
};

const schoolSelectedSet = value => {
  return {
    type: 'SCHOOL_SELECTED_SET',
    value: value,
  };
};

const schoolSelect = value => {
  return async (dispatch, getState) => {
    dispatch(schoolSelectedSet(value));
    let newOrgId;
    if (value !== null) {
      // school is selected
      newOrgId = value.value;
    } else {
      // goes back to district
      newOrgId = getState().navigation.org.parentOrg.orgId;
    }
    dispatch(orgChange(newOrgId));
  };
};

// districtIdInput is not used, only for future change
const schoolsListGet = () => {
  return async dispatch => {
    dispatch(schoolsListGetStart());
    dispatch(listOrganizations());
    //   let districtId = districtIdInput;
    //   if (!districtId) {
    //     const user = getState().auth.user;
    //     if (user['custom:role'] === 'district_champion') {
    //       districtId = user['custom:orgId'];
    //     } else if (user['custom:role'] === 'tm_admin') {
    //       districtId = getState().navigation.districtSelected?.value
    //     }
    //   }
    //   console.log(`schoolsListGet districtId = ${districtId}`)
    //   if (districtId) {
    //     const url = `${REACT_APP_API_URL}/org/search?classification=school&parentOrgId=${districtId}`;
    //     const response = await axios({
    //       url,
    //       method: 'GET',
    //       headers: { Authorization: getState().auth.idToken },
    //     })
    //     const schoolsList = response.data.map(school => { return { value: school.orgId, label: school.name} })
    //     dispatch(schoolsListGetSuccess(schoolsList))
    //   }
  };
};

/* DistrictsList */
const districtsListGetStart = () => {
  return {
    type: 'DISTRICTS_LIST_GET_START',
  };
};

const districtsListGetSuccess = districtsList => {
  return {
    type: 'DISTRICTS_LIST_GET_SUCCESS',
    value: districtsList,
  };
};

const districtSelectedSet = value => {
  return {
    type: 'DISTRICT_SELECTED_SET',
    value: value,
  };
};

const districtSelect = (value, callback) => {
  return async dispatch => {
    dispatch(districtSelectedSet(value));
    let newOrgId;
    if (value !== null) {
      // district is selected
      newOrgId = value.value;
    } else {
      // goes back to tm_admin
      newOrgId = null;
    }
    dispatch(orgChange(newOrgId, callback));
  };
};

const districtsListGet = () => {
  return async dispatch => {
    dispatch(districtsListGetStart());
    dispatch(listOrganizations());
    // const url = `${REACT_APP_API_URL}/org/search?classification=school-district`;
    // const response = await axios({
    //   url,
    //   method: 'GET',
    //   headers: { Authorization: getState().auth.idToken },
    // })
    // const districtsList = response.data.map(district => { return { value: district.orgId, label: district.name} })
    // dispatch(districtsListGetSuccess(districtsList))
  };
};

// const navigateToDistrictAndSchool = ({ districtId, schoolId }) => {
//   return async (dispatch, getState) => {
//     const { role, orgId } = getState().auth;
//     if (role === 'school_champion' && orgId === schoolId) return; // user the school champ, do nothing
//     if (role === 'district_champion' && orgId === districtId) { // user is the distirict champ, select school
//       dispatch(schoolSelect({ value: schoolId }));
//     } else if (role === 'tm_admin') {
//       console.log('here!');
//       dispatch(districtSelect({ value: districtId }));
//       // dispatch(schoolSelect({ value: schoolId }));
//     }
//   };
// };

export { updateSomething, schoolsListGet, schoolSelectedSet, schoolsListGetSuccess, schoolSelect, districtsListGet, districtsListGetSuccess, districtSelect, orgChange };
