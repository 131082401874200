import { faCircleInfo, faFile, faPause, faPlay, faQuestion, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Container, Row } from 'reactstrap';

const ProctorPlayer = () => {
  const { list } = useParams();
  const indicatorIds = ['Intro', ...list.split(',')];
  const s3base = `${process.env.REACT_APP_MEDIA_WEB_URL}/${process.env.REACT_APP_MEDIA_S3_PREFIX}/proctor/`;
  const player = {
    play: id => {
      player.setState(prevState => {
        return {
          ...prevState,
          playing: true,
          id: id,
        };
      });
    },
    next: () => {
      const index = indicatorIds.indexOf(player.state.id);
      if (index < indicatorIds.length - 1) {
        player.setState(prevState => {
          return {
            ...prevState,
            playing: true,
            id: indicatorIds[index + 1],
          };
        });
      }
    },
    toggle: () => {
      player.setState(prevState => {
        return {
          ...prevState,
          playing: !prevState.playing,
        };
      });
    },
    ref: rp => (player.player = rp),
  };
  [player.state, player.setState] = useState({
    id: 'Intro',
    // url: `${s3base}${indicatorIds[0]}.mp4`,
    playing: false,
  });
  //   document.onkeydown = function(evt) {
  //     evt = evt || window.event;
  //     if (evt.code === "Space" ) {
  //       player.toggle();
  //     }
  // };
  return (
    <>
      <Container className="mb-3">
        <Row>
          <Col>
            <h1>Proctoring</h1>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <h2>Playlist</h2>
            <p>
              <FontAwesomeIcon className="me-1" icon={faCircleInfo} />
              The video player will play through this list automatically but you may skip to a particular item by clicking it.
            </p>
            <p>
              <a className="me-3" title="Proctor Video instructions (new window)" target="_blank" href="https://s3.amazonaws.com/org.terracemetrics.dev/static/media/proctor/proctor-instructions.mp4" rel="noreferrer">
                <FontAwesomeIcon className="me-1" icon={faQuestion} />
                Proctor Instructions
              </a>
              <a href="https://s3.amazonaws.com/org.terracemetrics.dev/static/media/proctor/k-2+script.pdf" rel="noreferrer" target='_blank'>
                <FontAwesomeIcon className="me-1" icon={faFile} />
                Script
              </a><br/>
              <a title="Download Full video" href="https://s3.amazonaws.com/org.terracemetrics.dev/static/media/proctor/Full+Master+Video.mp4" download={"Full Master Video"}>
                <FontAwesomeIcon className="me-1" icon={faVideo} />
                Download Full Video
              </a>
            </p>
            <Button className="my-3" color="primary" size="lg" block={true} onClick={player.toggle}>
              {player.state.playing ? (
                <>
                  <FontAwesomeIcon className="me-1" icon={faPause} /> Pause
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="me-1" icon={faPlay} /> Play
                </>
              )}
            </Button>
            <ul className="list-group mb-3">
              {indicatorIds.map(indicatorId => {
                return (
                  <li style={{ cursor: 'pointer' }} className={`list-group-item ${player.state.id === indicatorId ? 'active' : ''}`} onClick={() => player.play(indicatorId)} key={indicatorId}>
                    {indicatorId}
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col md="9">
            <ReactPlayer
              ref={player.ref}
              onEnded={player.next}
              height=""
              width="100%"
              style={{ aspectRatio: '16/9' }}
              url={`${s3base}${player.state.id}.mp4`}
              playing={player.state.playing}
              controls
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProctorPlayer;
