import React, { useRef, useState, useEffect } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { getContent } from '../../../../../utilities/getContent/getContent';
import { isValidPhone } from '../../../../../utilities/validation';
// import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';
import { dateFormat, adjustDate } from '../../../../../utilities/dates';
import Spinner from '../../../../../components/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const EditParticipantForm = (props) => {
  const { item, surveyName, surveyStatus, isEmployerAdmin, onSubmit, closeModal } = props;
  const consentSelectOptions = [{ value: true, label: "Consent Given" }, { value: false, label: "Consent Denied" }];
  if (item.consentGranted === undefined) consentSelectOptions.push({ value: undefined, label: "No Response" })

  const [status, setStatus] = useState(item.participantStatus);
  const [contacted, setContacted] = useState(item.contacted);
  const [parentMobile, setParentMobile] = useState(item.parentMobile);
  const [isLoading, setIsLoading] = useState(false);
  const [isDomReady, setIsDomReady] = useState(false);
  const [consent, setConsent] = useState(item.consentGranted)
  // Uncontrolled inputs
  const parentEmailRef = useRef(null);
  const groupNameRef = useRef(null);
  const participantEmailRef = useRef(null);
  const notesRef = useRef(null);
  const referredToRef = useRef(null);
  const isUK = () => process.env.REACT_APP_STAGE === 'uk';

  const getGenderLabelFromOption = () => {
    if (item.gender) {
      if (item.gender === 'm') {
        return 'Male';
      } else if (item.gender === 'f') {
        return 'Female';
      } else if (item.gender === 'no_response') {
        return isUK() ? 'Preferred not to answer' : 'N/A';
      } else {
        return item.gender;
      }
    }
    return '-';
  };

  const handleUpdateUserSubmit = () => {
    setIsLoading(true);

    const parentEmailValue = parentEmailRef.current ? parentEmailRef.current.value : '';
    const groupNameValue = groupNameRef.current ? groupNameRef.current.value : '';
    const participantEmailValue = participantEmailRef.current ? participantEmailRef.current.value : '';
    const notesValue = notesRef.current ? notesRef.current.value : '';
    const referredToValue = referredToRef.current ? referredToRef.current.value : '';

    onSubmit(
      contacted,
      status,
      parentEmailValue,
      parentMobile,
      item.surveyParticipantId,
      groupNameValue,
      participantEmailValue,
      notesValue,
      referredToValue,
      consent
    );
  };
  useEffect(() => {
    setIsDomReady(true);
  }, []);
  const statusChangeWarning = surveyStatus === "Completed" && item.participantStatus !== 'Completed';

  return (
    <div className='edit-participant-form'>
      <div className="modal-header-edit-participant flex-row">
        <div>
          <label className="label" htmlFor="username">Participant Name</label>
          <p>{`${item.firstName} ${item.lastName}`}</p>
        </div>
        {!surveyName.includes('Adult') && (
          <div>
            <label className="label" htmlFor="username">{getContent('admin_participant-entry-grade')}</label>
            <p>{item.grade}</p>
          </div>
        )}
        <div>
          <label className="label" htmlFor="username">Date of Birth</label>
          <p>{adjustDate(item.birthDate).format(dateFormat)}</p>
        </div>
        <div>
          <label className="label" htmlFor="username">{surveyName.includes('Adult') ? 'Participant ID' : 'Student ID'}</label>
          <p>{item.studentId ? item.studentId : '-'}</p>
        </div>
        {!isEmployerAdmin && (
          <>
            <div>
              <label className="label" htmlFor="username">Priority</label>
              <p>{item.priority || item.priority === 0 ? item.priority : '-'}</p>
            </div>
            <div>
              <label className="label" htmlFor="username">Wellness Code</label>
              <p>{item.parentSurveyAuthCode ? (item.parentSurveyAuthCode.length === 16 ? item.parentSurveyAuthCode.match(/.{1,4}/g).join('-') : item.parentSurveyAuthCode) : '-'}</p>
            </div>
          </>
        )}
      </div>
      <div className="edit-participant-form__fields ">
        {(!surveyName.includes('Adult') || item.parentEmail) && (
          <div className="label-field-container">
            <label className="label" htmlFor="parentEmail">Notification Email</label>
            <input
              name="parentEmail"
              defaultValue={item.parentEmail || ''}
              placeholder="Notification Email (optional)"
              type="text"
              className="participant-entry-field"
              ref={parentEmailRef}
            />
          </div>
        )}
        <div className="label-field-container">
          <label className="label" htmlFor="parentMobile">Notification Mobile</label>
          <PhoneInput
            name="parentMobile"
            defaultCountry={'us'}
            placeholder="(Optional) Include Country Code"
            type="tel"
            value={parentMobile}
            onChange={ setParentMobile } 
            component="input"
            validate={[isValidPhone]}
            className="participant-entry-field"
          />
        </div>
        <div className="label-field-container">
          <label className="label" htmlFor="participantEmail">Participant Email</label>
          <input
            name="participantEmail"
            defaultValue={item.participantEmail || ''}
            placeholder="Participant Email"
            type="text"
            className="participant-entry-field"
            ref={participantEmailRef}
          />
        </div>
      </div>
      <label className="label" htmlFor="status">
        Status{statusChangeWarning && (<span className='error' style={{ display: "inline" }}>*</span>)}
        <FontAwesomeIcon icon={faInfoCircle} id='statusnote' className='info' />
      </label>
      <DropdownList
        data={['Not Started', 'Not Completed']}
        className="tm-dropdown"
        onChange={(value) => setStatus(value)}
        value={status}
        disabled={item.participantStatus === 'Completed'}
      />
      {statusChangeWarning && (
        <p className='error'><strong>* Editing a participant status will reopen the assessment.</strong></p>
      )}
      {isDomReady && <UncontrolledTooltip target={"statusnote"} placement='right' trigger="click">
        Typically a participant’s status should only be manually updated in
        case of illness, inability to complete survey, etc.
      </UncontrolledTooltip>}
      <div>
        <label className="label" htmlFor="gender">Consent Response</label>
        <div>
          <DropdownList
            data={consentSelectOptions}
            className="tm-dropdown"
            onChange={o => { setConsent(o.value); }}
            // defaultValue={consentSelectOptions.find( o => o.value === item.consentGranted)}
            value={consentSelectOptions.find( o => o.value === consent)}
            dataKey='value'
            textField='label'
            disabled={item.participantStatus === 'Completed'}
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="label" htmlFor="gender">Gender</label>
        <p>{getGenderLabelFromOption()}</p>
      </div>
      <div className="participant-row">
        <div className="label-field-container">
          <label className="label">{isUK() ? 'Grouping' : 'Group'} Name</label>
          <input
            className="participant-entry-field"
            defaultValue={item.groupName || ''}
            ref={groupNameRef}
          />
        </div>
      </div>

      {!isEmployerAdmin && (
        <div>
          <div className="label-field-container" style={{ flexGrow: 1 }}>
            <p className="label">
              Results
              <FontAwesomeIcon icon={faInfoCircle} id='participantresult' className='info' />
            </p>
            <label className="student-results-label-edit-participant">
              <input
                name="contacted"
                type="checkbox"
                onChange={() => setContacted(!contacted)}
                checked={contacted}
                disabled={item.participantStatus !== 'Completed'}
              />
              Participant has been contacted and result validated
            </label>
            {isDomReady && <UncontrolledTooltip target={"participantresult"} placement="right" trigger="click">
              "Participant has been contacted and result validated” is typically used to denote a member
              of the response team has contacted a participant flagged with a possible priority concern.
              This will only unlock after a survey is complete.
            </UncontrolledTooltip>}
          </div>
          <div className="label-field-container" style={{ flexGrow: 5 }}>
            <label className="label">Referred To</label>
            <input
              className="participant-entry-field"
              defaultValue={item.referredTo || ''}
              ref={referredToRef}
              disabled={item.participantStatus !== 'Completed'}
              placeholder="Name of person student was referred to"
            />
          </div>
        </div>
      )}
      {!isEmployerAdmin && (
        <div>
          <label className="label">Notes</label>
          <textarea
            defaultValue={item.notes || ''}
            ref={notesRef}
            placeholder="Leave notes for other users about this survey participant..."
            style={{ "width": "100%" }}
          />
        </div>
      )}
      <div className="btnContainer-edit-participant">
        <Button
          onClick={handleUpdateUserSubmit}
          className='small-button'
          type="submit"
        >
          {isLoading && <Spinner />} Save Changes
        </Button>
        <Button
          onClick={closeModal}
          className='small-blue-button-outlined'
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default EditParticipantForm;
