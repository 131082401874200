import React from 'react';
import { connect } from 'react-redux';
// import { ModalHeader, ModalBody } from 'reactstrap';
// import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
// import { ReactComponent as IconAvatar } from '../../../../assets/imgs/icon-avatar.svg';
import AddUserForm from './AddUserForm';
import sanitizePhoneNumber from '../../../../utilities/sanitizePhoneNumber';
import displayPhoneNumber from '../../../../utilities/displayPhoneNumber';
import Overlay from '../../../../components/Overlay/overlay';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as actions from '../../../../store/actions';


// this modal is used to add or update users
// if props.user is provided, it's an update, otherwise it's an add
// either from the user list 
// or from the profile (self editing)

const AddUserModal = (props) => {

  const handleAddNewUser = async (values) => {
    let isDesignatedValue = undefined;

    if (values.isDesignated !== undefined) {
      if (values.isDesignated === true) {
        isDesignatedValue = "1";
      }
      else if (values.isDesignated === false) {
        isDesignatedValue = "0";
      }
    }

    // get basic data fromm the form 
    let user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
      isDesignated: isDesignatedValue,
    };

    // adding new user
    if (props.newUser) {
      props.createUser( { ...user, ...props.newUser} );
    } else {
      if (props.isUserProfile && values.password && values.password.length > 0) {
        user.password = values.password;
      }  
      props.updateUser(props.user.username, user, props.isUserProfile);
    }

  };

  const initialValues = {};
  if (props.user) {
    initialValues.firstName = props.user.firstName;
    initialValues.lastName = props.user.lastName;
    initialValues.email = props.user.email;
    if (props.user.phoneNumber) {
      initialValues.phoneNumber = displayPhoneNumber(props.user.phoneNumber);
    }
    if (props.user.role === 'district_champion' && props.user.isDesignated) {
      initialValues.isDesignated = props.user.isDesignated === "1" ? true : false;
    }
    if (props.user.isActivated) {
      initialValues.isActivated = props.user.isActivated;
    }
  }

  let createButtonText =  'Save';
  let titleText = props.user ? 'Edit User' : 'Add New User';
  let labelText = 'User';



  // When Editing your own profile
  if (props.isUserProfile) {
    titleText = 'Edit your profile';
    createButtonText = 'Save Edits';
    labelText = 'User';
  }

  return (
    <Overlay
      isOpen={props.show}
      variant='small'
      title={
        <>
          <FontAwesomeIcon icon={faPerson} color='#377b9d' style={{ marginRight: '1rem' }} />
          <span>{titleText}</span>
        </>
      }
      onToggle={props.closeModal}
    >
      <div>
        {typeof props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{props.error}</span> : null}
      </div>
      <AddUserForm
        onSubmit={handleAddNewUser}
        initialValues={initialValues}
        labelText={labelText}
        organization={props.organization}
        saveButtonText={props.user ? 'Save Edits' : createButtonText}
        closeModal={props.closeModal}
        isUserProfile={props.isUserProfile}
        item={props.user}
        showDesignatedToggle={props.role === 'district_champion' && !props.isUserProfile ? true : false}
        isCleverOrg={false}
        idToken={props.idToken}
        isEditMode={!!props.user}
      />
    </Overlay>
  );
}

const mapStateToProps = state => {
  return {
    activeOrg: state.navigation.org,
    role: state.auth.role,
    organization: state.organizations.organization,
    error: state.users.error,
    idToken: state.auth.idToken,
    realRole: state.auth.realRole,
    schoolSearchName: state.organizations.schoolSearchName,
    districtSearchName: state.organizations.districtSearchName,
    parentOrgId: state.organizations.districtSearchName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listUsers: (orgId) => dispatch(actions.listUsers(orgId)),
    createUser: userProps => dispatch(actions.createUser(userProps)),
    updateUser: (userId, values) => dispatch(actions.updateUser(userId, values)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(AddUserModal);
