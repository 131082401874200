import React, { Component } from 'react';
import { dateFormat } from '../../../../utilities/dates';
import dayjs from 'dayjs';
import ScoreBlock from './ScoreBlock';
import { withRouter } from 'react-router-dom';
import scoreMap from '../scoreMap';
import { getContent } from '../../../../utilities/getContent/getContent';
import * as actions from '../../../../store/actions';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import CheckBox from '../../../../components/CheckBox/CheckBox';

const isUK = process.env.REACT_APP_STAGE === 'uk';

class TFSReport extends Component {
  handleIsShowingNotConcerningResponses = () => {
    this.props.toggleShowNotConcerningResponses();
  };

  getTimeDiff(start, stop) {
    start = new Date(start);
    stop = new Date(stop);
    let seconds = Math.floor((stop - start) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return { hours, minutes, seconds };
  }

  openConfirmationModal = confirm => {
    this.props.openModal({
      modalType: 'RETAKE_CONFIRMATION_MODAL',
      show: true,
      closeModal: this.props.closeModal(),
      confirm,
    });
  };
  /**
   * TimeSpan
   * Get h m s from two dates
   * @param {Date} start
   * @param {Date} stop
   */
  timeSpan(start, stop) {
    let timeDiff = this.getTimeDiff(start, stop);
    if (timeDiff.hours) {
      return `${timeDiff.hours}h ${timeDiff.minutes}m ${timeDiff.seconds}s`;
    } else {
      return `${timeDiff.minutes}m ${timeDiff.seconds}s`;
    }
  }

  /**
   * getPreviousScore is used to look at the previous
   * survey and return the previous risk level if possible
   * returns riskLevel or null
   * @param {Object} previousSurvey
   * @param {String} indicatorName
   */
  getPreviousScore(previousSurvey, indicatorName) {
    if (!previousSurvey || !previousSurvey.indicators || !indicatorName) {
      return null;
    }
    const { indicators } = previousSurvey;
    const foundPreviousIndicator = indicators.find(i => i.indicator === indicatorName);
    if (!foundPreviousIndicator || !foundPreviousIndicator.riskLevel) {
      return null;
    }
    return foundPreviousIndicator.riskLevel;
  }

  getIndicatorRiskLevel(indicators, indicatorName) {
    if (!indicators || !indicatorName) {
      return null;
    }
    const matchedIndicator = indicators.find(indicator => indicator.indicator === indicatorName);
    return matchedIndicator && matchedIndicator.riskLevel ? matchedIndicator.riskLevel : null;
  }

  render() {
    const payload = this.props.payload;
    const isAdult = payload.appliedTo && payload.appliedTo === 'adult';
    // const isAdult = payload.surveyName && payload.surveyName.includes('Adult');
    const tooFast = this.props.showTimeBox && payload && payload.createdAt && payload.isAllCore ? this.getTimeDiff(payload.surveyStartedAt, payload.surveySubmittedAt).minutes <= 3 : false;
    // const supplementalIndicators = payload.indicators.filter(indicator => indicator.category === 'supplemental');
    const indicatorsByType = {};
    const isParentReport = this.props.isParentReport;
    const replacements = [
      {
        match: '{LEARNMORE_URL}',
        replacement: isUK ? 'https://www.mymynd.co.uk/parents' : 'http://www.terracemetrics.org/parents',
      },
      {
        match: '{LEARNMORE_PASSWORD}',
        replacement: 'TMparents',
      },
      {
        match: '{LEARNMORE_URL_ADULT}',
        replacement: isUK ? 'https://www.mymynd.co.uk/adults' : 'http://www.terracemetrics.org/adults',
      },
      {
        match: '{LEARNMORE_PASSWORD_ADULT}',
        replacement: 'TMadults',
      },
      { match: '{payload\\.participant\\.firstName}', replacement: payload.participant.firstName },
    ];
    payload.indicators
      .filter(indicator => indicator.category !== 'supplemental')
      .forEach(indicator => {
        if (!indicatorsByType[indicator.type]) {
          indicatorsByType[indicator.type] = [];
        }
        indicatorsByType[indicator.type].push(indicator);
      });

    const supplementalSurveyIndicators = payload.surveyIndicators.filter(indicator => indicator.category === 'supplemental');
    const surveyIndicatorsByType = {};
    payload.surveyIndicators
      .filter(indicator => indicator.category !== 'supplemental')
      .forEach(indicator => {
        if (!surveyIndicatorsByType[indicator.type]) {
          surveyIndicatorsByType[indicator.type] = [];
        }
        surveyIndicatorsByType[indicator.type].push(indicator);
      });

    const previousReportExists = !!payload.previous;

    const createdAt = dayjs(payload.createdAt).format(dateFormat);
    let previousCreatedAt;
    if (previousReportExists && payload && payload.previous) {
      previousCreatedAt = payload.previous.createdAt ? dayjs(payload.previous.createdAt).format(dateFormat) : null;
    }
    // const professionQuestions = payload.professionQuestions;
    return (
      <div className="page page-report-results container">
        <div className="row d-print-block clearfix">
          {/* Main Content */}
          <main className="col-12 col-md-7 col-print-7">
            <div className="tfs-scores">
              <div className="current-tfs mb-4">
                <h2>{getContent('report_heading-current-tfs')}</h2>
                <p>{getContent('report_subhead-current-tfs')}</p>
                <div className={`tfs-bar col-12 col-md-10 mt-3  block block-${scoreMap[`${payload.overallRiskLevel}`].color}`}>
                  <label>{createdAt}</label>
                  <div className="value">{getContent(`risk_${payload.overallRiskLevel}`)}</div>
                </div>
              </div>
              {this.props.isWellnessCenterHome && isAdult ? (
                <Button
                  type="submit"
                  className="small-blue-button-outlined mb-3"
                  size="lg"
                  variant="success"
                  onClick={() =>
                    this.openConfirmationModal(() => {
                      window.open(`/survey?accessCode=${payload.participantSurveyAuthCode}`, '_blank');
                      this.props.closeModal();
                    })
                  }
                >
                  Retake Survey
                </Button>
              ) : null}
              {previousReportExists && payload.previous && payload.previous.overallRiskLevel ? (
                <div className="current-tfs">
                  <h2>{getContent('report_heading-previous-tfs')}</h2>
                  <div className={`tfs-bar col-12 col-md-10 mt-3  block block-${scoreMap[`${payload.previous.overallRiskLevel}`].color}`}>
                    {previousCreatedAt ? (
                      <>
                        <label>{previousCreatedAt}</label>
                        <div className="value">{getContent(`risk_${payload.previous.overallRiskLevel}`)}</div>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/* End Previous Score */}
            </div>
            {/* End TFS Scores  */}

            {/* Specific Indicator Results */}
            <h2 className="mb-2 mt-4">{getContent('report_heading-particular-indicator-scores')}</h2>
            <p className="mb-4">{getContent('report_content-particular-indicator-scores', replacements)}</p>

            {surveyIndicatorsByType['resilience'] && surveyIndicatorsByType['resilience'].length > 0 ? (
              <table className="table table-sm table-1-3 table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>{getContent('report_table-heading-resilience-indicator')}</th>

                    <th>{getContent('report_table-heading-current-category')}</th>
                    {previousReportExists ? <th>{getContent('report_table-heading-previous-category')}</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {surveyIndicatorsByType['resilience']
                    .sort((a, b) => {
                      return a.indicator > b.indicator ? 1 : -1;
                    })
                    .map((node, ni) => {
                      return (
                        <tr key={ni}>
                          <td>
                            <div className="d-flex flex-row align-items-center justify-space-between">{getContent(node.contentId || node.indicator)}</div>
                          </td>

                          <td>
                            <ScoreBlock
                              score={this.getIndicatorRiskLevel(payload.indicators, node.indicator)}
                              solid={true}
                              showLink={this.props.isParentReport ? true : false}
                              indicator={node.indicator}
                              isAdult={isAdult}
                            />
                          </td>
                          {this.getPreviousScore(payload.previous, node.indicator) ? (
                            <td>
                              <ScoreBlock score={this.getPreviousScore(payload.previous, node.indicator)} solid={false} showLink={false} />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : null}

            {surveyIndicatorsByType['risk'] && surveyIndicatorsByType['risk'].length > 0 ? (
              <table className="table table-sm table-1-3 table-bordered results mb-4">
                <thead>
                  <tr>
                    <th>{getContent('report_table-heading-risk-indicator')}</th>

                    <th>{getContent('report_table-heading-current-category')}</th>
                    {previousReportExists ? <th>{getContent('report_table-heading-previous-category')}</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {surveyIndicatorsByType['risk']
                    .sort((a, b) => {
                      return a.indicator > b.indicator ? 1 : -1;
                    })
                    .map((node, ni) => {
                      return (
                        <tr key={ni}>
                          <td>
                            <div className="d-flex flex-row align-items-center justify-space-between">{getContent(node.contentId || node.indicator)}</div>
                          </td>
                          <td>
                            <ScoreBlock
                              score={this.getIndicatorRiskLevel(payload.indicators, node.indicator)}
                              solid={true}
                              showLink={this.props.isParentReport ? true : false}
                              indicator={node.indicator}
                              isAdult={isAdult}
                            />
                          </td>
                          {this.getPreviousScore(payload.previous, node.indicator) ? (
                            <td>
                              <ScoreBlock score={this.getPreviousScore(payload.previous, node.indicator)} solid={false} showLink={false} />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : null}

            {/* Supplemental Indicator Results */}
            {supplementalSurveyIndicators && supplementalSurveyIndicators.length > 0 ? (
              <>
                <h2 className="mb-2 mt-4">{getContent('report_supplemental-screener-score')}</h2>
                <p className="mb-4">{getContent('report_supplemental-screener-score-intro', replacements)}</p>
                <table className="table table-sm table-1-3 table-bordered results results-supplimental mb-4">
                  <thead>
                    <tr>
                      <th>{getContent('report_heading-supplemental-indicators')}</th>

                      <th>{getContent('report_table-heading-current-category')}</th>
                      {previousReportExists ? <th>{getContent('report_table-heading-previous-category')}</th> : null}
                    </tr>
                    {supplementalSurveyIndicators
                      .sort((a, b) => {
                        return a.indicator > b.indicator ? 1 : 1;
                      })
                      .map((node, ni) => {
                        return (
                          <tr key={ni}>
                            <td>
                              <div className="d-flex flex-row align-items-center justify-space-between">{getContent(node.contentId || node.indicator)}</div>
                            </td>

                            <td>
                              <ScoreBlock
                                score={this.getIndicatorRiskLevel(payload.indicators, node.indicator)}
                                solid={true}
                                showLink={this.props.isParentReport ? true : false}
                                indicator={node.indicator}
                                isAdult={isAdult}
                              />
                            </td>
                            {this.getPreviousScore(payload.previous, node.indicator) ? (
                              <td>
                                <ScoreBlock score={this.getPreviousScore(payload.previous, node.indicator)} solid={false} showLink={false} />
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                  </thead>
                </table>
              </>
            ) : null}

            {/* Self Harm Endorsed? */}
            {payload.selfHarmFlag ? (
              <h2 className="mb-1 mt-3">
                {getContent('report_table-heading-at-rist-self-harm')}
                <div className="block d-inline-block block-red ml-4 px-4 py-2">{getContent('response_yes')}</div>
              </h2>
            ) : null}

            {isParentReport ? null : (
              <CheckBox
                className="show-not-concerning-responses"
                labelClasses={['show-not-concerning-responses']}
                label="Show not concerning responses"
                name="Show not concerning responses"
                value="Show not concerning responses"
                isChecked={this.props.isShowingNotConcerningResponses}
                onClick={this.handleIsShowingNotConcerningResponses}
              />
            )}
          </main>

          {/* Side Content */}
          <aside className="col-12 col-md-4 col-print-4 offset-print-1 offset-md-1">
            {tooFast ? (
              <div className={`mb-4 p-3 block text-center ${tooFast ? 'block-outline-red' : 'block-outline-grey'}`}>
                <div className="mb-2 font-size-sm">
                  Time Spent: <strong>{this.timeSpan(payload.surveyStartedAt, payload.surveySubmittedAt)}</strong>
                </div>
                {payload.avgSurveyTime ? (
                  <div className="font-size-xs">
                    Avg Time: <strong>{this.timeSpan(0, payload.avgSurveyTime)}</strong>
                  </div>
                ) : null}
              </div>
            ) : null}

            <h2 className="mb-2">{getContent('report_heading-interpreting-categories')}</h2>
            <p className="mb-4">{getContent('report_content-interpreting-categories')}</p>
            <div className="p-3 block block-green mb-3">
              <h3>{getContent('report_heading-optimal')}</h3>
              <p>{getContent('report_content-optimal-description-1')}</p>
              <p>{isAdult ? getContent('report_content-optimal-description-2-adult') : getContent('report_content-optimal-description-2')}</p>
            </div>

            <div className="p-3 block block-light-green mb-3">
              <h3>{getContent('report_heading-satisfactory')}</h3>
              <p>{getContent('report_content-satisfactory-description-1')}</p>
              <p>{getContent('report_content-satisfactory-description-2')}</p>
            </div>
            <div className="p-3 block block-yellow mb-3">
              <h3>{getContent('report_heading-moderate-concern')}</h3>
              <p>{getContent('report_content-moderate-concern-description-1')}</p>
              <p>{isAdult ? getContent('report_content-moderate-concern-description-2-adult') : getContent('report_content-moderate-concern-description-2')}</p>
            </div>
            <div className="p-3 block block-red mb-3">
              <h3>{getContent('report_heading-at-risk')}</h3>
              <p>{getContent('report_content-at-risk-description-1')}</p>
              <p>{getContent('report_content-at-risk-description-2')}</p>
            </div>
          </aside>
          {this.props.isParentReport && !this.props.isWellnessCenterHome ? (
            <div className="page-footer mt-3 col-12">
              <h2 className="mb-2">{!payload.surveyName.includes('Adults') ? getContent('report_heading-for-more-information') : getContent('report_heading-for-more-information-adult')}</h2>
              {isAdult ? <p>{getContent('report_content-for-more-information-adult', replacements)}</p> : <p>{getContent('report_content-for-more-information', replacements)}</p>}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isShowingNotConcerningResponses: state.reports.isShowingNotConcerningResponses,
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(actions.closeModal()),
  openModal: modalProps => dispatch(actions.openModal(modalProps)),
  toggleShowNotConcerningResponses: () => dispatch(actions.toggleShowNotConcerningResponses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TFSReport));
