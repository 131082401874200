import React, { useRef, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
// import Footer from './Footer/Footer';
import Header from './Header/Header';
import Spinner from '../../components/Spinner/Spinner';
import * as actions from '../../store/actions/';
import Toaster from '../../components/Toaster/Toaster';

import { NavbarVerical } from '../NavbarVerical/NavbarVerical';
import NavbarMobile from '../NavbarVerical/NavbarMobile';
import classNames from 'classnames';
import { Alert } from 'reactstrap';

const withLayout = (WrappedComponent, { ...props }) => {
  const defaultOptions = { showLogo: false, showContact: false, showUser: false, showLang: false, requireOrg: false, requireOrgExceptTM: false };
  const { showLogo, showUser, showLang, requireOrg, requireOrgExceptTM } = { ...defaultOptions, ...props };
  const Layout = props => {
    const activeOrg = props.navigation.org;
    const orgIsLoading = props.navigation.orgIsLoading;
    const isReady = requireOrg ? activeOrg !== null : requireOrgExceptTM ? activeOrg !== null || props.role === 'tm_admin' : true;
    const idleTimerRef = useRef(null);

    useEffect(() => {
      if (idleTimerRef.current) {
        idleTimerRef.current.reset();
      }
    }, [props]);

    const constredirectToLogin = () => {
      props.history.push('/login');
    };
    const { innerWidth: width } = window;
    const isMobileScreen = width < 900;
    // const [isCanvasOpen, setIsCanvasOpen] = useState(isMobileScreen ? false:true);
    const [isCanvasOpen, setIsCanvasOpen] = useState(true);

    const showGetStarted = useSelector(state => state.meta.showGetStarted);

    const toggleOffcanvas = () => {
      setIsCanvasOpen(!isCanvasOpen);
    };
    const InfoSelectDistrict = () => {
      return (
        <Alert className="alert" variant="info">
        <div className="alertHeader">To access this page, please <u>select an organization</u> first in the selector above.</div>
      </Alert>

      )
    };
    return (
      <>
        <IdleTimer ref={idleTimerRef} element={document} onIdle={constredirectToLogin} debounce={250} timeout={1000 * 60 * 15} />
        <Toaster />
        <div className={classNames('tm-layout', { 'opacity-50': showGetStarted })} data-role={props.realRole ? props.realRole : props.role}>
          <div className="tm-layout__flex">
            <div className={isCanvasOpen ? 'container-main' : 'container-main--expanded'}>
              {props.role && <Header showLogo={showLogo} showUser={showUser} showLang={showLang} />}
              <div className="main-page-center">
                <div>
                  {props.role && !isMobileScreen && (
                    <NavbarVerical
                      showLang={showLang}
                      role={props.role}
                      onClick={toggleOffcanvas}
                      isOpen={isCanvasOpen}
                      activeOrgIsSchool={props.navigation.org && props.navigation.org.classification === 'school'}
                    />
                  )}
                  {props.role && isMobileScreen && (
                    <NavbarMobile
                      showLang={showLang}
                      role={props.role}
                      activeOrgIsSchool={props.navigation.org && props.navigation.org.classification === 'school'}
                      // onClick={toggleOffcanvas}
                      // isOpen={isCanvasOpen}
                    />
                  )}
                </div>
                <div className="main-page-center__nav-buttons">{orgIsLoading ? <Spinner /> : isReady ? <WrappedComponent {...props} /> : <InfoSelectDistrict/> }</div>
              </div>
              {/* {props.role && <Footer showContact={showContact} />} */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const mapStateToProps = state => {
    return {
      navigation: state.navigation,
      role: state.auth.role,
      realRole: state.auth.realRole,
      lang: state.meta.lang,
      org: state.organizations,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      logout: cb => dispatch(actions.logout(cb)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Layout);
};

export default withLayout;
