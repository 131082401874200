import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import RenderField from '../../../../components/RenderField/RenderField';
import RenderPhoneField from '../../../../components/RenderPhoneField/RenderPhoneField';
// import displayPhoneNumber from '../../../../utilities/displayPhoneNumber';
import { confirmPasswordsMatch, required, isValidEmail } from '../../../../utilities/validation';
import PasswordConfirmation from './PasswordConfirmation';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';

const AddUserForm = (props) => {
  const [isDesignatedChecked, setIsDesignatedChecked] = useState(props.initialValues.isDesignated)
  const [isActivatedChecked, setIsActivatedChecked] = useState(props.initialValues.isActivated)
  const updating = useSelector(state => state.users.isLoading);
  const isUK = () => (process.env.REACT_APP_STAGE === 'uk');

  const toggleDesignated = () => {
    setIsDesignatedChecked(!isDesignatedChecked)
  };
  const toggleActivated = () => {
    setIsActivatedChecked(!isActivatedChecked)
  };

  // const userChangeHandler = (value) => {
  //   const formName = 'add-user-form';
  //   const item = value[0];
  //   if (!item) return;
  //   props.dispatch(change(formName), 'searchUser', value);
  //   props.dispatch(change(formName, 'firstName', item.firstName || item.given_name || ''));
  //   props.dispatch(change(formName, 'lastName', item.lastName || item.family_name || ''));
  //   props.dispatch(change(formName, 'phoneNumber', displayPhoneNumber(item.phoneNumber) || ''));
  //   props.dispatch(change(formName, 'email', item.email || ''));
  // }

  const [formFields, setFormFields] = useState({
    firstName: null,
    lastName: null,
    email: null,
  })


  const handleChange = (fieldName, e) => {
    setFormFields(prevState => ({
      ...prevState,
      [fieldName]: e.target.value
    }));
  };

  const isEditable = !props.item || (props.item && !props.item.cleverData);
  const { invalid, pristine, submitting, isCleverOrg, isEditMode } = props;
  const disableForCleverOrgs = isCleverOrg;
  return (
    <form onSubmit={props.handleSubmit}>
      {/* {
          isCleverOrg ? <div className="label-field-container">
            <label className="label" htmlFor="searchUser">Search Users</label>
            <Field
              name="searchUser"
              component={UsersTypeahead}
              handleSearch={(query, setOptions, setIsLoading) => handleUserSearch(query, setOptions, setIsLoading, orgSearchId, props.idToken)}
              orgSearch={orgSearchId}
              onChange={userChangeHandler}
              disabled={props.item}
            />
          </div> : null} */}
      <div className='mb-2 w-100' >
        <label className="label" htmlFor="firstName">{props.labelText} First Name</label>
        <Field
          name="firstName"
          placeholder="First Name"
          type="text"
          component={RenderField}
          className={classNames("participant-entry-field w-50", isEditMode == false && (formFields.firstName ? "participant-entry-field--filed" : "participant-entry-field--required"))}
          validate={isEditable ? [required] : null}
          disabled={!isEditable}
          required
          onChange={(e) => handleChange("firstName", e)}
        />
      </div>
      <div className='mb-2 w-100'>
        <label className="label" htmlFor="lastName">{props.labelText} Last Name</label>
        <Field
          name="lastName"
          placeholder="Last Name"
          type="text"
          component={RenderField}
          className={classNames("participant-entry-field w-50", isEditMode == false && (formFields.lastName ? "participant-entry-field--filed" : "participant-entry-field--required"))}
          validate={isEditable ? [required] : null}
          disabled={!isEditable}
          required
          onChange={(e) => handleChange("lastName", e)}
        />
      </div>
      <div className='mb-2 w-100'>
        <label className="label" htmlFor="email">{props.labelText} Email</label>
        <Field
          name="email"
          placeholder="Email Address"
          type="text"
          component={RenderField}
          className={classNames("participant-entry-field w-50", isEditMode == false && (formFields.email ? "participant-entry-field--filed" : "participant-entry-field--required"))}
          validate={isEditable ? [required, isValidEmail] : null}
          disabled={!isEditable}
          required
          onChange={(e) => handleChange("email", e)}
        />
      </div>
      <div className='mb-2 w-100'>
        <label className="label" htmlFor="phoneNumber">{props.labelText} Phone (Optional)</label>
        <div>
          <Field
            name="phoneNumber"
            type="tel"
            component={RenderPhoneField}
            className={"participant-entry-field w-50"}
            disabled={!isEditable}
          />
        </div>
      </div>
      {props.showDesignatedToggle ?
        <div className="mt-1">
          <label>
            <Field
              name="isDesignated"
              type='checkbox'
              component="input"
              onChange={toggleDesignated}
              disabled={!isEditable}
              value={isDesignatedChecked}
            />
            Designated {isUK() ? 'Group' : 'Organization'} Champion
          </label>
        </div>
        : null}
      {props.isCleverOrg && props.item && typeof props.item.isActivated === 'boolean' ?
        <div className="mt-1">
          <label>
            <Field
              name="isActivated"
              type='checkbox'
              component="input"
              onChange={toggleActivated}
              disabled={!disableForCleverOrgs}
              value={isActivatedChecked}
            />
            User Activated
          </label>
        </div>
        : null}
      {props.isUserProfile && !(props.isCleverOrg && props.item && typeof props.item.isActivated === 'boolean') ?
        <PasswordConfirmation />
        : null}
      <div className='btnContainer mt-5' style={{marginLeft: "auto"}}>
        <Button
          type="submit"
          className='small-button'
          size="lg"
          color="success"
          disabled={invalid || pristine || submitting || updating }
        >{updating && <Spinner/>}
          {props.saveButtonText}
        </Button>
        <Button
          onClick={(e) => props.closeModal(e)}
          className='small-blue-button-outlined'
          size="lg"
          variant="outline-primary"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'add-user-form',
  validate: confirmPasswordsMatch,
})(AddUserForm);
