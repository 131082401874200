import React from 'react';
import RadioButton from '../RadioButton/RadioButton';
import { getContent, getAudio } from '../../utilities/getContent/getContent'

const RadioGroup = ({
  onKeyDown,
  options,
  selectedIndex,
  radioGroupHandler,
  showAudioButton
}) => {

  return (
    <div className='radioGroup' onKeyDown={onKeyDown}>
      {
        options.map((option, i) => {
          return (
            <RadioButton
              key={i}
              isChecked={selectedIndex === i}
              text={getContent(option.field)}
              field={option.field}
              value={option.value}
              index={i}
              handler={() => radioGroupHandler(i, option.value)}
              tabIndex={0}
              audio={showAudioButton ? getAudio(option.field) : null}
            />
          );
        })
      }
    </div>
  )
}

export default RadioGroup;
