import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import modalConductor from './modalConductor';
import auth from './auth';
import studentAuth from './studentAuth';
import survey from './survey';
import surveyCrud from './surveyCrud';
import reports from './reports';
import overallSurveyReport from './overallSurveyReport';
import users from './users';
import organizations from './organizations';
import toaster from './toaster';
import meta from './meta';
import media from './media';
import wellnessCenterAuth from './wellnessCenterauth';
import navigation from './navigation';

export default combineReducers({
  form: reduxFormReducer,
  auth,
  studentAuth,
  survey,
  modalConductor,
  surveyCrud,
  reports,
  overallSurveyReport,
  users,
  organizations,
  toaster,
  meta,
  media,
  wellnessCenterAuth,
  navigation,
});
