import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../../../store/actions';
import CheckBox from '../../../../../components/CheckBox/CheckBox';

class WellnessCenterCheck extends Component {
  state = {
    isChecked: false,
  };

  toggleDesignated = () => {
    this.setState({isChecked: !this.state.isChecked})
    this.props.setWellnessCenterCheck(!this.state.isChecked);
  };
	render() {
		return (
			<>
				<div className="file-input-create-survey-step-group">
        <CheckBox
        labelClasses={['indicator-headers']}
        label={`Redirect participants to results. `}
        name={'wellnessCenterCheck'}
        value={this.state.isChecked}
        isChecked={this.state.isChecked ? 'checked' : undefined}
        onClick={() => this.toggleDesignated()}
        description={"If checked, participants will have the option to view the results immediately after taking the assessment."}
      />
				</div>
			</>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return {
    setWellnessCenterCheck: (value) => dispatch(actions.setWellnessCenterCheck(value))
  };
};

export default withRouter(
	connect(
    null,
		mapDispatchToProps
	)(WellnessCenterCheck)
);
