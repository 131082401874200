import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconAvatar } from '../../../../assets/imgs/icon-avatar.svg';
import AddResponderForm from './AddResponderForm';
import sanitizePhoneNumber from '../../../../utilities/sanitizePhoneNumber';
import displayPhoneNumber from '../../../../utilities/displayPhoneNumber';

class AddResponderModal extends Component {
  isUK = ()=>( process.env.REACT_APP_STAGE === 'uk');

  handleAddNewUser = (values) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
      role: 'responder',
    };

    console.log('user:', user);

    if (this.props.isUserProfile && values.password && values.password.length > 0) {
      user.password = values.password;
    }

    if (this.props.user) {
      this.props.addUser(this.props.user.username, user, this.props.isUserProfile);
    } else {
      this.props.addUser(user);
    }
  };

  render() {
    const initialValues = {};
    if (this.props.user) {
      initialValues.firstName = this.props.user.firstName;
      initialValues.lastName = this.props.user.lastName;
      initialValues.email = this.props.user.email;
      if (this.props.user.phoneNumber) {
        initialValues.phoneNumber = displayPhoneNumber(this.props.user.phoneNumber);
      }
    }

    let createButtonText = 'Add Responder';
    let titleText = this.props.user ? 'Edit Responder' : 'Add New Responder';
    let labelText = 'Responder';

    return (
      <ModalWrapper show={this.props.show} resetModalErrorsOnClose={true}>
        <ModalHeader toggle={this.props.closeModal}>
            <IconAvatar style={{ marginRight: '1rem' }} />
            {titleText}
        </ModalHeader>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <ModalBody bsPrefix="modalContent">
          <AddResponderForm
            onSubmit={this.handleAddNewUser}
            initialValues={initialValues}
            labelText={labelText}
            saveButtonText={this.props.user ? 'Save Edits' : createButtonText}
            closeModal={this.props.closeModal}
            isUserProfile={this.props.isUserProfile}
          />
        </ModalBody>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.users.error,
  };
};

export default connect(mapStateToProps)(AddResponderModal);
