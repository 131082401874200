import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import TMButton from '../../../components/TMButton/TMButton';
import AudioButton from '../../../components/Button/ButtonAudio';
import * as QuizButtonGroup from '../QuizButtonGroup/QuizButtonGroup';
import * as surveyActions from '../../../store/actions/survey/survey';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';

class SurveyQuestion extends Component {
  constructor(props) {
    super(props);
    this.questionRef = React.createRef();
    this.nextPressed = false;
  }

  handleSelectedAnswer = (selected, value) => {
    // TODO: Fix when going to the next question, to unset the selected value.. It's not being cleared.
    // console.log('Something Answers', selected, value);
    const { responseOptionsIndex: indexOptionSelected, toggleSurveyAnswer } = this.props;

    toggleSurveyAnswer({
      isSelected: selected,
      answerValue: value,
      indexOptionSelected,
    });
  };

  moveToNextQuestion = () => {
    const opacityClass = 'opacity-hidden';
    let curEl = this.questionRef.current;
    curEl.classList.add(opacityClass);
    this.props.onIncrementIndex();
    //delay removing the class/animating in new question
    setTimeout(() => {
      curEl.classList.remove(opacityClass);
    }, 100);
  };

  parseQuestion = question => {
    const errorString = `SurveyQuestion missing response.questionText: ${JSON.stringify(question)}`;
    try {
      if (typeof this.props.currentQuestion.questionText !== 'string') {
        throw Error(errorString);
      }
      return getContent(this.props.currentQuestion.questionText, null, this.props.receivedSurveyData.appliedTo);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      return 'Next to each statement, please select the answer that most accurately describes how much you agree with that statement.';
    }
  };
  // test with code GMIG-PYSI-IRMF-IOND
  render() {
    const answers = this.props.receivedSurveyData.responseOptionsIndex[this.props.currentQuestion.questionResponseOptionsType];
    console.log(`HERE: ${this.props.currentQuestion.questionId}, ${this.props.currentQuestion.questionResponseOptionsType}`);
    const isFatEmoji = answers.length > 0 && answers[0].hasOwnProperty('emoji') && answers.length <= 4;
    return (
      <>
        {!this.props.currentQuestion || !this.props.currentQuestion.questionResponseOptionsType || this.props.currentQuestion.questionResponseOptionsType === 'no-response-options' ? (
          <div className="container-single-question justifyCenter" ref={this.questionRef}>
            <KeyboardEventHandler
              handleKeys={['enter']}
              onKeyEvent={() => {
                if (this.nextPressed === false) {
                  this.nextPressed = true;
                  setTimeout(() => {
                    this.moveToNextQuestion();
                    this.nextPressed = false;
                  }, 125);
                } else {
                  console.log('oof');
                }
              }}
            />
            <div className="heading centerText">
              <p className="head" id="locator_question-no-response-options">
                {this.props.currentQuestion && this.props.currentQuestion.questionText ? this.parseQuestion(this.props.currentQuestion.questionText) : ''}
              </p>

              {this.props.currentQuestion ? (
                <AudioButton className="my-3 mx-auto" label={getContent('survey_button-read-question')} playlist={[getAudio(this.props.currentQuestion.questionText)]} />
              ) : (
                <></>
              )}
            </div>

            <TMButton
              className="btn-lg btn-round btn-green w-300"
              disabled={false}
              onClick={this.moveToNextQuestion}
              iconRight={this.props.currentQuestion ? this.props.currentQuestion.iconRight : ''}
              locatorId="locator_next-button"
            >
              {getContent('survey_button-next-question')}
            </TMButton>
          </div>
        ) : (
          <div className="container-single-question" ref={this.questionRef}>
            <KeyboardEventHandler
              handleKeys={['enter']}
              onKeyEvent={() => {
                if (this.props.isAnswerSelected) {
                  this.moveToNextQuestion();
                }
              }}
            />
            <div className="heading">
              <p className="subhead">{getContent('survey_subhead-please-answer-the-following')}</p>
              <p className="head" id="locator_question-response-options">
                {this.parseQuestion(this.props.currentQuestion.questionText)}
              </p>
              {this.props.currentQuestion && this.props.currentQuestion.questionText ? (
                <AudioButton className="mt-2" label={getContent('survey_button-read-question')} playlist={[getAudio(this.props.currentQuestion.questionText)]} />
              ) : (
                ''
              )}
            </div>
            <div className="answerContainer">
              {isFatEmoji ? (
                <QuizButtonGroup.Fat
                  answers={answers}
                  answerSelected={this.handleSelectedAnswer}
                  responseType={this.props.currentQuestion.questionResponseOptionsType}
                  isSelected={this.props.isAnswerSelected}
                  questionType={this.props.indicatorData.type}
                  indicatorName={this.props.indicatorData.indicator}
                  elementary={this.props.receivedSurveyData.appliedTo === 'elementary'}
                  questionId={this.props.currentQuestion.questionId}
                  isFatEmoji={isFatEmoji}
                />
              ) : (
                <QuizButtonGroup.Skinny
                  answers={answers}
                  answerSelected={this.handleSelectedAnswer}
                  responseType={this.props.currentQuestion.questionResponseOptionsType}
                  isSelected={this.props.isAnswerSelected}
                  questionType={this.props.indicatorData.type}
                  indicatorName={this.props.indicatorData.indicator}
                  elementary={this.props.receivedSurveyData.appliedTo === 'elementary'}
                  questionId={this.props.currentQuestion.questionId}
                />
              )}
            </div>
            <TMButton
              className="btn-lg btn-round btn-green w-300"
              disabled={!this.props.isAnswerSelected}
              onClick={this.moveToNextQuestion}
              iconRight={this.props.currentQuestion.iconRight}
              locatorId="locator_next-button"
            >
              {getContent('survey_button-next-question')}
            </TMButton>
          </div>
        )}
      </>
    );
  }
}

SurveyQuestion.propTypes = {
  isAnswerSelected: PropTypes.bool,
  responseIndex: PropTypes.number,
  responseOptionsIndex: PropTypes.number,
  indicatorData: PropTypes.object,
  answerValue: PropTypes.number,
  onIncrementIndex: PropTypes.func,
  surveyActions: PropTypes.object,
  toggleSurveyAnswer: PropTypes.func,
  incrementSurveyIndicatorIndex: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    isAnswerSelected: state.survey.isAnswerSelected,
    answerValue: state.survey.answerValue,
    responseIndex: state.survey.indicatorResponseIndex,
    responseOptionsIndex: state.survey.indicatorResponseOptionsIndex,
    receivedSurveyData: state.survey.receivedSurveyData,
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSurveyAnswer: bindActionCreators(surveyActions.toggleSurveyAnswer, dispatch),
    incrementSurveyIndicatorIndex: () => dispatch(surveyActions.incrementSurveyIndicatorIndex()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyQuestion));
