import React from 'react';
import { Field } from 'redux-form';
import RenderField from '../../../../components/RenderField/RenderField';
import { isValidPassword } from '../../../../utilities/validation';

const PasswordConfirmation = () => {
  return (
    <div>
      <label htmlFor="password" className="label">Password</label>
      <Field
        name="password"
        placeholder="Password"
        type="password"
        component={RenderField}
        className="modalInput"
        validate={[isValidPassword]}
      />
      <Field
        name="confirmPassword"
        placeholder="Confirm Password"
        type="password"
        component={RenderField}
        className="modalInput"
      />
      <p><strong>Password Strength:</strong> Use at least 8 characters.  Don't use a password from another site or something too obvious like your pet's name.</p>
    </div>
  );
};

export default PasswordConfirmation;
