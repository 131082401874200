import React from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';

// import DropdownList from 'react-widgets/lib/DropdownList';
// import "react-widgets/dist/css/react-widgets.css";
import { getContent } from '../../../../../utilities/getContent/getContent';

import * as actions from '../../../../../store/actions';
import { Label } from 'reactstrap';

const SurveyDateSelect = (props) => {


  var yearOptions = [];
  const appliedTo = props.appliedTo;
  const currentYear = new Date().getFullYear();

  for (let index = 0; index < 10; index++) {
    yearOptions.push({ label: `${currentYear + index}`, value: currentYear + index });
  }

  const termOptions = {
    'en-US': [
      { label: 'Fall', value: 'Fall' },
      { label: 'Winter', value: 'Winter' },
      { label: 'Spring', value: 'Spring' },
      { label: 'Summer', value: 'Summer' },
      { label: 'First', value: 'First' },
      { label: 'Second', value: 'Second' },
      { label: 'Third', value: 'Third' },
      { label: 'Fourth', value: 'Fourth' },
    ],
    'en-UK': [
      { label: 'Winter', value: 'Winter' },
      { label: 'Spring', value: 'Spring' },
      { label: 'Summer', value: 'Summer' },
      { label: 'Autumn', value: 'Autumn' }
    ],
    'en-UK-adult': [
      { label: 'January', value: 'January' },
      { label: 'February', value: 'February' },
      { label: 'March', value: 'March' },
      { label: 'April', value: 'April' },
      { label: 'May', value: 'May' },
      { label: 'June', value: 'June' },
      { label: 'July', value: 'July' },
      { label: 'August', value: 'August' },
      { label: 'September', value: 'September' },
      { label: 'October', value: 'October' },
      { label: 'November', value: 'November' }
    ]
  };
  const periodOptions = termOptions[`${props.lang}${appliedTo === 'adult' && props.lang === 'en-UK' ? '-adult' : ''}`]

  return (
    <div className="form-container-create-survey">
      <div className="dropdown-container">
        <Label>{getContent('admin_label-term')}</Label>
        <Select
          id='locator_select-date-period'
          options={periodOptions}
          className="tm-dropdown"
          onChange={props.setSurveyQuarter}
          placeholder="Please select..."
          value={props.quarter}
        />
      </div>
      <div className="dropdown-container">
        <Label>Year</Label>
        <Select
          options={yearOptions}
          className="tm-dropdown"
          onChange={props.setSurveyYear}
          placeholder="Please select..."
          value={props.year}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    quarter: state.surveyCrud.createSurveyObj.quarter,
    year: state.surveyCrud.createSurveyObj.year,
    lang: state.meta.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSurveyQuarter: (value) => dispatch(actions.setSurveyQuarter(value)),
    setSurveyYear: (value) => dispatch(actions.setSurveyYear(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDateSelect);
