import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Button } from 'reactstrap';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import CheckedImage from '../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../store/actions';

class AssignResponderModal extends Component {
	state = {
		organizations: [],
		searchedOrganizations: [],
		search: ''
	};
	componentWillMount() {
	const initialOrganizations = this.props.districts.map((organization, index) => {
      organization.checked = organization.responderIds && organization.responderIds.includes(this.props.responderId) ? true : false;
      organization.changed = false;
			organization.position = index;
			return organization;
    });

		this.setState({ organizations: initialOrganizations });
  }
 

	handleSubmit = () => {
		const organizationIds = this.state.organizations
			.filter(organization => organization.changed === true)
      .map(organization => organization.orgId);
      this.props.assignResponder(organizationIds,this.props.responderId);
	};

	searchForNames = e => {
		console.log(e.target.value);
		console.log(this.state.organizations);
		const searchedOrganizations = this.props.districts.filter(organization =>
			organization.name.toLowerCase().includes(e.target.value.toLowerCase())
		);

		this.setState({ searchedOrganizations, search: e.target.value });
	};

	isUK = () => process.env.REACT_APP_STAGE === 'uk';

	handleClick = index => {
		const getClickedOrganization = () => {
			if (this.state.search !== '') {
				const organizations = this.state.organizations.map(organization => {
					if (organization.position === this.state.searchedOrganizations[index].position) {
            organization.checked = !organization.checked;
            organization.changed = !organization.changed;
					}
					return organization;
        });
				this.setState({ organizations });
			} else {
				const organizations = this.state.organizations.map(organization => {
					if (organization.position === index) {
            organization.checked = !organization.checked;
            organization.changed = !organization.changed;
					}
					return organization;
        });
				this.setState({ organizations });
			}
		};
		getClickedOrganization();
	};

	generateTableData = () => {
		const result = this.state.organizations.map(organization => ({
      name: `${organization.name}`,
      providerEmployer: organization.employerProvider,
			selected: (
				<img
					className="checkbox"
					style={{ cursor: 'pointer' }}
					alt="Checkbox"
					src={organization.checked ? CheckedImage : UncheckedImage}
				/>
			)
		}));
		if (this.state.search !== '')
			return this.state.searchedOrganizations.map(organization => ({
				name: `${organization.name}`,

				selected: (
					<img
						className="checkbox"
						alt="Checkbox"
						src={organization.checked ? CheckedImage : UncheckedImage}
					/>
				)
			}));
		return result;
	};

	render() {
		return (
			<ModalWrapper
				className="survey-status-modal-wrapper"
				show={this.props.show}
				onExited={this.props.closeModal}
			>
				<ModalHeader closeButton>
            Assign Responder
				</ModalHeader>
				<ModalBody bsPrefix="modalContent survey-status-modal">
					<label className="label">Search</label>
					<input
						className="form-control"
						style={{ backgroundColor: '#eaeaea' }}
						type="text"
						onChange={this.searchForNames}
						value={this.state.search}
					/>
					<div className="modal-table-container">
						<TableGenerator
							config={{
								headers: [`${this.isUK()? 'Group' : 'District'} Name`, 'Provider/Employer' ,''],
								contentRows: this.generateTableData(),
								rowFunction: this.handleClick
							}}
						/>
					</div>

					<div className="btnContainer-edit-participant">
						<Button
							onClick={() => this.handleSubmit()}
							type="submit"
							className="modalBtn"
							size="lg"
							color="success"
						>
							Assign Responder
						</Button>
						<Button
							onClick={this.props.closeModal}
							className="modalBtn"
							size="lg"
							variant="outline-primary"
						>
							Cancel
						</Button>
					</div>
				</ModalBody>
			</ModalWrapper>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return {
    closeModal: () => dispatch(actions.closeModal()),
    assignResponder: (orgIds,responderId)=> dispatch(actions.assignResponder(orgIds,responderId)),
	};
};

export default connect(
	null,
	mapDispatchToProps
)(AssignResponderModal);
