import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import SchoolChampionFields from './SchoolChampionFields';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import RenderDropdown from '../../../../../components/RenderDropdown/RenderDropdown';
import { required } from '../../../../../utilities/validation';
import classNames from 'classnames';
import Spinner from '../../../../../components/Spinner/Spinner';

const CreateSchoolForm = ({ invalid, pristine, submitting, handleSubmit, item, isSchool, champions, addChampion, saveButtonText, initialValues, closeModal, removeChampion, isLoading, isNew }) => {
  const isUK = () => {
    return process.env.REACT_APP_STAGE === 'uk';
  };
  const isCleverItem = () => {
    return item && item?.cleverData;
  };
  const [formFields, setFormFields] = useState({
    address: initialValues.address,
    city: initialValues.city,
    name: initialValues.name,
    phoneNumber: initialValues.phoneNumber,
  });

  const handleChange = (fieldName, e) => {
    if (fieldName == 'phoneNumber') {
      setFormFields(prevState => ({
        ...prevState,
        [fieldName]: e,
      }));
    } else {
      setFormFields(prevState => ({
        ...prevState,
        [fieldName]: e.target.value,
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ margin: '16px' }}>
      <div className="mb-2">
        <div className="label-field-container flex-grow-1">
          <label className="label" htmlFor="name">
            {isUK() ? 'Organisation Name' : 'Group Name'}
          </label>
          <Field
            name="name"
            placeholder=""
            type="text"
            component="input"
            className={classNames('participant-entry-field', formFields.name ? 'participant-entry-field--filed' : 'participant-entry-field--required')}
            list="autocompleteOff"
            disabled={() => isCleverItem()}
            required
            validate={[required]}
            onChange={e => handleChange('name', e)}
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label className="label" htmlFor="address">
            {isUK() ? 'Organisation Address' : 'Group Address'}
          </label>
          <Field
            name="address"
            placeholder=""
            type="text"
            component="input"
            className={classNames('participant-entry-field', formFields.address ? 'participant-entry-field--filed' : 'participant-entry-field--required')}
            list="autocompleteOff"
            disabled={() => isCleverItem()}
            required
            validate={[required]}
            onChange={e => handleChange('address', e)}
          />
        </div>
        <div className="label-field-container flex-grow-1">
          <label className="label" htmlFor="city">
            {isUK() ? 'Organisation City' : 'Group City'}
          </label>
          <Field
            name="city"
            placeholder=""
            type="text"
            component="input"
            className={classNames('participant-entry-field', formFields.city ? 'participant-entry-field--filed' : 'participant-entry-field--required')}
            list="autocompleteOff"
            disabled={() => isCleverItem()}
            required
            validate={[required]}
            onChange={e => handleChange('city', e)}
          />
        </div>
      </div>
      <div className="d-flex align-items-end mb-2">
        <div className="label-field-container col-4 pl-0 pr-2">
          <label className="label" htmlFor="phoneNumber">
            {isUK() ? 'Organisation Phone' : 'Group Phone'}
          </label>
          <Field
            name="phoneNumber"
            type="tel"
            component={RenderPhoneField}
            className={classNames('participant-entry-field', formFields.phoneNumber ? 'participant-entry-field--filed' : 'participant-entry-field--required')}
            disabled={() => isCleverItem()}
            required
            validate={[required]}
            onChange={e => handleChange('phoneNumber', e)}
          />
        </div>
      </div>
      {isSchool ? (
        <div className="label-field-container col-4 pl-0 pr-2">
          <label className="label" htmlFor="classificationLevel">
            Type of school
          </label>
          <Field
            name="classificationLevel"
            type="text"
            placeholder=""
            component={RenderDropdown}
            className="tm-dropdown"
            data={[
              { label: 'Elementary School', value: 1 },
              { label: 'Middle School (or Jr High)', value: 2 },
              { label: 'High School', value: 3 },
            ]}
            textField="label"
            valueField="value"
            disabled={initialValues.hasOwnProperty('classificationLevel') && typeof initialValues.classificationLevel !== 'undefined'}
          />
        </div>
      ) : null}
      {isNew && (
        <>
          <div>
            {champions &&
              champions.map((item, index) => {
                return <SchoolChampionFields key={index} index={index} disabled={() => isCleverItem()} removeChampion={removeChampion} />;
              })}
            {addChampion ? (
              <button onClick={addChampion} className="btn px-0">
                <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: '0.5rem' }} color="#198753" />
                {isUK() ? 'Add Organisation Champion' : 'Add Group Champion'}
              </button>
            ) : null}
          </div>
          {!champions || champions.length === 0 ? (
            <div>
              <p>
                Note that if you create {isUK() ? 'an organisation' : 'a group'} without any {isUK() ? 'users' : 'group champions'}, no one will be able to create assessments for this{' '}
                {isUK() ? 'organisation' : 'group'} yet. (You can always add {isUK() ? 'users' : 'group champions'} later.)
              </p>
            </div>
          ) : null}
        </>
      )}

      <div className="btnContainer justify-content-end">
        <Button type="submit" className="small-button" size="lg" color="success" disabled={invalid || pristine || submitting}>
          {isLoading && <Spinner />} {saveButtonText}
        </Button>
        <Button className="small-blue-button-outlined" size="lg" variant="outline-primary" onClick={e => closeModal(e)}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'create-school-form',
})(CreateSchoolForm);
